const separatorList = {
  comma: {
    name: 'comma',
    regex: /^\d+(,\d{0,2})?$/
  },
  period: {
    name: 'period',
    regex: /^\d+(\.\d{0,2})?$/
  }
};

export default function isValidSeperator(value: any) {
  return separatorList['period'].regex.test(value);
}
