import api from './api';
import eventHandler from './eventHandler';
import TokenService from './tokenService';
const login = (username: string, password: string, tenantName: string | null) => {
  let isTenant = tenantName != null && tenantName.length > 0;
  let tenantRoute = `/authentication/tenant/${tenantName}`;
  let authenticationRoute = isTenant ? tenantRoute : '/authentication/system';

  return api
    .get(authenticationRoute, {
      auth: {
        username,
        password
      }
    })
    .then((response) => {
      let user = { ...response.data, ...{ realm: isTenant ? 'tenant' : 'system' } };
      if (user.token) {
        TokenService.setUser(user);
        if (isTenant) TokenService.setTenantName(tenantName!);
      }
      return user;
    });
};
const logout = () => {
  TokenService.removeUser();
  eventHandler.dispatch('logout');
};
const getCurrentUser = () => {
  return TokenService.getUser();
};
const AuthService = {
  login,
  logout,
  getCurrentUser
};
export default AuthService;
