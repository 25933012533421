import { Autocomplete, InputAdornment, MenuItem, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import customerService from '../../services/customerService';
import employeeService from '../../services/employeeService';
import Employee from '../../objects/employee';
import { Project } from '../../objects/project';
import Customer from '../../objects/customer';

interface CreateProjectProps {
  error: boolean;
  project: Partial<Project>;
  handleChange: Function;
}

export default function CreateProject(props: CreateProjectProps) {
  const { t } = useTranslation('projects');
  const [employees, setEmployees] = useState<Partial<Employee>[]>([]);
  const [customers, setCustomers] = useState<Partial<Customer>[]>([]);

  const [project, setProject] = useState<Partial<Project>>();

  useEffect(() => {
    props.handleChange(project);
  }, [project]);

  useEffect(() => {
    (async () => {
      await customerService.all().then((result) => setCustomers(result.rows));
      await employeeService.all().then((result) => setEmployees(result.rows));
    })();
  }, []);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: 10
      }}
      elevation={0}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          required
          error={props.error && !project?.name}
          label={t('name')}
          variant="outlined"
          value={project?.name ?? ''}
          onChange={(e) => setProject({ ...project, ...{ name: e.target.value } })}
          fullWidth
        />
      </div>
      <TextField
        fullWidth
        select
        required
        error={props.error && !project?.responsible?.id}
        label={t('responsible')}
        value={project?.responsible?.id ?? ''}
        onChange={(e) =>
          setProject({
            ...project,
            ...{
              responsible: employees.find((employee) => employee.id == e.target.value)
            }
          })
        }>
        {employees.map((option: any) => (
          <MenuItem key={option.id} value={option.id}>
            {option.firstName + ' ' + option.lastName}
          </MenuItem>
        ))}
      </TextField>
      <Autocomplete
        value={project?.customer ?? ''}
        onChange={(e: any, value: any) => {
          setProject({
            ...project,
            ...{ customer: value }
          });
        }}
        selectOnFocus
        handleHomeEndKeys
        autoHighlight
        options={customers}
        getOptionLabel={(option: any) => {
          return option.name ?? '';
        }}
        renderOption={(props, option: any) => (
          <MenuItem {...props} key={option.id}>
            {option.name}
          </MenuItem>
        )}
        freeSolo
        fullWidth
        renderInput={(params) => (
          <TextField
            required
            error={props.error && !project?.customer}
            {...params}
            label={t('customer')}
          />
        )}
      />
      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>
        }}
        type="number"
        onFocus={(event) => event.target.select()}
        style={{ display: 'flex', flexDirection: 'row' }}
        label={t('volume')}
        variant="outlined"
        value={project?.budget ?? 0}
        onChange={(e) => {
          setProject({
            ...project,
            ...{ budget: Number.parseInt(e.target.value).toString() }
          });
        }}
        fullWidth
      />
    </Paper>
  );
}
