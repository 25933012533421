import { defaultService } from './api';
import EquipmentBooking from '../objects/equipmentBooking';

const makeEquipmentBookingModel = (equipmentBooking: Partial<EquipmentBooking>) => {
  return {
    equipmentId: equipmentBooking.equipment?.id,
    warehouseId: equipmentBooking.warehouse?.id,
    amount: equipmentBooking.amount
  };
};

const route = 'equipment-bookings';
const get = async (): Promise<Partial<EquipmentBooking>> =>
  (await defaultService.getAll(route)).data;
const create = (equipmentBookings: Partial<EquipmentBooking>[]) =>
  defaultService.create(route, equipmentBookings.map(makeEquipmentBookingModel));

const equipmentBookingService = {
  get,
  create
};

export default equipmentBookingService;
