import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useIcons from '../hooks/useIcons';
import { useTranslation } from 'react-i18next';

const StyledListItem = styled(ListItem)<{ $active: boolean }>`
  &:hover {
    background: #141414;
  }

  ${({ $active }) => $active && `background: #141414; border-left: 5px solid #a71d31;`}
`;

const MyIcon = (data: any) => {
  const CustomIcon = useIcons(data.iconName);
  return <CustomIcon />;
};

export default function SystemNavLinks() {
  const { t } = useTranslation('navigation');
  let navigate = useNavigate();
  let modules = [
    { name: 'system/tenants', icon: 'Money' },
    { name: 'system/users', icon: 'Person' }
  ];

  const handleClick = (name: string) => {
    navigate('/' + name.toLowerCase());
  };

  const isActive = (name: string) => {
    return window.location.pathname.startsWith('/' + name.toLowerCase());
  };
  const getPath = (module: any) => {
    return module.path ?? module.name;
  };

  return (
    <List>
      {modules.map((i) => (
        <div key={i.name}>
          <StyledListItem $active={isActive(i.name)} onClick={() => handleClick(getPath(i))}>
            <ListItemIcon style={{ color: '#fff' }}>
              <MyIcon iconName={i.icon} />
            </ListItemIcon>
            <ListItemText primary={t(i.name)} />
          </StyledListItem>
        </div>
      ))}
    </List>
  );
}
