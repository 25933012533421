import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Employee from '../../objects/employee';
import Role from '../../objects/role';
import roleService from '../../services/roleService';

const ItemHeight = 48;
const ItemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
      width: 250
    }
  }
};

interface UpdateEmployeeProps {
  employee: Partial<Employee>;
  handleChange: Function;
  canUpdateUser: boolean;
  error: boolean;
}

export default function UpdateEmployee(props: UpdateEmployeeProps) {
  const { t } = useTranslation('employees');

  const [employee, setEmployee] = useState(props.employee);
  const [roles, setRoles] = useState<Partial<Role>[]>([]);
  // const [password, setPassword] = useState<string>('');

  useEffect(() => {
    props.handleChange(employee);
  }, [employee]);

  useEffect(() => {
    roleService.all().then((result) => setRoles(result.rows));
  }, []);

  // const userActive = employee.user?.active != null && employee.user.active;
  // const isNewUser =
  //   props.employee.user == undefined ||
  //   props.employee.user.login == undefined ||
  //   props.employee.user.login == '';

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && !employee.firstName}
            style={{ marginBottom: 10, marginRight: 10, width: '50%' }}
            label={t('firstName')}
            variant="outlined"
            value={employee.firstName ?? ''}
            onChange={(e) => setEmployee({ ...employee, firstName: e.target.value })}
          />
          <TextField
            required
            error={props.error && !employee?.lastName}
            style={{ marginBottom: 10, width: '50%' }}
            label={t('lastName')}
            variant="outlined"
            value={employee.lastName ?? ''}
            onChange={(e) => setEmployee({ ...employee, lastName: e.target.value })}
          />
        </div>
        <TextField
          style={{ marginBottom: 10 }}
          label={t('reference')}
          fullWidth
          variant="outlined"
          value={employee.reference ?? ''}
          onChange={(e) => setEmployee({ ...employee, reference: e.target.value })}
        />
        <TextField
          style={{ marginBottom: 10 }}
          label={t('email')}
          fullWidth
          variant="outlined"
          value={employee.email ?? ''}
          onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
        />
        <TextField
          fullWidth
          required
          error={props.error && !employee?.pricePerHour}
          style={{ marginBottom: 10 }}
          label={t('priceByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={employee?.pricePerHour?.toString()}
          onChange={(e) => {
            let number = Number(e.target.value);
            if (number > 0)
              setEmployee({
                ...employee,
                ...{ pricePerHour: number }
              });
          }}
        />
        <TextField
          fullWidth
          required
          error={props.error && !employee?.costPerHour}
          style={{ marginBottom: 10 }}
          label={t('costByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={employee?.costPerHour?.toString()}
          onChange={(e) => {
            let number = Number(e.target.value);
            if (number > 0)
              setEmployee({
                ...employee,
                ...{ costPerHour: number }
              });
          }}
        />
        <FormControl
          style={{ marginBottom: 10, flex: 1 }}
          fullWidth
          error={props.error && (!employee?.roles || employee.roles.length == 0)}
          required>
          <InputLabel>{t('roles')}</InputLabel>
          <Select
            multiple
            value={(employee.roles ?? []).map((role) => role.id)}
            onChange={(e) => {
              e.stopPropagation();
              setEmployee({
                ...employee,
                ...{
                  roles: (e.target.value as string[])
                    .map((id) => roles.find((role) => role.id == id)!)
                    .filter((role) => role != null)
                }
              });
            }}
            input={<OutlinedInput label={t('roles')} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value: any) => (
                  <Chip key={value} label={roles.find((role) => role.id == value)?.name} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}>
            {roles.map((role: any) => (
              <MenuItem key={role.id} value={role.id}>
                <p>{role.name}</p>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* {props.canUpdateUser && (
          <>
            <Divider style={{ marginTop: 10, marginBottom: 20 }} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox
                disableRipple
                checked={userActive}
                onChange={(e, value) => {
                  e.stopPropagation();
                  setEmployee({
                    ...employee,
                    ...{ user: { ...employee.user, ...{ active: value } } }
                  });
                }}
              />
              <TextField
                disabled={!userActive}
                style={{ marginBottom: 10 }}
                label={t('user.login')}
                error={userActive && props.error && !employee?.user?.login}
                fullWidth
                variant="outlined"
                value={employee.user?.login ?? ''}
                onChange={(e) => {
                  e.stopPropagation();
                  setEmployee({
                    ...employee,
                    ...{ user: { ...employee.user, ...{ login: e.target.value } } }
                  });
                }}
              />
            </div>

            <TextField
              disabled={!userActive}
              error={userActive && props.error && !employee?.user?.password}
              label={isNewUser ? t('user.password') : t('user.newPassword')}
              style={{ marginBottom: 10 }}
              fullWidth
              type="password"
              variant="outlined"
              // error={userActive && password != employee.user?.password}
              value={userActive ? password : ''}
              onChange={(e) => {
                e.stopPropagation();
                setPassword(e.target.value);
                setEmployee({
                  ...employee,
                  ...{ user: { ...employee.user, ...{ password: e.target.value } } }
                });
              }}
            />

            {/* <FormControlLabel
              disabled={!userActive}
              label={<Typography>{t('user.appOnlyDescriptive')}</Typography>}
              control={
                <Checkbox
                  disabled={!userActive}
                  disableRipple
                  checked={employee.user?.appUser ?? false}
                  onChange={(e, value) => {
                    e.stopPropagation();
                    setEmployee({
                      ...employee,
                      ...{ user: { ...employee.user, ...{ appUser: value } } }
                    });
                  }}
                />
              }
            />
          </>
        )} */}
      </div>
    </Paper>
  );
}
