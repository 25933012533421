import { Button, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import { useSnackbar } from 'notistack';
import PersonIcon from '@mui/icons-material/Person';
import employeeService from '../../services/employeeService';
import Employee from '../../objects/employee';

interface Props {
  employee: any;
}

export default function UpdateLogin(props: Props) {
  const { t } = useTranslation(['roles', 'itemDialog', 'administration', 'employees']);
  const { enqueueSnackbar } = useSnackbar();

  const [employee, setEmployee] = useState<any>();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);

    employeeService.all().then((result) => {
      let foundEmployee = result.rows.find((item: Employee) => item?.id == props.employee?.id);
      console.log(foundEmployee);
      setEmployee(foundEmployee);
    });
  };

  async function saveItem() {
    if (employeeService.validateEdit(employee)) {
      await employeeService
        .update(employee.id!, employee)
        .then(() => enqueueSnackbar(t('app:passwordChangeSuccessful'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));

      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  return (
    <>
      <Button style={{ margin: 5 }} onClick={handleClickOpen} startIcon={<PersonIcon />}>
        {t('user.changeLogin', { ns: 'employees' })}
      </Button>
      <CreateDialog
        noBackdrop
        isEdit
        title={t('changeLoginHeader', { ns: 'app' })}
        saveItem={saveItem}
        open={open}
        setOpen={setOpen}
        withActions>
        <Paper
          style={{
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          elevation={0}>
          <TextField
            style={{ marginBottom: 10 }}
            label={t('user.login', { ns: 'employees' })}
            error={error && employee?.user?.login}
            fullWidth
            variant="outlined"
            value={employee?.user?.login ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              setEmployee({
                ...employee,
                ...{ user: { ...employee?.user, ...{ login: e.target.value } } }
              });
            }}
          />
          <TextField
            error={error && !employee?.user?.password}
            label={t('user.newPassword', { ns: 'employees' })}
            style={{ marginBottom: 10 }}
            fullWidth
            type="password"
            variant="outlined"
            value={employee?.user?.password ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              setEmployee({
                ...employee,
                ...{ user: { ...employee?.user, ...{ password: e.target.value } } }
              });
            }}
          />
        </Paper>
      </CreateDialog>
    </>
  );
}
