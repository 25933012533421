import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store } from './redux/store';

// mui dateAdapter
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { de } from 'date-fns/locale';
import 'moment/locale/de';

// translation
import './i18n';

// roboto font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import LocalesProvider from './services/localesProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '06ae4ab943499383253c16365f64ef22T1JERVI6NDMyNzYsRVhQSVJZPTE2ODM1NDQ1OTMwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<p>Loading</p>}>
          <LocalesProvider>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={'de'}>
              <App />
            </LocalizationProvider>
          </LocalesProvider>
        </Suspense>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
