import { IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import projectService from '../../services/projectService';
import { ProjectEquipmentBooking, ProjectEquipmentReservation } from '../../objects/project';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import navigationService from '../../services/navigationService';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import Warehouse from '../../objects/warehouses';
import TokenService from '../../services/tokenService';
import { Check, AccessTime, Place } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import UpdateReservation from '../../components/projects/UpdateReservation';

interface ProjectEquipment {
  id: string;
  isReservation: boolean;
  from?: Date;
  to?: Date;
  amount: number;
  unit: string;
  name: string;
  reference: string;
  warehouse: Partial<Warehouse>;
  // supplier: Partial<Supplier>;
}

export default function ProjectEquipments() {
  //TODO: correct translation path!!!
  const { t } = useTranslation(['projects', 'articles', 'equipments']);
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const { enqueueSnackbar } = useSnackbar();

  // const [project, setProject] = useState<Partial<Project>>();
  const [bookedEquipments, setBookedEquipments] = useState<Partial<ProjectEquipmentBooking>[]>([]);
  const [reservedEquipments, setReservedEquipments] = useState<
    Partial<ProjectEquipmentReservation>[]
  >([]);
  const [equipments, setEquipments] = useState<ProjectEquipment[]>([]);

  const canUpdateBookings = TokenService.tenantFullfills([], ['Projects:Bookings:Update']);
  const canUpdateReservations = TokenService.tenantFullfills([], ['Projects:Reservations:Update']);
  const canViewBookings = TokenService.tenantFullfills([], ['Projects:Bookings:View']);
  const canViewReservations = TokenService.tenantFullfills([], ['Projects:Reservations:View']);
  const canViewWarehouses = TokenService.tenantFullfills([], ['Warehouses:View']);

  useEffect(() => {
    let bookedProjectEquipments = bookedEquipments.map((bookedEquipment) => {
      return {
        id: bookedEquipment.id,
        amount: bookedEquipment.amount,
        unit: bookedEquipment.equipment?.unit,
        name: bookedEquipment.equipment?.name,
        reference: bookedEquipment.equipment?.reference,
        warehouse: bookedEquipment.warehouse
      } as ProjectEquipment;
    });
    let reservedProjectEquipments = reservedEquipments.map((reservedEquipment) => {
      return {
        id: reservedEquipment.id,
        isReservation: true,
        from: reservedEquipment.from,
        to: reservedEquipment.to,
        amount: reservedEquipment.amount,
        unit: reservedEquipment.equipment?.unit,
        name: reservedEquipment.equipment?.name,
        reference: reservedEquipment.equipment?.reference,
        warehouse: reservedEquipment.warehouse
        //supplier: reservedEquipment.supplier
      } as ProjectEquipment;
    });
    setEquipments(bookedProjectEquipments.concat(reservedProjectEquipments));
  }, [bookedEquipments, reservedEquipments]);

  const fetchData = async () => {
    if (projectId != null) {
      if (canViewBookings)
        await projectService
          .allEquipmentBookings(projectId)
          .then((result) => setBookedEquipments(result.rows));
      if (canViewReservations)
        projectService
          .allEquipmentReservations(projectId)
          .then((result) => setReservedEquipments(result.rows));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let columns = [
    createColumn('isReservation', {
      minWidth: 90,
      maxWidth: 90,
      headerName: t('detail.isReservation'),
      renderCell: (params: any) => {
        const TooltipTitle = (projectEquipment: ProjectEquipment) => {
          if (!projectEquipment.isReservation)
            return (
              <div>
                <Typography>{t('detail.booked')}</Typography>
              </div>
            );
          else
            return (
              <div>
                <Typography>
                  {t('detail.reservedFromTo', {
                    from: moment(new Date(projectEquipment.from!)).format(t('dateFormat:longDate')),
                    to: moment(new Date(projectEquipment.to!)).format(t('dateFormat:longDate'))
                  })}
                </Typography>
              </div>
            );
        };
        return (
          <>
            <Tooltip title={TooltipTitle(params.row)}>
              {params.row.isReservation ? (
                <AccessTime color="primary" />
              ) : (
                <Check color="primary" />
              )}
            </Tooltip>
          </>
        );
      }
    }),
    createColumn('equipmentTimeperiod', {
      minWidth: 160,
      maxWidth: 160,
      headerName: t('detail.equipmentTimeperiod'),
      renderCell: (params: any) => {
        const TooltipTitle = (projectEquipment: ProjectEquipment) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>
                {moment(new Date(projectEquipment.from!)).format(t('dateFormat:longDate'))}
              </Typography>
              <Typography>
                {moment(new Date(projectEquipment.to!)).format(t('dateFormat:longDate'))}
              </Typography>
            </div>
          );
        };
        return <>{params.row.isReservation && TooltipTitle(params.row)}</>;
      }
    }),
    createColumn('amount', {
      minWidth: 80,
      maxWidth: 180,
      headerName: t('detail.amount')
    }),
    createColumn('name', {
      minWidth: 170,
      headerName: t('name', { ns: 'equipments' })
    }),
    createColumn('reference', {
      minWidth: 170,
      headerName: t('reference', { ns: 'equipments' })
    }),
    createColumn('unit', {
      minWidth: 80,
      headerName: t('unit', { ns: 'equipments' })
    })
  ];

  if (canViewWarehouses)
    columns.push(
      createColumn('warehouse', {
        minWidth: 200,
        headerName: t('warehouse', { ns: 'equipments' }),
        valueGetter: (params: any) => params.row.warehouse?.displayName
      })
    );

  columns.push(
    createColumn('actions', {
      minWidth: 140,
      maxWidth: 140,
      sortable: false,
      hideable: false,
      renderCell: (params: any) => {
        return (
          <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
            {((canUpdateBookings && !params.row.isReservation) ||
              (canUpdateReservations && params.row.isReservation)) && (
              <>
                <ItemDialog
                  kind={ItemDialogKind.Delete}
                  type={t(
                    params.row.isReservation ? 'detail.reservationType' : 'detail.bookingType'
                  )}
                  initial={params.row}
                  name={params.row.name!}
                  confirm={async (item) => {
                    if (projectId == null) return;

                    if (params.row.isReservation)
                      projectService
                        .deleteEquipmentReservation(projectId, item.id!)
                        .then(() => fetchData());
                    else
                      await projectService
                        .deleteEquipmentBooking(projectId, item.id!)
                        .then(() => fetchData());
                  }}
                />
                {params.row.isReservation && (
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    type={t(
                      params.row.isReservation ? 'detail.bookingType' : 'detail.reservationType'
                    )}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={async (item) => {
                      if (projectId == null) return;

                      try {
                        await projectService.updateEquipmentReservations(
                          projectId!,
                          item!.id!,
                          item.data
                        );
                        fetchData();
                      } catch {
                        enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
                      }
                    }}>
                    {(item, handleChange, validation) => (
                      <UpdateReservation
                        item={item}
                        handleChange={handleChange}
                        error={validation}
                        type="equipment"
                      />
                    )}
                  </ItemDialog>
                )}
                {params.row.isReservation && !params.row.hasBeenMovedToProject && (
                  <IconButton
                    onClick={() =>
                      projectService
                        .moveEquipmentReservationToProject(projectId!, params.row.id)
                        .then(() =>
                          enqueueSnackbar(
                            t('equipments:movement.project.reservationMovingSuccess'),
                            {
                              variant: 'success'
                            }
                          )
                        )
                        .catch((err) => {
                          if (err.response.data.includes('Reservation already moved'))
                            enqueueSnackbar(
                              t('movement.project.reservationAlreadyMoved', { ns: 'equipments' }),
                              {
                                variant: 'warning'
                              }
                            );
                          else enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
                        })
                    }
                    color="primary">
                    <Place />
                  </IconButton>
                )}
              </>
            )}
          </div>
        );
      }
    })
  );

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
          display: 'none'
        }
      }}
      disableColumnMenu
      loading={false}
      rows={equipments ?? []}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      localeText={{
        toolbarColumns: t('toolbar:columns'),
        toolbarFilters: t('toolbar:filters'),
        toolbarDensity: t('toolbar:density'),
        toolbarExport: t('toolbar:export')
      }}
      components={{
        Toolbar: CustomToolbar
      }}
    />
  );
}
