import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import appointmentService from '../../services/appointmentService';
import { useState } from 'react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DetailPopover(props: any) {
  const [isEditable] = useState(props.isEditable ?? true);
  const { t } = useTranslation('appointments');
  console.log(props.data.event);
  return (
    <Card sx={{ maxWidth: 450 }}>
      <CardHeader
        style={{ padding: 10 }}
        action={
          <>
            {isEditable && (
              <IconButton
                onClick={() => {
                  props.setOpenPopover(false);
                  props.setOpenEditDialog(true);
                }}>
                <EditIcon />
              </IconButton>
            )}
            <IconButton
              onClick={async () => {
                await appointmentService.deleteAppointment(props.data.event.id);
                props.setOpenPopover(false);
              }}>
              <DeleteIcon />
            </IconButton>

            <IconButton aria-label="settings" onClick={() => props.setOpenPopover(false)}>
              <CloseIcon />
            </IconButton>
          </>
        }
      />
      <Divider />
      <div
        style={{
          padding: 18,
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 18 }}>
          <div
            style={{
              background: props.data.event.backgroundColor,
              height: 20,
              borderRadius: 5,
              marginRight: 16,
              minWidth: 22,
              width: 22
            }}></div>

          <p style={{ margin: 0, fontSize: 18 }}>{props.data.event.title}</p>
        </div>
        <p style={{ margin: 0, fontSize: 16, display: 'flex', gap: 10 }}>
          <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}>{t('description')}:</p>
          {props.data.event.extendedProps.description}
        </p>
        {props.data.event.extendedProps.employee != '' && (
          <p style={{ margin: 0, fontSize: 16, display: 'flex', gap: 10 }}>
            <p style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}>{t('employee')}:</p>
            {props.data.event.extendedProps.employee}
          </p>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon style={{ marginRight: 16, width: 22 }} />
          <p style={{ margin: 0, fontSize: 14 }}>
            {moment(props.data.event.startStr).format('DD.MMMM YYYY, HH:mm') +
              (props.data.event.endStr
                ? ' bis ' + moment(props.data.event.endStr).format('DD.MMMM YYYY, HH:mm')
                : '')}
          </p>
        </div>
      </div>
    </Card>
  );
}
