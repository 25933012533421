import { Paper, Tab, Tabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Article from '../../objects/article';
import articleService from '../../services/articleService';
import ArticleBookingTrend from './ArticleBookingTrend';
import ArticleInformation from './ArticleInformation';
import { useTranslation } from 'react-i18next';
import TokenService from '../../services/tokenService';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;
  return <div style={{ flexGrow: 1 }}>{index === value && children}</div>;
}

export default function ArticleDetails() {
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const id = searchParams.get('articleId');
  const { t } = useTranslation('detailPages');

  const [article, setArticle] = useState<Partial<Article>>();

  useEffect(() => {
    articleService.get(id!).then((result) => {
      setArticle(result);
    });
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const canViewArticleBookings = TokenService.tenantFullfills(
    ['Analysis'],
    ['Articles:Bookings:View']
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
          <Tab label={t('details')} />
          {canViewArticleBookings && <Tab label={t('analysis')} />}
        </Tabs>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          {currentTab == 0 && (
            <TabPanel value={currentTab} index={0}>
              <ArticleInformation article={article} />
            </TabPanel>
          )}
          {canViewArticleBookings && currentTab == 1 && (
            <TabPanel value={currentTab} index={1}>
              <ArticleBookingTrend article={article} />
            </TabPanel>
          )}
        </div>
      </Paper>
    </div>
  );
}
