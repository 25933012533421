import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { useEffect, useState } from 'react';
import CreateAppointment from '../../components/appointments/CreateAppointment';
import allLocales from '@fullcalendar/core/locales-all';
import { Popover } from '@mui/material';
import DetailPopover from '../../components/appointments/DetailPopover';
import appointmentService from '../../services/appointmentService';
import { green, purple } from '@mui/material/colors';
import EditAppointment from '../../components/appointments/EditAppointment';
import Legend from './Legend';

const colors = ['#3498DB', '#9B59B6', '#F1C40F', '#E67E22', '#E74C3C'];

const generatePalette = (types: any) => {
  let palette = Object.assign(
    {},
    ...types.map((x: string, index: number) => ({ [x]: colors[index] }))
  );
  return palette;
};

export default function Calendar() {
  const [events, setEvents] = useState();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [coordX, setCoordX] = useState(0);
  const [coordY, setCoordY] = useState(0);

  const fetchData = async () => {
    const appTypesAppointment = await appointmentService.getAppointmentUserTypes('Appointment');
    const palette = generatePalette(appTypesAppointment);
    const result = await appointmentService.getAppointments(
      new Map<string, string | string[]>([['appointmentTypes', ['Employee', 'Appointment']]])
    );
    const mappedData = result.data.elements.map(function (i: any) {
      return {
        id: i.id,
        title: i.subject,
        extendedProps: {
          description: i.description,
          employee: i.employee?.displayName ?? ''
        },
        start: i.start,
        end: i.end,
        backgroundColor:
          i.type == 'Project'
            ? purple[500]
            : i.type == 'Employee'
            ? green[500]
            : i.type == 'Equipment'
            ? '#fbc02d'
            : i.type == 'ExternalServiceProjectAppointment'
            ? '#f44336'
            : i.type == 'EmployeeProjectAppointment'
            ? '#dcbeff'
            : palette[i.userType],
        borderColor:
          i.type == 'Project'
            ? purple[500]
            : i.type == 'Employee'
            ? green[500]
            : i.type == 'Equipment'
            ? '#fbc02d'
            : i.type == 'ExternalServiceProjectAppointment'
            ? '#f44336'
            : i.type == 'EmployeeProjectAppointment'
            ? '#dcbeff'
            : palette[i.userType],
        userType: i.userType,
        type: i.type,
        employeeId: i.employee?.id,
        projectId: i.project?.id
      };
    });
    setEvents(mappedData);
  };

  useEffect(() => {
    fetchData();
  }, [open, openPopover, openEditDialog]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#fff',
          padding: 20,
          borderRadius: 10
        }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          events={events}
          locales={allLocales}
          nowIndicator
          locale={localStorage.getItem('i18nextLng') ?? 'en'}
          height="80vh"
          weekNumbers
          selectable
          dayMaxEventRows
          views={{
            dayGridMonth: { dayMaxEventRows: 4 }
          }}
          customButtons={{
            create: {
              text: '+',
              click: function () {
                setSelectedDate(null);
                setSelectedEvent(null);
                setOpen(true);
              }
            }
          }}
          headerToolbar={{
            start: 'prev,next,today create', // will normally be on the left. if RTL, will be on the right
            center: 'title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' // will normally be on the right. if RTL, will be on the left
          }}
          dateClick={(info: any) => {
            setSelectedEvent(info);
            setOpen(true);
          }}
          select={(info: any) => {
            setSelectedEvent(info);
            setOpen(true);
          }}
          eventClick={(info: any) => {
            setSelectedEvent(info);
            setCoordX(info.jsEvent.pageX);
            setCoordY(info.jsEvent.pageY);
            setOpenPopover(true);
          }}
        />
        <Legend usedTypes={['Employee', 'Appointment']} />
      </div>
      <CreateAppointment
        open={open}
        setOpen={setOpen}
        selectedDate={selectedDate}
        selectedEvent={selectedEvent}
      />
      <EditAppointment data={selectedEvent} open={openEditDialog} setOpen={setOpenEditDialog} />
      <Popover
        open={openPopover}
        anchorReference="anchorPosition"
        onClose={() => setOpenPopover(false)}
        anchorPosition={{ left: coordX, top: coordY - 35 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <DetailPopover
          data={selectedEvent}
          setOpenPopover={setOpenPopover}
          setOpenEditDialog={setOpenEditDialog}
        />
      </Popover>
    </div>
  );
}
