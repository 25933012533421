/* eslint-disable no-unused-vars */
import { Paper, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import Equipment from '../../objects/equipment';
import moment from 'moment';
import analysisService from '../../services/analysisService';
import { useTranslation } from 'react-i18next';
import EquipmentBookingAnalysis from '../../objects/equipmentBookingAnalysis';
import TimeSeriesWithPrognosisChart from '../../components/TimeSeriesWithPrognosisChart';

interface EquipmentBookingTrendProps {
  equipment: Partial<Equipment> | undefined;
}

export default function EquipmentBookingTrend(props: EquipmentBookingTrendProps) {
  const [equipmentBookingAnalysis, setEquipmentBookingAnalysis] =
    useState<EquipmentBookingAnalysis>();
  const { t } = useTranslation(['equipments']);

  useEffect(() => {
    const dateNow = new Date();
    let start = moment.utc(dateNow).startOf('day').subtract(30, 'days').toDate();
    let end = moment.utc(dateNow).endOf('day').toDate();
    let cumulation = '01:00:00:00';

    analysisService
      .getEquipmentBookingAnalysis(props.equipment?.id!, start, end, cumulation)
      .then((result) => setEquipmentBookingAnalysis(result));
  }, []);

  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        {!equipmentBookingAnalysis && (
          <Skeleton variant="rectangular" width={'100%'} height={500} />
        )}
        {equipmentBookingAnalysis && (
          <TimeSeriesWithPrognosisChart
            cumulation
            cumulationOffset={equipmentBookingAnalysis.currentAmount}
            data={equipmentBookingAnalysis.points}
            prognosis={equipmentBookingAnalysis.prognosisPoints}
            getDate={(point) => new Date(point.start)}
            getPositiveValue={(point) => point.receiptAmount}
            getNegativeValue={(point) => point.issuedAmount}
            legend={{
              label: t('bookingTrend.amount'),
              prognosisLabel: t('bookingTrend.amountPrognosis'),
              cumulationLabel: t('bookingTrend.cumulation')
            }}
          />
        )}
      </Paper>
    </>
  );
}
