import CircleIcon from '@mui/icons-material/Circle';
import { green, purple } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

interface Props {
  usedTypes: any[];
}

export default function Legend({ usedTypes }: Props) {
  const { t } = useTranslation('appointments');
  console.log(usedTypes);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        gap: 20,
        marginTop: 10
      }}>
      {usedTypes?.includes('Employee') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: green[500], width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('employee')}</p>
        </div>
      )}
      {usedTypes?.includes('Appointment') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: '#3498DB', width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('appointment')}</p>
        </div>
      )}
      {usedTypes?.includes('Project') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: purple[500], width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('project')}</p>
        </div>
      )}
      {usedTypes?.includes('EmployeeProject') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: '#dcbeff', width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('employee-project')}</p>
        </div>
      )}
      {usedTypes?.includes('Equipment') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: '#fbc02d', width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('equipment')}</p>
        </div>
      )}
      {usedTypes?.includes('ExternalService') && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <CircleIcon sx={{ color: '#f44336', width: 24, height: 24 }} />
          <p style={{ margin: 0 }}>{t('external-service')}</p>
        </div>
      )}
    </div>
  );
}
