import React from 'react';
import {
  IconButton,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useLocales from '../../hooks/useLocales';

const lngs = {
  en: 'enEN',
  de: 'deDE'
};

const i18nlngs = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
};

export default function Language() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { changeLocale } = useLocales();
  const { i18n } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleClick = (lng) => {
    i18n.changeLanguage(lng);
    changeLocale(lngs[lng]);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus();
    prevOpen.current = open;
  }, [open]);

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ color: '#fff' }}
        onClick={handleToggle}>
        <Typography>{i18n.language.toUpperCase()}</Typography>
      </IconButton>
      <Popper
        open={open}
        placement="bottom-end"
        style={{ marginTop: 20 }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {Object.keys(i18nlngs).map((lng) => (
                    <MenuItem
                      key={lng}
                      style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}
                      type="submit"
                      onClick={() => handleClick(lng)}>
                      {i18nlngs[lng].nativeName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
