import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import appointmentService from '../../services/appointmentService';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Project } from '../../objects/project';
import projectService from '../../services/projectService';

export default function CreateExternalServiceAppointment(props: any) {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  //const [appointmentTypes, setAppointmentTypes] = useState<string[]>();
  const [selectedUserType, setSelectedUserType] = useState('');
  const [start, setStart] = useState<Date | null>(new Date());
  const [end, setEnd] = useState<Date | null>(new Date());
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [projects, setProjects] = useState<Partial<Project>[]>([]);
  const [error, setError] = useState(false);
  const [isDoubleBooking, setIsDoubleBooking] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation('appointments');

  async function saveItem() {
    if (
      subject &&
      start &&
      end &&
      selectedProject &&
      moment(start).isValid() &&
      moment(end).isValid()
    ) {
      let item: any = {
        userType: selectedUserType,
        subject: subject,
        description: description,
        start: start,
        end: end,
        projectId: selectedProject?.id,
        externalServiceId: props.externalServiceId
      };
      try {
        await appointmentService.createAppointment(item, 'external-service-project-', {
          params: { ignoreConflicts: isDoubleBooking }
        });
      } catch (error) {
        if (error.response.status == 409) {
          setIsDoubleBooking(true);
          enqueueSnackbar(t('app:doubleBookingDetected'), { variant: 'error' });
        } else enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
        return false;
      }

      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  async function editItem() {
    appointmentService.updateAppointment(props.selectedEvent?.event?.id ?? props.selectedEvent.id, {
      description: description,
      start: start,
      end: end
    });
    return true;
  }

  useEffect(() => {
    projectService.all().then((result) => setProjects(result.rows));
    setSubject('');
    setSelectedUserType('');
    setIsDoubleBooking(false);
    setError(false);
    setDescription(props.selectedEvent?.event?.title ?? '');
    setStart(
      props.selectedEvent?.event?.start ??
        props.selectedEvent?.start ??
        props.selectedEvent?.date ??
        new Date()
    );
    setEnd(
      props.selectedEvent?.event?.end ??
        props.selectedEvent?.end ??
        props.selectedEvent?.date ??
        new Date()
    );
  }, [props.selectedDate, props.open, props.selectedEvent]);

  return (
    <CreateDialog
      noBackdrop
      title={t('addAppointment')}
      saveItem={props.selectedEvent?.event?.title ? editItem : saveItem}
      open={props.open}
      setOpen={props.setOpen}
      withActions>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div>
          <TextField
            required
            error={error && !subject}
            style={{ marginBottom: 10 }}
            label={t('subject')}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 10 }}
            label={t('description')}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          <FormControl
            fullWidth
            style={{ marginBottom: 10 }}
            required
            error={error && !selectedProject}>
            <InputLabel>{t('project')}</InputLabel>
            <Select
              value={selectedProject}
              label={t('project')}
              onChange={(e: any) => setSelectedProject(e.target.value)}>
              {projects.map((project: any, index: number) => (
                <MenuItem key={index} value={project}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 10 }}>
            <DatePicker
              label={t('startdate')}
              value={start}
              mask="__.__.____"
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            <TimePicker
              label={t('starttime')}
              value={start}
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  required
                  style={{ width: '50%' }}
                />
              )}
            />
          </div>
          <div style={{ display: 'flex', gap: 10, width: '100%' }}>
            <DatePicker
              label={t('enddate')}
              value={end}
              mask="__.__.____"
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            <TimePicker
              label={t('endtime')}
              value={end}
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  required
                  style={{ width: '50%' }}
                />
              )}
            />
          </div>
          {isDoubleBooking && (
            <Typography color="error" style={{ maxWidth: 460 }}>
              {t('app:doubleBookingDetectedPrompt')}
            </Typography>
          )}
        </div>
      </Paper>
    </CreateDialog>
  );
}
