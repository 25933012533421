import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import appointmentService from '../../services/appointmentService';
import employeeService from '../../services/employeeService';
import projectService from '../../services/projectService';
import Employee from '../../objects/employee';
import { Project } from '../../objects/project';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import moment from 'moment';

export default function EditAppointment(props: any) {
  const [selectedType, setSelectedType] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [employees, setEmployees] = useState<Partial<Employee>[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [projects, setProjects] = useState<Partial<Project>[]>([]);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  // const [appointmentTypes, setAppointmentTypes] = useState<Appointment[]>([]);
  // const [selectedUserType, setSelectedUserType] = useState('');
  const [start, setStart] = useState<Date | null>(moment(new Date()).startOf('minute').toDate());
  const [end, setEnd] = useState<Date | null>(moment(new Date()).startOf('minute').toDate());
  const [isWholeDay, setIsWholeDay] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isDoubleBooking, setIsDoubleBooking] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['appointments', 'app']);
  const types = ['appointment', 'project', 'employee'];

  async function editItem() {
    if (subject && start && end && moment(start).isValid() && moment(end).isValid()) {
      try {
        await appointmentService.updateAppointment(
          props.data?.event?.id,
          {
            subject: subject,
            description: description,
            start: start,
            end: end
          },
          { params: { ignoreConflicts: isDoubleBooking } }
        );
      } catch (error) {
        if (error.response.status == 409) {
          setIsDoubleBooking(true);
          enqueueSnackbar(t('app:doubleBookingDetected'), { variant: 'error' });
        } else enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
        return false;
      }
      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  async function getAllTypes() {
    // let appointmentTypes = await appointmentService.getAppointmentUserTypes('Appointment');
    // let projectTypes = await appointmentService.getAppointmentUserTypes('Project');
    // let employeeTypes = await appointmentService.getAppointmentUserTypes('Employee');
    // setAppointmentTypes([
    //   ...appointmentTypes.map((obj: any) => ({ userType: obj, type: 'Appointment' })),
    //   ...projectTypes.map((obj: any) => ({ userType: obj, type: 'Project' })),
    //   ...employeeTypes.map((obj: any) => ({ userType: obj, type: 'Employee' }))
    // ]);
  }

  useEffect(() => {
    getAllTypes();
    employeeService.all().then((result) => setEmployees(result.rows));
    projectService.all().then((result) => setProjects(result.rows));
    setIsDoubleBooking(false);
    setSelectedEmployee(props.data?.employee?.id);
    setSelectedProject(props.data?.project?.id);
    setSubject(props.data?.event?.title);
    // setSelectedUserType(props.data?.event?.extendedProps?.userType ?? '');
    setSelectedType(props.data?.event?.extendedProps?.type ?? '');
    setError(false);
    setDescription(props.data?.event?.extendedProps.description ?? '');
    let start =
      props.data?.event?.start ??
      props.data?.start ??
      props.data?.date ??
      moment(new Date()).startOf('minute').toDate();
    setStart(start);
    setEnd(props.data?.event?.end ?? props.data?.end ?? props.data?.date ?? start);
  }, [props.data, props.open]);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsWholeDay(event.target.checked);
    setStart(moment(start).startOf('day').toDate());
    setEnd(moment(end).endOf('day').toDate());
  };

  return (
    <CreateDialog
      noBackdrop
      title={t('editAppointment')}
      saveItem={editItem}
      open={props.open}
      setOpen={props.setOpen}
      isEdit
      withActions>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div>
          {false && (
            <FormControl
              required
              error={error && !selectedType}
              fullWidth
              style={{ marginBottom: 10 }}>
              <InputLabel>{t('category')}</InputLabel>
              <Select
                value={selectedType}
                disabled
                label={t('category')}
                onChange={(e: any) => setSelectedType(e.target.value)}>
                {types.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {t(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedType == 'Employee' && (
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel>{t('employee')}</InputLabel>
              <Select
                disabled
                value={selectedEmployee}
                label={t('employee')}
                onChange={(e: any) => setSelectedEmployee(e.target.value)}>
                {employees.map((employee: any, index: number) => (
                  <MenuItem key={index} value={employee}>
                    {employee.firstName + ' ' + employee.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedType == 'Project' && (
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel>{t('project')}</InputLabel>
              <Select
                disabled
                value={selectedProject}
                label={t('project')}
                onChange={(e: any) => setSelectedProject(e.target.value)}>
                {projects.map((project: any, index: number) => (
                  <MenuItem key={index} value={project}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            required
            error={error && !subject}
            style={{ marginBottom: 10 }}
            id="outlined-basic"
            label={t('subject')}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 10 }}
            id="outlined-basic"
            label={t('description')}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          {/* {selectedUserType && (
            <Autocomplete
              disabled={selectedType == ''}
              value={selectedUserType}
              style={{ marginBottom: 10 }}
              groupBy={(option) => option.type}
              onInputChange={(e, inputValue) => {
                setSelectedUserType(inputValue);
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={appointmentTypes.filter((item: Appointment) => item.type == selectedType)}
              getOptionLabel={(option: any) => option?.userType ?? option}
              freeSolo
              fullWidth
              renderInput={(params) => <TextField {...params} label={t('type')} />}
            />
          )} */}

          <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 10 }}>
            <DatePicker
              label={t('startdate')}
              value={start}
              mask="__.__.____"
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            {!isWholeDay && (
              <TimePicker
                label={t('starttime')}
                value={start}
                onChange={(value: any) => {
                  setStart(value);
                  if (value > end!) setEnd(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    required
                    style={{ width: '50%' }}
                  />
                )}
              />
            )}
          </div>
          <div style={{ display: 'flex', gap: 10, width: '100%' }}>
            <DatePicker
              label={t('enddate')}
              value={end}
              mask="__.__.____"
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            {!isWholeDay && (
              <TimePicker
                label={t('endtime')}
                value={end}
                onChange={(value: any) => {
                  setEnd(value);
                  if (value < start!) setStart(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    required
                    style={{ width: '50%' }}
                  />
                )}
              />
            )}
          </div>
          <FormControlLabel
            checked={isWholeDay}
            control={<Checkbox onChange={handleChangeCheckbox} />}
            label={t('wholeDay')}
          />
          {isDoubleBooking && (
            <Typography color="error" style={{ maxWidth: 460 }}>
              {t('app:doubleBookingDetectedPrompt')}
            </Typography>
          )}
        </div>
      </Paper>
    </CreateDialog>
  );
}
