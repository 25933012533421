import { defaultService, ItemState } from './api';
import Booking from '../objects/booking';

const route = 'bookings';

const bookingService = {
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Booking>(route, itemState)
};

export default bookingService;
