import { Paper } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import projectService from '../../services/projectService';
import navigationService from '../../services/navigationService';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import UpdateProject from '../../components/projects/UpdateProject';
import { ItemState, itemStateService } from '../../services/api';
import filterService from '../../services/filterService';
import { Project } from '../../objects/project';
import TokenService from '../../services/tokenService';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import CustomToolbar from './CustomToolbar';

export default function Projects() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['projects', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn, createNavigationWithPropsColumn } = navigationService.create(t, navigate);
  const [changeAmount, setChangeAmount] = useState<number>();
  const [columns, setColumns] = useState<any[]>();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    lastEdit: false
  });

  useEffect(() => {
    setChangeAmount(0);
  }, []);

  const [projectsState, setProjectsState] = useState<ItemState>();
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = projectsState;
  }, [projectsState]);
  const [projects, setProjects] = useState({
    loading: true,
    rows: new Array<Partial<Project>>(0),
    rowCount: 0
  });

  useEffect(() => {
    setColumns([
      createNavigationWithPropsColumn(
        'name',
        'projects/details',
        (rowValue: any) => {
          return { projectId: rowValue.id };
        },
        { width: 150 }
      ),
      createColumn('customer', {
        width: 110,
        valueGetter: (x: any) => x.value?.displayName
      }),
      createColumn('responsible', {
        width: 130,
        valueGetter: (x: any) => x.value?.displayName
      }),
      createColumn('budget'),
      createColumn('state', {
        width: 80,
        valueGetter: (x: any) => t('states.' + x.value)
      }),
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      createColumn('actions', {
        sortable: false,
        width: 120,
        hideable: false,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateProjects && (
                <>
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    type={t('type')}
                    initial={params.row}
                    validate={projectService.validateUpdate}
                    confirm={(item) => {
                      projectService
                        .update(item.reference!, item)
                        .then(() => fetchData())
                        .catch(() =>
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' })
                        );
                    }}>
                    {(item, handleChange, validation) => (
                      <UpdateProject
                        project={item}
                        handleChange={handleChange}
                        error={validation}
                      />
                    )}
                  </ItemDialog>
                  <ItemDialog
                    kind={ItemDialogKind.Delete}
                    type={t('type')}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={(item) => {
                      projectService.delete(item.id!).then(() => fetchData());
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      })
    ]);

    const state = itemStateService.getItemStateFromUrl(searchParams, 15);
    if (state.sort.size == 0) {
      state.sort.set('lastEdit', 'desc');
      itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
    }
    setProjectsState(state);
  }, []);

  const fetchData = () => {
    projectService
      .all(stateRef.current)
      .then((result) => {
        setChangeAmount(0);
        setProjects({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setProjects({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  const canUpdateProjects = TokenService.tenantFullfills([], ['Projects:Update']);

  useEffect(() => {
    if (projectsState == null) return;
    if (projectsState.sort.size == 0) projectsState.sort.set('lastEdit', 'desc');
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, projectsState);
    fetchData();
  }, [projectsState]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              toolbar: {
                changeAmount,
                setChangeAmount,
                fetchData,
                projectsState,
                setProjectsState
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            loading={projects.loading}
            rows={projects.rows ?? []}
            columns={columns ?? []}
            page={projectsState?.paginationSettings?.page}
            pageSize={15}
            rowCount={projects.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(projectsState?.filter)}
            sortModel={filterService.createGridSort(projectsState?.sort)}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onPageChange={(page) => {
              if (projectsState == null) return;
              if (filterService.updateItemStatePage(projectsState, page, 15))
                setProjectsState({ ...projectsState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (projectsState == null) return;
              if (
                filterService.updateItemStateFilter(
                  projectsState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setProjectsState({ ...projectsState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (projectsState == null) return;
              if (
                filterService.updateItemStateSort(
                  projectsState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setProjectsState({ ...projectsState });
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
