import { Paper, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Project } from '../../objects/project';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import Equipment from '../../objects/equipment';
import Article from '../../objects/article';

interface UpdateReservationProps {
  item: Partial<Article | Equipment | any>;
  handleChange: Function;
  error: boolean;
  type: 'article' | 'equipment';
}

export default function UpdateReservation(props: UpdateReservationProps) {
  const { item, handleChange, type } = props;
  const [start, setStart] = useState<Date | null>(new Date());
  const [end, setEnd] = useState<Date | null>(new Date());
  const [isWholeDay, setIsWholeDay] = useState<boolean>(false);

  const { t } = useTranslation(['appointments', 'app']);

  useEffect(() => {
    if (type == 'article') setEnd(item.until);
    else {
      setStart(item.from);
      setEnd(item.to);
    }
  }, []);

  useEffect(() => {
    if (start && end && moment(start).isValid() && moment(end).isValid()) {
      let articleItem = {
        // priceAtTime: 0,
        expirationTime: moment(end).toISOString(),
        amount: item.amount
      };

      let equipmentItem = {
        // depreciationAtTime: 0,
        from: moment(start).toISOString(),
        to: moment(end).toISOString(),
        amount: item.amount
      };
      handleChange({ ...item, ...{ data: type == 'article' ? articleItem : equipmentItem } });
    }
  }, [start, end]);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsWholeDay(event.target.checked);
    setStart(moment(start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate());
    setEnd(moment(end).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate());
  };

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        {type == 'equipment' && (
          <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 10 }}>
            <DatePicker
              label={t('startdate')}
              value={start}
              mask="__.__.____"
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            {!isWholeDay && (
              <TimePicker
                label={t('starttime')}
                value={start}
                onChange={(value: any) => {
                  setStart(value);
                  if (value > end!) setEnd(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    required
                    style={{ width: '50%' }}
                  />
                )}
              />
            )}
          </div>
        )}
        <div style={{ display: 'flex', gap: 10, width: '100%' }}>
          <DatePicker
            label={t('enddate')}
            value={end}
            mask="__.__.____"
            onChange={(value: any) => {
              setEnd(value);
              if (value < start!) setStart(value);
            }}
            renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
          />
          {!isWholeDay && (
            <TimePicker
              label={t('endtime')}
              value={end}
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  required
                  style={{ width: '50%' }}
                />
              )}
            />
          )}
        </div>
        <FormControlLabel
          checked={isWholeDay}
          control={<Checkbox onChange={handleChangeCheckbox} />}
          label={t('wholeDay')}
        />
      </div>
    </Paper>
  );
}
