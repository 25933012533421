import {
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../../objects/article';
import DeleteIcon from '@mui/icons-material/Delete';
import ItemDialog, { ItemDialogKind } from '../Helper/ItemDialog';
import CreateSupplier from '../suppliers/CreateSupplier';
import Supplier from '../../objects/supplier';
import supplierService from '../../services/supplierService';
import isValidSeperator from '../Helper/seperatorRegex';

const SupplierFilter = createFilterOptions<Partial<Supplier>>();

interface CreateArticleSuppliersProps {
  article: Partial<Article>;
  handleChange: Function;
  suppliers: Partial<Supplier>[];
  isUpdate?: boolean;
}

export default function CreateArticleSuppliers(props: CreateArticleSuppliersProps) {
  const { t } = useTranslation(['articles', 'suppliers']);
  const [article, setArticle] = useState<Partial<Article>>(props.article);
  const [suppliers, setSuppliers] = useState(props.suppliers);
  const [enteredSupplierName, setEnteredSupplierName] = useState('');

  useEffect(() => {
    if (props.isUpdate) setArticle(props.article);
  }, []);

  useEffect(() => {
    props.handleChange(article);
  }, [article]);

  return (
    <>
      <Paper
        style={{
          padding: 10,
          display: 'flex',
          flexDirection: 'column'
        }}
        elevation={0}>
        <>
          {article?.suppliers
            ?.sort((current, next) => current!.supplier!.id!.localeCompare(next!.supplier!.id!))
            .map((articleSupplier) => {
              let index = article?.suppliers!.indexOf(articleSupplier)!;
              return (
                <div
                  key={articleSupplier!.supplier!.id!}
                  style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    InputProps={{
                      endAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    style={{ marginBottom: 10, marginRight: 10 }}
                    label={t('supplierPrice')}
                    variant="outlined"
                    value={articleSupplier.price}
                    onFocus={(event) => event.target.select()}
                    type="number"
                    onChange={(e) => {
                      e.stopPropagation();

                      if (!isValidSeperator(e.target.value)) return;
                      let newPrice = Number(e.target.value);
                      if (Number.isNaN(newPrice)) return;

                      let suppliersClone = Array.from(article?.suppliers ?? []);
                      suppliersClone.splice(index, 1);
                      let newItem = { ...articleSupplier, ...{ price: Math.abs(newPrice) } };
                      suppliersClone.push(newItem);
                      setArticle({ ...article, ...{ suppliers: suppliersClone } });
                    }}
                  />
                  <TextField
                    fullWidth
                    value={articleSupplier.supplier!.name ?? articleSupplier.supplier!.displayName}
                    disabled
                  />
                  <div style={{ alignItems: 'center' }}>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        let suppliersClone = Array.from(article?.suppliers ?? []);
                        suppliersClone.splice(index, 1);
                        setArticle({ ...article, ...{ suppliers: suppliersClone } });
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Autocomplete
              value={enteredSupplierName}
              onChange={(e, value) => {
                e.stopPropagation();

                if (value == null) return;
                let supplierValue = value as Partial<Supplier>;
                if (supplierValue.id == null || supplierValue.name == null) return;
                if (!suppliers.find((supplier) => supplier.id == supplierValue.id)) return;

                let suppliersClone = Array.from(article?.suppliers ?? []);
                suppliersClone.push({ price: 0.0, supplier: supplierValue });
                setArticle({ ...article, ...{ suppliers: suppliersClone } });
                setEnteredSupplierName('');
              }}
              filterOptions={(options, params) => SupplierFilter(options, params)}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={suppliers}
              getOptionLabel={(option: any) => {
                return option.name ?? '';
              }}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id ?? ''}>
                  {option.name ?? ''}
                </MenuItem>
              )}
              freeSolo
              fullWidth
              renderInput={(params) => <TextField {...params} label={t('supplier')} />}
            />
          </div>
        </>
      </Paper>
      <ItemDialog
        buttonContainerProps={{
          style: {
            marginBottom: 10,
            padding: 10,
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }}
        buttonProps={{
          fullWidth: true,
          size: 'medium',
          style: {
            width: '70%'
          }
        }}
        withTypedText
        autoSize
        kind={ItemDialogKind.Create}
        type={t('suppliers:type')}
        confirm={(item) =>
          supplierService
            .create(item)
            .then(() => supplierService.all().then((result) => setSuppliers(result.rows)))
            .catch(() => {
              //TODO Fehlermeldung
            })
        }>
        {(item, handleChange) => <CreateSupplier supplier={item} handleChange={handleChange} />}
      </ItemDialog>
    </>
  );
}
