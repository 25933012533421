import { Paper, Tabs, Tab } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import projectService from '../../services/projectService';
import { Project } from '../../objects/project';
import ProjectArticles from './ProjectArticles';
import ProjectEquipments from './ProjectEquipments';
import ProjectBookingTrend from './ProjectBookingTrend';
import ProjectCalendar from './ProjectCalendar';
import ProjectInformation from './ProjectInformation';
import TokenService from '../../services/tokenService';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;
  return <div style={{ flexGrow: 1 }}>{index === value && children}</div>;
}

export default function ProjectDetails() {
  const { t } = useTranslation(['projects', 'articles', 'dateFormat', 'detailPages']);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [project, setProject] = useState<Partial<Project>>();

  const fetchData = () => {
    if (projectId != null) {
      projectService.get(projectId).then((result) => setProject(result));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const hasCalendar = TokenService.tenantFullfills(['Calendar'], []);
  const canViewArticles = TokenService.tenantFullfills([], ['Articles:View']);
  const canViewEquipments = TokenService.tenantFullfills([], ['Equipments:View']);
  const hasAnalysis = TokenService.tenantFullfills(['Analysis'], []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
          <Tab label={t('details')} tabIndex={0} />
          {canViewArticles && <Tab label={t('articles', { ns: 'detailPages' })} tabIndex={1} />}
          {canViewEquipments && <Tab label={t('equipments', { ns: 'detailPages' })} tabIndex={2} />}
          {hasCalendar && <Tab label={t('calendar', { ns: 'detailPages' })} tabIndex={3} />}
          {hasAnalysis && <Tab label={t('analysis', { ns: 'detailPages' })} tabIndex={4} />}
        </Tabs>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          {currentTab == 0 && (
            <TabPanel value={currentTab} index={0}>
              <ProjectInformation project={project} />
            </TabPanel>
          )}
          {canViewArticles && currentTab == 1 && (
            <TabPanel value={currentTab} index={1}>
              <ProjectArticles />
            </TabPanel>
          )}
          {canViewEquipments && currentTab == 2 && (
            <TabPanel value={currentTab} index={2}>
              <ProjectEquipments />
            </TabPanel>
          )}
          {hasCalendar && currentTab == 3 && (
            <TabPanel value={currentTab} index={3}>
              <ProjectCalendar />
            </TabPanel>
          )}
          {hasAnalysis && currentTab == 4 && (
            <TabPanel value={currentTab} index={4}>
              <ProjectBookingTrend project={project} />
            </TabPanel>
          )}
        </div>
      </Paper>
    </div>
  );
}
