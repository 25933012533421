import { Paper, TextField, MenuItem, InputAdornment, Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Customer from '../../objects/customer';
import Employee from '../../objects/employee';
import customerService from '../../services/customerService';
import employeeService from '../../services/employeeService';
import projectStatesService from '../../services/projectStatesService';
import cloneDeep from 'lodash/cloneDeep';

export default function UpdateProject(props: any) {
  const { t } = useTranslation('projects');
  const [employees, setEmployees] = useState<Partial<Employee>[]>([]);
  const [customers, setCustomers] = useState<Partial<Customer>[]>([]);

  const [selectedCustomer, setSelectedCustomer] = useState<Partial<Customer>>();
  const [states, setStates] = useState<string[]>([]);
  const [project, setProject] = useState<any>(undefined);

  useEffect(() => {
    let tempProject: any = cloneDeep(props.project);
    setProject({
      name: tempProject.name,
      reference: tempProject.id,
      customerId: tempProject.customer?.id,
      responsibleId: tempProject.responsible?.id,
      budget: tempProject.budget
    });
  }, []);

  useEffect(() => {
    // since the database stores the number and not the sate string, we have to convert
    let tempProject: any = cloneDeep(props.project);
    let convertedState = states.indexOf(tempProject.state);
    if (convertedState != -1) setProject({ ...project, ...{ state: convertedState } });
  }, [states]);

  useEffect(() => {
    props.handleChange(project);
  }, [project]);

  useEffect(() => {
    setSelectedCustomer(customers.find((item) => item?.id == project?.customerId));
  }, [customers]);

  useEffect(() => {
    (async () => {
      await customerService.all().then((result) => setCustomers(result.rows));
      await employeeService.all().then((result) => setEmployees(result.rows));
      await projectStatesService.all().then((result) => setStates(result));
    })();
  }, []);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
      }}
      elevation={0}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          required
          error={props.error && !project?.name}
          style={{ marginBottom: 10 }}
          label={t('name')}
          variant="outlined"
          value={project?.name ?? ''}
          onChange={(e) => setProject({ ...project, ...{ name: e.target.value } })}
          fullWidth
        />
      </div>
      <TextField
        required
        error={props.error && !project?.responsibleId}
        style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
        fullWidth
        select
        value={project?.responsibleId ?? ''}
        onChange={(e) =>
          setProject({
            ...project,
            ...{ responsibleId: e.target.value }
          })
        }
        label={t('responsible')}>
        {employees.map((option: any) => (
          <MenuItem key={option.id} value={option.id}>
            {option.firstName + ' ' + option.lastName}
          </MenuItem>
        ))}
      </TextField>
      <Autocomplete
        style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
        freeSolo
        fullWidth
        selectOnFocus
        handleHomeEndKeys
        autoHighlight
        value={selectedCustomer ?? ''}
        options={customers}
        getOptionLabel={(option: any) => {
          return option.name ?? '';
        }}
        renderOption={(props, option: any) => (
          <MenuItem {...props} key={option.id}>
            {option.name}
          </MenuItem>
        )}
        onChange={(e: any, value: any) => {
          setSelectedCustomer(value);
          setProject({
            ...project,
            ...{ customerId: value.id }
          });
        }}
        renderInput={(params) => (
          <TextField
            required
            error={props.error && !project?.customerId}
            {...params}
            label={t('customer')}
          />
        )}
      />

      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>
        }}
        type="number"
        style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}
        label={t('volume')}
        variant="outlined"
        value={project?.budget ?? ''}
        onChange={(e) => setProject({ ...project, ...{ budget: e.target.value } })}
        fullWidth
      />
    </Paper>
  );
}
