import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListItemAvatar, Paper, Tooltip } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
import Booking from '../objects/booking';
import navigationService from '../services/navigationService';
import bookingService from '../services/bookingService';
import { ItemState, itemStateService } from '../services/api';
import filterService from '../services/filterService';
import BookingTypeIcon from '../components/BookingTypeIcon';
import moment from 'moment';
import TokenService from '../services/tokenService';

export default function BookingLog() {
  const { t } = useTranslation(['bookingLog', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const { createColumn, createNavigationWithPropsColumn, createDynamicNavigationWithPropsColumn } =
    navigationService.create(t, navigate);
  const [searchParams, setSearchParams] = useSearchParams();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const [itemState, setItemState] = useState<ItemState>();
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = itemState;
  }, [itemState]);
  const [bookings, setBookings] = useState({
    loading: true,
    rows: new Array<Partial<Booking>>(0),
    rowCount: 0
  });

  const canViewEmployees = TokenService.tenantFullfills([], ['Employees:View']);
  const canViewWarehouses = TokenService.tenantFullfills([], ['Warehouses:View']);
  const canViewProjects = TokenService.tenantFullfills([], ['Projects:View']);
  const canViewSuppliers = TokenService.tenantFullfills([], ['Suppliers:View']);

  const [columns, setColumns] = useState<any[]>();
  useEffect(() => {
    const tempColumns = [];

    tempColumns.push({
      field: 'bookingType',
      headerName: '',
      sortable: false,
      width: 70,
      renderCell: (params: any) => {
        let bookingType = params.value;
        return (
          <Tooltip title={t(bookingType)}>
            <ListItemAvatar>
              <BookingTypeIcon bookingType={bookingType} />
            </ListItemAvatar>
          </Tooltip>
        );
      }
    });

    if (canViewEmployees)
      tempColumns.push(
        createColumn('bookerEmployee', {
          sortable: false,
          filterable: false,
          minWidth: 140,
          valueGetter: (x: any) => x.value?.displayName
        })
      );

    tempColumns.push(
      createColumn('bookingDateTime', {
        sortable: false,
        filterable: false,
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:longDate'))
      }),
      createColumn('amount', {
        minWidth: 120,
        hideable: false,
        sortable: false,
        filterable: false
      }),
      createColumn('purchasingPrice', {
        minWidth: 120,
        hideable: false,
        sortable: false,
        filterable: false,
        valueGetter: (x: any) => (x.value ? x.value?.toFixed(2) + ' €' : '')
      }),
      createColumn('sellingPrice', {
        minWidth: 120,
        hideable: false,
        sortable: false,
        filterable: false,
        valueGetter: (x: any) => (x.value ? x.value?.toFixed(2) + ' €' : '')
      }),
      createDynamicNavigationWithPropsColumn(
        'name',
        (row: Partial<Booking>) => {
          if (row.article?.id != null) return 'articles/details';
          else if (row.equipment?.id != null) return 'equipments/details';
        },
        (row: Partial<Booking>) => {
          if (row.article?.id != null) return { articleId: row.article!.id };
          else if (row.equipment?.id != null) return { id: row.equipment!.id };
        },
        {
          sortable: false,
          filterable: false,
          minWidth: 140,
          valueGetter: (params: any) =>
            params.row.article?.displayName ?? params.row.equipment?.displayName
        }
      )
    );

    if (canViewProjects)
      tempColumns.push(
        createNavigationWithPropsColumn(
          'project',
          'projects/details',
          (row: Partial<Booking>) => {
            return { projectId: row.project?.id };
          },
          {
            minWidth: 140,
            valueGetter: (x: any) => x.value?.displayName,
            sortable: false,
            filterable: false
          }
        )
      );

    if (canViewWarehouses)
      tempColumns.push(
        createColumn('fromWarehouse', {
          minWidth: 140,
          sortable: false,
          filterable: false,
          valueGetter: (x: any) => x.value?.displayName
        })
      );

    if (canViewSuppliers)
      tempColumns.push(
        createColumn('withSupplier', {
          minWidth: 140,
          sortable: false,
          filterable: false,
          valueGetter: (x: any) => x.value?.displayName
        })
      );

    setColumns(tempColumns);
  }, []);

  useEffect(() => {
    const filter = new Map<string, string>();

    const dateNow = new Date();
    let start = moment.utc(dateNow).startOf('day').subtract(30, 'days').toDate();
    let end = moment.utc(dateNow).endOf('day').toDate();
    filter.set('creationGreaterOrEqual', start.toISOString());
    filter.set('creationLessOrEqual', end.toISOString());

    setItemState({
      filter,
      sort: new Map<string, 'asc' | 'desc'>(),
      paginationSettings: { page: 0, pageSize: 15 }
    });
  }, []);

  useEffect(() => {
    if (itemState == null) return;
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, itemState);
    fetchData();
  }, [itemState]);

  const fetchData = () => {
    setBookings({ ...bookings, ...{ loading: true } });
    bookingService
      .all(stateRef.current)
      .then((result) => {
        setBookings({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setBookings({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              panel: {
                sx: {
                  [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                    display: 'none'
                  }
                }
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={bookings.loading}
            rows={bookings.rows ?? []}
            columns={columns ?? []}
            page={itemState?.paginationSettings?.page}
            pageSize={15}
            rowCount={bookings.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(itemState?.filter)}
            sortModel={filterService.createGridSort(itemState?.sort)}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onPageChange={(page) => {
              if (itemState == null) return;
              if (filterService.updateItemStatePage(itemState, page, 15))
                setItemState({ ...itemState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (itemState == null) return;
              if (
                filterService.updateItemStateFilter(
                  itemState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setItemState({ ...itemState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (itemState == null) return;
              if (
                filterService.updateItemStateSort(
                  itemState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setItemState({ ...itemState });
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
