import { ImporterInformation } from '../objects/export';
import instance from './api';

const importersRoute = 'importers';
const importRoute = 'import';
const exportRoute = 'export';

const exportService = {
  import: (information: ImporterInformation, options: any, file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    return instance.post(importRoute, formData, {
      headers: {
        'Content-Type': `application/${information.type}`
      },
      params: {
        ...{
          type: information.type,
          name: information.name
        },
        ...options
      }
    });
  },
  export: (type: string, name: string) =>
    instance.get(exportRoute, {
      params: {
        type: type,
        name: name
      },
      responseType: 'blob'
    }),
  getExporters: () => instance.get(`${exportRoute}/types`),
  getImporters: async (): Promise<ImporterInformation[]> => {
    const data = (await instance.get(importersRoute)).data;

    return data.map((entry: any) => {
      let options = new Map<string, 'Boolean' | 'Number' | 'String' | 'Supllier'>();
      entry.options.forEach((option: any) => {
        options.set(option.key, option.value);
      });

      return {
        type: entry.type,
        name: entry.name,
        options: options
      };
    });
  }
};

export default exportService;
