import Equipment from '../objects/equipment';
import instance, { defaultService, ItemState } from './api';

const makeCreateEquipmentModel = (equipment: Partial<Equipment>) => {
  return {
    reference: equipment.reference,
    name: equipment.name,
    description: equipment.description,
    unit: equipment.unit,
    state: equipment.state,
    monetaryDepreciationByHour: equipment.monetaryDepreciationByHour,
    priceByHour: equipment.priceByHour,
    userType: equipment.userType,
    userProperties: equipment.userProperties,
    warehouses: equipment.warehouses?.map((warehouse) => {
      return {
        amount: warehouse.amount,
        warehouseId: warehouse.warehouse!.id
      };
    })
  };
};

const route = 'equipments';
const equipmentService = {
  get: async (id: string): Promise<Partial<Equipment>> => {
    let result = await defaultService.get(route, id);
    return result.data;
  },
  validate: (equipment: any): boolean => {
    return (
      equipment.reference?.length > 0 &&
      equipment.name?.length > 0 &&
      equipment.monetaryDepreciationByHour > 0
    );
  },
  create: (employee: Partial<Equipment>) =>
    defaultService.create(route, makeCreateEquipmentModel(employee)),
  update: (id: string, employee: Partial<Equipment>) =>
    defaultService.update(route, id, makeCreateEquipmentModel(employee)),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Equipment>(route, itemState),
  getReservations: (id: string, start: Date, end: Date) =>
    instance.get(route + '/' + id + '/actions/get-reservations', {
      params: {
        start: start,
        end: end
      }
    })
};
export default equipmentService;

const getDefaultVehicleProperties = () => {
  return {
    manufacturer: '',
    type: '',
    numberPlate: '',
    serviceContractor: ''
  };
};

const getDefaultToolProperties = () => {
  return {
    manufacturer: '',
    serviceContractor: ''
  };
};

const equipmentPropertyTemplates = {
  getDefaultVehicleProperties,
  getDefaultToolProperties
};

export { equipmentPropertyTemplates };
