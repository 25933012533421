import { Paper, Tabs, Tab } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import TokenService from '../../services/tokenService';
import externalServiceService from '../../services/externalServiceService';
import ExternalService from '../../objects/externalService';
import ExternalServiceInformation from './ExternalServiceInformation';
import ExternalServiceCalendar from './ExternalServiceCalendar';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;
  return <div style={{ flexGrow: 1 }}>{index === value && children}</div>;
}

export default function ExternalServiceDetail() {
  const { t } = useTranslation(['externalServices', 'articles', 'dateFormat', 'detailPages']);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchParams] = useSearchParams();
  const externalServiceId = searchParams.get('id');

  const [externalService, setExternalService] = useState<Partial<ExternalService>>();

  const fetchData = () => {
    if (externalServiceId != null) {
      externalServiceService
        .get(externalServiceId)
        .then((result) => {
          console.log(result);
          setExternalService(result);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    console.log(externalService);
  }, [externalService]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const hasCalendar = TokenService.tenantFullfills(['Calendar'], []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
          <Tab label={t('details')} tabIndex={0} />
          {hasCalendar && <Tab label={t('calendar', { ns: 'detailPages' })} tabIndex={1} />}
        </Tabs>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          {currentTab == 0 && (
            <TabPanel value={currentTab} index={0}>
              <ExternalServiceInformation externalService={externalService} />
            </TabPanel>
          )}
          {hasCalendar && currentTab == 1 && (
            <TabPanel value={currentTab} index={1}>
              <ExternalServiceCalendar />
            </TabPanel>
          )}
        </div>
      </Paper>
    </div>
  );
}
