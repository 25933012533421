import {
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { Page, Document, StyleSheet, Text, Image, View, PDFViewer } from '@react-pdf/renderer';
import CreateDialog from '../Helper/MyDialog';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import jsLogo from '../../assets/ICON_JOSTECSYSTEMS.png';
import articleService from '../../services/articleService';
import PageFormat from './labelFormats/PageFormat';

GenerateQRCodes.propTypes = {
  selectedArticles: PropTypes.array
};

const formats = ['90x29 mm Label', '70x36 mm A4'];

export default function GenerateQRCodes({ selectedArticles }) {
  const [generateAll, setGenerateAll] = useState(false);
  const [format, setFormat] = useState(formats[0]);
  const [articles, setArticles] = useState();
  const [src, setSrc] = useState([]);
  const { t } = useTranslation('qrcode');

  const fetchData = () => {
    if (generateAll)
      articleService.all().then((result) => {
        generateQRCodes(result.rows);
      });
    else generateQRCodes(articles);
  };

  const generateQRCodes = (_articles) => {
    let temp = [];
    _articles?.forEach((item) => {
      QRCode.toDataURL(item.reference).then((result) =>
        temp.push({ data: result, value: item.reference, name: item.name })
      );
    });
    setSrc(temp);
  };

  useEffect(() => {
    fetchData();
  }, [generateAll]);

  useEffect(() => {
    generateQRCodes(articles);
  }, [articles]);

  useEffect(() => {
    setArticles(selectedArticles);
  }, [selectedArticles]);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap'
    }
  });
  //29mmx90mm
  // old: 70x36mm
  const MyDoc = () => (
    <>
      {format == formats[1] && (
        <PDFViewer style={{ width: 450, height: 700 }}>{src && <PageFormat src={src} />}</PDFViewer>
      )}
      {format == formats[0] && (
        <PDFViewer style={{ width: 450, height: 700 }}>
          {src && (
            <Document>
              <Page size={[90, 29]} style={styles.page}>
                {src?.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: 90,
                      height: 29
                    }}
                    wrap={false}>
                    <Image
                      src={item.data}
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                    <View key={index} style={{ maxWidth: 45, marginTop: 3 }}>
                      <Text style={{ fontSize: 4 }}>{item.value}</Text>
                      <Text style={{ fontSize: 4, marginTop: 1, maxLines: 4 }}>{item.name}</Text>
                    </View>
                    <Image
                      src={jsLogo}
                      style={{ height: 10, width: 10, position: 'absolute', bottom: 2, right: 2 }}
                    />
                  </View>
                ))}
              </Page>
            </Document>
          )}
        </PDFViewer>
      )}
    </>
  );

  return (
    <CreateDialog showButton btnOpen={t('Generate')} iconName="QrCode">
      <Paper
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Format</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={format}
            label="Format"
            onChange={(e) => setFormat(e.target.value)}>
            <MenuItem value={formats[0]}>{formats[0]}</MenuItem>
            <MenuItem value={formats[1]}>{formats[1]}</MenuItem>
          </Select>
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={generateAll} onChange={(e) => setGenerateAll(e.target.checked)} />
            }
            label={t('GenerateForAll')}
          />
        </FormGroup>
        {selectedArticles.length == 0 && !generateAll ? (
          <p style={{ backgroundColor: '#ddd', padding: 5, borderRadius: 5, textAlign: 'center' }}>
            {t('NoData')}
          </p>
        ) : (
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <MyDoc />
            </Suspense>
          </div>
        )}
      </Paper>
    </CreateDialog>
  );
}
