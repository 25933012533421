import {
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Box,
  Link,
  Typography,
  IconButton
} from '@mui/material';
import { useEffect, useState } from 'react';
import grayLogo from '../assets/logo_ework.png';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import tenantService from '../services/system/tenantService';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function SupportAccessGrant() {
  const footerLinks = ['conditions', 'privacy', 'legalNotice'];
  const { t } = useTranslation(['app', 'navigation']);
  const [cookieVisible, setCookieVisible] = useState<'show' | 'hidden'>(
    Cookies.get('session') === 'accepted' ? 'hidden' : 'show'
  );
  const [state, setState] = useState<boolean>();
  const [params] = useSearchParams();
  const supportUserId = params.get('id');

  useEffect(() => {
    if (supportUserId)
      tenantService
        .grantSupportAccess(supportUserId)
        .then((result) => setState(result.status == 200))
        .catch(() => setState(false));
    else setState(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }} style={{ background: '#f6f6f6', height: '100vh' }}>
      <Box component="main" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <form
            className="login"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Card sx={{ width: 300 }} style={{ marginBottom: '50px' }}>
              <CardMedia
                style={{
                  paddingTop: '20px',
                  width: '250px',
                  margin: 'auto'
                }}
                component="img"
                image={grayLogo}
                alt="zenamo logo"
              />

              <CardContent>
                {state == undefined ? (
                  <LinearProgress color="error" />
                ) : state ? (
                  <>
                    <IconButton
                      color="success"
                      style={{ transform: 'scale(2)', verticalAlign: 'center' }}>
                      <CheckCircleOutlineIcon />
                    </IconButton>
                    <Typography>{t('support.access.granted')}</Typography>
                  </>
                ) : (
                  <>
                    <IconButton
                      color="error"
                      style={{ transform: 'scale(2)', verticalAlign: 'center' }}>
                      <CancelOutlinedIcon />
                    </IconButton>
                    <Typography>{t('support.access.failed')}</Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </form>
          <CookieConsent
            onAccept={() => {
              Cookies.set('session', 'accepted');
              setCookieVisible('hidden');
            }}
            visible={cookieVisible}
            overlay>
            {t('cookieConsent')}
          </CookieConsent>
        </div>
        <Box
          style={{
            background: '#2C2C2C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {footerLinks.map((i) => (
            <Link
              key={i}
              style={{ margin: 5, fontSize: 15, marginRight: 5, marginLeft: 5 }}
              href={'/' + i}
              color="#c9c9c9"
              underline="hover"
              rel="noopener"
              target="_blank">
              {t(i, { ns: 'navigation' })}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
