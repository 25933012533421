import ArticleBookingAnalysis from '../objects/articleBookingAnalysis';
import EquipmentBookingAnalysis from '../objects/equipmentBookingAnalysis';
import ProjectCostAnalysis from '../objects/projectCostAnalysis';
import instance from './api';

const analysisRoute = 'analysis';

const getArticleBookingAnalysis = async (
  articleId: string,
  start: Date,
  end: Date,
  cumulationTimespan: string,
  prognosisAmount?: number
): Promise<ArticleBookingAnalysis> =>
  (
    await instance.get(`${analysisRoute}/article-bookings/${articleId}`, {
      params: {
        creationGreaterOrEqual: start.toISOString(),
        creationLessOrEqual: end.toISOString(),
        cumulation: cumulationTimespan,
        prognosisAmount: prognosisAmount ?? 7
      }
    })
  ).data;
const getEquipmentBookingAnalysis = async (
  equipmentId: string,
  start: Date,
  end: Date,
  cumulationTimespan: string,
  prognosisAmount?: number
): Promise<EquipmentBookingAnalysis> =>
  (
    await instance.get(`${analysisRoute}/equipment-bookings/${equipmentId}`, {
      params: {
        creationGreaterOrEqual: start.toISOString(),
        creationLessOrEqual: end.toISOString(),
        cumulation: cumulationTimespan,
        prognosisAmount: prognosisAmount ?? 7
      }
    })
  ).data;

const getProjectCostAnalysis = async (
  projectId: string,
  cumulationTimespan: string,
  prognosisAmount?: number
): Promise<ProjectCostAnalysis> =>
  (
    await instance.get(`${analysisRoute}/project-cost/${projectId}`, {
      params: {
        cumulation: cumulationTimespan,
        prognosisAmount: prognosisAmount ?? 7
      }
    })
  ).data;

const analysisService = {
  getArticleBookingAnalysis,
  getEquipmentBookingAnalysis,
  getProjectCostAnalysis
};

export default analysisService;
