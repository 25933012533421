import { useState } from 'react';
import tokenService from '../services/tokenService';

export default function useToken() {
  const [token, setToken] = useState(tokenService.getLocalAccessToken());

  const saveToken = (userToken) => {
    if (userToken) {
      setToken(userToken);
    }
  };

  const deleteToken = () => setToken(undefined);

  return {
    setToken: saveToken,
    deleteToken,
    token
  };
}
