import { Paper, TextField, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Equipment from '../../objects/equipment';
import { equipmentPropertyTemplates } from '../../services/equipmentService';

export default function CreateEquipment(props: any) {
  const { t } = useTranslation('equipments');
  const [equipment, setEquipment] = useState<Partial<Equipment>>(props.equipment);

  useEffect(() => {
    props.handleChange(equipment);
  }, [equipment]);

  return (
    <Paper
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      elevation={0}>
      <>
        <TextField
          required
          error={props.error && !equipment?.reference}
          style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
          label={t('inventoryNumber')}
          variant="outlined"
          value={equipment?.reference ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ reference: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !equipment?.name}
          style={{ marginBottom: 10 }}
          label={t('name')}
          variant="outlined"
          value={equipment?.name ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          style={{ marginBottom: 10 }}
          label={t('state')}
          variant="outlined"
          value={equipment?.state ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ state: e.target.value } })}
        />
        <TextField
          style={{ marginBottom: 10 }}
          fullWidth
          label={t('unit')}
          variant="outlined"
          value={equipment?.unit ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ unit: e.target.value } })}
        />
        <TextField
          style={{ marginBottom: 10 }}
          required
          error={props.error && !equipment?.monetaryDepreciationByHour}
          fullWidth
          label={t('monetaryDepreciationByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={equipment?.monetaryDepreciationByHour?.toString()}
          onChange={(e) =>
            setEquipment({
              ...equipment,
              ...{ monetaryDepreciationByHour: Number(e.target.value) }
            })
          }
        />
        <TextField
          style={{ marginBottom: 10 }}
          required
          error={props.error && !equipment?.priceByHour}
          fullWidth
          label={t('priceByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={equipment?.priceByHour?.toString()}
          onChange={(e) =>
            setEquipment({
              ...equipment,
              ...{ priceByHour: Number(e.target.value) }
            })
          }
        />
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
          <TextField
            required
            error={props.error && !equipment?.userType}
            select
            variant="outlined"
            fullWidth
            label={t('equipmentType')}
            value={equipment?.userType ?? ''}
            onChange={(e) => {
              switch (e.target.value) {
                case 'Equipment':
                  setEquipment({
                    ...equipment,
                    ...{
                      userType: e.target.value,
                      userProperties: {}
                    }
                  });
                  break;
                case 'Tool':
                  setEquipment({
                    ...equipment,
                    ...{
                      userType: e.target.value,
                      userProperties: equipmentPropertyTemplates.getDefaultToolProperties()
                    }
                  });
                  break;
                case 'Vehicle':
                  setEquipment({
                    ...equipment,
                    ...{
                      userType: e.target.value,
                      userProperties: equipmentPropertyTemplates.getDefaultVehicleProperties()
                    }
                  });
                  break;
              }
            }}>
            <MenuItem key="Equipment" value="Equipment">
              {t('typeNames.Equipment')}
            </MenuItem>
            <MenuItem key="Tool" value="Tool">
              {t('typeNames.Tool')}
            </MenuItem>
            <MenuItem key="Vehicle" value="Vehicle">
              {t('typeNames.Vehicle')}
            </MenuItem>
          </TextField>
        </div>
        {Array.from(Object.entries(equipment?.userProperties ?? {})).map((entry) => {
          return (
            <div key={entry[0]} style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                style={{ marginBottom: 10 }}
                fullWidth
                label={t(`${equipment?.userType}.${entry[0]}`) ?? entry[0]}
                variant="outlined"
                value={entry[1] ?? ''}
                onChange={(e) =>
                  setEquipment({
                    ...equipment,
                    ...{
                      userProperties: {
                        ...equipment?.userProperties,
                        ...{ [entry[0]]: e.target.value }
                      }
                    }
                  })
                }
              />
            </div>
          );
        })}
        {/* <TextField fullWidth label={t('description')} multiline rows={4} /> */}
      </>
    </Paper>
  );
}
