/* eslint-disable no-unused-vars */
import { Divider, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExternalService from '../../objects/externalService';

interface ExternalServiceInformationProps {
  externalService?: Partial<ExternalService>;
}

const PropInfo = (props: any) => {
  const { translation, value } = props;
  const { t } = useTranslation(['externalServices', 'dateFormat']);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
      <p style={{ width: 200, margin: 0, fontWeight: 'bold' }}>{t(translation)}:</p>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
};

export default function ExternalServiceInformation(props: ExternalServiceInformationProps) {
  const [externalService, setExternalService] = useState<any>(props.externalService);

  const { t } = useTranslation('externalServices');

  useEffect(() => {
    setExternalService(props.externalService);
  }, [props.externalService]);

  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div style={{ width: '100%', height: '500px' }}>
          <h2 style={{ margin: 5 }}>{t('detailHeader')}</h2>
          <Divider style={{ margin: 10 }} />
          <PropInfo translation="name" value={externalService?.name ?? ''} />
          <PropInfo translation="description" value={externalService?.description ?? ''} />
          <PropInfo
            translation="priceByHour"
            value={(Number(externalService?.priceByHour) ?? '0')?.toFixed(2) + ' €'}
          />
          <PropInfo
            translation="costByHour"
            value={(Number(externalService?.costByHour) ?? '0')?.toFixed(2) + ' €'}
          />

          <PropInfo translation="address" value={externalService?.address ?? ''} />
          <PropInfo translation="city" value={externalService?.city ?? ''} />
          <PropInfo translation="zipCode" value={externalService?.zipCode ?? ''} />
          <PropInfo translation="iban" value={externalService?.iban ?? ''} />
          <PropInfo translation="email" value={externalService?.email ?? ''} />
          <PropInfo translation="phone" value={externalService?.phone ?? ''} />
        </div>
      </Paper>
    </>
  );
}
