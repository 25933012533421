import { defaultService } from './api';
import ArticleBooking from '../objects/articleBooking';

const makeArticleBookingModel = (articleBooking: Partial<ArticleBooking>) => {
  return {
    articleId: articleBooking.article?.id,
    warehouseId: articleBooking.warehouse?.id,
    amount: articleBooking.amount
  };
};

const route = 'article-bookings';
const get = async (): Promise<Partial<ArticleBooking>> => (await defaultService.getAll(route)).data;
const create = (articleBookings: Partial<ArticleBooking>[]) =>
  defaultService.create(route, articleBookings.map(makeArticleBookingModel));

const articleBookingService = {
  get,
  create
};

export default articleBookingService;
