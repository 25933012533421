import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import appointmentService from '../../services/appointmentService';
import employeeService from '../../services/employeeService';
import Employee from '../../objects/employee';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import TokenService from '../../services/tokenService';
import { TenantUser } from '../../objects/user';

// interface Appointment {
//   type: string; //calculated; defined by used endpoint
//   userType: string; //create
// }

export default function CreateAppointment(props: any) {
  const [selectedType, setSelectedType] = useState<'appointment' | 'employee'>('appointment');

  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [employees, setEmployees] = useState<Partial<Employee>[]>([]);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  //const [appointmentUserTypes, setAppointmentUserTypes] = useState<Appointment[]>([]);
  const [selectedUserType, setSelectedUserType] = useState('');
  const [start, setStart] = useState<Date | null>(moment(new Date()).startOf('minute').toDate());
  const [end, setEnd] = useState<Date | null>(moment(new Date()).startOf('minute').toDate());
  const [isWholeDay, setIsWholeDay] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isDoubleBooking, setIsDoubleBooking] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['appointments', 'app']);
  const types = ['appointment', 'employee'];

  async function saveItem() {
    if (
      selectedType &&
      subject &&
      start &&
      end &&
      moment(start).isValid() &&
      moment(end).isValid()
    ) {
      let props: any = {
        userType: selectedUserType,
        subject: subject,
        description: description,
        start: start,
        end: end
      };
      if (selectedType == 'employee') {
        if (!selectedEmployee) setError(true);
        props.employeeId = selectedEmployee?.id;
      }
      try {
        await appointmentService.createAppointment(
          props,
          selectedType == 'appointment' ? '' : selectedType + '-',
          { params: { ignoreConflicts: isDoubleBooking } }
        );
      } catch (error) {
        if (error.response.status == 409) {
          setIsDoubleBooking(true);
          enqueueSnackbar(t('app:doubleBookingDetected'), { variant: 'error' });
          //TODO: show dialog, if response is ok -> do anyway, if no -> show arrow
        } else enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
        return false;
      }
      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  async function getAllTypes() {
    // let appointmentTypes = await appointmentService.getAppointmentUserTypes('Appointment');
    // let projectTypes = await appointmentService.getAppointmentUserTypes('Project');
    // let employeeTypes = await appointmentService.getAppointmentUserTypes('Employee');
    // setAppointmentUserTypes([
    //   ...appointmentTypes.map((obj: any) => ({ userType: obj, type: 'Appointment' })),
    //   ...projectTypes.map((obj: any) => ({ userType: obj, type: 'Project' })),
    //   ...employeeTypes.map((obj: any) => ({ userType: obj, type: 'Employee' }))
    // ]);
  }

  const canOnlyViewOwnAppointments =
    !TokenService.tenantFullfills(['Calendar'], ['Appointments:AllowAccessToAll']) ||
    !TokenService.tenantFullfills([], ['Employees:View']);

  useEffect(() => {
    setSelectedUserType('');
  }, [selectedType]);

  useEffect(() => {
    getAllTypes();

    (async () => {
      await employeeService.all().then((result) => setEmployees(result.rows));
    })();

    setIsDoubleBooking(false);
    setSubject('');
    setSelectedUserType('');
    setSelectedType('appointment');
    setError(false);
    setDescription(props.selectedEvent?.event?.title ?? '');
    setStart(
      props.selectedEvent?.event?.start ??
        props.selectedEvent?.start ??
        props.selectedEvent?.date ??
        new Date()
    );
    setEnd(
      props.selectedEvent?.event?.end ??
        props.selectedEvent?.end ??
        props.selectedEvent?.date ??
        new Date()
    );

    if (canOnlyViewOwnAppointments) {
      let employee = {} as Employee;
      employee.id = (TokenService.getUser() as TenantUser).employee.id;
      setSelectedEmployee(employee);
    } else setSelectedEmployee(undefined);
  }, [props.selectedDate, props.open, props.selectedEvent]);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsWholeDay(event.target.checked);
    setStart(moment(start).startOf('day').toDate());
    setEnd(moment(end).endOf('day').toDate());
  };

  return (
    <>
      <CreateDialog
        noBackdrop
        title={t('addAppointment')}
        saveItem={saveItem}
        open={props.open}
        setOpen={props.setOpen}
        withActions>
        <Paper
          style={{
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          elevation={0}>
          <div>
            <FormControl
              fullWidth
              style={{ marginBottom: 10 }}
              required
              error={error && !selectedType}>
              <InputLabel>{t('category')}</InputLabel>
              <Select
                value={selectedType}
                label={t('category')}
                onChange={(e: any) => setSelectedType(e.target.value)}>
                {types.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {t(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedType.includes('employee') &&
              (canOnlyViewOwnAppointments ? (
                <TextField
                  disabled
                  fullWidth
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  label={
                    (TokenService.getUser() as TenantUser).employee.firstName +
                    ' ' +
                    (TokenService.getUser() as TenantUser).employee.lastName
                  }
                />
              ) : (
                <FormControl
                  fullWidth
                  style={{ marginBottom: 10 }}
                  required
                  error={error && !selectedEmployee}>
                  <InputLabel>{t('employee')}</InputLabel>
                  <Select
                    value={selectedEmployee}
                    label={t('employee')}
                    onChange={(e: any) => setSelectedEmployee(e.target.value)}>
                    {employees.map((employee: any, index: number) => (
                      <MenuItem key={index} value={employee}>
                        {employee.firstName + ' ' + employee.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}

            <TextField
              required
              error={error && !subject}
              style={{ marginBottom: 10 }}
              id="outlined-basic"
              label={t('subject')}
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              fullWidth
            />
            <TextField
              style={{ marginBottom: 10 }}
              id="outlined-basic"
              label={t('description')}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
            {/* <Autocomplete
          disabled={selectedType == ''}
          value={selectedUserType}
          style={{ marginBottom: 10 }}
          groupBy={(option) => option.type}
          onInputChange={(e, inputValue) => {
            setSelectedUserType(inputValue);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={appointmentTypes.filter((item: Appointment) => item.type == selectedType)}
          getOptionLabel={(option: any) => option?.userType ?? option}
          freeSolo
          fullWidth
          renderInput={(params) => <TextField {...params} label={t('type')} />}
        /> */}
            <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 10 }}>
              <DatePicker
                label={t('startdate')}
                value={start}
                onChange={(value: any) => {
                  setStart(value);
                  if (value > end!) setEnd(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} required style={{ width: '50%' }} />
                )}
              />
              {!isWholeDay && (
                <TimePicker
                  label={t('starttime')}
                  value={start}
                  onChange={(value: any) => {
                    setStart(value);
                    if (value > end!) setEnd(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      required
                      style={{ width: '50%' }}
                    />
                  )}
                />
              )}
            </div>
            <div style={{ display: 'flex', gap: 10, width: '100%' }}>
              <DatePicker
                label={t('enddate')}
                value={end}
                onChange={(value: any) => {
                  setEnd(value);
                  if (value < start!) setStart(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} required style={{ width: '50%' }} />
                )}
              />
              {!isWholeDay && (
                <TimePicker
                  label={t('endtime')}
                  value={end}
                  onChange={(value: any) => {
                    setEnd(value);
                    if (value < start!) setStart(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      required
                      style={{ width: '50%' }}
                    />
                  )}
                />
              )}
            </div>
            <FormControlLabel
              checked={isWholeDay}
              control={<Checkbox onChange={handleChangeCheckbox} />}
              label={t('wholeDay')}
            />
            {isDoubleBooking && (
              <Typography color="error" style={{ maxWidth: 460 }}>
                {t('app:doubleBookingDetectedPrompt')}
              </Typography>
            )}
          </div>
        </Paper>
      </CreateDialog>
    </>
  );
}
