import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import { validate } from 'email-validator';

export default function UpdateCustomer(props: any) {
  const { t } = useTranslation('customers');
  const [customer, setCustomer] = useState(props.customer);

  useEffect(() => {
    props.handleChange(customer);
  }, [customer]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          required
          error={props.error && !customer?.name}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('name')}
          variant="outlined"
          value={customer.name ?? ''}
          onChange={(e) => setCustomer({ ...customer, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !customer?.reference}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('reference')}
          variant="outlined"
          value={customer.reference ?? ''}
          onChange={(e) => setCustomer({ ...customer, ...{ reference: e.target.value } })}
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10, marginRight: 10, flex: 1 }}
            label={t('address')}
            variant="outlined"
            value={customer.address ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ address: e.target.value } })}
          />
          <TextField
            style={{ marginBottom: 10, marginRight: 10, width: 100 }}
            label={t('zipCode')}
            variant="outlined"
            value={customer.zipCode ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ zipCode: e.target.value } })}
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('city')}
            variant="outlined"
            value={customer.city ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ city: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!IBAN.isValid(customer?.iban) && customer?.iban?.length > 0}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('iban')}
            variant="outlined"
            value={customer.iban ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ iban: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!validate(customer?.email) && customer?.email?.length > 0}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('email')}
            variant="outlined"
            value={customer.email ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ email: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            label={t('phone')}
            variant="outlined"
            value={customer.phone ?? ''}
            onChange={(e) => setCustomer({ ...customer, ...{ phone: e.target.value } })}
          />
        </div>
      </div>
    </Paper>
  );
}
