import { InputAdornment, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../../objects/article';
import isValidSeperator from '../Helper/seperatorRegex';

interface UpdateArticleProps {
  error: boolean;
  article: Partial<Article>;
  handleChange: Function;
}

export default function UpdateArticle(props: UpdateArticleProps) {
  const { t } = useTranslation('articles');
  const [article, setArticle] = useState(props.article);

  useEffect(() => {
    props.handleChange(article);
  }, [article]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          required
          error={props.error && !article?.reference}
          style={{ marginBottom: 10 }}
          label={t('reference')}
          variant="outlined"
          value={article?.reference}
          onChange={(e) => setArticle({ ...article, ...{ reference: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !article?.name}
          style={{ marginBottom: 10 }}
          label={t('name')}
          variant="outlined"
          value={article?.name}
          onChange={(e) => setArticle({ ...article, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !article?.price}
          InputProps={{
            inputProps: { min: 0, autoComplete: 'new-password' },
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('price')}
          type="number"
          variant="outlined"
          value={article?.price ?? 0}
          onChange={(e) => {
            if (!isValidSeperator(e.target.value)) return;
            setArticle({ ...article, ...{ price: Math.abs(Number(e.target.value)) } });
          }}
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && !article?.minimalAmount}
            style={{ marginBottom: 10, marginRight: 10, flex: 1 }}
            label={t('minimalAmount')}
            variant="outlined"
            onFocus={(event) => event.target.select()}
            type="number"
            value={article?.minimalAmount}
            onChange={(e) =>
              setArticle({ ...article, ...{ minimalAmount: Number(e.target.value) } })
            }
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('unit')}
            variant="outlined"
            value={article?.unit}
            onChange={(e) => setArticle({ ...article, ...{ unit: e.target.value } })}
          />
        </div>
        {/* {article.tags && article.tags.length > 0 && (
          <div style={{ marginBottom: 10 }}>
            <Scroller overflowX="auto" overflowY="hidden">
              <List
                style={{
                  flexWrap: 'nowrap',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  padding: 0
                }}>
                {article.tags?.map((data, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{
                        padding: 8
                      }}>
                      <Chip
                        label={data}
                        onDelete={() => {
                          setArticle({
                            ...article,
                            ...{ tags: article.tags?.filter((_, idx) => idx !== index) }
                          });
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Scroller>
          </div>
        )} */}
        {/* <TextField
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('tags')}
          variant="outlined"
          value={currentTagName ?? ''}
          onChange={(e) => {
            const currentInput = e.target.value;
            const currentTags = currentInput.split(/[\s,;]+/);
            if (currentTags.length > 1) {
              setCurrentTagName('');
              const newArray = (article.tags ?? []).concat(
                currentTags.filter((tag) => tag?.length > 0)
              );
              const onlyUnique = (value: string, index: number, self: string[]) => {
                return self.indexOf(value) === index;
              };
              setArticle({ ...article, ...{ tags: newArray.filter(onlyUnique) } });
            } else setCurrentTagName(currentInput);
          }}
          fullWidth
        /> */}
        {/* <TextField
          fullWidth
          label={t('description')}
          value={article.description}
          onChange={(e) => setArticle({ ...article, ...{ description: e.target.value } })}
          multiline
          rows={4}
        /> */}
      </div>
    </Paper>
  );
}
