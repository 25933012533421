import { TextField, Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateArticle from '../../components/articles/CreateArticle';
import CreateArticleSuppliers from '../../components/articles/CreateArticleSuppliers';
import CreateArticleWarehouses from '../../components/articles/CreateArticleWarehouses';
import GenerateQRCodes from '../../components/articles/GenerateQRCodes';
import ChangeIndicator from '../../components/ChangeIndicator';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import Warehouse from '../../objects/warehouses';
import articleService from '../../services/articleService';
import TokenService from '../../services/tokenService';
import SearchIcon from '@mui/icons-material/Search';
import Supplier from '../../objects/supplier';
import { ItemState } from '../../services/api';
import Article from '../../objects/article';
import { useSnackbar } from 'notistack';

interface CustomToolbarProps {
  warehouses: Partial<Warehouse>[];
  suppliers: Partial<Supplier>[];
  changeAmount: number | undefined;
  setChangeAmount(input: number): void;
  fetchData(): void;
  articlesState: ItemState;
  setArticlesState(input: ItemState): void;
  selectedArticles: Map<string, Partial<Article>>;
}

const CustomToolbar = (props: CustomToolbarProps) => {
  const {
    warehouses,
    suppliers,
    changeAmount,
    setChangeAmount,
    fetchData,
    articlesState,
    setArticlesState,
    selectedArticles
  } = props;
  const { t } = useTranslation(['articles', 'app', 'customToolBar']);
  const { enqueueSnackbar } = useSnackbar();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [beforeSearchState, setBeforeSearchState] = useState<ItemState>();

  const canUpdateArticles = TokenService.tenantFullfills([], ['Articles:Update']);

  useEffect(() => {
    if (searchText == null) setBeforeSearchState(articlesState);
  }, [articlesState]);

  function handleSearch() {
    if (articlesState != null && searchText != null) {
      articlesState.filterType = 'or';
      articlesState.filter.set('reference', searchText);
      articlesState.filter.set('name', searchText);
      setArticlesState({ ...articlesState });
      setIsSearching(false);
    } else if (isSearching) setArticlesState(beforeSearchState!);
  }

  return (
    <GridToolbarContainer>
      {isSearching ? (
        <div style={{ margin: 5, display: 'flex', flex: 1 }}>
          <TextField
            size="small"
            label={t('search')}
            style={{ flex: 5 }}
            variant="outlined"
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && handleSearch()}
            autoFocus
            spellCheck={false}
          />
          <Button
            variant="contained"
            style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
            onClick={handleSearch}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <Button size="small" onClick={() => setIsSearching(false)}>
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <>
          {canUpdateArticles && (
            <ItemDialog
              withText
              kind={ItemDialogKind.Create}
              type={t('type')}
              validate={articleService.validate}
              confirm={(item) => {
                articleService
                  .create(item)
                  .then(() => fetchData())
                  .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
              }}>
              {{
                label: t('general'),
                render: (item, handleChange, validation) => (
                  <CreateArticle article={item} handleChange={handleChange} error={validation} />
                )
              }}
              {{
                label: t('warehouses'),
                render: (item, handleChange) => (
                  <CreateArticleWarehouses
                    article={item}
                    warehouses={warehouses}
                    handleChange={handleChange}
                  />
                )
              }}
              {{
                label: t('suppliers'),
                render: (item, handleChange) => (
                  <CreateArticleSuppliers
                    article={item}
                    suppliers={suppliers}
                    handleChange={handleChange}
                  />
                )
              }}
            </ItemDialog>
          )}
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <GenerateQRCodes selectedArticles={Array.from(selectedArticles.values())} />
          <Button size="small" startIcon={<SearchIcon />} onClick={() => setIsSearching(true)}>
            {t('search', { ns: 'customToolBar' })}
          </Button>

          <ChangeIndicator
            changeAmount={changeAmount ?? 0}
            setChangeAmount={setChangeAmount}
            type={'article'}
            onClick={fetchData}
          />
        </>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
