import { Paper, Divider, Checkbox, Button, styled } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InventoryIcon from '@mui/icons-material/Inventory';
import ProjectIcon from '@mui/icons-material/AccountTree';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ActionPanelButton = styled(Button)(() => ({
  margin: 10,
  marginBottom: 0,
  paddingTop: 20,
  paddingBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const ActionPanelButtonText = styled('p')(() => ({
  margin: 0,
  marginTop: 10,
  textTransform: 'none'
}));

interface QuickActionPanelProps {
  allowArticleBookings: boolean;
  allowArticleProjectBookings: boolean;
  selectedArticles: any[];
}

export default function QuickActionPanel(props: QuickActionPanelProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('quickAction');

  return (
    <Paper
      variant="outlined"
      style={{
        margin: 10,
        width: 120,
        display: 'flex',
        flexDirection: 'column'
      }}
      elevation={0}>
      <div style={{ margin: 10, alignItems: 'center', display: 'flex', height: 75 }}>
        <p style={{ textAlign: 'center', margin: 5, flex: 1, fontSize: 18 }}>{t('quickActions')}</p>
      </div>

      <Divider />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Checkbox edge="start" checked tabIndex={-1} disableRipple />
        <p>{props.selectedArticles?.length ?? 0}</p>
      </div>
      {props.allowArticleBookings && (
        <ActionPanelButton
          variant="outlined"
          disabled={props.selectedArticles.length === 0}
          onClick={() =>
            navigate('/articles/movement', {
              state: { articles: props.selectedArticles, type: 'rearrange' }
            })
          }>
          <InventoryIcon fontSize="large" />
          <ActionPanelButtonText>{t('bookWarehose')}</ActionPanelButtonText>
        </ActionPanelButton>
      )}
      {props.allowArticleProjectBookings && (
        <ActionPanelButton
          variant="outlined"
          disabled={props.selectedArticles.length === 0}
          onClick={() =>
            navigate('/articles/project-movement', { state: { articles: props.selectedArticles } })
          }>
          <ProjectIcon fontSize="large" />
          <ActionPanelButtonText>{t('bookProject')}</ActionPanelButtonText>
        </ActionPanelButton>
      )}
      {props.allowArticleBookings && (
        <>
          <ActionPanelButton
            variant="outlined"
            disabled={props.selectedArticles.length === 0}
            onClick={() =>
              navigate('/articles/movement', {
                state: { articles: props.selectedArticles, type: 'receipt' }
              })
            }>
            <AddCircleOutlineIcon fontSize="large" />
            <ActionPanelButtonText>{t('goodsReceipt')}</ActionPanelButtonText>
          </ActionPanelButton>
          <ActionPanelButton
            variant="outlined"
            disabled={props.selectedArticles.length === 0}
            onClick={() =>
              navigate('/articles/movement', {
                state: { articles: props.selectedArticles, type: 'issue' }
              })
            }>
            <RemoveCircleOutlineIcon fontSize="large" />
            <ActionPanelButtonText>{t('goodsIssue')}</ActionPanelButtonText>
          </ActionPanelButton>
        </>
      )}
    </Paper>
  );
}
