/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { useTheme } from '@mui/material/styles';
import useAmLocales from '../hooks/useAmLocales';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface TimeSeriesWithPrognosisChartLegendProps {
  label: string;
  prognosisLabel: string;
  cumulationLabel?: string;
}
export interface Limit {
  label: string;
  value: any;
  color: 'information' | 'warning' | 'alarm';
}
export interface TimeSeriesWithPrognosisChartProps<Point> {
  data: Point[];
  prognosis: Point[];
  getDate: (point: Point) => Date;
  getPositiveValue: (point: Point) => any;
  getNegativeValue?: (point: Point) => any;
  legend?: TimeSeriesWithPrognosisChartLegendProps;
  limits?: Limit[];
  cumulation?: boolean;
  cumulationOffset?: number;
}

interface InternalPoint {
  date: number;
  positiveValue: number;
  negativeValue: number;
  value: number;
  absoluteValue: number;
  prognosis: boolean;
}

export default function TimeSeriesWithPrognosisChart<Point>(
  props: TimeSeriesWithPrognosisChartProps<Point>
) {
  const theme = useTheme();
  const [data, setData] = useState<InternalPoint[]>([]);
  const [root, setRoot] = useState<am5.Root>();
  const [start, setStart] = useState(
    moment(new Date(props?.getDate(props?.data[0])?.valueOf())).toDate() ?? new Date()
  );
  const [end, setEnd] = useState(new Date());
  const [dateAxis, setDateAxis] = useState<any>();
  const { t } = useTranslation(['appointments']);

  const limitColorStringToColor = (color: 'information' | 'warning' | 'alarm') => {
    switch (color) {
      case 'information':
        return am5.color(theme.palette.info.main);
      case 'warning':
        return am5.color(theme.palette.warning.main);
      case 'alarm':
        return am5.color(theme.palette.error.main);
    }
  };

  useEffect(() => {
    if (dateAxis) dateAxis!.zoomToDates(new Date(start), new Date(end));
  }, [start, end]);

  useEffect(() => {
    setRoot(am5.Root.new('chartdiv'));

    let absoluteTotalValue = 0;
    props.data.forEach((value) => {
      const pos = Math.abs(props.getPositiveValue(value));
      const neg = props.getNegativeValue != null ? Math.abs(props.getNegativeValue(value)) * -1 : 0;
      absoluteTotalValue += pos + neg;
    });

    absoluteTotalValue =
      (props.cumulationOffset ?? 0) > 0 ? props.cumulationOffset! - absoluteTotalValue : 0;
    let absoluteValue = 0;
    setData(
      props.data
        .map((point) => {
          const pos = Math.abs(props.getPositiveValue(point));
          const neg =
            props.getNegativeValue != null ? Math.abs(props.getNegativeValue(point)) * -1 : 0;
          const absValue = (absoluteValue += pos + neg);
          return {
            date: props.getDate(point).valueOf(),
            positiveValue: pos,
            negativeValue: neg,
            value: pos + neg,
            absoluteValue: absValue,
            absoluteTotalValue: absValue + absoluteTotalValue,
            prognosis: false
          };
        })
        .concat(
          props.prognosis.map((point) => {
            const pos = Math.abs(props.getPositiveValue(point));
            const neg =
              props.getNegativeValue != null ? Math.abs(props.getNegativeValue(point)) * -1 : 0;
            const absValue = (absoluteValue += pos + neg);
            return {
              date: props.getDate(point).valueOf(),
              positiveValue: pos,
              negativeValue: neg,
              value: pos + neg,
              absoluteValue: absValue,
              absoluteTotalValue: absValue + absoluteTotalValue,
              prognosis: true
            };
          })
        )
    );
  }, []);

  useEffect(() => {
    makeChart();
  }, [data, root]);

  const makeChart = async () => {
    if (root == null) return;

    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = useAmLocales();
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true
      })
    );

    let cursor = chart.set('cursor', am5xy.XYCursor.new(root, { behavior: 'none' }));
    cursor.lineY.set('visible', false);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        tooltipDateFormat: 'yyyy-MM-dd'
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, { pan: 'zoom' })
      })
    );

    if (props.limits) {
      for (const limit of props.limits) {
        const color = limitColorStringToColor(limit.color);
        const rangeDataItem = yAxis.makeDataItem({
          above: true,
          value: limit.value
        });
        yAxis.createAxisRange(rangeDataItem);
        rangeDataItem.get('grid')!.setAll({
          stroke: color,
          strokeOpacity: 1
        });
        rangeDataItem.get('label')!.setAll({
          background: am5.RoundedRectangle.new(root, {
            fill: color
          }),
          text: limit.value
        });
      }
    }

    let positiveSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'positiveValue',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}'
        })
      })
    );
    positiveSeries.columns.template.setAll({
      strokeOpacity: 0,
      strokeWidth: 0,
      cornerRadiusTL: 2,
      cornerRadiusTR: 2
    });
    positiveSeries.columns.template.adapters.add('fill', function (fill, target) {
      const point = target.dataItem?.dataContext as InternalPoint;
      if (point == null) return am5.color(0xa71d31);

      if (!point.prognosis) return am5.color(0xa71d31);
      else return am5.color(0x1da792);
    });
    positiveSeries.data.setAll(data);
    positiveSeries.appear(1000);

    if (props.getNegativeValue) {
      let negativeSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'negativeValue',
          valueXField: 'date',
          stacked: true,
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}'
          })
        })
      );
      negativeSeries.columns.template.setAll({
        strokeOpacity: 0,
        strokeWidth: 0,
        cornerRadiusTL: 2,
        cornerRadiusTR: 2
      });
      negativeSeries.columns.template.adapters.add('fill', function (fill, target) {
        const point = target.dataItem?.dataContext as InternalPoint;
        if (point == null) return am5.color(0xa71d31);

        let desiredFill;
        if (!point.prognosis) desiredFill = am5.color(0xa71d31);
        else desiredFill = am5.color(0x1da792);

        return am5.Color.brighten(desiredFill, 1.001);
      });
      negativeSeries.data.setAll(data);
      negativeSeries.appear(1000);
    }

    if (props.cumulation) {
      let absoluteSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'absoluteValue',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{absoluteTotalValue}'
          })
        })
      );
      absoluteSeries.strokes.template.setAll({
        strokeWidth: 3,
        strokeDasharray: [8, 4],
        templateField: 'strokeSettings'
      });
      absoluteSeries.data.setAll(
        data.map((point) => {
          const isFirst = data.indexOf(point) == 0;
          const firstPrognosisIndex = data.findIndex((point) => point.prognosis);
          const isLastReal = data.indexOf(point) == firstPrognosisIndex - 1;

          return {
            ...point,
            ...{
              bulletSettings: { visible: isFirst || isLastReal },
              strokeSettings: {
                stroke: point.prognosis ? am5.color(0x1da792) : am5.color(0x471aba)
              }
            }
          };
        })
      );
      absoluteSeries.bullets.push(function (root) {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            fill: am5.color(0x471aba),
            radius: 4,
            templateField: 'bulletSettings'
          })
        });
      });
      absoluteSeries.appear(1000);
    }

    let scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 60
      })
    );

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'hour',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {})
      })
    );

    let sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );
    let sbSeries = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'value',
        valueXField: 'date',
        xAxis: sbDateAxis,
        yAxis: sbValueAxis
      })
    );
    setDateAxis(xAxis);
    // we have to save the exporting into a variable with the exact name: exporting!
    // do not delete!
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      dataSource: data,
      title: 'Analyse E-Work',
      pdfOptions: {
        addURL: false
      }
    });

    if (props.legend) {
      let legend = chart.children.push(
        am5.Legend.new(root, {
          nameField: 'name',
          fillField: 'color',
          strokeField: 'color',
          templateField: 'template',
          centerX: am5.percent(50),
          x: am5.percent(50),
          layout: am5.GridLayout.new(root, {
            maxColumns: 2
          })
        })
      );
      let legendData: any[] = [
        {
          name: props.legend.label,
          color: am5.color(0xa71d31)
        },
        {
          name: props.legend.prognosisLabel,
          color: am5.color(0x1da792)
        }
      ];
      if (props.cumulation)
        legendData.push({
          name: props.legend.cumulationLabel,
          color: am5.color(0x471aba)
        });
      legend.data.setAll(legendData);
      legend.appear(1000);
    }
    sbSeries.data.setAll(data);

    chart.appear(1000, 100);
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
        <DatePicker
          label={t('startdate')}
          value={start}
          onChange={(value: any) => {
            setStart(value);
            if (value > end) setEnd(value);
          }}
          renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
        />
        <DatePicker
          label={t('enddate')}
          value={end}
          onChange={(value: any) => {
            setEnd(value);
            if (value < start) setStart(value);
          }}
          renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
        />
      </div>

      <div
        id="chartdiv"
        style={{
          width: '100%',
          height: '75vh',
          display: props?.data.length <= 0 ? 'none' : 'visible'
        }}></div>
      <div
        style={{
          display: props?.data.length > 0 ? 'none' : 'flex',
          width: '100%',
          height: '75vh',
          textAlign: 'center',
          verticalAlign: 'center',
          backgroundColor: '#ccc',
          borderRadius: 10,
          justifyContent: 'center'
        }}>
        <p style={{ placeSelf: 'center' }}>No Data</p>
      </div>
    </>
  );
}
