import ExternalService from '../objects/externalService';
import { defaultService, ItemState } from './api';

const route = 'external-services';
const externalServiceService = {
  validate: (externalService: any): boolean =>
    externalService?.name?.length > 0 && externalService?.description?.length > 0,
  get: async (id: string): Promise<Partial<ExternalService>> =>
    (await defaultService.get(route, id)).data,
  create: (employee: Partial<ExternalService>) => defaultService.create(route, employee),
  update: (id: string, employee: Partial<ExternalService>) =>
    defaultService.update(route, id, employee),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<ExternalService>(route, itemState)
};

export default externalServiceService;
