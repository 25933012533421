import axios from 'axios';
import Paginated from '../objects/paginated';
import PaginationSettings from '../paginationSettings';
import TokenService from './tokenService';
import eventHandler from './eventHandler';
import { EmployeeAppointment } from '../objects/appointment';
import moment from 'moment';

let url = new URL(window.location.origin);
url.port = (url.protocol == 'http:' ? 80 : 443).toString();
const baseURL = url + 'api/';

var instanceIsGettingRefreshToken = false;

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config!.headers!['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      TokenService.resetLogoutTimer();
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== '/authentication-refresh' && err.response) {
      // Access Token is expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (instanceIsGettingRefreshToken) return instance(originalConfig);

        instanceIsGettingRefreshToken = true;

        try {
          const rs = await instance.post('/authentication-refresh', {
            accessToken: TokenService.getUser().token,
            refreshToken: TokenService.getUser().refreshToken
          });
          const { accessToken, refreshToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken, refreshToken);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        } finally {
          instanceIsGettingRefreshToken = false;
        }
      }
    } else {
      TokenService.removeUser();
      eventHandler.dispatch('logout');
    }
    return Promise.reject(err);
  }
);
export default instance;

class ChangeService {
  changeCallbacks: Function[];

  constructor() {
    this.changeCallbacks = new Array<Function>(0);
  }

  public change() {
    this.changeCallbacks.forEach((callback) => {
      try {
        callback();
      } catch (exception) {
        console.log(exception);
      }
    });
  }

  public onChange(onChange: Function) {
    this.changeCallbacks.push(onChange);
    return (): void => {
      this.changeCallbacks.splice(this.changeCallbacks.indexOf(onChange));
    };
  }
}
const changeServices = new Map<string, ChangeService>();

const changeService = {
  get: (name: string): ChangeService => {
    if (!changeServices.has(name)) changeServices.set(name, new ChangeService());
    return changeServices.get(name)!;
  }
};

export { changeService };

class AppointmentsNotificationService {
  appointments: Partial<EmployeeAppointment>[];
  shown15minuteWarning: string[];
  showWarning?: (appointment: Partial<EmployeeAppointment>) => void;

  constructor(checkPeriodMs: number) {
    this.appointments = [];
    this.shown15minuteWarning = [];
    setInterval(() => this.updateAppointments(this.appointments), checkPeriodMs);
  }

  public setShowWarning(showWarning: (appointment: Partial<EmployeeAppointment>) => void) {
    this.showWarning = showWarning;
  }

  public updateAppointments(appointments: Partial<EmployeeAppointment>[]) {
    this.appointments = appointments;
    let newIds = new Set(appointments.map((appointment) => appointment.id));
    this.shown15minuteWarning = this.shown15minuteWarning.filter((id) => newIds.has(id));
    for (let appointment of appointments) {
      var dateNow = new Date();
      var dateIn15Minutes = moment(dateNow).add(15, 'm').toDate();
      var appointmentStart = moment(appointment.start!).toDate();
      if (appointmentStart > dateNow && appointmentStart < dateIn15Minutes) {
        if (this.shown15minuteWarning.indexOf(appointment.id!) < 0 && this.showWarning != null) {
          this.showWarning(appointment);
          this.shown15minuteWarning.push(appointment.id!);
        }
      }
    }
  }
}

const appointmentsNotificationService = new AppointmentsNotificationService(60 * 1000);
export { appointmentsNotificationService };

const defaultGetPrimitive = (name: any) => {
  return instance.get('/' + name);
};
const defaultGet = (name: any, id: any) => {
  return instance.get('/' + name + '/' + id);
};
const defaultCreate = (name: any, params: any, queryParams?: any) => {
  return instance.post('/' + name, params, queryParams);
};
const defaultUpdate = (name: any, id: string, params: any, queryParams?: any) => {
  return instance.post('/' + name + '/' + id, params, queryParams);
};
const defaultDelete = (name: any, id: any) => {
  return instance.delete('/' + name + '/' + id);
};
const defaultGetAll = (name: any, itemState?: ItemState) => {
  let params = itemState == null ? new Map<string, string>() : getItemStateAsParameters(itemState);
  return instance.get('/' + name, {
    params: params,
    paramsSerializer: (params: Map<string, string>) => {
      return Array.from(params.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
  });
};
const defaultGetAllPaginated = async <T>(
  name: any,
  itemState?: ItemState,
  // eslint-disable-next-line no-unused-vars
  transform?: (received: any) => T
): Promise<Paginated<T>> => {
  let result = await defaultGetAll(name, itemState);
  return {
    rows: transform == null ? result.data.elements : result.data.elements.map(transform),
    page: result.data.page,
    pageSize: result.data.pageSize,
    rowCount: result.data.totalElements
  };
};

const defaultService = {
  getPrimitive: defaultGetPrimitive,
  get: defaultGet,
  create: defaultCreate,
  update: defaultUpdate,
  delete: defaultDelete,
  getAll: defaultGetAll,
  getAllPaginated: defaultGetAllPaginated
};

const getToken = () => {
  return instance.get('/token');
};

const tokenService = {
  get: getToken
};

export interface ItemState {
  paginationSettings?: PaginationSettings;
  stagedFilter?: Map<string, string>;
  filter: Map<string, string>;
  sort: Map<string, 'asc' | 'desc'>;
  filterType?: 'or' | 'and';
}

const getItemStateFromUrl = (urlQuery: URLSearchParams, pageSize: number): ItemState => {
  let page = Number.parseInt(urlQuery.get('page') ?? '0');
  let filterKeys = Array.from(urlQuery.keys()).filter((key) => key.startsWith('filter-'));
  let stagedFilterKeys = Array.from(urlQuery.keys()).filter((filter) =>
    filter.startsWith('staged-filter-')
  );
  let sortKeys = Array.from(urlQuery.keys()).filter((paramName) => paramName.startsWith('sort-'));

  let stagedFilters = new Map<string, string>();
  stagedFilterKeys.forEach((stagedFilterKey) => {
    let filterValue = urlQuery.get(stagedFilterKey);
    if (filterValue != null)
      stagedFilters.set(stagedFilterKey.substring('staged-filter-'.length), filterValue);
  });

  let filters = new Map<string, string>();
  filterKeys.forEach((filterKey) => {
    let filterValue = urlQuery.get(filterKey);
    if (filterValue != null) filters.set(filterKey.substring('filter-'.length), filterValue);
  });

  let sorts = new Map<string, 'asc' | 'desc'>();
  sortKeys.forEach((sortKey) => {
    let sortValue = urlQuery.get(sortKey);
    if (sortValue != null)
      sorts.set(sortKey.substring('sort-'.length), sortValue == 'asc' ? 'asc' : 'desc');
  });

  return {
    paginationSettings: {
      page: Number.isNaN(page) ? 0 : page,
      pageSize: pageSize
    },
    stagedFilter: stagedFilters,
    filter: filters,
    sort: sorts,
    filterType: urlQuery.get('filter') == 'or' ? 'or' : 'and'
  };
};
const setItemStateAsUrl = (
  urlQuery: URLSearchParams,
  // eslint-disable-next-line no-unused-vars
  setSearchParams: (urlQuery: URLSearchParams) => void,
  itemState: ItemState
) => {
  let originalSearchParams = urlQuery.toString();

  if (urlQuery.has('page')) urlQuery.delete('page');
  Array.from(urlQuery.keys())
    .filter((key) => key.startsWith('filter-'))
    .forEach((key) => urlQuery.delete(key));
  Array.from(urlQuery.keys())
    .filter((key) => key.startsWith('staged-filter-'))
    .forEach((key) => urlQuery.delete(key));
  Array.from(urlQuery.keys())
    .filter((key) => key.startsWith('sort-'))
    .forEach((key) => urlQuery.delete(key));
  if (urlQuery.has('filter')) urlQuery.delete('filter');

  if (itemState.paginationSettings != null && itemState.paginationSettings.page > 0)
    urlQuery.set('page', itemState.paginationSettings.page.toString());
  itemState.stagedFilter?.forEach((value, key) => {
    let stagedFilterKey = 'staged-filter-' + key;
    if (value != undefined && value.length > 0) urlQuery.set(stagedFilterKey, value);
  });
  itemState.filter?.forEach((value, key) => {
    let filterKey = 'filter-' + key;
    if (value != undefined && value.length > 0) urlQuery.set(filterKey, value);
  });
  itemState.sort?.forEach((value, key) => {
    let sortKey = 'sort-' + key;
    if (value != undefined && value.length > 0) urlQuery.set(sortKey, value);
  });
  if (itemState.filterType != null && itemState.filterType == 'or') urlQuery.set('filter', 'or');

  let modifiedSearchParams = urlQuery.toString();
  if (originalSearchParams != modifiedSearchParams) setSearchParams(urlQuery);
};
const getItemStateAsParameters = (itemState: ItemState): Map<string, string> => {
  let paramsMap = new Map<string, string>();
  if (itemState.paginationSettings != null) {
    if (itemState.paginationSettings.page > 0)
      paramsMap.set('page', itemState.paginationSettings.page.toString());
    if (itemState.paginationSettings.pageSize > 0)
      paramsMap.set('pageSize', itemState.paginationSettings.pageSize.toString());
  }
  paramsMap.set('filter', itemState.filterType ?? 'and');

  itemState.stagedFilter?.forEach((value, key) => {
    if (value != undefined && value.length > 0) paramsMap.set(key, value);
  });
  itemState.filter?.forEach((value, key) => {
    if (value != undefined && value.length > 0) paramsMap.set(key, value);
  });
  itemState.sort?.forEach((value, key) => {
    let sortKey = 'sortBy' + key;
    if (value != undefined && value.length > 0)
      paramsMap.set(sortKey, value == 'asc' ? 'true' : 'false');
  });
  return paramsMap;
};

const itemStateService = {
  getItemStateFromUrl,
  setItemStateAsUrl,
  getItemStateAsParameters
};

export { defaultService, tokenService, itemStateService };
