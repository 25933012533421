/* eslint-disable no-unused-vars */
import { Divider, Paper } from '@mui/material';
import Article from '../../objects/article';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

interface ArticleBookingTrendProps {
  article: Partial<Article> | undefined;
}

const PropInfo = (props: any) => {
  const { translation, value } = props;
  const { t } = useTranslation(['articles', 'dateFormat']);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
      <p style={{ width: 200, margin: 0, fontWeight: 'bold' }}>{t(translation)}:</p>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
};

export default function ArticleInformation(props: ArticleBookingTrendProps) {
  const { t } = useTranslation('articles');
  const { article } = props;

  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div style={{ width: '100%', height: '500px' }}>
          <h2 style={{ margin: 5 }}>{t('articleDetails')}</h2>
          <Divider style={{ margin: 10 }} />
          <PropInfo translation="reference" value={article?.reference ?? ''} />
          <PropInfo translation="name" value={article?.name ?? ''} />
          <PropInfo
            translation="price"
            value={(Math.round((article?.price ?? 0) * 100) / 100)?.toFixed(2) + ' €'}
          />
          <PropInfo translation="totalAmount" value={article?.totalAmount ?? ''} />
          <PropInfo translation="minimalAmount" value={article?.minimalAmount ?? ''} />
          <PropInfo translation="lowestPrice" value={(article?.lowestPrice ?? 0) + ' €'} />
          <PropInfo translation="reservedAmount" value={article?.reservedAmount ?? '0'} />
          <PropInfo translation="trend" value={t('trends.' + (article?.trend ?? 'Stable'))} />
          <Divider style={{ margin: 10 }} />
          <PropInfo translation="lastEditor" value={article?.lastEditor?.displayName ?? ''} />
          <PropInfo
            translation="lastEdit"
            value={moment(article?.lastEdit).format(t('longDate', { ns: 'dateFormat' })) ?? ''}
          />
        </div>
      </Paper>
    </>
  );
}
