import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navigationService from '../../services/navigationService';
import { Paper, Button, TextField, Chip, MenuItem, Select } from '@mui/material';
import { DataGridPro, GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import DeleteDialog from '../../components/Helper/DeleteDialog';
import warehouseService from '../../services/warehouseService';
import { useSnackbar } from 'notistack';
import Warehouse from '../../objects/warehouses';
import Equipment, { EquipmentWarehouse } from '../../objects/equipment';
import equipmentBookingService from '../../services/equipmentBookingService';

interface EquipmentMovementLocationState {
  equipments: Partial<Equipment>[];
  type: 'receipt' | 'issue' | 'rearrange';
}

interface EquipmentMovementData extends Partial<Equipment> {
  movementAmount: number;
  movementFromWarehouse: Partial<Warehouse>;
  movementToWarehouse: Partial<Warehouse> | null;
}

export default function EquipmentMovement() {
  const { t } = useTranslation(['equipments', 'toolbar']);
  const navigate = useNavigate();
  const location = useLocation();
  const { createColumn } = navigationService.create(t, navigate);
  const { enqueueSnackbar } = useSnackbar();
  const locationState = location.state as EquipmentMovementLocationState;
  const [currentChangeAmount, setCurrentChangeAmount] = useState(0);
  const [currentChangeWarehouse, setCurrentChangeWarehouse] = useState();
  const [currentChangeWarehouses, setCurrentChangeWarehouses] = useState<any>();

  const [equipments, setEquipments] = useState<EquipmentMovementData[]>([]);
  const [warehouses, setWarehouses] = useState<Partial<Warehouse>[]>([]);
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    let tempColumns = [
      createColumn('name', { minWidth: 170 }),
      createColumn('reference', { minWidth: 100 }),
      createColumn('totalAmount', { minWidth: 60, type: 'number' }),
      createColumn('movementAmount', {
        headerName: t('movement.amount'),
        minWidth: 100,
        renderCell: (params: any) => {
          return (
            <TextField
              InputProps={{ inputProps: { min: 0, autoComplete: 'new-password' } }}
              style={{ background: '#ffffe6' }}
              fullWidth
              type="number"
              value={params.row.movementAmount ?? currentChangeAmount ?? 0}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                let parsedNumber = Math.abs(Number.parseInt(e.target.value));
                params.row.movementAmount = parsedNumber;
                setCurrentChangeAmount(parsedNumber);
              }}
            />
          );
        }
      })
    ];
    if (locationState.type != 'receipt')
      tempColumns.push(
        createColumn('movementFromWarehouse', {
          minWidth: 200,
          headerName: t('movement.fromWarehouse'),
          renderCell: (params: any) => (
            <Select
              fullWidth
              style={{ background: params.row.warehouses?.length < 1 ? 'white' : '#ffffe6' }}
              disabled={params.row.warehouses?.length < 1}
              label={t('movement.fromWarehouse')}
              onChange={(e) => {
                e.stopPropagation();
                let warehouse = params.row.warehouses?.find(
                  (warehouse: any) => warehouse.warehouse?.id == e.target.value
                );
                if (warehouse?.warehouse != undefined) {
                  params.row.movementFromWarehouse = warehouse.warehouse;
                  setCurrentChangeWarehouse(warehouse.warehouse);
                }
              }}
              value={params.row.movementFromWarehouse?.id ?? currentChangeWarehouse ?? ''}>
              {params.row.warehouses?.map((warehouse: EquipmentWarehouse) => (
                <MenuItem key={warehouse.warehouse.id} value={warehouse?.warehouse?.id}>
                  <Chip size="small" label={warehouse.amount ?? 0} style={{ marginRight: 10 }} />
                  {warehouse?.warehouse?.displayName}
                </MenuItem>
              ))}
            </Select>
          )
        })
      );
    else
      tempColumns.push(
        createColumn('movementToWarehouse', {
          headerName: t('movement.article.toWarehouse'),
          minWidth: 200,
          renderCell: (params: any) => {
            return (
              <Select
                fullWidth
                style={{ background: warehouses.length < 0 ? 'white' : '#ffffe6' }}
                disabled={warehouses.length < 0}
                label={t('movement.article.toWarehouse')}
                onChange={(e) => {
                  e.stopPropagation();
                  let warehouse = warehouses?.find((warehouse) => warehouse?.id == e.target.value);
                  if (warehouse != undefined) {
                    params.row.movementToWarehouse = warehouse;
                    setCurrentChangeWarehouses(warehouse);
                  }
                }}
                value={params.row?.movementToWarehouse?.id ?? currentChangeWarehouses ?? ''}>
                {warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    <Chip
                      size="small"
                      label={
                        params.row.warehouses?.filter((i: any) => i.warehouse.id == warehouse.id)[0]
                          ?.amount ?? 0
                      }
                      style={{ marginRight: 10 }}
                    />
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            );
          }
        })
      );

    if (locationState.type == 'rearrange')
      tempColumns.push(
        createColumn('movementToWarehouse', {
          headerName: t('movement.article.toWarehouse'),
          minWidth: 200,
          renderCell: (params: any) => {
            return (
              <Select
                fullWidth
                style={{ background: warehouses.length < 0 ? 'white' : '#ffffe6' }}
                disabled={warehouses.length < 0}
                label={t('movement.article.toWarehouse')}
                onChange={(e) => {
                  e.stopPropagation();

                  let warehouse = warehouses?.find((warehouse) => warehouse?.id == e.target.value);
                  if (warehouse != undefined) {
                    params.row.movementToWarehouse = warehouse;
                    setCurrentChangeWarehouses(warehouse);
                  }
                }}
                value={params.row?.movementToWarehouse?.id ?? currentChangeWarehouses ?? ''}>
                {warehouses
                  .filter((warehouse) => warehouse.id !== params.row?.movementFromWarehouse?.id)
                  .map((warehouse) => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>
                      <Chip
                        size="small"
                        label={
                          params.row.warehouses?.filter(
                            (i: any) => i.warehouse.id == warehouse.id
                          )[0]?.amount ?? 0
                        }
                        style={{ marginRight: 10 }}
                      />
                      {warehouse.name}
                    </MenuItem>
                  ))}
              </Select>
            );
          }
        })
      );

    tempColumns.push(
      createColumn('actions', {
        headerName: '',
        maxWidth: 80,
        width: 80,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          const onDeleteItem = () =>
            setEquipments((prevState) =>
              prevState.filter((equipment) => equipment.reference != params.row.reference)
            );

          return (
            <DeleteDialog
              isSelection
              deleteItem={onDeleteItem}
              type={t('type')}
              name={params.row.name}
            />
          );
        }
      })
    );

    setColumns(tempColumns);
  }, [warehouses]);
  t;

  useEffect(() => {
    setEquipments(
      locationState?.equipments?.map((equipment: Partial<Equipment>) => ({
        ...equipment,
        movementAmount: 0,
        movementFromWarehouse: equipment.warehouses
          ?.sort((warehouse) => warehouse?.amount ?? 0)!
          .at(0)?.warehouse!,
        movementToWarehouse: null
      }))
    );
    warehouseService.all().then((result) => setWarehouses(result.rows));
  }, []);

  const receiptOrIssueArticles = async () => {
    let toReceiptArticles = equipments.map((equipment) => {
      return {
        equipment: equipment,
        warehouse: equipment.movementToWarehouse!,
        amount: equipment.movementAmount
      };
    });
    let toIssueArticles = equipments.map((equipment) => {
      return {
        equipment: equipment,
        warehouse: equipment.movementFromWarehouse,
        amount: -1 * equipment.movementAmount
      };
    });

    if (locationState.type == 'issue' || locationState.type == 'rearrange')
      await equipmentBookingService
        .create(toIssueArticles)
        .then(() => enqueueSnackbar(t('movement.article.issueSuccess'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(t('movement.article.issueFailure'), { variant: 'error' }))
        .finally(() => navigate('/equipments'));

    if (locationState.type == 'receipt' || locationState.type == 'rearrange')
      await equipmentBookingService
        .create(toReceiptArticles)
        .then(() => enqueueSnackbar(t('movement.article.receiptSuccess'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(t('movement.article.receiptFailure'), { variant: 'error' }))
        .finally(() => navigate('/equipments'));
  };

  // we need this because mui doesnt update cells by them self, only the copy
  const handleCommit = (e: GridCellEditCommitParams) => {
    const array = equipments.map((i: any) => {
      if (i.id === e.id) return { ...i, [e.field]: e.value };
      else return { ...i };
    });
    setEquipments(array);
  };

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
      }}>
      <Paper style={{ display: 'flex', flexDirection: 'column', width: '100%' }} elevation={0}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          }}
          pagination
          pageSize={15}
          style={{ height: '92%' }}
          onCellEditCommit={handleCommit}
          rows={equipments}
          columns={columns}
          disableSelectionOnClick
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: '#eee'
          }}>
          <Button
            onClick={receiptOrIssueArticles}
            style={{ margin: 10, backgroundColor: '#2C2C2C' }}
            variant="contained">
            {t(`movement.article.${locationState.type}`)}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
