import { useState, useEffect, createRef } from 'react';
import { GanttChart } from 'ibm-gantt-chart-react';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import projectService from '../../services/projectService';
import PropTypes from 'prop-types';
import moment from 'moment';
import CreateAppointment from '../../components/disposition/CreateAppointment';

MyGanttChart.propTypes = {
  data: PropTypes.object,
  fetchData: PropTypes.func
};

export default function MyGanttChart(props) {
  const [selectedDate] = useState(null);
  const [selectedEvent] = useState(null);
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    gantt: props.data?.resources,
    projects: [],
    config: createHouseBuildingGanttConfig(props.data?.resources, [])
  });

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  });

  useEffect(() => {
    props.fetchData();
  }, [open]);

  useEffect(() => {
    projectService
      .all()
      .then((result) => {
        const mappedData = result.rows.map(function (i) {
          return {
            id: i.id,
            name: i.name
          };
        });
        setData({ ...data, ...{ projects: mappedData } });
      })
      .catch(() => {
        setData({ ...data, ...{ projects: [] } });
      });
  }, []);

  useEffect(() => {
    let tempConfig = createHouseBuildingGanttConfig(props.data?.resources, data?.projects);
    setData({ ...data, ...{ gantt: props.data?.resources, config: { ...tempConfig } } });
  }, [props.data]);

  const download = (image, { name = 'img', extension = 'jpg' } = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  const openAppointmentDialog = () => setOpen(true);

  console.log(image);

  function createHouseBuildingGanttConfig(data, projects) {
    function processProjectTimeWindows(workers, settings) {
      const gantt = settings.gantt;
      gantt.colorMode = 'showAll';
      gantt.projectTimeWindows = {};
      let assigns, assign, iAssign, wnd;
      for (let iWorker = 0, count = workers.length; iWorker < count; ++iWorker) {
        if ((assigns = workers[iWorker].entries)) {
          for (iAssign = 0; iAssign < assigns.length; ++iAssign) {
            assign = assigns[iAssign];
            if (!(wnd = gantt.projectTimeWindows[assign.projectId])) {
              gantt.projectTimeWindows[assign.projectId] = { start: assign.start, end: assign.end };
            } else {
              if (wnd.start > assign.start) wnd.start = assign.start;
              if (wnd.end < assign.end) wnd.end = assign.end;
            }
          }
        }
      }

      return workers;
    }

    return {
      data: {
        resources: {
          data: data,
          success: processProjectTimeWindows,
          parent: 'parentResourceId',
          id: 'resource.id',
          activities: 'entries',
          name: 'resource.displayName'
        },
        activities: {
          start(date) {
            return moment(date.start).valueOf();
          },
          end(date) {
            return moment(date.end).valueOf();
          },
          name: 'name' // The name of the activity is provided with the name property of the model object
        }
      },
      timeTable: {
        renderer: [
          {
            selector(object, ctx) {
              return ctx.gantt.colorMode === 'showAll';
            },
            text(activity) {
              return activity.name;
            },
            background: {
              palette: [
                '#e6194b',
                '#3cb44b',
                '#ffe119',
                '#4363d8',
                '#f58231',
                '#911eb4',
                '#46f0f0',
                '#f032e6',
                '#bcf60c',
                '#fabebe',
                '#008080',
                '#e6beff',
                '#9a6324',
                '#fffac8',
                '#800000',
                '#aaffc3',
                '#808000',
                '#ffd8b1',
                '#000075',
                '#808080',
                '#ffffff',
                '#000000'
              ],
              values() {
                return Object.keys(projects);
              },
              getValue: 'projectId'
            },
            color: 'automatic'
          }
        ]
        //   interactor: {
        //     move: {
        //       startMove(p) {
        //         console.log('Start move ' + p.activity.getData().TASK.NAME);
        //       },
        //       acceptRowChange(p) {
        //         console.log(
        //           '   Accept task ' +
        //             p.activity.getData().TASK.NAME +
        //             ' to move to ' +
        //             p.row.data.NAME +
        //             ' at time ' +
        //             p.currentTime.format()
        //         );
        //         return !!p.row.data.MANAGER_ID;
        //       },
        //       acceptMove(p) {
        //         console.log(
        //           '   Accept move of ' +
        //             p.activity.getData().TASK.NAME +
        //             ' at time ' +
        //             p.currentTime.format()
        //         );
        //         return true;
        //       },
        //       abortMove() {
        //         console.log('   Abort move a task');
        //       },
        //       applyMove() {
        //         console.log('   Apply move of a task');
        //       },
        //       stopMove(p) {
        //         console.log('Stop move ' + +p.activity.getData().TASK.NAME);
        //       }
        //     }
        //   }
      },
      toolbar: [
        'title',
        {
          type: 'button',
          text: 'Appointment',
          fontIcon: 'fa fa-plus fa-lg',
          onclick() {
            openAppointmentDialog();
          }
        },
        'separator',
        'separator',
        'search',
        'separator',
        'separator',
        {
          type: 'button',
          text: 'Export',
          fontIcon: 'fa fa-download fa-lg',
          onclick() {
            downloadScreenshot();
          }
        },
        // {
        //   type: 'button',
        //   text: 'Refresh',
        //   fontIcon: 'fa fa-refresh fa-lg',
        //   onclick(ctx) {
        //     ctx.gantt.draw();
        //   }
        // },
        'separator',
        'fitToContent',
        'zoomIn',
        'zoomOut',
        'separator',

        // User specific toolbar components

        // TODO: get Projectnames by id with projectservice api request
        {
          type: 'select',
          text: 'Filter on project',
          options: [
            { value: 'none', text: 'None' },

            ...projects.map((project) => ({ value: project.id, text: project.name }))
          ],
          onchange(project, ctx) {
            const gantt = ctx.gantt;
            if (gantt.projectFilter) {
              gantt.removeFilter(gantt.projectFilter);
            }
            if (project && project !== 'none') {
              gantt.projectFilter = gantt.addFilter(
                function (obj) {
                  return obj.projectId && obj.projectId === project;
                },
                true /* filter rows */,
                true /* filter activities */
              );
            }
          }
        }
        // {
        //   type: 'select',
        //   text: 'Time Window',
        //   options: [
        //     { value: 'none', text: 'None' },
        //     { value: '1', text: 'Project 1' },
        //     { value: '2', text: 'Project 2' },
        //     { value: '3', text: 'Project 3' },
        //     { value: '4', text: 'Project 4' },
        //     { value: '5', text: 'Project 5' },
        //     { value: '6', text: 'Project 6' },
        //     { value: '7', text: 'Project 7' },
        //     { value: '8', text: 'Project 8' },
        //     { value: '9', text: 'Project 9' },
        //     { value: '10', text: 'Project 10' },
        //     { value: '11', text: 'Project 11' },
        //     { value: '12', text: 'Project 12' },
        //     { value: '13', text: 'Project 13' },
        //     { value: '14', text: 'Project 14' },
        //     { value: '15', text: 'Project 15' },
        //     { value: '16', text: 'Project 16' },
        //     { value: '17', text: 'Project 17' },
        //     { value: '18', text: 'Project 18' },
        //     { value: '19', text: 'Project 19' },
        //     { value: '20', text: 'Project 20' }
        //   ],
        //   onchange(project, ctx) {
        //     const gantt = ctx.gantt;
        //     if (gantt.timeMarkers) {
        //       gantt.removeTimeMarker(gantt.timeMarkers.markerStart);
        //       gantt.removeTimeMarker(gantt.timeMarkers.markerEnd);
        //       gantt.removeTimeLineItem(gantt.timeMarkers.item);
        //     }
        //     if (project && project !== 'none') {
        //       gantt.timeMarkers = {
        //         markerStart: 'HouseStart' + project,
        //         markerEnd: 'HouseEnd' + project,
        //         item: 'item' + project
        //       };
        //       gantt.addTimeMarker(
        //         gantt.timeMarkers.markerStart,
        //         gantt.projectTimeWindows[project].start
        //       );
        //       gantt.addTimeMarker(
        //         gantt.timeMarkers.markerEnd,
        //         gantt.projectTimeWindows[project].end
        //       );
        //       gantt.addTimeLineItem(gantt.timeMarkers.item, {
        //         start: gantt.projectTimeWindows[project].start,
        //         end: gantt.projectTimeWindows[project].end,
        //         className: 'HouseBackground' + project,
        //         type: 'background',
        //         title: 'Time window Project ' + project,
        //         content:
        //           '<div style="border:solid 1px black; background-color:white; color:black; font-size: 12px;">' +
        //           'Time window Project ' +
        //           project +
        //           (project === '2' ? ' <br>On top of tasks</br>' : '') +
        //           '</div>'
        //       });
        //     }
        //     gantt.draw();
        //   }
        // }
      ],
      classes: 'ibm',
      title() {
        return 'Disposition';
      }
    };
  }

  const defaultValues = ['Project', 'Employees', 'Equipments', 'ExternalServices'];

  function checkData() {
    let filtered = data.gantt.filter((i) => !defaultValues.includes(i.resource.displayName));
    return filtered.length > 0;
  }

  return (
    <div ref={ref} style={{ height: '100%' }}>
      {data.gantt?.length > 0 && checkData() && (
        <GanttChart config={data.config} style={{ height: '92%' }} />
      )}
      <CreateAppointment
        open={open}
        setOpen={setOpen}
        selectedDate={selectedDate}
        selectedEvent={selectedEvent}
      />
    </div>
  );
}
