/* eslint-disable no-unused-vars */
import { Paper, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Project } from '../../objects/project';
import { useTranslation } from 'react-i18next';
import analysisService from '../../services/analysisService';
import ProjectCostAnalysis from '../../objects/projectCostAnalysis';
import TimeSeriesWithPrognosisChart from '../../components/TimeSeriesWithPrognosisChart';

interface ProjectProps {
  project: Partial<Project> | undefined;
}

export default function ProjectBookingTrend(props: ProjectProps) {
  const [projectCostAnalysis, setProjectCostAnalysis] = useState<ProjectCostAnalysis>();
  const { project } = props;
  const { t } = useTranslation('projects');

  useEffect(() => {
    let cumulation = '01:00:00:00';
    analysisService.getProjectCostAnalysis(project?.id!, cumulation).then((result) => {
      setProjectCostAnalysis(result);
    });
  }, []);

  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        {!projectCostAnalysis && <Skeleton variant="rectangular" width={'100%'} height={500} />}
        {projectCostAnalysis && (
          <TimeSeriesWithPrognosisChart
            cumulation
            data={projectCostAnalysis.points}
            prognosis={projectCostAnalysis.prognosisPoints}
            getDate={(point) => new Date(point?.start)}
            getPositiveValue={(point) => point.cost}
            legend={{
              label: t('bookingTrend.amount'),
              prognosisLabel: t('bookingTrend.amountPrognosis'),
              cumulationLabel: t('bookingTrend.cumulation')
            }}
            limits={[
              {
                label: t('budget'),
                value: projectCostAnalysis.budget,
                color: 'alarm'
              }
            ]}
          />
        )}
      </Paper>
    </>
  );
}
