import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Card,
  CardContent,
  TextField,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  CardMedia,
  LinearProgress,
  Box,
  Link,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import grayLogo from '../assets/logo_ework.png';
import authService from '../services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import moment from 'moment';

interface LoginProps {
  setToken: (token: any) => void;
  tenantName: string | null;
}
interface LoginLocationState {
  path: string;
}

export default function Login(props: LoginProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [cookieVisible, setCookieVisible] = useState<'show' | 'hidden'>(
    Cookies.get('session') === 'accepted' ? 'hidden' : 'show'
  );

  const location = useLocation();
  const state = location.state as LoginLocationState;
  const footerLinks = ['conditions', 'privacy', 'legalNotice'];
  const navigate = useNavigate();
  const { t } = useTranslation(['login', 'navigation']);

  const [tenantName, setTenantName] = useState<string | null>(props.tenantName);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    username: '',
    password: ''
  });

  const handleChange = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setSpinnerActive(true);
    authService
      .login(values.username, values.password, tenantName)
      .then((response) => {
        props.setToken(response.token);
        if (response.realm == 'tenant') navigate(state?.path ?? '/articles');
        else navigate(state?.path ?? '/system/tenants');
        enqueueSnackbar(t('loginSuccessful'), { variant: 'success' });
      })
      .catch((err) => {
        console.log(err);
        setSpinnerActive(false);
        enqueueSnackbar(t('loginFailed'), { variant: 'error' });
      });
  };
  return (
    <Box sx={{ display: 'flex' }} style={{ background: '#f6f6f6', height: '100vh' }}>
      <Box component="main" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <form
            className="login"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Card sx={{ width: 300 }} style={{ marginBottom: '50px' }}>
              <CardMedia
                style={{
                  paddingTop: '20px',
                  width: '250px',
                  margin: 'auto'
                }}
                component="img"
                image={grayLogo}
                alt="zenamo logo"
              />

              <CardContent>
                {spinnerActive ? (
                  <LinearProgress color="error" />
                ) : (
                  <>
                    {props.tenantName == null && (
                      <TextField
                        placeholder={t('administration')}
                        value={tenantName ?? ''}
                        style={{ margin: '8px', width: '250px' }}
                        label={t('tenant')}
                        variant="outlined"
                        onChange={(e) => setTenantName(e.target.value)}
                      />
                    )}
                    <TextField
                      style={{ margin: '8px', width: '250px' }}
                      id="outlined-basic"
                      label={t('username')}
                      variant="outlined"
                      onChange={handleChange('username')}
                    />
                    <FormControl style={{ margin: 8 }} sx={{ width: 250 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={t('password')}
                      />
                    </FormControl>
                    <Typography
                      key={'recover-password'}
                      style={{ margin: 8, fontSize: 15, padding: 0 }}
                      component={Button}
                      onClick={() => navigate('/request-recover-password')}
                      textTransform={'none'}
                      color="#c9c9c9">
                      {t('app:passwordRecovery.link')}
                    </Typography>
                    <Button
                      style={{ margin: 8, marginTop: 20, width: '250px', background: '#a71d31' }}
                      variant="contained"
                      type="submit"
                      onClick={handleLogin}>
                      {t('login')}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
            {moment().isoWeekday() == 7 && (
              <Card
                sx={{
                  width: 300
                }}
                style={{ background: '#ffffff1f' }}>
                <p style={{ margin: 10, color: 'rgb(235 235 235)', fontWeight: 'bold' }}>
                  {t('maintenanceTitle')}
                </p>
                <p style={{ margin: 10, color: 'rgb(235 235 235)', fontWeight: 'normal' }}>
                  {t('maintenanceContent')}
                </p>
              </Card>
            )}
          </form>
          <CookieConsent
            onAccept={() => {
              Cookies.set('session', 'accepted');
              setCookieVisible('hidden');
            }}
            visible={cookieVisible}
            overlay>
            {t('cookieConsent')}
          </CookieConsent>
        </div>
        <Box
          style={{
            background: '#2C2C2C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {footerLinks.map((i) => (
            <Link
              key={i}
              style={{ margin: 5, fontSize: 15, marginRight: 5, marginLeft: 5 }}
              href={'/' + i}
              color="#c9c9c9"
              underline="hover"
              rel="noopener"
              target="_blank">
              {t(i, { ns: 'navigation' })}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
