import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: 'deDe'
  },
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateLanguage: updateLanguage } = languageSlice.actions;

export default languageSlice.reducer;
