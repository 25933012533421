import {
  Card,
  CardContent,
  TextField,
  Button,
  CardMedia,
  Box,
  Link,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import grayLogo from '../assets/logo_ework.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import instance from '../services/api';

interface RequestRecoverPasswordProps {
  tenantName: string | null;
}

export default function RequestRecoverPassword(props: RequestRecoverPasswordProps) {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const footerLinks = ['conditions', 'privacy', 'legalNotice'];
  const { t } = useTranslation(['login', 'navigation', 'app']);

  const [tenantName, setTenantName] = useState<string | null>(props.tenantName);

  const [values, setValues] = useState({
    username: '',
    email: ''
  });

  const [requestMade, setRequestMade] = useState(false);

  const handleChange = (prop: string) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleRequest = async (e: any) => {
    e.preventDefault();

    instance
      .post('password/request-recovery', {
        tenantName,
        userLogin: values.username,
        //email only used to verify the request is really from this user
        email: values.email
      })
      .then(() => setRequestMade(true))
      .catch(() => enqueueSnackbar(t('app:passwordRecovery.recoveryRequestFailed')));
  };
  return (
    <Box sx={{ display: 'flex' }} style={{ background: '#f6f6f6', height: '100vh' }}>
      <Box component="main" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <form
            className="login"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Card sx={{ width: 300 }} style={{ marginBottom: '50px' }}>
              <CardMedia
                style={{
                  paddingTop: '20px',
                  width: '250px',
                  margin: 'auto'
                }}
                component="img"
                image={grayLogo}
                alt="zenamo logo"
              />
              <CardContent>
                {requestMade ? (
                  <>
                    <Typography style={{ margin: 8 }}>
                      {t('app:passwordRecovery.allDone')}
                    </Typography>
                    <Button
                      style={{ margin: '8px', width: '250px', background: '#a71d31' }}
                      variant="contained"
                      type="submit"
                      onClick={() => navigate('/login')}>
                      {t('app:passwordRecovery.goBack')}
                    </Button>
                  </>
                ) : (
                  <>
                    {props.tenantName == null && (
                      <TextField
                        placeholder={t('administration')}
                        value={tenantName ?? ''}
                        style={{ margin: '8px', width: '250px' }}
                        label={t('tenant')}
                        variant="outlined"
                        onChange={(e) => setTenantName(e.target.value)}
                      />
                    )}
                    <TextField
                      style={{ margin: '8px', width: '250px' }}
                      label={t('username')}
                      variant="outlined"
                      onChange={handleChange('username')}
                    />
                    <TextField
                      style={{ margin: '8px', width: '250px' }}
                      label={t('app:email')}
                      variant="outlined"
                      onChange={handleChange('email')}
                    />
                    <Button
                      style={{ margin: '8px', width: '250px', background: '#a71d31' }}
                      variant="contained"
                      type="submit"
                      onClick={handleRequest}>
                      {t('app:passwordRecovery.request')}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </form>
        </div>
        <Box
          style={{
            background: '#2C2C2C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {footerLinks.map((i) => (
            <Link
              key={i}
              style={{ margin: 5, fontSize: 15, marginRight: 5, marginLeft: 5 }}
              href={'/' + i}
              color="#c9c9c9"
              underline="hover"
              rel="noopener">
              {t(i, { ns: 'navigation' })}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
