import { Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import navigationService from '../../services/navigationService';
import { useNavigate } from 'react-router-dom';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import User from '../../objects/system/user';
import userService from '../../services/system/userService';
import CreateUser from '../../components/system/users/CreateUser';

export default function Users() {
  const { t } = useTranslation(['systemUsers', 'toolbar']);
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);

  const [users, setUsers] = useState({
    loading: true,
    rows: new Array<Partial<User>>(0)
  });

  const fetchData = () => {
    setUsers({ ...users, ...{ loading: true } });
    userService
      .all()
      .then((result) => {
        setUsers({
          loading: false,
          rows: result.rows
        });
      })
      .catch(() => {
        setUsers({
          loading: false,
          rows: []
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ItemDialog
          withText
          autoSize
          kind={ItemDialogKind.Create}
          validate={userService.validate}
          type={t('type')}
          confirm={(item) => {
            userService.create(item).then(() => fetchData());
          }}>
          {(item, handleChange, validation) => (
            <CreateUser user={item} handleChange={handleChange} error={validation} />
          )}
        </ItemDialog>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns = [
    createColumn('login'),
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 120,
      hideable: false,
      renderCell: (params: any) => {
        return (
          <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
            <ItemDialog
              kind={ItemDialogKind.Delete}
              type={t('type')}
              initial={params.row}
              name={params.row.login!}
              confirm={(item) => {
                userService.delete(item.login!).then(() => fetchData());
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            disableColumnMenu
            loading={users.loading}
            rows={users.rows ?? []}
            columns={columns}
            page={0}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={(r: any) => r.login}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows')
            }}
            components={{
              Toolbar: CustomToolbar
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
