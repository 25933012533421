import { Button, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Role from '../../objects/role';
import roleService from '../../services/roleService';
import CreateDialog from '../Helper/MyDialog';
import TransferList from '../Helper/TransferList';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';

interface CreateRoleProps {
  rights: string[];
  refresh(): void;
}

export default function CreateRole(props: CreateRoleProps) {
  const { t } = useTranslation(['roles', 'itemDialog', 'administration']);
  const { enqueueSnackbar } = useSnackbar();

  const [role, setRole] = useState<Partial<Role>>({});
  const [rights, setRights] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setRights(props.rights);
  }, []);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  async function saveItem() {
    if (roleService.validate(role)) {
      await roleService
        .create(role)
        .then(props.refresh)
        .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));

      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  return (
    <>
      <Button
        style={{ margin: 5 }}
        variant="contained"
        size="small"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}>
        {t('create', { ns: 'itemDialog' })}
      </Button>
      <CreateDialog
        noBackdrop
        title={t('addRole', { ns: 'administration' })}
        saveItem={saveItem}
        open={open}
        setOpen={setOpen}
        withActions>
        <Paper
          style={{
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          elevation={0}>
          <TextField
            required
            error={error}
            style={{ marginBottom: 10 }}
            label={t('name')}
            variant="outlined"
            value={role.name ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              setRole({ ...role, ...{ name: e.target.value } });
            }}
            fullWidth
          />
          <TransferList
            leftArray={rights}
            rightArray={[]}
            changeSelection={(rights: string[]) => {
              setRole({ ...role, ...{ rights: rights } });
            }}
          />
        </Paper>
      </CreateDialog>
    </>
  );
}
