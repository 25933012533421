import { Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import navigationService from '../../services/navigationService';
import { useNavigate } from 'react-router-dom';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import moduleService from '../../services/system/moduleService';
import tenantService from '../../services/system/tenantService';
import CreateTenant from '../../components/system/tenants/CreateTenant';
import Tenant from '../../objects/system/tenant';
import UpdateTenant from '../../components/system/tenants/UpdateTenant';
import RequestSupportAccess from '../../components/system/tenants/RequestSupportAccess';

export default function Tenants() {
  const { t } = useTranslation(['tenants', 'toolbar']);
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);
  const [modules, setModules] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tenants, setTenants] = useState(new Array<Partial<Tenant>>(0));

  const fetchData = async () => {
    setLoading(true);
    let tenants = await tenantService
      .all()
      .then((result: any) => {
        setLoading(false);
        return result.rows;
      })
      .catch(() => {
        setLoading(false);
        return [];
      });
    setTenants(tenants);
  };

  useEffect(() => {
    (async () => {
      await moduleService.all().then((result) => setModules(result));
      fetchData();
    })();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ItemDialog
          withText
          kind={ItemDialogKind.Create}
          type={t('type')}
          validate={tenantService.validate}
          confirm={(item) => {
            tenantService.create(item).then(() => fetchData());
          }}>
          {(item, handleChange, validation) => (
            <CreateTenant
              modules={modules}
              tenant={item}
              handleChange={handleChange}
              error={validation}
            />
          )}
        </ItemDialog>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns = [
    createColumn('name'),
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 220,
      hideable: false,
      renderCell: (params: any) => {
        return (
          <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
            <ItemDialog
              kind={ItemDialogKind.Update}
              autoSize
              type={t('type')}
              initial={params.row}
              validate={tenantService.validateUpdate}
              confirm={(item) => {
                tenantService.update(item.name!, item).then(() => fetchData());
              }}>
              {(item, handleChange, validation) => {
                return (
                  <UpdateTenant
                    modules={modules}
                    tenantName={item.name}
                    handleChange={handleChange}
                    error={validation}
                  />
                );
              }}
            </ItemDialog>
            <ItemDialog
              kind={ItemDialogKind.Delete}
              type={t('type')}
              initial={params.row}
              name={params.row.name!}
              confirm={(item) => {
                tenantService.delete(item.name!).then(() => fetchData());
              }}
            />
            <RequestSupportAccess tenantName={params.row.name!} />
          </div>
        );
      }
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={loading}
            rows={tenants ?? []}
            columns={columns}
            page={0}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={(r: any) => r.name}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows')
            }}
            components={{
              Toolbar: CustomToolbar
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
