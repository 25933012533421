import { Page, View, StyleSheet, Image, Text, Document } from '@react-pdf/renderer';
import jsLogo from '../../../assets/ICON_JOSTECSYSTEMS.png';

interface PageFormatProps {
  src: any[];
}

export default function PageFormat(props: PageFormatProps) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap'
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {props?.src?.map((item, index) => (
          <View
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '70mm',
              height: '36mm',
              borderWidth: 1,
              borderColor: '#aaa'
            }}>
            <Image
              src={item.data}
              style={{
                objectFit: 'contain'
              }}
            />
            <View key={index} style={{ maxWidth: 80, marginTop: 12 }}>
              <Text style={{ fontSize: 10 }}>{item.value}</Text>
              <Text style={{ fontSize: 10, marginTop: 5 }}>{item.name}</Text>
            </View>
            <Image
              src={jsLogo}
              style={{ height: 25, width: 25, position: 'absolute', bottom: 10, right: 10 }}
            />
          </View>
        ))}
      </Page>
    </Document>
  );
}
