import Employee from '../objects/employee';
import { defaultService, ItemState } from './api';

const makeEmployeeModel = (employee: Partial<Employee>) => {
  return {
    email: employee.email,
    reference: employee.reference,
    firstName: employee.firstName,
    lastName: employee.lastName,
    employmentDate: employee.employmentDate,
    roles: employee.roles?.map((role) => role.id),
    user: employee.user,
    pricePerHour: employee.pricePerHour,
    costPerHour: employee.costPerHour
  };
};

const route = 'employees';

const employeeService = {
  get: async (id: string): Promise<Partial<Employee>> => {
    let result = await defaultService.get(route, id);
    return result.data;
  },
  validateEdit: (employee: any): boolean => {
    let success =
      employee?.firstName?.length > 0 &&
      employee?.lastName?.length > 0 &&
      employee?.roles?.length > 0;
    if (employee?.user?.active && success)
      success = employee?.user?.login && employee?.user?.password;
    return success;
  },
  validateEditInfo: (employee: any): boolean => {
    let success =
      employee?.firstName?.length > 0 &&
      employee?.lastName?.length > 0 &&
      employee?.roles?.length > 0;
    return success;
  },
  validate: (employee: any): boolean => {
    let success =
      employee?.firstName?.length > 0 &&
      employee?.lastName?.length > 0 &&
      employee?.roles?.length > 0;
    if (employee?.user?.active && success)
      success = employee?.user?.login && employee?.user?.password;
    return success;
  },
  create: (employee: Partial<Employee>) =>
    defaultService.create(route, makeEmployeeModel(employee)),
  update: (id: string, employee: Partial<Employee>) =>
    defaultService.update(route, id, makeEmployeeModel(employee)),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Employee>(route, itemState)
};

export default employeeService;
