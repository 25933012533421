import {
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  IconButton
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../../objects/article';
import Warehouse from '../../objects/warehouses';
import DeleteIcon from '@mui/icons-material/Delete';
import ItemDialog, { ItemDialogKind } from '../Helper/ItemDialog';
import warehouseService from '../../services/warehouseService';
import CreateWarehouse from '../warehouses/CreateWarehouse';

const warehouseFilter = createFilterOptions<Partial<Warehouse>>();

interface CreateArticleWarehousesProps {
  article: Partial<Article>;
  handleChange: Function;
  warehouses: Partial<Warehouse>[];
}

export default function CreateArticleWarehouses(props: CreateArticleWarehousesProps) {
  const { t } = useTranslation(['articles', 'warehouses']);
  const [article, setArticle] = useState<Partial<Article>>(props.article);
  const [warehouses, setWarehouses] = useState(props.warehouses);
  const [enteredWarehouseName, setEnteredWarehouseName] = useState('');

  useEffect(() => {
    props.handleChange(article);
  }, [article]);

  return (
    <>
      <Paper
        style={{
          padding: 10,
          display: 'flex',
          flexDirection: 'column'
        }}
        elevation={0}>
        <>
          {article?.warehouses
            ?.sort((current, next) => current!.warehouse!.id!.localeCompare(next!.warehouse!.id!))
            .map((articleWarehouse) => {
              let index = article?.warehouses!.indexOf(articleWarehouse)!;
              return (
                <div
                  key={articleWarehouse!.warehouse!.id!}
                  style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    style={{ marginBottom: 10, marginRight: 10 }}
                    label={t('warehouseAmount')}
                    variant="outlined"
                    value={articleWarehouse.amount?.toString()}
                    onFocus={(event) => event.target.select()}
                    type="number"
                    onChange={(e) => {
                      e.stopPropagation();

                      let newAmount = Number(e.target.value);
                      if (Number.isNaN(newAmount)) return;
                      let parsedNumber = Math.abs(newAmount);
                      let warehousesClone = Array.from(article?.warehouses ?? []);
                      warehousesClone.splice(index, 1);
                      let newItem = { ...articleWarehouse, ...{ amount: parsedNumber } };
                      warehousesClone.push(newItem);
                      setArticle({ ...article, ...{ warehouses: warehousesClone } });
                    }}
                  />
                  <TextField
                    fullWidth
                    value={
                      articleWarehouse.warehouse!.name ?? articleWarehouse.warehouse!.displayName
                    }
                    disabled
                  />
                  <div style={{ alignItems: 'center' }}>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        let warehousesClone = Array.from(article?.warehouses ?? []);
                        warehousesClone.splice(index, 1);
                        setArticle({ ...article, ...{ warehouses: warehousesClone } });
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Autocomplete
              value={enteredWarehouseName}
              onChange={(e, value) => {
                e.stopPropagation();

                if (value == null) return;
                let warehouseValue = value as Partial<Warehouse>;
                if (warehouseValue.id == null || warehouseValue.name == null) return;

                if (!warehouses.find((warehouse) => warehouse.id == warehouseValue.id)) return;

                let warehousesClone = Array.from(article?.warehouses ?? []);
                warehousesClone.push({ amount: 0, warehouse: warehouseValue });
                setArticle({ ...article, ...{ warehouses: warehousesClone } });
                setEnteredWarehouseName('');
              }}
              filterOptions={(options, params) => warehouseFilter(options, params)}
              selectOnFocus
              handleHomeEndKeys
              autoHighlight
              options={warehouses}
              getOptionLabel={(option: any) => {
                return option.name ?? '';
              }}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id ?? ''}>
                  {option.name ?? ''}
                </MenuItem>
              )}
              freeSolo
              fullWidth
              renderInput={(params) => <TextField {...params} label={t('warehouse')} />}
            />
          </div>
        </>
      </Paper>
      <ItemDialog
        buttonContainerProps={{
          style: {
            marginBottom: 10,
            padding: 10,
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }}
        buttonProps={{
          fullWidth: true,
          size: 'medium',
          style: {
            width: '70%'
          }
        }}
        withTypedText
        autoSize
        kind={ItemDialogKind.Create}
        type={t('warehouses:type')}
        confirm={async (item) => {
          warehouseService
            .create(item)
            .then(() => warehouseService.all().then((result) => setWarehouses(result.rows)))
            .catch(() => {
              //TODO Fehlermeldung
            });
        }}>
        {(item, handleChange) => <CreateWarehouse warehouse={item} handleChange={handleChange} />}
      </ItemDialog>
    </>
  );
}
