import { Paper } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import { ItemState, itemStateService } from '../../services/api';
import filterService from '../../services/filterService';
import navigationService from '../../services/navigationService';
import moment from 'moment';
import CustomToolbar from './CustomToolbar';
import TokenService from '../../services/tokenService';
import { useSnackbar } from 'notistack';
import externalServiceService from '../../services/externalServiceService';
import ExternalService from '../../objects/externalService';
import UpdateExternalService from '../../components/externalServices/UpdateExternalService';

export default function ExternalServices() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['externalServices', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn, createNavigationWithPropsColumn } = navigationService.create(t, navigate);

  const [changeAmount, setChangeAmount] = useState<number>();
  const [columns, setColumns] = useState<any[]>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    address: false,
    zipCode: false,
    iban: false,
    lastEdit: false
  });

  const [externalServicesState, setExternalServicesState] = useState<ItemState>();
  const [externalServices, setExternalServices] = useState({
    loading: true,
    rows: new Array<Partial<ExternalService>>(0),
    rowCount: 0
  });
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = externalServicesState;
  }, [externalServicesState]);

  useEffect(() => {
    console.log(externalServices.rows);
  }, [externalServices]);

  const canUpdateExternalService = TokenService.tenantFullfills(
    ['Disposition'],
    ['ExternalServices:Update']
  );

  useEffect(() => {
    setChangeAmount(0);
    setColumns([
      createNavigationWithPropsColumn(
        'name',
        'external-services/details',
        (rowValue: any) => {
          return { id: rowValue.id };
        },
        { width: 200 }
      ),
      createColumn('description', { width: 120 }),
      createColumn('address', { width: 150 }),
      createColumn('city', { width: 110 }),
      createColumn('zipCode', { width: 110, type: 'number' }),
      createColumn('iban', { width: 150 }),
      createColumn('priceByHour', { width: 150 }),
      createColumn('costByHour', { width: 150 }),
      createColumn('email', { width: 200 }),
      createColumn('phone', { width: 150 }),
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      {
        field: 'actions',
        headerName: t('actions', { ns: 'externalServices' }),
        sortable: false,
        hideable: false,
        minWidth: 140,
        maxWidth: 140,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateExternalService && (
                <>
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    type={t('type')}
                    initial={params.row}
                    validate={externalServiceService.validate}
                    confirm={(item) => {
                      externalServiceService
                        .update(item.id!, item)
                        .then(() => fetchData())
                        .catch(() =>
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' })
                        );
                    }}>
                    {(item, handleChange, validation) => (
                      <UpdateExternalService
                        externalService={item}
                        handleChange={handleChange}
                        error={validation}
                      />
                    )}
                  </ItemDialog>
                  <ItemDialog
                    kind={ItemDialogKind.Delete}
                    type={t('type')}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={(item) => {
                      externalServiceService.delete(item.id!).then(() => fetchData());
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      }
    ]);

    const state = itemStateService.getItemStateFromUrl(searchParams, 15);
    if (state.sort.size == 0) {
      state.sort.set('lastEdit', 'desc');
      itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
    }
    setExternalServicesState(state);
  }, []);

  const fetchData = async () => {
    setExternalServices({ ...externalServices, ...{ loading: true } });
    externalServiceService
      .all(stateRef.current)
      .then((result) => {
        setChangeAmount(0);
        setExternalServices({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setExternalServices({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  useEffect(() => {
    if (externalServicesState == null) return;
    if (externalServicesState.sort.size == 0) externalServicesState.sort.set('lastEdit', 'desc');
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, externalServicesState);
    fetchData();
  }, [externalServicesState]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              toolbar: {
                changeAmount,
                setChangeAmount,
                fetchData,
                externalServicesState,
                setExternalServicesState
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={externalServices.loading}
            rows={externalServices.rows}
            columns={columns ?? []}
            page={externalServicesState?.paginationSettings?.page}
            pageSize={15}
            rowCount={externalServices.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(externalServicesState?.filter)}
            sortModel={filterService.createGridSort(externalServicesState?.sort)}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onPageChange={(page) => {
              if (externalServicesState == null) return;
              if (filterService.updateItemStatePage(externalServicesState, page, 15))
                setExternalServicesState({ ...externalServicesState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (externalServicesState == null) return;
              if (
                filterService.updateItemStateFilter(
                  externalServicesState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setExternalServicesState({ ...externalServicesState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (externalServicesState == null) return;
              if (
                filterService.updateItemStateSort(
                  externalServicesState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setExternalServicesState({ ...externalServicesState });
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
