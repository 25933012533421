import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'ibm-gantt-chart/dist/ibm-gantt-chart.css';
import MyGanttChart from '../../pages/disposition/MyGanttChart';
import { employeeDispositionService } from '../../services/dispositionService';
import moment from 'moment';

export default function EmployeeDisposition() {
  const [start, setStart] = useState(moment(new Date()).subtract(14, 'day').toDate());
  const [end, setEnd] = useState(moment(new Date()).add(3, 'month').toDate());
  const [data, setData] = useState();
  const { t } = useTranslation(['appointments']);

  function fetchData() {
    employeeDispositionService.get(start, end).then((result) => setData(result));
  }

  useEffect(() => {
    fetchData();
    //employeeDispositionService.delete(start, end) -> delete
    //employeeDispositionService.updateTime(start, end) -> for moving inside chart
  }, [start, end]);

  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10,
        height: '100%'
      }}>
      <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
        <DatePicker
          label={t('startdate')}
          value={start}
          onChange={(value) => {
            setStart(value);
            if (value > end) setEnd(value);
          }}
          renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
        />
        <DatePicker
          label={t('enddate')}
          value={end}
          onChange={(value) => {
            setEnd(value);
            if (value < start) setStart(value);
          }}
          renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
        />
        {/* <ButtonGroup variant="contained">
          {[ViewMode.Day, ViewMode.Week, ViewMode.Month].map((mode, index) => (
            <Button key={index} onClick={() => setSelectedMode(mode)}>
              {mode}
            </Button>
          ))}
        </ButtonGroup> */}
      </div>
      <MyGanttChart data={data} fetchData={fetchData} />
    </div>
  );
}
