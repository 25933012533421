import am5locales_en_US from '@amcharts/amcharts5/locales/en_US';
import am5locales_de_DE from '@amcharts/amcharts5/locales/de_DE';

function useAmLocales() {
  let currentLocale = localStorage.getItem('i18nextLng');
  switch (currentLocale) {
    case 'en':
      return am5locales_en_US;
    case 'de':
      return am5locales_de_DE;
  }

  // return english as default
  return am5locales_en_US;
}

export default useAmLocales;
