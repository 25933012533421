import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import appointmentService from '../../services/appointmentService';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Employee from '../../objects/employee';
import employeeService from '../../services/employeeService';
import TokenService from '../../services/tokenService';
import { TenantUser } from '../../objects/user';
import Equipment from '../../objects/equipment';
import { Project } from '../../objects/project';
import equipmentService from '../../services/equipmentService';
import projectService from '../../services/projectService';
import ExternalService from '../../objects/externalService';
import externalServiceService from '../../services/externalServiceService';

export default function CreateProjectAppointment(props: any) {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  //const [appointmentTypes, setAppointmentTypes] = useState<string[]>();
  const [selectedUserType, setSelectedUserType] = useState('');
  const [start, setStart] = useState<Date | null>(new Date());
  const [end, setEnd] = useState<Date | null>(new Date());
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isDoubleBooking, setIsDoubleBooking] = useState(false);

  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [selectedExternalService, setSelectedExternalService] = useState<ExternalService>();

  const [employees, setEmployees] = useState<Partial<Employee>[]>([]);
  const [projects, setProjects] = useState<Partial<Project>[]>([]);
  const [equipments, setEquipments] = useState<Partial<Equipment>[]>([]);
  const [externalServices, setExternalServices] = useState<Partial<ExternalService>[]>([]);

  const [selectedType, setSelectedType] = useState<
    'project' | 'employee-project' | 'employee' | 'equipment' | 'external-service-project'
  >('project');
  const types = [
    'project',
    'employee-project',
    'employee',
    'equipment',
    'external-service-project'
  ];

  const { t } = useTranslation(['appointments', 'app']);

  async function saveItem() {
    if (subject && start && end && moment(start).isValid() && moment(end).isValid()) {
      let item: any = {
        userType: selectedUserType,
        subject: subject,
        description: description,
        start: start,
        end: end
      };
      if (selectedType.includes('employee')) {
        if (!selectedEmployee) setError(true);
        item.employeeId = selectedEmployee?.id;
      }
      if (selectedType.includes('equipment')) {
        // Error, not beeing used!
        if (!selectedEmployee) setError(true);
        item.equipmentId = selectedEquipment?.id;
      }
      if (selectedType.includes('project')) {
        if (!selectedEmployee) setError(true);
        item.projectId = selectedProject?.id;
      }
      if (selectedType.includes('external-service')) {
        if (!selectedExternalService) setError(true);
        item.externalServiceId = selectedExternalService?.id;
      }

      try {
        await appointmentService.createAppointment(item, selectedType + '-', {
          params: { ignoreConflicts: isDoubleBooking }
        });
      } catch (err) {
        if (err.response.status == 409) {
          setIsDoubleBooking(true);
          enqueueSnackbar(t('app:doubleBookingDetected'), { variant: 'error' });
        } else enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
        return false;
      }

      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  async function editItem() {
    appointmentService.updateAppointment(props.selectedEvent?.event?.id ?? props.selectedEvent.id, {
      description: description,
      start: start,
      end: end
    });
  }

  const canOnlyViewOwnAppointments =
    !TokenService.tenantFullfills(['Calendar'], ['Appointments:AllowAccessToAll']) ||
    !TokenService.tenantFullfills([], ['Employees:View']);

  useEffect(() => {
    // appointmentService
    //   .getAppointmentUserTypes('Project')
    //   .then((result) => setAppointmentTypes(result));
    (async () => {
      await employeeService.all().then((result) => setEmployees(result.rows));
      await projectService.all().then((result) => setProjects(result.rows));
      await equipmentService.all().then((result) => setEquipments(result.rows));
      await externalServiceService.all().then((result) => setExternalServices(result.rows));
    })();
    setSubject('');
    setSelectedUserType('');
    setSelectedType('project');
    setSelectedProject(undefined);
    setSelectedEmployee(undefined);
    setSelectedEquipment(undefined);
    setError(false);
    setIsDoubleBooking(false);
    setDescription(props.selectedEvent?.event?.title ?? '');
    setStart(new Date());
    setEnd(new Date());

    if (canOnlyViewOwnAppointments) {
      let employee = {} as Employee;
      employee.id = (TokenService.getUser() as TenantUser).employee.id;
      setSelectedEmployee(employee);
    } else setSelectedEmployee(undefined);
  }, [props.selectedDate, props.open, props.selectedEvent]);

  return (
    <CreateDialog
      noBackdrop
      title={t('addAppointment')}
      saveItem={props.selectedEvent?.event?.title ? editItem : saveItem}
      open={props.open}
      setOpen={props.setOpen}
      withActions>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div>
          <FormControl
            fullWidth
            style={{ marginBottom: 10 }}
            required
            error={error && !selectedType}>
            <InputLabel>{t('category')}</InputLabel>
            <Select
              value={selectedType}
              label={t('category')}
              onChange={(e: any) => setSelectedType(e.target.value)}>
              {types.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {t(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedType.includes('employee') &&
            (canOnlyViewOwnAppointments ? (
              <TextField
                disabled
                fullWidth
                style={{ marginBottom: 10 }}
                variant="outlined"
                label={
                  (TokenService.getUser() as TenantUser).employee.firstName +
                  ' ' +
                  (TokenService.getUser() as TenantUser).employee.lastName
                }
              />
            ) : (
              <FormControl
                fullWidth
                style={{ marginBottom: 10 }}
                required
                error={error && !selectedEmployee}>
                <InputLabel>{t('employee')}</InputLabel>
                <Select
                  value={selectedEmployee}
                  label={t('employee')}
                  onChange={(e: any) => setSelectedEmployee(e.target.value)}>
                  {employees.map((employee: any, index: number) => (
                    <MenuItem key={index} value={employee}>
                      {employee.firstName + ' ' + employee.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          {selectedType.includes('project') && (
            <FormControl
              fullWidth
              style={{ marginBottom: 10 }}
              required
              error={error && !selectedProject}>
              <InputLabel>{t('project')}</InputLabel>
              <Select
                value={selectedProject}
                label={t('project')}
                onChange={(e: any) => setSelectedProject(e.target.value)}>
                {projects.map((project: any, index: number) => (
                  <MenuItem key={index} value={project}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedType.includes('equipment') && (
            <FormControl
              fullWidth
              style={{ marginBottom: 10 }}
              required
              error={error && !selectedEquipment}>
              <InputLabel>{t('equipment')}</InputLabel>
              <Select
                value={selectedEquipment}
                label={t('equipment')}
                onChange={(e: any) => setSelectedEquipment(e.target.value)}>
                {equipments.map((equipment: any, index: number) => (
                  <MenuItem key={index} value={equipment}>
                    {equipment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedType.includes('external-service') && (
            <FormControl
              fullWidth
              style={{ marginBottom: 10 }}
              required
              error={error && !selectedEquipment}>
              <InputLabel>{t('external-service')}</InputLabel>
              <Select
                value={selectedExternalService}
                label={t('equipment')}
                onChange={(e: any) => setSelectedExternalService(e.target.value)}>
                {externalServices.map((externalService: any, index: number) => (
                  <MenuItem key={index} value={externalService}>
                    {externalService.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            required
            error={error && !subject}
            style={{ marginBottom: 10 }}
            label={t('subject')}
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
          />
          <TextField
            style={{ marginBottom: 10 }}
            label={t('description')}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
          {/* <Autocomplete
            value={selectedUserType}
            style={{ marginBottom: 10 }}
            groupBy={(option) => option}
            onInputChange={(e, inputValue) => {
              setSelectedUserType(inputValue);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={appointmentTypes ?? []}
            getOptionLabel={(option: any) => option}
            freeSolo
            fullWidth
            renderInput={(params) => <TextField {...params} label={t('type')} />}
          /> */}
          <div style={{ display: 'flex', gap: 10, width: '100%', marginBottom: 10 }}>
            <DatePicker
              label={t('startdate')}
              value={start}
              mask="__.__.____"
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            <TimePicker
              label={t('starttime')}
              value={start}
              onChange={(value: any) => {
                setStart(value);
                if (value > end!) setEnd(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  required
                  style={{ width: '50%' }}
                />
              )}
            />
          </div>
          <div style={{ display: 'flex', gap: 10, width: '100%' }}>
            <DatePicker
              label={t('enddate')}
              value={end}
              mask="__.__.____"
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => <TextField {...params} required style={{ width: '50%' }} />}
            />
            <TimePicker
              label={t('endtime')}
              value={end}
              onChange={(value: any) => {
                setEnd(value);
                if (value < start!) setStart(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  required
                  style={{ width: '50%' }}
                />
              )}
            />
          </div>
          {isDoubleBooking && (
            <Typography color="error" style={{ maxWidth: 460 }}>
              {t('app:doubleBookingDetectedPrompt')}
            </Typography>
          )}
        </div>
      </Paper>
    </CreateDialog>
  );
}
