import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function UpdateEquipment(props: any) {
  const { t } = useTranslation('equipments');
  const [equipment, setEquipment] = useState(props.equipment);

  useEffect(() => {
    setEquipment(props.equipment);
  }, []);

  useEffect(() => {
    props.handleChange(equipment);
  }, [equipment]);

  return (
    <Paper
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      elevation={0}>
      <>
        <TextField
          required
          error={props.error && !equipment?.reference}
          style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
          label={t('inventoryNumber')}
          variant="outlined"
          value={equipment.reference ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ reference: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !equipment.name}
          style={{ marginBottom: 10 }}
          label={t('name')}
          variant="outlined"
          value={equipment.name ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          style={{ marginBottom: 10 }}
          label={t('state')}
          variant="outlined"
          value={equipment.state ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ state: e.target.value } })}
        />
        <TextField
          style={{ marginRight: 10, width: '50%', marginBottom: 10 }}
          label={t('unit')}
          variant="outlined"
          value={equipment.unit ?? ''}
          onChange={(e) => setEquipment({ ...equipment, ...{ unit: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !equipment?.monetaryDepreciationByHour}
          style={{ marginBottom: 10 }}
          label={t('monetaryDepreciationByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={equipment?.monetaryDepreciationByHour?.toString()}
          onChange={(e) =>
            setEquipment({
              ...equipment,
              ...{ monetaryDepreciationByHour: Number(e.target.value) }
            })
          }
        />
        <TextField
          required
          error={props.error && !equipment?.priceByHour}
          style={{ marginBottom: 10 }}
          label={t('priceByHour')}
          variant="outlined"
          type="number"
          onFocus={(event) => event.target.select()}
          value={equipment?.priceByHour?.toString()}
          onChange={(e) =>
            setEquipment({
              ...equipment,
              ...{ priceByHour: Number(e.target.value) }
            })
          }
        />
        {Array.from(Object.entries(equipment.userProperties ?? {})).map((entry) => {
          return (
            <div key={entry[0]} style={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                key={entry[0]}
                style={{ marginBottom: 10 }}
                fullWidth
                label={t(`${equipment.userType}.${entry[0]}`) ?? entry[0]}
                variant="outlined"
                value={entry[1]}
                onChange={(e) =>
                  setEquipment({
                    ...equipment,
                    ...{
                      userProperties: {
                        ...equipment.userProperties,
                        ...{ [entry[0]]: e.target.value }
                      }
                    }
                  })
                }
              />
            </div>
          );
        })}
        <TextField fullWidth label={t('description')} multiline rows={4} />
      </>
    </Paper>
  );
}
