import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navigationService from '../../services/navigationService';
import { Paper, Button, TextField, Chip, MenuItem, Select, Typography } from '@mui/material';
import { DataGridPro, GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import DeleteDialog from '../../components/Helper/DeleteDialog';
import warehouseService from '../../services/warehouseService';
import { useSnackbar } from 'notistack';
import Article, { ArticleWarehouse } from '../../objects/article';
import Warehouse from '../../objects/warehouses';
import articleBookingService from '../../services/articleBookingService';

interface ArticleMovementLocationState {
  articles: Partial<Article>[];
  type: 'receipt' | 'issue' | 'rearrange';
}

interface ArticleMovementData extends Partial<Article> {
  movementAmount: number;
  movementFromWarehouse: Partial<Warehouse>;
  movementToWarehouse: Partial<Warehouse> | null;
}

export default function ArticleMovement() {
  const { t } = useTranslation(['articles', 'toolbar']);
  const navigate = useNavigate();
  const location = useLocation();
  const { createColumn } = navigationService.create(t, navigate);
  const { enqueueSnackbar } = useSnackbar();
  const locationState = location.state as ArticleMovementLocationState;
  const [currentChangeAmount, setCurrentChangeAmount] = useState(0);
  const [currentChangeWarehouse, setCurrentChangeWarehouse] = useState();
  const [currentChangeWarehouses, setCurrentChangeWarehouses] = useState<any>();

  const [articles, setArticles] = useState<ArticleMovementData[]>([]);
  const [warehouses, setWarehouses] = useState<Partial<Warehouse>[]>([]);
  const [columns, setColumns] = useState<any>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    let tempColumns = [
      createColumn('name', { minWidth: 170 }),
      createColumn('reference', { minWidth: 100 }),
      createColumn('totalAmount', { minWidth: 60, type: 'number' }),
      createColumn('movementAmount', {
        headerName: t('movement.amount'),
        minWidth: 100,
        renderCell: (params: any) => {
          return (
            <TextField
              InputProps={{ inputProps: { min: 0, autoComplete: 'new-password' } }}
              style={
                (params.row.movementAmount >
                  params.row.warehouses?.find(
                    (warehouse: ArticleWarehouse) =>
                      warehouse?.warehouse?.id == params.row?.movementFromWarehouse.id
                  )?.amount! &&
                  locationState.type != 'receipt') ||
                params.row.movementAmount == 0
                  ? { background: '#ea9b9b' }
                  : { background: '#ffffe6' }
              }
              fullWidth
              type="number"
              value={params.row.movementAmount ?? currentChangeAmount ?? 0}
              onFocus={(event) => {
                event.target.select();
              }}
              onChange={(e) => {
                let parsedNumber = Math.abs(Number.parseInt(e.target.value));
                params.row.movementAmount = parsedNumber;
                setCurrentChangeAmount(parsedNumber);
              }}
            />
          );
        }
      })
    ];
    if (locationState.type != 'receipt')
      tempColumns.push(
        createColumn('movementFromWarehouse', {
          minWidth: 200,
          headerName: t('movement.fromWarehouse'),
          renderCell: (params: any) => (
            <Select
              fullWidth
              style={{
                background:
                  params.row.warehouses?.length < 1
                    ? 'white'
                    : !params.row.movementFromWarehouse
                    ? '#ea9b9b'
                    : '#ffffe6'
              }}
              disabled={params.row.warehouses?.length < 1}
              label={t('movement.fromWarehouse')}
              onChange={(e) => {
                e.stopPropagation();
                let warehouse = params.row.warehouses?.find(
                  (warehouse: any) => warehouse.warehouse?.id == e.target.value
                );
                if (warehouse?.warehouse != undefined) {
                  params.row.movementFromWarehouse = warehouse.warehouse;
                  setCurrentChangeWarehouse(warehouse.warehouse);
                }
              }}
              value={params.row.movementFromWarehouse?.id ?? currentChangeWarehouse ?? ''}>
              {params.row.warehouses?.map((warehouse: ArticleWarehouse) => (
                <MenuItem key={warehouse.warehouse.id} value={warehouse?.warehouse?.id}>
                  <Chip size="small" label={warehouse?.amount ?? 0} style={{ marginRight: 10 }} />
                  {warehouse?.warehouse?.displayName}
                </MenuItem>
              ))}
            </Select>
          )
        })
      );
    else
      tempColumns.push(
        createColumn('movementToWarehouse', {
          headerName: t('movement.article.toWarehouse'),
          minWidth: 200,
          renderCell: (params: any) => {
            return (
              <Select
                fullWidth
                style={{
                  background:
                    params.row.warehouses?.length < 1
                      ? 'white'
                      : !params.row.movementToWarehouse
                      ? '#ea9b9b'
                      : '#ffffe6'
                }}
                disabled={warehouses.length < 0}
                label={t('movement.article.toWarehouse')}
                onChange={(e) => {
                  e.stopPropagation();
                  let warehouse = warehouses?.find((warehouse) => warehouse?.id == e.target.value);
                  if (warehouse != undefined) {
                    params.row.movementToWarehouse = warehouse;
                    setCurrentChangeWarehouses(warehouse);
                  }
                }}
                value={params.row?.movementToWarehouse?.id ?? currentChangeWarehouses ?? ''}>
                {warehouses.map((warehouse) => (
                  <MenuItem key={warehouse.id} value={warehouse.id}>
                    <Chip
                      size="small"
                      label={
                        params.row.warehouses?.filter((i: any) => i.warehouse.id == warehouse.id)[0]
                          ?.amount ?? 0
                      }
                      style={{ marginRight: 10 }}
                    />
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            );
          }
        })
      );

    if (locationState.type == 'rearrange')
      tempColumns.push(
        createColumn('movementToWarehouse', {
          headerName: t('movement.article.toWarehouse'),
          minWidth: 200,
          renderCell: (params: any) => {
            return (
              <Select
                fullWidth
                style={{
                  background:
                    params.row.warehouses?.length < 1
                      ? 'white'
                      : !params.row.movementToWarehouse
                      ? '#ea9b9b'
                      : '#ffffe6'
                }}
                disabled={warehouses.length < 0}
                label={t('movement.article.toWarehouse')}
                onChange={(e) => {
                  e.stopPropagation();

                  let warehouse = warehouses?.find((warehouse) => warehouse?.id == e.target.value);
                  if (warehouse != undefined) {
                    params.row.movementToWarehouse = warehouse;
                    setCurrentChangeWarehouses(warehouse);
                  }
                }}
                value={params.row?.movementToWarehouse?.id ?? currentChangeWarehouses ?? ''}>
                {warehouses
                  .filter((warehouse) => warehouse.id !== params.row?.movementFromWarehouse?.id)
                  .map((warehouse) => (
                    <MenuItem key={warehouse.id} value={warehouse.id}>
                      <Chip
                        size="small"
                        label={
                          params.row.warehouses?.filter(
                            (i: any) => i.warehouse.id == warehouse.id
                          )[0]?.amount ?? 0
                        }
                        style={{ marginRight: 10 }}
                      />
                      {warehouse.name}
                    </MenuItem>
                  ))}
              </Select>
            );
          }
        })
      );

    tempColumns.push(
      createColumn('actions', {
        headerName: '',
        maxWidth: 80,
        width: 80,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          const onDeleteItem = () =>
            setArticles((prevState) =>
              prevState.filter((article) => article.reference != params.row.reference)
            );

          return (
            <DeleteDialog
              isSelection
              deleteItem={onDeleteItem}
              type={t('type')}
              name={params.row.name}
            />
          );
        }
      })
    );

    setColumns(tempColumns);
  }, [warehouses]);

  useEffect(() => {
    setArticles(
      locationState?.articles?.map((article: Partial<Article>) => ({
        ...article,
        movementAmount: 0,
        movementFromWarehouse: article.warehouses
          ?.sort((warehouse) => warehouse?.amount ?? 0)!
          .at(0)?.warehouse!,
        movementToWarehouse: null
      }))
    );
    warehouseService.all().then((result) => setWarehouses(result.rows));
  }, []);

  const receiptOrIssueArticles = () => {
    let error = false;
    let toReceiptArticles = articles.map((article: ArticleMovementData) => {
      if (locationState.type == 'receipt' || locationState.type == 'rearrange') {
        if (!error)
          error =
            !article.movementToWarehouse! ||
            !article.movementAmount! ||
            article.movementAmount == 0;
      }

      return {
        article: article,
        warehouse: article.movementToWarehouse!,
        amount: article.movementAmount
      };
    });
    let toIssueArticles = articles.map((article) => {
      if (locationState.type == 'issue' || locationState.type == 'rearrange') {
        if (!error)
          error =
            !article.movementFromWarehouse! ||
            !article.movementAmount ||
            article.movementAmount >
              article?.warehouses?.find(
                (warehouse) => warehouse?.warehouse?.id == article?.movementFromWarehouse.id
              )?.amount! ||
            article.movementAmount == 0;
      }

      return {
        article: article,
        warehouse: article.movementFromWarehouse,
        amount: -1 * article.movementAmount
      };
    });

    if (error) {
      setError(error);
      return;
    }

    (async () => {
      if (locationState.type == 'issue' || locationState.type == 'rearrange')
        await articleBookingService
          .create(toIssueArticles)
          .then(() => enqueueSnackbar(t('movement.article.issueSuccess'), { variant: 'success' }))
          .catch(() => enqueueSnackbar(t('movement.article.issueFailure'), { variant: 'error' }))
          .finally(() => navigate('/articles'));

      if (locationState.type == 'receipt' || locationState.type == 'rearrange')
        await articleBookingService
          .create(toReceiptArticles)
          .then(() => enqueueSnackbar(t('movement.article.receiptSuccess'), { variant: 'success' }))
          .catch(() => enqueueSnackbar(t('movement.article.receiptFailure'), { variant: 'error' }))
          .finally(() => navigate('/articles'));
    })();
  };

  // we need this because mui doesnt update cells by them self, only the copy
  const handleCommit = (e: GridCellEditCommitParams) => {
    const array = articles.map((i: any) => {
      if (i.id === e.id) return { ...i, [e.field]: e.value };
      else return { ...i };
    });
    setArticles(array);
  };

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
      }}>
      <Paper style={{ display: 'flex', flexDirection: 'column', width: '100%' }} elevation={0}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          }}
          pagination
          pageSize={15}
          style={{ height: '92%' }}
          onCellEditCommit={handleCommit}
          rows={articles}
          columns={columns}
          disableSelectionOnClick
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: '#eee'
          }}>
          {error && (
            <Typography color="error" style={{ margin: 10, alignSelf: 'center' }}>
              {t('resolveError')}
            </Typography>
          )}
          <Button
            onClick={receiptOrIssueArticles}
            style={{ margin: 10, backgroundColor: '#2C2C2C' }}
            variant="contained">
            {t(`movement.article.${locationState.type}`)}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
