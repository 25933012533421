import { Paper, ListItemAvatar, Avatar } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import navigationService from '../../services/navigationService';
import employeeService from '../../services/employeeService';
import filterService from '../../services/filterService';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import UpdateEmployee from '../../components/employees/UpdateEmployee';
import { ItemState, itemStateService } from '../../services/api';
import Employee from '../../objects/employee';
import TokenService from '../../services/tokenService';
import { TenantUser } from '../../objects/user';
import avatarService from '../../services/avatarService';
import Role from '../../objects/role';
import moment from 'moment';

import { useSnackbar } from 'notistack';
import CustomToolbar from './CustomToolbar';

export default function Employees() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['employees', 'toolbar', 'administration', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn } = navigationService.create(t, navigate);

  const [changeAmount, setChangeAmount] = useState<number>();
  const [columns, setColumns] = useState<any[]>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    employmentDate: false,
    email: false,
    lastEdit: false
  });

  const [employeesState, setEmployeesState] = useState<ItemState>();
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = employeesState;
  }, [employeesState]);

  useEffect(() => {
    setChangeAmount(0);

    setColumns([
      {
        field: 'Avatar',
        headerName: '',
        sortable: false,
        width: 70,
        renderCell: (params: any) => {
          let firstName = params.getValue(params.id, 'firstName');
          let lastName = params.getValue(params.id, 'lastName');

          return (
            <ListItemAvatar>
              <Avatar {...avatarService.stringAvatar(firstName + ' ' + lastName)} />
            </ListItemAvatar>
          );
        }
      },
      createColumn('user', {
        headerName: t('user.login'),
        valueGetter: (x: any) => x.value?.login
      }),
      createColumn('reference', { width: 120 }),
      createColumn('email', { width: 120 }),
      createColumn('firstName'),
      createColumn('lastName'),
      createColumn('employmentDate', {
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      createColumn('roles', {
        sortable: false,
        valueGetter: (x: any) => {
          return x.value.map((role: Partial<Role>) => role.name).join(', ');
        }
      }),
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      {
        field: 'action',
        headerName: t('actions'),
        sortable: false,
        hideable: false,
        width: 120,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateEmployees && (
                <ItemDialog
                  kind={ItemDialogKind.Update}
                  type={t('type')}
                  initial={params.row}
                  validate={employeeService.validateEditInfo}
                  confirm={(item) => {
                    employeeService
                      .update(item.id!, item)
                      .then(() => fetchData())
                      .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
                  }}>
                  {(item, handleChange, validation) => (
                    <UpdateEmployee
                      canUpdateUser={
                        canUpdateUsers ||
                        (TokenService.getUser() as TenantUser).employee.name == item.name
                      } //TODO: use id
                      employee={item}
                      handleChange={handleChange}
                      error={validation}
                    />
                  )}
                </ItemDialog>
              )}
              {canDeleteEmployees && (
                <ItemDialog
                  kind={ItemDialogKind.Delete}
                  type={t('type')}
                  initial={params.row}
                  name={params.row.reference!}
                  confirm={(item) => {
                    employeeService.delete(item.id!).then(() => fetchData());
                  }}
                />
              )}
            </div>
          );
        }
      }
    ]);

    const state = itemStateService.getItemStateFromUrl(searchParams, 15);
    if (state.sort.size == 0) {
      state.sort.set('lastEdit', 'desc');
      itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
    }
    setEmployeesState(state);
  }, []);

  const [employees, setEmployees] = useState({
    loading: true,
    rows: new Array<Partial<Employee>>(0),
    rowCount: 0
  });

  const fetchData = () => {
    setEmployees({ ...employees, ...{ loading: true } });
    employeeService.all(stateRef.current).then((result) => {
      setChangeAmount(0);
      setEmployees({
        loading: false,
        rows: result.rows,
        rowCount: result.rowCount
      });
    });
  };

  const canUpdateEmployees = TokenService.tenantFullfills([], ['Employees:Update']);
  const canDeleteEmployees = TokenService.tenantFullfills([], ['Employees:Delete']);
  const canUpdateUsers = TokenService.tenantFullfills([], ['Users:Update']);

  useEffect(() => {
    if (employeesState == null) return;

    fetchData();
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, employeesState);
  }, [employeesState]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              toolbar: {
                employees: employees.rows,
                changeAmount,
                setChangeAmount,
                fetchData,
                employeesState,
                setEmployeesState
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={employees.loading}
            rows={employees.rows ?? []}
            columns={columns ?? []}
            page={employeesState?.paginationSettings?.page}
            pageSize={15}
            rowCount={employees.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(employeesState?.filter)}
            sortModel={filterService.createGridSort(employeesState?.sort)}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onPageChange={(page) => {
              if (employeesState == null) return;
              if (filterService.updateItemStatePage(employeesState, page, 15))
                setEmployeesState({ ...employeesState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (employeesState == null) return;
              if (
                filterService.updateItemStateFilter(
                  employeesState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setEmployeesState({ ...employeesState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (employeesState == null) return;
              if (
                filterService.updateItemStateSort(
                  employeesState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setEmployeesState({ ...employeesState });
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
