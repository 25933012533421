import { Paper, Tab, Tabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Equipment from '../../objects/equipment';
import equipmentService from '../../services/equipmentService';
import EquipmentCalendar from './EquipmentCalendar';
import EquipmentBookingTrend from './EquipmentBookingTrend';
import { useTranslation } from 'react-i18next';
import TokenService from '../../services/tokenService';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;
  return <div style={{ flexGrow: 1 }}>{index === value && children}</div>;
}

export default function EquipmentDetails() {
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const id = searchParams.get('id');
  const { t } = useTranslation('detailPages');

  const [equipment, setEquipment] = useState<Partial<Equipment>>();

  useEffect(() => {
    equipmentService.get(id!).then((result) => {
      setEquipment(result);
    });
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const canViewEquipmentBookings = TokenService.tenantFullfills(
    ['Analysis'],
    ['Equipments:Bookings:View']
  );
  const hasCalendar = TokenService.tenantFullfills(['Calendar'], []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
          {hasCalendar && <Tab label={t('calendar')} tabIndex={0} />}
          {canViewEquipmentBookings && <Tab label={t('analysis')} tabIndex={1} />}
        </Tabs>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          {hasCalendar && currentTab == 0 && (
            <TabPanel value={currentTab} index={0}>
              <EquipmentCalendar />
            </TabPanel>
          )}
          {canViewEquipmentBookings && currentTab == 1 && (
            <TabPanel value={currentTab} index={1}>
              <EquipmentBookingTrend equipment={equipment} />
            </TabPanel>
          )}
        </div>
      </Paper>
    </div>
  );
}
