import Role from '../objects/role';
import { defaultService, ItemState } from './api';

const route = 'roles';

const roleService = {
  validate: (role: any): boolean => role.name?.length > 0,
  get: async (id: string): Promise<Partial<Role>> => (await defaultService.get(route, id)).data,
  create: (role: Partial<Role>) => defaultService.create(route, role),
  update: (id: string, role: Partial<Role>) => defaultService.update(route, id, role),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Role>(route, itemState)
};

export default roleService;
