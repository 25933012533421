import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import { validate } from 'email-validator';

export default function UpdateSupplier(props: any) {
  const { t } = useTranslation('suppliers');
  const [supplier, setSupplier] = useState(props.supplier);

  useEffect(() => {
    props.handleChange(supplier);
  }, [supplier]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          required
          error={props.error}
          style={{ marginBottom: 10 }}
          label={t('name')}
          variant="outlined"
          value={supplier.name ?? ''}
          onChange={(e) => setSupplier({ ...supplier, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('reference')}
          variant="outlined"
          value={supplier.reference ?? ''}
          onChange={(e) => setSupplier({ ...supplier, ...{ reference: e.target.value } })}
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10, marginRight: 10, flex: 1 }}
            label={t('address')}
            variant="outlined"
            value={supplier.address ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ address: e.target.value } })}
          />
          <TextField
            style={{ marginBottom: 10, marginRight: 10, width: 100 }}
            label={t('zipCode')}
            variant="outlined"
            value={supplier.zipCode ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ zipCode: e.target.value } })}
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('city')}
            variant="outlined"
            value={supplier.city ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ city: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!IBAN.isValid(supplier?.iban) && supplier?.iban?.length > 0}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('iban')}
            variant="outlined"
            value={supplier.iban ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ iban: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!validate(supplier?.email) && supplier?.email?.length > 0}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('email')}
            variant="outlined"
            value={supplier.email ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ email: e.target.value } })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            label={t('phone')}
            variant="outlined"
            value={supplier.phone ?? ''}
            onChange={(e) => setSupplier({ ...supplier, ...{ phone: e.target.value } })}
          />
        </div>
      </div>
    </Paper>
  );
}
