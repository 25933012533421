import instance, { defaultService } from '../api';
import Tenant from '../../objects/system/tenant';
import { validate } from 'email-validator';

const route = 'system/tenants';

const tenantService = {
  validate: (tenant: any): boolean =>
    (tenant.template == undefined || tenant.template == 'workshop' || tenant.template == '') &&
    tenant.name?.length > 0 &&
    tenant.userLimit != null &&
    tenant.userLimit > 0 &&
    tenant.activeModules?.length > 0 &&
    tenant.activeModules != undefined &&
    tenant.executiveEmployee?.email.length > 0 &&
    validate(tenant.executiveEmployee?.email) &&
    tenant.executiveEmployee?.firstName.length > 0 &&
    tenant.executiveEmployee?.lastName.length > 0 &&
    tenant.executiveEmployee?.user?.login?.length > 0 &&
    tenant.executiveEmployee?.user?.password?.length > 0,
  validateUpdate: (tenant: any): boolean =>
    tenant.name?.length > 0 && tenant.userLimit != null && tenant.userLimit > 0,
  getInformation: () => instance.get('tenantInformation'),
  getExecutives: (name: string) => defaultService.getAll(route + `/${name}/executives`),
  requestSupport: (name: string, executiveLogin: string) =>
    instance.post(route + `/${name}/executives/${executiveLogin}/support-access`),
  grantSupportAccess: (id: string) => instance.post(`/support-access/${id}`, true),
  get: (name: string) => defaultService.get(route, name),
  update: (name: string, tenant: Partial<Tenant>) => defaultService.update(route, name, tenant),
  create: (tenant: Partial<Tenant>) => defaultService.create(route, tenant),
  delete: (id: string) => defaultService.delete(route, id),
  all: () => defaultService.getAllPaginated<Partial<Tenant>>(route)
};

export default tenantService;
