import { Paper, Tabs, Tab } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import projectService from '../../services/projectService';
import { Project } from '../../objects/project';
import TokenService from '../../services/tokenService';
import EmployeeDisposition from '../../components/disposition/EmployeeDisposition';
import EquipmentDisposition from '../../components/disposition/EquipmentDisposition';
import ProjectDisposition from '../../components/disposition/ProjectDisposition';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value } = props;
  return <div style={{ flexGrow: 1 }}>{index === value && children}</div>;
}

export default function Disposition() {
  const { t } = useTranslation(['projects', 'articles', 'dateFormat', 'detailPages']);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [project, setProject] = useState<Partial<Project>>();
  console.log(project);

  const fetchData = () => {
    if (projectId != null) {
      projectService.get(projectId).then((result) => setProject(result));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const canViewProjects = TokenService.tenantFullfills([], ['Projects:View']);
  const canViewEquipments = TokenService.tenantFullfills([], ['Equipments:View']);
  const canViewEmployees = TokenService.tenantFullfills([], ['Employees:View']);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <Tabs value={currentTab} onChange={handleChangeTab} variant="fullWidth">
          {canViewProjects && <Tab label={t('projects', { ns: 'detailPages' })} tabIndex={0} />}
          {canViewEquipments && <Tab label={t('employees', { ns: 'detailPages' })} tabIndex={1} />}
          {canViewEmployees && <Tab label={t('equipments', { ns: 'detailPages' })} tabIndex={2} />}
        </Tabs>
        <div style={{ flexGrow: 1, display: 'flex' }}>
          {canViewProjects && currentTab == 0 && (
            <TabPanel value={currentTab} index={0}>
              <ProjectDisposition />
            </TabPanel>
          )}
          {canViewEmployees && currentTab == 1 && (
            <TabPanel value={currentTab} index={1}>
              <EmployeeDisposition />
            </TabPanel>
          )}
          {canViewEquipments && currentTab == 2 && (
            <TabPanel value={currentTab} index={2}>
              <EquipmentDisposition />
            </TabPanel>
          )}
        </div>
      </Paper>
    </div>
  );
}
