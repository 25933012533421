import { AppBar, Toolbar, Typography } from '@mui/material';

export default function Privacy() {
  return (
    <div style={{ padding: 20 }}>
      <AppBar
        position="fixed"
        style={{
          backgroundImage: 'linear-gradient(315deg, #70101a 0%, #a71d31 70%)',
          boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 25%)'
        }}>
        <Toolbar>
          <Typography variant="h6" noWrap color="#fff">
            E.Work
          </Typography>
        </Toolbar>
      </AppBar>
      <h2 style={{ marginTop: 60 }}>DATENSCHUTZERKLÄRUNG</h2>
      <div>
        <p>Datenschutz hat für die Jostec Systems UG einen hohen Stellenwert. </p>
        <p>
          Für die Nutzung von Internetseiten der Jostec Systems UG ist generell ohne die Preisgabe
          personenbezogener Daten möglich.
        </p>
        <p>
          In verschiedenen Fällen könnte jedoch das übermitteln von Daten erforderlich. Wenn eine
          Verarbeitung der personenbezogenen Daten, für die keine gesetzliche Grundlage erforderlich
          ist, müssen die betroffenen Personen einwilligen. Die zu verarbeitenden personenbezogenen
          Daten sind zum Beispiel: Namen, Anschrift, E-Mail-Adresse, Telefonnummer. Dabei werden die
          Datenschutz-Grundverordnung und die landesspezifischen Datenschutzbestimmungen betrachtet.
          Mit der Datenschutzerklärung möchten wir öffentlich über die Verwendung, den Datentyp und
          den Umgang mit den Daten informieren. Im Weiteren klären wir hiermit die betroffenen
          Personen über Ihre zustehenden Rechte auf.
        </p>
        <p>
          Die Jostec Systems UG hat als Verantwortlicher für das Verarbeiten viele technische
          Maßnahmen durchgeführt, um den Besten Schutz für die auf dieser Internetseite
          verarbeiteten personenbezogenen Daten zu bieten. Internetbasierte Übertragungen von Daten
          können aber von Grund auf Lücken in der Sicherheit aufweisen, sodass ein vollumfänglicher
          Schutz nie sichergestellt werden kann. Darum entscheidet jede Person frei, ihre
          personenbezogene Daten auch auf anderweitig, z.B. per Telefon, an uns zu übermitteln.
        </p>
        <h2>1. BEGRIFFSBESTIMMUNGEN</h2>
        <p>
          Diese Datenschutzerklärung ist Grundlage der Begrifflichkeiten, der Europäischen
          Richtliniengeber und Verordnungsgeber durch den Beschluss der Datenschutz-Grundverordnung
          (DS-GVO) umgesetzt sind. Diese Datenschutzerklärung gilt Öffentlich, aber auch für Kunden
          und Geschäftspartner. Zum besseren Verständnis wollen die Begriffe genauer erläutern:
        </p>
        <h4>A) PERSONENBEZOGENE DATEN</h4>
        <p>
          Das sind alle Daten und Informationen, die sich zur Identifikation einer natürlichen
          Person eignen. Eine natürliche Person wird als identifizierbar definiert, wenn direkte
          oder indirekte Merkmale und Kennungen vorliegen. Dazu gehören u.a.: Name, Kennnummern,
          Standortinformationen. Außerdem weitere besondere Merkmale und Klassifikationen der
          Identität, wie unter anderem: genetische-, wirtschaftliche-, physiologische-, physische-,
          psychische-, soziale- oder kulturelle-Identität.
        </p>
        <h4>B) BETROFFENE PERSON</h4>
        <p>Es sind Personen, deren personenbezogene Daten erfasst und verarbeitet wurden.</p>
        <h4>C) VERARBEITUNG</h4>
        <p>
          Die Verarbeitung beschreibt den Prozess des Erhebens, Erfassens, des Aufbaus und der
          Ordnung, Verwendung, der Weiterleitung, sowie der Speicherung und Bearbeitung (digital
          oder analog) von personenbezogenen Daten.{' '}
        </p>
        <h4>D) EINSCHRÄNKUNG DER VERARBEITUNG</h4>
        <p>
          Mit der Einschränkung der Verarbeitung werden bestimmte Daten gekennzeichnet und Ihre
          Verarbeitung eingeschränkt.
        </p>
        <h4>E) PROFILING</h4>
        <p>
          Im Profiling werden personenbezogene Daten angewendet, um Merkmale der Persönlichkeit von
          natürlichen Personen auszuwerten und Prognosen aufzustellen. Beispiele für diese Merkmale
          können sein: Leistungserhebung (Arbeit), Wirtschaftlichkeit, Gesundheit, Vorlieben,
          Interessen, Zuverlässigkeit, Verhalten, Standort, Standortverfolgung.
        </p>
        <h4>F) PSEUDONYMISIERUNG</h4>
        <p>
          Durch die Pseudonymisierung können keine Verbindungen von erhobenen Daten und den
          zugehörigen natürlichen Personen nachvollzogen werden.
        </p>
        <h4>G) VERANTWORTLICHER ODER FÜR DIE VERARBEITUNG VERANTWORTLICHER</h4>
        <p>
          Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
          juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
          entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das
          Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können
          die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
          Mitgliedstaaten vorgesehen werden.
        </p>
        <h4>H) AUFTRAGSVERARBEITER</h4>
        <p>
          Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
          andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
        </p>
        <h4>I) EMPFÄNGER</h4>
        <p>
          Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei
          ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
          Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
          möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
        </p>
        <h4>J) DRITTER</h4>
        <p>
          Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den
          Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
        </p>
        <h4>K) EINWILLIGUNG</h4>
        <p>
          Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
          informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer
          Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
          personenbezogenen Daten einverstanden ist.
        </p>
        <h2>2. NAME UND ANSCHRIFT DES FÜR DIE VERARBEITUNG VERANTWORTLICHEN</h2>
        <p>
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
          Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
          Bestimmungen mit datenschutzrechtlichem Charakter ist die: Jostec Systems UG Buchenstraße
          20 93426 Roding Deutschland Tel.: 015152520430 E-Mail: info@jostec-systems.de Website:
          www.jostec-systems.de
        </p>
        <h2>3. NAME UND ANSCHRIFT DES DATENSCHUTZBEAUFTRAGTEN</h2>
        <p>
          Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist: Maximilian
          Stöckl Jägerruitstraße 3 93189 Reichenbach Deutschland Tel.: +49 15152520430 E-Mail:
          maximilian.stoeckl@jostec-systems.de Jede betroffene Person kann sich jederzeit bei allen
          Fragen und Anregungen zum Datenschutz direkt an unseren Datenschutzbeauftragten wenden.
        </p>
        <h2>4. COOKIES</h2>
        <p>Die Internetseiten der Jostec Systems UG verwenden Cookies.</p>
        <p>
          Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem
          abgelegt und gespeichert werden. Zahlreiche Internetseiten und Server verwenden Cookies.
          Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige
          Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und
          Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie
          gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den
          individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere
          Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die
          eindeutige Cookie-ID wiedererkannt und identifiziert werden. Durch den Einsatz von Cookies
          kann die Jostec Systems UG den Nutzern dieser Internetseite nutzerfreundlichere Services
          bereitstellen, die ohne die Cookie-Setzung nicht möglich wären. Mittels eines Cookies
          können die Informationen und Angebote auf unserer Internetseite im Sinne des Benutzers
          optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
          Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die
          Verwendung unserer Internetseite zu erleichtern. Der Benutzer einer Internetseite, die
          Cookies verwendet, muss beispielsweise nicht bei jedem Besuch der Internetseite erneut
          seine Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
          Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist
          das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die
          ein Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie. Die betroffene Person
          kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer
          entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
          von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über
          einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen
          gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von
          Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen
          unserer Internetseite vollumfänglich nutzbar.
        </p>
        <h2>5. ERFASSUNG VON ALLGEMEINEN DATEN UND INFORMATIONEN</h2>
        <p>
          Die Internetseite der Jostec Systems UG erfasst mit jedem Aufruf der Internetseite durch
          eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten
          und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des
          Servers gespeichert. Erfasst werden können die
        </p>
        <p>(1) verwendeten Browsertypen und Versionen,</p>
        <p>(2) das vom zugreifenden System verwendete Betriebssystem,</p>
        <p>
          (3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite
          gelangt (sogenannte Referrer),
        </p>
        <p>
          (4) die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite
          angesteuert werden,
        </p>
        <p>(5) das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,</p>
        <p>(6) eine Internet-Protokoll-Adresse (IP-Adresse),</p>
        <p>(7) der Internet-Service-Provider des zugreifenden Systems und </p>
        <p>
          (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
          Angriffen auf unsere informationstechnologischen Systeme dienen.
        </p>
        <p>
          Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Jostec Systems UG
          keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr
          benötigt, um
        </p>
        <p>(1) die Inhalte unserer Internetseite korrekt auszuliefern,</p>
        <p>(2) die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,</p>
        <p>
          (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der
          Technik unserer Internetseite zu gewährleisten sowie
        </p>
        <p>
          (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung
          notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen
          werden durch die Jostec Systems UG daher einerseits statistisch und ferner mit dem Ziel
          ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um
          letztlich ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten
          sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch
          eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
        </p>
        <h2>6. ABONNEMENT UNSERES NEWSLETTERS</h2>
        <p>
          Auf der Internetseite der Jostec Systems UG wird den Benutzern die Möglichkeit eingeräumt,
          den Newsletter unseres Unternehmens zu abonnieren. Welche personenbezogenen Daten bei der
          Bestellung des Newsletters an den für die Verarbeitung Verantwortlichen übermittelt
          werden, ergibt sich aus der hierzu verwendeten Eingabemaske. Die Jostec Systems UG
          informiert ihre Kunden und Geschäftspartner in regelmäßigen Abständen im Wege eines
          Newsletters über Angebote des Unternehmens. Der Newsletter unseres Unternehmens kann von
          der betroffenen Person grundsätzlich nur dann empfangen werden, wenn
        </p>
        <p>(1) die betroffene Person über eine gültige E-Mail-Adresse verfügt und</p>
        <p>(2) die betroffene Person sich für den Newsletterversand registriert.</p>
        <p>
          An die von einer betroffenen Person erstmalig für den Newsletterversand eingetragene
          E-Mail-Adresse wird aus rechtlichen Gründen eine Bestätigungsmail im
          Double-Opt-In-Verfahren versendet. Diese Bestätigungsmail dient der Überprüfung, ob der
          Inhaber der E-Mail-Adresse als betroffene Person den Empfang des Newsletters autorisiert
          hat. Bei der Anmeldung zum Newsletter speichern wir ferner die vom
          Internet-Service-Provider (ISP) vergebene IP-Adresse des von der betroffenen Person zum
          Zeitpunkt der Anmeldung verwendeten Computersystems sowie das Datum und die Uhrzeit der
          Anmeldung. Die Erhebung dieser Daten ist erforderlich, um den(möglichen) Missbrauch der
          E-Mail-Adresse einer betroffenen Person zu einem späteren Zeitpunkt nachvollziehen zu
          können und dient deshalb der rechtlichen Absicherung des für die Verarbeitung
          Verantwortlichen. Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen
          Daten werden ausschließlich zum Versand unseres Newsletters verwendet. Ferner könnten
          Abonnenten des Newsletters per E-Mail informiert werden, sofern dies für den Betrieb des
          Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich ist, wie dies im
          Falle von Änderungen am Newsletterangebot oder bei der Veränderung der technischen
          Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im Rahmen des
          Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. Das Abonnement unseres
          Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die Einwilligung
          in die Speicherung personenbezogener Daten, die die betroffene Person uns für den
          Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs
          der Einwilligung findet sich in jedem Newsletter ein entsprechender Link. Ferner besteht
          die Möglichkeit, sich jederzeit auch direkt auf der Internetseite des für die Verarbeitung
          Verantwortlichen vom Newsletterversand abzumelden oder dies dem für die Verarbeitung
          Verantwortlichen auf andere Weise mitzuteilen.
        </p>
        <h2>7. NEWSLETTER-TRACKING</h2>
        <p>
          Die Newsletter der Jostec Systems UG enthalten sogenannte Zählpixel. Ein Zählpixel ist
          eine Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im HTML-Format
          versendet werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen.
          Dadurch kann eine statistische Auswertung des Erfolges oder Misserfolges von
          Online-Marketing-Kampagnen durchgeführt werden. Anhand des eingebetteten Zählpixels kann
          die Jostec Systems UG erkennen, ob und wann eine E-Mail von einer betroffenen Person
          geöffnet wurde und welche in der E-Mail befindlichen Links von der betroffenen Person
          aufgerufen wurden. Solche über die in den Newslettern enthaltenen Zählpixel erhobenen
          personenbezogenen Daten, werden von dem für die Verarbeitung Verantwortlichen gespeichert
          und ausgewertet, um den Newsletterversand zu optimieren und den Inhalt zukünftiger
          Newsletter noch besser den Interessen der betroffenen Person anzupassen. Diese
          personenbezogenen Daten werden nicht an Dritte weitergegeben. Betroffene Personen sind
          jederzeit berechtigt, die diesbezügliche gesonderte, über das Double-Opt-In-Verfahren
          abgegebene Einwilligungserklärung zu widerrufen. Nach einem Widerruf werden diese
          personenbezogenen Daten von dem für die Verarbeitung Verantwortlichen gelöscht. Eine
          Abmeldung vom Erhalt des Newsletters deutet die Jostec Systems UG automatisch als
          Widerruf.
        </p>
        <h2>8. KONTAKTMÖGLICHKEIT ÜBER DIE INTERNETSEITE</h2>
        <p>
          Die Internetseite der Jostec Systems UG enthält aufgrund von gesetzlichen Vorschriften
          Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
          unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der
          sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene Person
          per E-Mail oder über ein Kontaktformular den Kontakt mit dem für die Verarbeitung
          Verantwortlichen aufnimmt, werden die von der betroffenen Person übermittelten
          personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer
          betroffenen Person an den für die Verarbeitung Verantwortlichen übermittelten
          personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur
          betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen Daten
          an Dritte.
        </p>
        <h2>9. ROUTINEMÄSSIGE LÖSCHUNG UND SPERRUNG VON PERSONENBEZOGENEN DATEN</h2>
        <p>
          Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten
          der betroffenen Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks
          erforderlich ist oder sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber
          oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die
          Verarbeitung Verantwortliche unterliegt, vorgesehen wurde. Entfällt der Speicherungszweck
          oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder einem anderen
          zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen
          Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        </p>
        <h2>10. RECHTE DER BETROFFENEN PERSON</h2>
        <h4>A) RECHT AUF BESTÄTIGUNG</h4>
        <p>
          Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
          eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber
          zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Möchte eine
          betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu
          jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </p>
        <h4>B) RECHT AUF AUSKUNFT</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die
          Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
          gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner
          hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über
          folgende Informationen zugestanden: - die Verarbeitungszwecke - die Kategorien
          personenbezogener Daten, die verarbeitet werden - die Empfänger oder Kategorien von
          Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch
          offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen
          Organisationen - falls möglich die geplante Dauer, für die die personenbezogenen Daten
          gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung
          dieser Dauer - das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
          betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den
          Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung - das Bestehen
          eines Beschwerderechts bei einer Aufsichtsbehörde - wenn die personenbezogenen Daten nicht
          bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die
          Herkunft der Daten - das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen
          Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und
          die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person
          Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene
          Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern
          dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über
          die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten. Möchte eine
          betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit
          an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </p>
        <h4>C) RECHT AUF BERICHTIGUNG</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche
          Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
          steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
          Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels
          einer ergänzenden Erklärung — zu verlangen. Möchte eine betroffene Person dieses
          Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter
          des für die Verarbeitung Verantwortlichen wenden.
        </p>
        <h4>D) RECHT AUF LÖSCHUNG (RECHT AUF VERGESSEN WERDEN)</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu
          verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
          sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich
          ist:
        </p>
        <p>
          - Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise
          verarbeitet, für welche sie nicht mehr notwendig sind. - Die betroffene Person widerruft
          ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO
          oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen
          Rechtsgrundlage für die Verarbeitung.
        </p>
        <p>
          - Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die
          Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung
          vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die
          Verarbeitung ein.
        </p>
        <p>- Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
        <p>
          - Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
          Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
          der Verantwortliche unterliegt.
        </p>
        <p>
          - Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
          Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
        </p>
        <p>
          Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung
          von personenbezogenen Daten, die bei der Jostec Systems UG gespeichert sind, veranlassen
          möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden. Der Mitarbeiter der Jostec Systems UG wird veranlassen, dass dem
          Löschverlangen unverzüglich nachgekommen wird.
        </p>
        <p>
          Wurden die personenbezogenen Daten von der Jostec Systems UG öffentlich gemacht und ist
          unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der
          personenbezogenen Daten verpflichtet, so trifft die Jostec Systems UG unter
          Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene
          Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche,
          welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu
          setzen, dass die betroffene Person von diesen anderen für die Datenverarbeitung
          Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von
          Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die
          Verarbeitung nicht erforderlich ist. Der Mitarbeiter der Jostec Systems UG wird im
          Einzelfall das Notwendige veranlassen.
        </p>
        <h4>E) RECHT AUF EINSCHRÄNKUNG DER VERARBEITUNG</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen
          die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen
          gegeben ist:
        </p>
        <p>
          - Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten,
          und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
          personenbezogenen Daten zu überprüfen.
        </p>
        <p>
          - Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
          personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der
          personenbezogenen Daten.
        </p>
        <p>
          - Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung
          nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          - Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO
          eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen
          gegenüber denen der betroffenen Person überwiegen.
        </p>
        <p>
          Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die
          Einschränkung von personenbezogenen Daten, die bei der Jostec Systems UG gespeichert sind,
          verlangen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Jostec Systems UG wird die
          Einschränkung der Verarbeitung veranlassen.
        </p>
        <h4>F) RECHT AUF DATENÜBERTRAGBARKEIT</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden
          personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen
          bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne
          Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
          wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1
          Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art.
          6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
          erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist,
          die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche
          dem Verantwortlichen übertragen wurde. Ferner hat die betroffene Person bei der Ausübung
          ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken,
          dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
          Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon
          nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden. Zur Geltendmachung
          des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen
          Mitarbeiter der Jostec Systems UG wenden.
        </p>
        <h4>G) RECHT AUF WIDERSPRUCH</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus
          ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender
          personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO
          erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes
          Profiling. Die Jostec Systems UG verarbeitet die personenbezogenen Daten im Falle des
          Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person
          überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen. Verarbeitet die Jostec Systems UG personenbezogene Daten, um
          Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch
          gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
          einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
          Verbindung steht. Widerspricht die betroffene Person gegenüber der Jostec Systems UG der
          Verarbeitung für Zwecke der Direktwerbung, so wird die Jostec Systems UG die
          personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten. Zudem hat die betroffene
          Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die
          sie betreffende Verarbeitung personenbezogener Daten, die bei der Jostec Systems UG zu
          wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß
          Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
          Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
          erforderlich. Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person
          direkt jeden Mitarbeiter der Jostec Systems UG oder einen anderen Mitarbeiter wenden. Der
          betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der
          Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht
          mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen
          verwendet werden.
        </p>
        <h4>H) AUTOMATISIERTE ENTSCHEIDUNGEN IM EINZELFALL EINSCHLIESSLICH PROFILIN</h4>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer ausschließlich
          auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden
          Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder
          sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung
        </p>
        <p>
          (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen
          Person und dem Verantwortlichen erforderlich ist, oder
        </p>
        <p>
          (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
          Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
          Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der
          betroffenen Person enthalten oder
        </p>
        <p>(3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.</p>
        <p>Ist die Entscheidung</p>
        <p>
          (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person
          und dem Verantwortlichen erforderlich oder
        </p>
        <p>
          (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, trifft die Jostec
          Systems UG angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten
          Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des
          Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen
          Standpunkts und auf Anfechtung der Entscheidung gehört.
        </p>
        <p>
          Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend
          machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden.
        </p>
        <p>I) RECHT AUF WIDERRUF EINER DATENSCHUTZRECHTLICHEN EINWILLIGUNG</p>
        <p>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
          Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur
          Verarbeitung personenbezogener Daten jederzeit zu widerrufen. Möchte die betroffene Person
          ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich hierzu jederzeit
          an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </p>
        <h2>11. DATENSCHUTZ BEI BEWERBUNGEN UND IM BEWERBUNGSVERFAHREN</h2>
        <p>
          Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen
          Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung
          kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein
          Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise
          per E-Mail oder über ein auf der Internetseite befindliches Webformular, an den für die
          Verarbeitung Verantwortlichen übermittelt. Schließt der für die Verarbeitung
          Verantwortliche einen Anstellungsvertrag mit einem Bewerber, werden die übermittelten
          Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der
          gesetzlichen Vorschriften gespeichert. Wird von dem für die Verarbeitung Verantwortlichen
          kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die Bewerbungsunterlagen
          zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer
          Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen
          entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine
          Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
        </p>
        <h2>12. DATENSCHUTZBESTIMMUNGEN ZU EINSATZ UND VERWENDUNG VON FACEBOOK</h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten des
          Unternehmens Facebook integriert. Facebook ist ein soziales Netzwerk. Ein soziales
          Netzwerk ist ein im Internet betriebener sozialer Treffpunkt, eine Online-Gemeinschaft,
          die es den Nutzern in der Regel ermöglicht, untereinander zu kommunizieren und im
          virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als Plattform zum Austausch
          von Meinungen und Erfahrungen dienen oder ermöglicht es der Internetgemeinschaft,
          persönliche oder unternehmensbezogene Informationen bereitzustellen. Facebook ermöglicht
          den Nutzern des sozialen Netzwerkes unter anderem die Erstellung von privaten Profilen,
          den Upload von Fotos und eine Vernetzung über Freundschaftsanfragen. Betreibergesellschaft
          von Facebook ist die Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. Für die
          Verarbeitung personenbezogener Daten Verantwortlicher ist, wenn eine betroffene Person
          außerhalb der USA oder Kanada lebt, die Facebook Ireland Ltd., 4 Grand Canal Square, Grand
          Canal Harbour, Dublin 2, Ireland. Durch jeden Aufruf einer der Einzelseiten dieser
          Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine Facebook-Komponente (Facebook-Plug-In) integriert wurde, wird der
          Internetbrowser auf dem informationstechnologischen System der betroffenen Person
          automatisch durch die jeweilige Facebook-Komponente veranlasst, eine Darstellung der
          entsprechenden Facebook-Komponente von Facebook herunterzuladen. Eine Gesamtübersicht über
          alle Facebook-Plug-Ins kann unter
          https://developers.facebook.com/docs/plugins/?locale=de_DE abgerufen werden. Im Rahmen
          dieses technischen Verfahrens erhält Facebook Kenntnis darüber, welche konkrete Unterseite
          unserer Internetseite durch die betroffene Person besucht wird. Sofern die betroffene
          Person gleichzeitig bei Facebook eingeloggt ist, erkennt Facebook mit jedem Aufruf unserer
          Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen
          Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite unserer Internetseite
          die betroffene Person besucht. Diese Informationen werden durch die Facebook-Komponente
          gesammelt und durch Facebook dem jeweiligen Facebook-Account der betroffenen Person
          zugeordnet. Betätigt die betroffene Person einen der auf unserer Internetseite
          integrierten Facebook-Buttons, beispielsweise den „Gefällt mir“-Button, oder gibt die
          betroffene Person einen Kommentar ab, ordnet Facebook diese Information dem persönlichen
          Facebook-Benutzerkonto der betroffenen Person zu und speichert diese personenbezogenen
          Daten. Facebook erhält über die Facebook-Komponente immer dann eine Information darüber,
          dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person
          zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei Facebook eingeloggt ist;
          dies findet unabhängig davon statt, ob die betroffene Person die Facebook-Komponente
          anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an Facebook von
          der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass
          sie sich vor einem Aufruf unserer Internetseite aus ihrem Facebook-Account ausloggt. Die
          von Facebook veröffentlichte Datenrichtlinie, die unter
          https://de-de.facebook.com/about/privacy/ abrufbar ist, gibt Aufschluss über die Erhebung,
          Verarbeitung und Nutzung personenbezogener Daten durch Facebook. Ferner wird dort
          erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz der Privatsphäre der
          betroffenen Person bietet. Zudem sind unterschiedliche Applikationen erhältlich, die es
          ermöglichen, eine Datenübermittlung an Facebook zu unterdrücken. Solche Applikationen
          können durch die betroffene Person genutzt werden, um eine Datenübermittlung an Facebook
          zu unterdrücken.
        </p>
        <h2>13. DATENSCHUTZBESTIMMUNGEN ZU EINSATZ UND VERWENDUNG VON XING</h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von Xing
          integriert. Xing ist ein Internetbasiertes soziales Netzwerk, das die Konnektierung der
          Nutzer mit bestehenden Geschäftskontakten sowie das Knüpfen von neuen Businesskontakten
          ermöglicht. Die einzelnen Nutzer können bei Xing ein persönliches Profil von sich anlegen.
          Unternehmen können beispielsweise Unternehmensprofile erstellen oder Stellenangebote auf
          Xing veröffentlichen. Betreibergesellschaft von Xing ist die XING SE, Dammtorstraße 30,
          20354 Hamburg, Deutschland. Durch jeden Aufruf einer der Einzelseiten dieser
          Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und auf
          welcher eine Xing-Komponente (Xing-Plug-In) integriert wurde, wird der Internetbrowser auf
          dem informationstechnologischen System der betroffenen Person automatisch durch die
          jeweilige Xing-Komponente veranlasst, eine Darstellung der entsprechenden Xing-Komponente
          von Xing herunterzuladen. Weitere Informationen zum den Xing-Plug-Ins können unter
          https://dev.xing.com/plugins abgerufen werden. Im Rahmen dieses technischen Verfahrens
          erhält Xing Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die
          betroffene Person besucht wird. Sofern die betroffene Person gleichzeitig bei Xing
          eingeloggt ist, erkennt Xing mit jedem Aufruf unserer Internetseite durch die betroffene
          Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer
          Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person
          besucht. Diese Informationen werden durch die Xing-Komponente gesammelt und durch Xing dem
          jeweiligen Xing-Account der betroffenen Person zugeordnet. Betätigt die betroffene Person
          einen der auf unserer Internetseite integrierten Xing-Buttons, beispielsweise den
          „Share“-Button, ordnet Xing diese Information dem persönlichen Xing-Benutzerkonto der
          betroffenen Person zu und speichert diese personenbezogenen Daten. Xing erhält über die
          Xing-Komponente immer dann eine Information darüber, dass die betroffene Person unsere
          Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer
          Internetseite gleichzeitig bei Xing eingeloggt ist; dies findet unabhängig davon statt, ob
          die betroffene Person die Xing-Komponente anklickt oder nicht. Ist eine derartige
          Übermittlung dieser Informationen an Xing von der betroffenen Person nicht gewollt, kann
          diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
          Internetseite aus ihrem Xing-Account ausloggt. Die von Xing veröffentlichten
          Datenschutzbestimmungen, die unter https://www.xing.com/privacy abrufbar sind, geben
          Aufschluss über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch Xing.
          Ferner hat Xing unter https://www.xing.com/app/share?op=data_protection
          Datenschutzhinweise für den XING-Share-Button veröffentlicht.
        </p>
        <h2>14. DATENSCHUTZBESTIMMUNGEN ZU EINSATZ UND VERWENDUNG VON LINKEDIN</h2>
        <p>
          Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des Dienstes LinkedIn
          eingebunden, angeboten durch die LinkedIn AG, Dammtorstraße 29-32, 20354 Hamburg,
          Deutschland. Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
          gehören, mit denen Nutzer Ihr Gefallen betreffend die Inhalte kundtun, den Verfassern der
          Inhalte oder unsere Beiträge abonnieren können. Sofern die Nutzer Mitglieder der Plattform
          LinkedIn sind, kann LinkedIn den Aufruf der o.g. Inhalte und Funktionen den dortigen
          Profilen der Nutzer zuordnen. Datenschutzerklärung von LinkedIn:
          https://www.linkedin.com/legal/privacy-policy. LinkedIn ist unter dem
          Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
          Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active) Opt-Out:
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
        </p>
        <h2>15. DATENSCHUTZBESTIMMUNGEN ZU EINSATZ UND VERWENDUNG VON YOUTUBE </h2>
        <p>
          Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von
          YouTube integriert. YouTube ist ein Internet-Videoportal, dass Video-Publishern das
          kostenlose Einstellen von Videoclips und anderen Nutzern die ebenfalls kostenfreie
          Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube gestattet die
          Publikation aller Arten von Videos, weshalb sowohl komplette Film- und Fernsehsendungen,
          aber auch Musikvideos, Trailer oder von Nutzern selbst angefertigte Videos über das
          Internetportal abrufbar sind. Betreibergesellschaft von YouTube ist die YouTube, LLC, 901
          Cherry Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der
          Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA. Durch jeden Aufruf
          einer der Einzelseiten dieser Internetseite, die durch den für die Verarbeitung
          Verantwortlichen betrieben wird und auf welcher eine YouTube-Komponente (YouTube-Video)
          integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System der
          betroffenen Person automatisch durch die jeweilige YouTube-Komponente veranlasst, eine
          Darstellung der entsprechenden YouTube-Komponente von YouTube herunterzuladen. Weitere
          Informationen zu YouTube können unter https://www.youtube.com/yt/about/de/ abgerufen
          werden. Im Rahmen dieses technischen Verfahrens erhalten YouTube und Google Kenntnis
          darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene Person
          besucht wird. Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist,
          erkennt YouTube mit dem Aufruf einer Unterseite, die ein YouTube-Video enthält, welche
          konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese
          Informationen werden durch YouTube und Google gesammelt und dem jeweiligen YouTube-Account
          der betroffenen Person zugeordnet. YouTube und Google erhalten über die YouTube-Komponente
          immer dann eine Information darüber, dass die betroffene Person unsere Internetseite
          besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite
          gleichzeitig bei YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die
          betroffene Person ein YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung
          dieser Informationen an YouTube und Google von der betroffenen Person nicht gewollt, kann
          diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer
          Internetseite aus ihrem YouTube-Account ausloggt. Die von YouTube veröffentlichten
          Datenschutzbestimmungen, die unter https://www.google.de/intl/de/policies/privacy/
          abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung
          personenbezogener Daten durch YouTube und Google.
        </p>
        <h2>16. NUTZUNG VON GOOGLE ANALYTICS</h2>
        <p>
          Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus
          gewonnenen Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren.
          Google Analytics ist ein Webanalysedienst, der von Google Inc. (1600 Amphitheatre Parkway,
          Mountain View, CA 94043, United States) betrieben und bereitgestellt wird. Google
          verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und verpflichtet sich
          vertraglich zu Maßnahmen, um die Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
          Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:
        </p>
        <p>- Aufgerufene Seiten</p>
        <p>
          - Die Erreichung von „Website-Zielen“ (z.B. Kontaktanfragen und Newsletter-Anmeldungen)
        </p>
        <p>
          - Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer)
        </p>
        <p>
          - Ihr ungefährer Standort (Land und Stadt) - Ihre IP-Adresse (in gekürzter Form, sodass
          keine eindeutige Zuordnung möglich ist)
        </p>
        <p>
          - Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung
        </p>
        <p>
          - Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel
          Sie zu uns gekommen sind)
        </p>
        <p>
          Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit
          Ihrem letzten Besuch. Diese Cookies enthaltene eine zufallsgenerierte User-ID, mit der Sie
          bei zukünftigen Website-Besuchen wiedererkannt werden können.
        </p>
        <p>
          Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert,
          was die Auswertung pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten
          werden automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in aggregierter Form
          unbefristet gespeichert. Sollten Sie mit der Erfassung nicht einverstanden sein, können
          Sie diese mit der einmaligen Installation des Browser-Add-ons zur Deaktivierung von Google
          Analytics unterbinden.
        </p>
        <h2>17. RECHTSGRUNDLAGE DER VERARBEITUNG</h2>
        <p>
          Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für
          Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
          Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur Erfüllung
          eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies
          beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren
          oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht
          die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
          Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind,
          etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser
          Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
          personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
          Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen
          könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige
          Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies
          wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde
          und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige
          Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden
          müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten
          Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage
          basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst
          werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
          Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns
          insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders
          erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
          anzunehmen sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist
          (Erwägungsgrund 47 Satz 2 DS-GVO).
        </p>
        <h2>
          18. BERECHTIGTE INTERESSEN AN DER VERARBEITUNG, DIE VON DEM VERANTWORTLICHEN ODER EINEM
          DRITTEN VERFOLGT WERDEN
        </h2>
        <p>
          Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser
          berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des
          Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.{' '}
        </p>
        <h2>19. DAUER, FÜR DIE DIE PERSONENBEZOGENEN DATEN GESPEICHERT WERDEN</h2>
        <p>
          Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige
          gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten
          routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
          erforderlich sind.{' '}
        </p>
        <h2>
          20. GESETZLICHE ODER VERTRAGLICHE VORSCHRIFTEN ZUR BEREITSTELLUNG DER PERSONENBEZOGENEN
          DATEN; ERFORDERLICHKEIT FÜR DEN VERTRAGSABSCHLUSS; VERPFLICHTUNG DER BETROFFENEN PERSON,
          DIE PERSONENBEZOGENEN DATEN BEREITZUSTELLEN; MÖGLICHE FOLGEN DER NICHTBEREITSTELLUNG
        </h2>
        <p>
          Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil
          gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen
          Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
          Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene Daten
          zur Verfügung stellt, die in der Folge durch uns verarbeitet werden müssen. Die betroffene
          Person ist beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, wenn
          unser Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung der
          personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht
          geschlossen werden könnte. Vor einer Bereitstellung personenbezogener Daten durch den
          Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser
          Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
          personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den
          Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen
          Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen
          Daten hätte.{' '}
        </p>
        <h2>21. BESTEHEN EINER AUTOMATISIERTEN ENTSCHEIDUNGSFINDUNG</h2>
        <p>
          Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
          Entscheidungsfindung oder ein Profiling. Diese Datenschutzerklärung wurde durch den
          Datenschutzerklärungs-Generator der DGD Deutsche Gesellschaft für Datenschutz GmbH, die
          als Externer Datenschutzbeauftragter Bayreuth tätig ist, erstellt.{' '}
        </p>
        <h2>22. BESCHWERDERECHT</h2>
        <p>
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
          zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen
          Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
          seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können
          der Website des BfDI entnommen werden.
        </p>
      </div>
    </div>
  );
}
