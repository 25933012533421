import { Link, Paper, Tooltip, Typography } from '@mui/material';
import {
  GridColumns,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import navigationService from '../../services/navigationService';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import equipmentService from '../../services/equipmentService';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import UpdateEquipment from '../../components/equipments/UpdateEquipment';
import filterService from '../../services/filterService';
import { ItemState, itemStateService } from '../../services/api';
import Equipment, { EquipmentWarehouse } from '../../objects/equipment';
import moment from 'moment';
import { DataGridPro } from '@mui/x-data-grid-pro';
import TokenService from '../../services/tokenService';
import warehouseService from '../../services/warehouseService';
import Warehouse from '../../objects/warehouses';
import QuickActionPanel from '../../components/equipments/QuickActionPanel';
import { useSnackbar } from 'notistack';
import CustomToolbar from './CustomToolbar';

export default function Equipments() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['equipments', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn, createNavigationWithPropsColumn } = navigationService.create(t, navigate);
  const [columns, setColumns] = useState<GridColumns>();
  const [warehouses, setWarehouses] = useState<Partial<Warehouse>[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<Map<string, Partial<Equipment>>>(
    new Map<string, Partial<Equipment>>()
  );

  const [equipmentsState, setEquipmentsState] = useState<ItemState>();
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = equipmentsState;
  }, [equipmentsState]);
  const [equipments, setEquipments] = useState({
    loading: true,
    rows: new Array<Partial<Equipment>>(),
    rowCount: 0
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    type: false,
    state: false,
    totalAmount: true,
    monetaryDepreciationByHour: true,
    lastReservingEmployee: false,
    lastEditor: false,
    lastEdit: false
  });

  const fetchData = () => {
    equipmentService
      .all(stateRef.current)
      .then((result) => {
        setEquipments({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setEquipments({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  const canViewWarehouses = TokenService.tenantFullfills([], ['Warehouses:View']);
  const canViewEmployees = TokenService.tenantFullfills([], ['Employees:View']);

  useEffect(() => {
    if (canViewWarehouses) warehouseService.all().then((result) => setWarehouses(result.rows));
  }, []);

  useEffect(() => {
    if (equipmentsState == null) return;

    fetchData();
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, equipmentsState);
  }, [equipmentsState]);

  useEffect(() => {
    let tempColumns = [
      createNavigationWithPropsColumn(
        'name',
        'equipments/details',
        (rowValue: any) => {
          return { id: rowValue.id };
        },
        { width: 150, hideable: false }
      ),
      createColumn('reference', {
        width: 150,
        headerName: t('inventoryNumber'),
        hideable: false
      }),
      createColumn('userType', {
        headerName: t('equipmentType'),
        width: 150,
        valueGetter: (params: any) => t('typeNames.' + (params.row.userType ?? 'Equipment'))
      }),
      createColumn('state', { width: 150 }),
      createColumn('unit', { width: 90 }),
      createColumn('totalAmount', { width: 150 }),
      createColumn('monetaryDepreciationByHour', {
        width: 180,
        headerName: t('monetaryDepreciationByHour'),
        valueGetter: (x: any) => (x.value ? x.value?.toFixed(2) + ' €' : '')
      })
    ];

    if (canViewWarehouses)
      tempColumns.push(
        createColumn('warehouses', {
          sortable: false,
          minWidth: 200,
          hideable: false,
          valueGetter: (x: any) =>
            x.value?.map((warehouse: any) => warehouse.warehouse.displayName).join(', '),
          renderCell: (params: any) => {
            const TooltipTitle = (warehouses: Partial<EquipmentWarehouse>[]) => {
              return (
                <div>
                  {warehouses.map((warehouse: any) => (
                    <p key={warehouse.warehouse.displayName}>
                      {warehouse.amount} - {warehouse.warehouse.displayName}
                    </p>
                  ))}
                </div>
              );
            };
            return (
              <>
                {params.row.warehouses?.length == 1 && (
                  <Typography>{params.row.warehouses[0].warehouse.displayName}</Typography>
                )}
                {params.row.warehouses?.length > 1 && (
                  <Tooltip title={TooltipTitle(params.row.warehouses)}>
                    <Link component="p" underline="none">
                      {t('multipleWarehouses')}
                    </Link>
                  </Tooltip>
                )}
              </>
            );
          }
        })
      );

    tempColumns.push(createColumn('reservedAmount', { sortable: false, width: 130 }));

    if (canViewEmployees)
      tempColumns.push(
        createNavigationWithPropsColumn(
          'lastReservingEmployee',
          'employee-details',
          (rowValue: any) => {
            return { employeeId: rowValue.identifier };
          },
          {
            width: 130,
            valueGetter: (x: any) => x.value?.displayName
          }
        )
      );

    tempColumns.push(
      createNavigationWithPropsColumn(
        'lastEditor',
        'employee-details',
        (rowValue: any) => {
          return { employeeId: rowValue.identifier };
        },
        {
          width: 130,
          valueGetter: (x: any) => x.value?.displayName
        }
      )
    );

    tempColumns.push(
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      createColumn('actions', {
        sortable: false,
        minWidth: 100,
        maxWidth: 100,
        hideable: false,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              <ItemDialog
                kind={ItemDialogKind.Update}
                type={t('type')}
                initial={params.row}
                validate={equipmentService.validate}
                confirm={(item) => {
                  equipmentService
                    .update(item.id!, item)
                    .then(() => fetchData())
                    .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
                }}>
                {(item, handleChange, validation) => (
                  <UpdateEquipment
                    equipment={item}
                    handleChange={handleChange}
                    error={validation}
                  />
                )}
              </ItemDialog>
              <ItemDialog
                kind={ItemDialogKind.Delete}
                type={t('type')}
                initial={params.row}
                name={params.row.name!}
                confirm={(item) => {
                  equipmentService.delete(item.id!).then(() => fetchData());
                }}
              />
            </div>
          );
        }
      })
    );

    setColumns(tempColumns);

    const state = itemStateService.getItemStateFromUrl(searchParams, 15);
    if (state.sort.size == 0) {
      state.sort.set('lastEdit', 'desc');
      itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
    }
    setEquipmentsState(state);
  }, []);

  const canUpdateEquipmentBookings = TokenService.tenantFullfills([], ['Equipments:Update']);
  const canUpdateEquipmentProjectBookings = TokenService.tenantFullfills(
    ['Project'],
    ['Projects:Bookings:Update']
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              toolbar: {
                warehouses,
                fetchData,
                equipmentsState,
                setEquipmentsState,
                selectedEquipments
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            pagination
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            loading={equipments.loading}
            rows={equipments.rows ?? []}
            columns={columns ?? []}
            page={equipmentsState?.paginationSettings?.page}
            pageSize={15}
            rowCount={equipments.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(equipmentsState?.filter)}
            sortModel={filterService.createGridSort(equipmentsState?.sort)}
            checkboxSelection
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onSelectionModelChange={(selectionModel) => {
              let gridSelectedRowIds = new Set(selectionModel);
              let dataMap = new Map<string, readonly [any, boolean]>(
                equipments.rows.map((row: any) => {
                  let enabled = gridSelectedRowIds.has(row.id);
                  return [row.id, [row, enabled]];
                })
              );
              let wasUpdated = false;
              dataMap.forEach((data, id) => {
                if (data[1]) {
                  if (!selectedEquipments.has(id)) wasUpdated = true;
                  selectedEquipments.set(id, data[0]);
                } else {
                  if (selectedEquipments.has(id)) wasUpdated = true;
                  selectedEquipments.delete(id);
                }
              });
              if (wasUpdated)
                setSelectedEquipments(new Map<string, Partial<Equipment>>(selectedEquipments));
            }}
            onPageChange={(page) => {
              if (equipmentsState == null) return;
              if (filterService.updateItemStatePage(equipmentsState, page, 15))
                setEquipmentsState({ ...equipmentsState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (equipmentsState == null) return;
              if (
                filterService.updateItemStateFilter(
                  equipmentsState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setEquipmentsState({ ...equipmentsState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (equipmentsState == null) return;
              if (
                filterService.updateItemStateSort(
                  equipmentsState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setEquipmentsState({ ...equipmentsState });
            }}
          />
        </div>
      </Paper>
      {(canUpdateEquipmentBookings || canUpdateEquipmentProjectBookings) && canViewWarehouses && (
        <QuickActionPanel
          selectedEquipments={Array.from(selectedEquipments.values())}
          allowArticleBookings={canUpdateEquipmentBookings}
          allowArticleProjectBookings={canUpdateEquipmentProjectBookings}
        />
      )}
    </div>
  );
}
