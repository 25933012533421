/* eslint-disable no-unused-vars */
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateState from '../../components/projects/UpdateState';
import {
  BookingCosts,
  EmployeeCosts,
  EquipmentCosts,
  ExternalServiceCosts,
  Project,
  ProjectCosts
} from '../../objects/project';
import projectService from '../../services/projectService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TokenService from '../../services/tokenService';

interface ProjectInformationProps {
  project?: Partial<Project>;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0
}));

const PropInfo = (props: any) => {
  const { translation, value } = props;
  const { t } = useTranslation(['projects', 'dateFormat']);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: 5 }}>
      <p style={{ width: 200, margin: 0, fontWeight: 'bold' }}>{t(translation)}:</p>
      <p style={{ margin: 0 }}>{value}</p>
    </div>
  );
};

export default function ProjectInformation(props: ProjectInformationProps) {
  const [project, setProject] = useState<any>(props.project);
  const [projectCosts, setProjectCosts] = useState<ProjectCosts>();
  const [employeeCosts, setEmployeeCosts] = useState<EmployeeCosts>();
  const [equipmentCosts, setEquipmentCosts] = useState<EquipmentCosts>();
  const [externalServiceCosts, setExternalServiceCosts] = useState<ExternalServiceCosts>();
  const [bookingCosts, setBookingCosts] = useState<BookingCosts>();

  const { t } = useTranslation('projects');

  const canViewExternalService = TokenService.tenantFullfills(
    ['Disposition'],
    ['Disposition:View']
  );

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  const fetchData = () => {
    if (project?.id != null) {
      projectService.get(project?.id).then((result) => setProject(result));
    }
  };

  const handleEmployeeCostsOpen = () => {
    if (employeeCosts == undefined)
      projectService
        .cumulateEmployeeCosts(props.project!.id!)
        .then((result) => setEmployeeCosts(result));
  };
  const handleEquipmentCostsOpen = () => {
    if (equipmentCosts == undefined)
      projectService
        .cumulateEquipmentCosts(props.project!.id!)
        .then((result) => setEquipmentCosts(result));
  };
  const handleExternalServiceCostsOpen = () => {
    if (externalServiceCosts == undefined)
      projectService
        .cumulateExternalServiceCosts(props.project!.id!)
        .then((result) => setExternalServiceCosts(result));
  };
  const handleBookingCostsOpen = () => {
    if (bookingCosts == undefined)
      projectService
        .cumulateBookingCosts(props.project!.id!)
        .then((result) => setBookingCosts(result));
  };
  const handleProjectCostsOpen = () => {
    if (projectCosts == undefined)
      projectService.cumulateCosts(props.project!.id!).then((result) => setProjectCosts(result));
  };

  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div style={{ width: '100%', height: '500px' }}>
          <h2 style={{ margin: 5 }}>{t('detail.detailHeader')}</h2>
          <Divider style={{ margin: 10 }} />
          <PropInfo translation="name" value={project?.name ?? ''} />
          <PropInfo translation="state" value={t('states.' + project?.state ?? '')} />
          <PropInfo translation="customer" value={project?.customer?.displayName ?? ''} />
          <PropInfo translation="responsible" value={project?.responsible?.displayName ?? ''} />
          <PropInfo translation="budget" value={(project?.budget ?? 0) + ' €'} />
          <UpdateState project={project} fetchData={fetchData} />
        </div>
        <div>
          <Accordion
            onChange={(e, expanded) => {
              if (expanded) handleEmployeeCostsOpen();
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>{t('EmployeeCosts')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {employeeCosts == undefined ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper} style={{ margin: 0, padding: 0 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Employee')}</TableCell>
                        <TableCell align="right">{t('Spent Time in hours (Total)')}</TableCell>
                        <TableCell align="right">{t('Cost (Total) €')}</TableCell>
                        <TableCell align="right">{t('Price (Total) €')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeCosts.costs.map((row) => (
                        <TableRow
                          key={row.employee.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.employee.displayName}
                          </TableCell>
                          <TableCell align="right">{row.spentTime?.toFixed(2) + ' h'}</TableCell>
                          <TableCell align="right">{row.totalCost?.toFixed(2) + ' €'}</TableCell>
                          <TableCell align="right">{row.totalPrice?.toFixed(2) + ' €'}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="total">
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                          {t('Totals')}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {employeeCosts.totalHours?.toFixed(2) + ' h'}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {employeeCosts.totalCost?.toFixed(2) + ' €'}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {employeeCosts.totalPrice?.toFixed(2) + ' €'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, expanded) => {
              if (expanded) handleEquipmentCostsOpen();
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography>{t('EquipmentCosts')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {equipmentCosts == undefined ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Equipment')}</TableCell>
                        <TableCell align="right">{t('Used Time in hours(Total)')}</TableCell>
                        <TableCell align="right">{t('Cost (Total) €')}</TableCell>
                        <TableCell align="right">{t('Price (Total) €')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {equipmentCosts.costs.map((row) => (
                        <TableRow
                          key={row.equipment.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.equipment.displayName}
                          </TableCell>
                          <TableCell align="right">{row.spentTime?.toFixed(2) + ' h'}</TableCell>
                          <TableCell align="right">{row.totalCost?.toFixed(2) + ' €'}</TableCell>
                          <TableCell align="right">{row.totalPrice?.toFixed(2) + ' €'}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="total">
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                          {t('Totals')}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontWeight: 'bold' }}></TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {equipmentCosts.totalCost?.toFixed(2) + ' €'}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {equipmentCosts.totalPrice?.toFixed(2) + ' €'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
          {canViewExternalService && (
            <Accordion
              onChange={(e, expanded) => {
                if (expanded) handleExternalServiceCostsOpen();
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography>{t('externalServiceCosts')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {externalServiceCosts == undefined ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('externalService')}</TableCell>
                          <TableCell align="right">{t('Used Time in hours(Total)')}</TableCell>
                          <TableCell align="right">{t('Cost (Total) €')}</TableCell>
                          <TableCell align="right">{t('Price (Total) €')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {externalServiceCosts.costs.map((row) => (
                          <TableRow
                            key={row.externalService.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              {row.externalService.displayName}
                            </TableCell>
                            <TableCell align="right">{row.spentTime?.toFixed(2) + ' h'}</TableCell>
                            <TableCell align="right">{row.totalPrice.toFixed(2) + ' €'}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow key="total">
                          <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                            {t('Totals')}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ fontWeight: 'bold' }}></TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            {externalServiceCosts.totalCost?.toFixed(2) + ' €'}
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            {externalServiceCosts.totalPrice?.toFixed(2) + ' €'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            onChange={(e, expanded) => {
              if (expanded) handleBookingCostsOpen();
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">
              <Typography>{t('bookingCosts')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {bookingCosts == undefined ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('booking')}</TableCell>
                        <TableCell align="right">{t('Cost (Total) €')}</TableCell>
                        <TableCell align="right">{t('Price (Total) €')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bookingCosts.costs.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.totalCost?.toFixed(2) + ' €'}</TableCell>
                          <TableCell align="right">{row.totalPrice?.toFixed(2) + ' €'}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="total">
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                          {t('Totals')}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {bookingCosts.totalCost?.toFixed(2) + ' €'}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {bookingCosts.totalPrice?.toFixed(2) + ' €'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, expanded) => {
              if (expanded) handleProjectCostsOpen();
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header">
              <Typography>{t('ProjectCosts')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {projectCosts == undefined ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Name')}</TableCell>
                        <TableCell align="right">{t('Price €')}</TableCell>
                        <TableCell align="right">{t('Unit')}</TableCell>
                        <TableCell align="right">{t('Amount')}</TableCell>
                        <TableCell align="right">{t('Total €')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectCosts.costs.map((row) => {
                        let price =
                          row.lowestPrice == row.highestPrice
                            ? row.lowestPrice?.toFixed(2)
                            : `${row.lowestPrice?.toFixed(2)} - ${row.highestPrice?.toFixed(2)}`;
                        return (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{price + ' €'}</TableCell>
                            <TableCell align="right">{t('units.' + row.unit)}</TableCell>
                            <TableCell align="right">
                              {Number.parseFloat(row?.amount?.toString())?.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">{row.totalPrice?.toFixed(2) + ' €'}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow key="total">
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                          {t('Totals')}
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          component="th"
                          scope="row"
                          style={{ fontWeight: 'bold' }}>
                          {'Budget: ' + projectCosts.budget?.toFixed(2) + ' €'}
                        </TableCell>
                        <TableCell align="right" style={{ fontWeight: 'bold' }}>
                          {projectCosts.totalPrice.toFixed(2) + ' €'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </Paper>
    </>
  );
}
