import Supplier from '../objects/supplier';
import { defaultService, ItemState } from './api';

const route = 'suppliers';

const supplierService = {
  validate: (supplier: any): boolean =>
    supplier?.name?.length > 0 && supplier?.reference?.length > 0,
  get: async (id: string): Promise<Partial<Supplier>> => (await defaultService.get(route, id)).data,
  create: (role: Partial<Supplier>) => defaultService.create(route, role),
  update: (id: string, role: Partial<Supplier>) => defaultService.update(route, id, role),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Supplier>(route, itemState)
};
export default supplierService;
