import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tenant from '../../../objects/system/tenant';
import tenantService from '../../../services/system/tenantService';

interface UpdateTenantProps {
  modules: string[];
  tenantName: string;
  handleChange: Function;
  error: boolean;
}

export default function UpdateTenant(props: UpdateTenantProps) {
  const { t } = useTranslation(['tenants', 'employees']);
  const [tenant, setTenant] = useState<Partial<Tenant>>({});

  useEffect(() => {
    tenantService.get(props.tenantName).then((result) => setTenant(result.data));
  }, []);

  useEffect(() => {
    props.handleChange(tenant);
  }, [tenant]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('name')}
          variant="outlined"
          value={tenant.name ?? ''}
          disabled
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error}
            style={{ marginBottom: 10, marginRight: 10, width: 100 }}
            label={t('userLimit')}
            variant="outlined"
            value={tenant.userLimit ?? 0}
            onChange={(e) => {
              let parsedNumber = Number.parseInt(e.target.value);
              let userLimit = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
              setTenant({ ...tenant, ...{ userLimit } });
            }}
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('appUserLimit')}
            variant="outlined"
            value={tenant.appUserLimit ?? 0}
            onChange={(e) => {
              let parsedNumber = Number.parseInt(e.target.value);
              let appUserLimit = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
              setTenant({ ...tenant, ...{ appUserLimit } });
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl style={{ marginBottom: 10, flex: 1 }} fullWidth required error={props.error}>
            <InputLabel>{t('activeModules')}</InputLabel>
            <Select
              multiple
              value={tenant.activeModules ?? []}
              onChange={(e) => {
                e.stopPropagation();
                setTenant({ ...tenant, ...{ activeModules: e.target.value as string[] } });
              }}
              input={<OutlinedInput label={t('activeModules')} />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected?.map((value: any, index: any) => (
                    <Chip key={index} label={value} />
                  ))}
                </Box>
              )}>
              {props.modules.map((module: string) => (
                <MenuItem key={module} value={module}>
                  <p>{module}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </Paper>
  );
}
