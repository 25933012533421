export default function useSubdomain() {
  const host = window.location.host;
  const subdomains = host.split('.');

  const isValidHost = (host: string) =>
    host == 'js-ework' || host == 'localhost' || host.startsWith('localhost:');

  if (subdomains[0] == 'www') subdomains.splice(0, 1);

  let subdomain = null;
  if (subdomains?.length > 1 && isValidHost(subdomains[1])) subdomain = subdomains[0];

  return {
    subdomain
  };
}
