import { TextField, Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeIndicator from '../../components/ChangeIndicator';
import TokenService from '../../services/tokenService';
import SearchIcon from '@mui/icons-material/Search';
import { ItemState } from '../../services/api';
import CreateRole from '../../components/roles/CreateRole';

interface CustomToolbarProps {
  rights: string[];
  changeAmount: number | undefined;
  setChangeAmount(input: number): void;
  fetchData(): void;
  rolesState: ItemState;
  setRolesState(input: ItemState): void;
}

const CustomToolbar = (props: CustomToolbarProps) => {
  const { rights, changeAmount, setChangeAmount, fetchData, rolesState, setRolesState } = props;
  const { t } = useTranslation(['roles', 'app', 'customToolBar']);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [beforeSearchState, setBeforeSearchState] = useState<ItemState>();

  const canUpdateRoles = TokenService.tenantFullfills([], ['Roles:Update']);

  useEffect(() => {
    if (searchText == null) setBeforeSearchState(rolesState);
  }, [setRolesState]);

  function handleSearch() {
    if (rolesState != null && searchText != null) {
      rolesState.filterType = 'or';
      rolesState.filter.set('name', searchText);
      setRolesState({ ...rolesState });
      setIsSearching(false);
    } else if (isSearching) setRolesState(beforeSearchState!);
  }

  return (
    <GridToolbarContainer>
      {isSearching ? (
        <div style={{ margin: 5, display: 'flex', flex: 1 }}>
          <TextField
            size="small"
            label={t('search')}
            style={{ flex: 5 }}
            variant="outlined"
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && handleSearch()}
            autoFocus
            spellCheck={false}
          />
          <Button
            variant="contained"
            style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
            onClick={handleSearch}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <Button size="small" onClick={() => setIsSearching(false)}>
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <>
          {canUpdateRoles && <CreateRole rights={rights} refresh={fetchData} />}
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Button size="small" startIcon={<SearchIcon />} onClick={() => setIsSearching(true)}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <ChangeIndicator
            changeAmount={changeAmount ?? 0}
            setChangeAmount={setChangeAmount}
            type={'role'}
            onClick={fetchData}
          />
        </>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
