import {
  Card,
  CardContent,
  TextField,
  Button,
  CardMedia,
  Box,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  LinearProgress
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import grayLogo from '../assets/logo_ework.png';
import { useTranslation } from 'react-i18next';
import instance from '../services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import authService from '../services/authService';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface RequestRecoverPasswordProps {
  tenantName: string | null;
  setToken: (token: any) => void;
}

export default function RecoverPassword(props: RequestRecoverPasswordProps) {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const footerLinks = ['conditions', 'privacy', 'legalNotice'];
  const { t } = useTranslation(['login', 'navigation', 'app']);

  const [tenantName, setTenantName] = useState<string | null>(props.tenantName);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const token = searchParams.get('token')!;
  const username = searchParams.get('username')!;
  const [spinnerActive, setSpinnerActive] = useState(false);

  const handleRecover = async (e: any) => {
    e.preventDefault();

    instance
      .post('password/recover', password, {
        params: {
          token
        }
      })
      .then(() => handleLogin(e, username, password))
      .catch(() => enqueueSnackbar(t('app:recoveryLoginFailed')));
  };
  const handleLogin = async (e: any, username: string, password: string) => {
    e.preventDefault();
    setSpinnerActive(true);
    authService
      .login(username, password, tenantName)
      .then((response) => {
        props.setToken(response.token);
        if (response.realm == 'tenant') navigate('/articles');
        else navigate('/system/tenants');
        enqueueSnackbar(t('loginSuccessful'), { variant: 'success' });
      })
      .catch((err) => {
        console.log(err);
        setSpinnerActive(false);
        enqueueSnackbar(t('loginFailed'), { variant: 'error' });
      });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  return (
    <Box sx={{ display: 'flex' }} style={{ background: '#f6f6f6', height: '100vh' }}>
      <Box component="main" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <form
            className="login"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Card sx={{ width: 300 }} style={{ marginBottom: '50px' }}>
              <CardMedia
                style={{
                  paddingTop: '20px',
                  width: '250px',
                  margin: 'auto'
                }}
                component="img"
                image={grayLogo}
                alt="zenamo logo"
              />
              <CardContent>
                {spinnerActive ? (
                  <LinearProgress color="error" />
                ) : (
                  <>
                    {props.tenantName == null && (
                      <TextField
                        placeholder={t('administration')}
                        value={tenantName ?? ''}
                        style={{ margin: '8px', width: '250px' }}
                        label={t('tenant')}
                        variant="outlined"
                        onChange={(e) => setTenantName(e.target.value)}
                      />
                    )}
                    <TextField
                      style={{ margin: '8px', width: '250px' }}
                      label={t('username')}
                      variant="outlined"
                      value={username}
                      disabled
                    />
                    <FormControl
                      style={{ margin: '8px' }}
                      sx={{ width: '250px' }}
                      variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label={t('password')}
                      />
                    </FormControl>
                    <Button
                      style={{ margin: '8px', width: '250px', background: '#a71d31' }}
                      variant="contained"
                      type="submit"
                      onClick={handleRecover}>
                      {t('app:passwordRecovery.recover')}
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </form>
        </div>
        <Box
          style={{
            background: '#2C2C2C',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {footerLinks.map((i) => (
            <Link
              key={i}
              style={{ margin: 5, fontSize: 15, marginRight: 5, marginLeft: 5 }}
              href={'/' + i}
              color="#c9c9c9"
              underline="hover"
              rel="noopener">
              {t(i, { ns: 'navigation' })}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
