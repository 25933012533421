import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Articles from './pages/articles/Articles';
import Projects from './pages/projects/Projects';
import Layout from './navigation/Layout';
import useToken from './hooks/useToken';
import Calendar from './pages/appointments/Calendar';
import Account from './pages/Account';
import Roles from './pages/roles/Roles';
import Customers from './pages/customers/Customers';
import Warehouses from './pages/warehouses/Warehouses';
import ProjectDetail from './pages/projects/ProjectDetails';
import Suppliers from './pages/suppliers/Suppliers';
import Employees from './pages/employees/Employees';
import ArticleMovement from './pages/articles/ArticleMovement';
import EquipmentMovement from './pages/equipments/EquipmentMovement';
import Conditions from './pages/Conditions';
import LegalNotice from './pages/LegalNotice';
import Privacy from './pages/Privacy';
import ArticleProjectMovement from './pages/articles/ArticleProjectMovement';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { appointmentsNotificationService, changeService } from './services/api';
import TokenService from './services/tokenService';
import SystemLayout from './navigation/SystemLayout';
import Tenants from './pages/system/Tenants';
import useSubdomain from './hooks/useSubdomain';
import { useTranslation } from 'react-i18next';
import Users from './pages/system/Users';
import eventHandler from './services/eventHandler';
import ImportExport from './pages/ImportExport';
import Equipments from './pages/equipments/Equipments';
import { IClientOptions, MqttClient, connect } from 'mqtt';
import EquipmentDetails from './pages/equipments/EquipmentDetails';
import EquipmentProjectMovement from './pages/equipments/EquipmentProjectMovement';
import { TenantUser } from './objects/user';
import ArticleDetails from './pages/articles/ArticleDetails';
import { v4 as uuidv4 } from 'uuid';
import BookingLog from './pages/BookingLog';
import RequestRecoverPassword from './pages/RequestRecoverPassword';
import RecoverPassword from './pages/RecoverPassword';
import EquipmentProjectReservation from './pages/equipments/EquipmentProjectReservation';
import SupportAccessGrant from './pages/SupportAccessGrant';
import Disposition from './pages/disposition/Disposition';
import ExternalServices from './pages/externalServices/ExternalServices';
import ExternalServiceDetail from './pages/externalServices/ExternalServiceDetail';

function App() {
  const { t } = useTranslation('app');
  const { subdomain } = useSubdomain();
  const { token, setToken, deleteToken } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const [isTenantApp, setIsTenantApp] = useState<boolean>();
  const [mqttClient, setMqttClient] = useState<null | MqttClient>();

  useEffect(() => {
    eventHandler.on('logout', () => deleteToken());
    return () => eventHandler.remove('logout');
  }, []);

  useEffect(() => {
    appointmentsNotificationService.setShowWarning((appointment) => {
      enqueueSnackbar(
        t('appointmentAlarm', {
          subject: appointment.subject,
          minutesLeft: 15
        }),
        {
          variant: 'info'
        }
      );
    });
  }, []);

  useEffect(() => {
    const isSystemApp = subdomain == null && TokenService.getUser()?.realm == 'system';
    setIsTenantApp(!isSystemApp);
  }, [token]);

  useEffect(() => {
    if (token != null && isTenantApp && mqttClient == null) {
      var employeeId = (TokenService.getUser() as TenantUser).employee.id;

      const options: IClientOptions = {
        username: 'tenant_' + uuidv4(),
        password: token,
        hostname: window.location.hostname,
        host: window.location.hostname,
        port: 443,
        protocolId: 'MQTT',
        protocol: 'wss',
        path: '/mqtt',
        wsOptions: {
          hostname: window.location.hostname,
          host: window.location.hostname
        }
      };

      let client = connect('wss://' + window.location.hostname + ':443/mqtt', options);
      client.on('connect', () => {
        client.subscribe('BelowMinimalAmount', { qos: 0 });
        client.subscribe('Change', { qos: 0 });
        client.subscribe('Appointments/' + employeeId, { qos: 0 });
      });

      client.on('message', (topic, message) => {
        let messageObject = JSON.parse(message.toString());
        switch (topic) {
          case 'BelowMinimalAmount': {
            if (!TokenService.tenantFullfills([], ['Notifications:ReceivesMinimalAmount'])) break;

            enqueueSnackbar(
              t('minimalAmountAlarm', {
                minimalAmount: messageObject.article.minimalAmount,
                articleName: messageObject.article.name,
                currentAmount: messageObject.currentAmount
              }),
              {
                variant: 'warning'
              }
            );
            break;
          }
          case 'Change': {
            changeService.get(messageObject.type).change();
            break;
          }
          case 'Appointments/' + employeeId: {
            if (!TokenService.tenantFullfills([], ['Notifications:ReceivesAppointments'])) break;

            appointmentsNotificationService.updateAppointments(
              messageObject.appointmentsStartingNextHour
            );
            break;
          }
          default:
            break;
        }
      });
      setMqttClient(client);
    }
  }, [isTenantApp]);

  const RequireAuth = (props: any) => {
    const location = useLocation();

    if (token) {
      if (props.isSupport) return props.children;
      else if (!isTenantApp) return <SystemLayout>{props.children}</SystemLayout>;
      else return <Layout>{props.children}</Layout>;
    }

    return <Navigate to="/login" replace state={{ path: location.pathname + location.search }} />;
  };

  const canImportOrExport =
    TokenService.tenantFullfills(['Export'], ['ImportExport:Import']) ||
    TokenService.tenantFullfills(['Export'], ['ImportExport:Export']);

  const authorizedSystemRoutes = [
    {
      path: '/system/tenants',
      element: <Tenants />
    },
    {
      path: '/system/users',
      element: <Users />
    },
    {
      path: '/account',
      element: <Account />,
      hidden: false
    }
  ];
  const authorizedTenantRoutes = [
    {
      path: '/articles',
      element: <Articles />,
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Articles:View'])
    },
    {
      path: '/articles/movement',
      element: <ArticleMovement />,
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Articles:Bookings:Update'])
    },
    {
      path: '/articles/project-movement',
      element: <ArticleProjectMovement />,
      hidden: !TokenService.tenantFullfills(['Warehouse', 'Project'], ['Projects:Bookings:Update'])
    },
    {
      path: '/articles/details',
      element: <ArticleDetails />,
      hidden: !TokenService.tenantFullfills(['Analysis'], ['Articles:View'])
    },
    {
      path: '/equipments',
      element: <Equipments />,
      hidden: !TokenService.tenantFullfills(['Equipment'], ['Equipments:View'])
    },
    {
      path: '/equipments/movement',
      element: <EquipmentMovement />,
      hidden: !TokenService.tenantFullfills(['Equipment'], ['Equipments:Bookings:Update'])
    },
    {
      path: '/equipments/project-movement',
      element: <EquipmentProjectMovement />,
      hidden: !TokenService.tenantFullfills(['Equipment', 'Project'], ['Projects:Bookings:Update'])
    },
    {
      path: '/equipments/project-reservation',
      element: <EquipmentProjectReservation />,
      hidden: !TokenService.tenantFullfills(['Equipment', 'Project'], ['Projects:Bookings:Update'])
    },
    {
      path: '/equipments/details',
      element: <EquipmentDetails />,
      hidden: !TokenService.tenantFullfills(['Equipment'], ['Equipments:View'])
    },
    {
      path: '/disposition',
      element: <Disposition />,
      hidden: !TokenService.tenantFullfills(['Disposition'], ['Disposition:View'])
    },
    {
      path: '/bookings',
      element: <BookingLog />,
      hidden: !(
        TokenService.tenantFullfills(['Warehouse'], ['Articles:Bookings:View']) ||
        TokenService.tenantFullfills(['Warehouse', 'Project'], ['Project:Bookings:View']) ||
        TokenService.tenantFullfills(['Equipment'], ['Equipment:Bookings:View']) ||
        TokenService.tenantFullfills(['Equipment', 'Project'], ['Project:Bookings:View'])
      )
    },
    {
      path: '/projects',
      element: <Projects />,
      hidden: !TokenService.tenantFullfills(['Project'], ['Projects:View'])
    },
    {
      path: '/projects/details',
      element: <ProjectDetail />,
      hidden: !TokenService.tenantFullfills(['Project'], ['Projects:View'])
    },
    {
      path: '/customers',
      element: <Customers />,
      hidden: !TokenService.tenantFullfills(['Project'], ['Projects:View'])
    },
    {
      path: '/external-services',
      element: <ExternalServices />,
      hidden: !TokenService.tenantFullfills(['Disposition'], ['Disposition:View'])
    },
    {
      path: '/external-services/details',
      element: <ExternalServiceDetail />,
      hidden: !TokenService.tenantFullfills(['Disposition'], ['Disposition:View'])
    },
    {
      path: '/suppliers',
      element: <Suppliers />,
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Suppliers:View'])
    },
    {
      path: '/warehouses',
      element: <Warehouses />,
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Warehouses:View'])
    },
    {
      path: '/calendar',
      element: <Calendar />,
      hidden: !TokenService.tenantFullfills(['Calendar'], ['Appointments:View'])
    },
    {
      path: '/export',
      element: <ImportExport />,
      hidden: !canImportOrExport
    },
    {
      path: '/administration/employees',
      element: <Employees />,
      hidden: !TokenService.tenantFullfills([], ['Employees:View'])
    },
    {
      path: '/administration/roles',
      element: <Roles />,
      hidden: !TokenService.tenantFullfills([], ['Roles:View'])
    },
    {
      path: '/account',
      element: <Account />,
      hidden: false
    }
  ];

  function getRootElement(routes: any) {
    return routes.find((route: any) => !route.hidden);
  }
  const authenticatedPath = isTenantApp
    ? getRootElement(authorizedTenantRoutes).path
    : getRootElement(authorizedSystemRoutes).path;
  const defaultPath = token ? authenticatedPath : '/login';

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login tenantName={subdomain} setToken={setToken} />} />
        <Route
          path="/support-access-grant"
          element={
            <RequireAuth isSupport>
              <SupportAccessGrant />
            </RequireAuth>
          }
        />
        <Route
          path="/request-recover-password"
          element={<RequestRecoverPassword tenantName={subdomain} />}
        />
        <Route
          path="/recover-password"
          element={<RecoverPassword tenantName={subdomain} setToken={setToken} />}
        />
        {!isTenantApp &&
          authorizedSystemRoutes.map((authorizedSystemRoute) => (
            <Route
              key={authorizedSystemRoute.path}
              path={authorizedSystemRoute.path}
              element={<RequireAuth>{authorizedSystemRoute.element}</RequireAuth>}
            />
          ))}
        {isTenantApp &&
          authorizedTenantRoutes
            .filter((authorizedTenantRoute) => !authorizedTenantRoute.hidden)
            .map((authorizedTenantRoute) => (
              <Route
                key={authorizedTenantRoute.path}
                path={authorizedTenantRoute.path}
                element={<RequireAuth>{authorizedTenantRoute.element}</RequireAuth>}
              />
            ))}
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/legalNotice" element={<LegalNotice />} />
        <Route path="*" element={<Navigate to={defaultPath} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
