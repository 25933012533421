import { Avatar } from '@mui/material';
import useIcons from '../hooks/useIcons';

const BookingTypeIcons = {
  Article: { iconName: 'Storage', color: '#1ABC9C' },
  Equipment: { iconName: 'Construction', color: '#9B59B6' },
  ArticleProject: { iconName: 'AccountTree', color: '#1ABC9C' },
  EquipmentProject: { iconName: 'AccountTree', color: '#9B59B6' }
};

const MyIcon = (data: any) => {
  const CustomIcon = useIcons(data.iconName);
  return <CustomIcon />;
};

export default function BookingTypeIcon({ bookingType }: any) {
  const avatarData = BookingTypeIcons[bookingType as keyof typeof BookingTypeIcons];

  return (
    <Avatar sx={{ bgcolor: avatarData.color }}>
      <MyIcon iconName={avatarData.iconName ?? 'Add'} />
    </Avatar>
  );
}
