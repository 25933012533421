import { IconButton, Paper } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UpdateCustomer from '../../components/customers/UpdateCustomer';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import { ItemState, itemStateService } from '../../services/api';
import customerService from '../../services/customerService';
import filterService from '../../services/filterService';
import navigationService from '../../services/navigationService';
import AccountTree from '@mui/icons-material/AccountTree';
import Customer from '../../objects/customer';
import moment from 'moment';
import CustomToolbar from './CustomToolbar';
import TokenService from '../../services/tokenService';
import { useSnackbar } from 'notistack';

export default function Customers() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['customers', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn } = navigationService.create(t, navigate);

  const [changeAmount, setChangeAmount] = useState<number>();
  const [columns, setColumns] = useState<any[]>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    address: false,
    zipCode: false,
    iban: false,
    discount: false,
    acquisition: false,
    lastEdit: false
  });

  const [customersState, setCustomersState] = useState<ItemState>();
  const [customers, setCustomers] = useState({
    loading: true,
    rows: new Array<Partial<Customer>>(0),
    rowCount: 0
  });
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = customersState;
  }, [customersState]);

  const canUpdateCustomers = TokenService.tenantFullfills([], ['Customers:Update']);
  const canViewProject = TokenService.tenantFullfills(['Project'], ['Projects:View']);

  useEffect(() => {
    setChangeAmount(0);
    setColumns([
      createColumn('name', { width: 200 }),
      createColumn('reference', { width: 120 }),
      createColumn('address', { width: 150 }),
      createColumn('city', { width: 110 }),
      createColumn('zipCode', { width: 110, type: 'number' }),
      createColumn('iban', { width: 150 }),
      createColumn('discount', { width: 150 }),
      createColumn('email', { width: 200 }),
      createColumn('phone', { width: 150 }),
      createColumn('acquisition', {
        width: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:shortDate'))
      }),
      {
        field: 'actions',
        headerName: t('actions'),
        sortable: false,
        hideable: false,
        minWidth: 140,
        maxWidth: 140,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateCustomers && (
                <>
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    type={t('type')}
                    initial={params.row}
                    validate={customerService.validate}
                    confirm={(item) => {
                      customerService
                        .update(item.id!, item)
                        .then(() => fetchData())
                        .catch(() =>
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' })
                        );
                    }}>
                    {(item, handleChange, validation) => (
                      <UpdateCustomer
                        customer={item}
                        handleChange={handleChange}
                        error={validation}
                      />
                    )}
                  </ItemDialog>
                  <ItemDialog
                    kind={ItemDialogKind.Delete}
                    type={t('type')}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={(item) => {
                      customerService.delete(item.id!).then(() => fetchData());
                    }}
                  />
                </>
              )}

              {canViewProject && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate('/projects?staged-filter-customerId=' + params.row.id);
                  }}>
                  <AccountTree color="primary" />
                </IconButton>
              )}
            </div>
          );
        }
      }
    ]);

    const state = itemStateService.getItemStateFromUrl(searchParams, 15);
    if (state.sort.size == 0) {
      state.sort.set('lastEdit', 'desc');
      itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
    }
    setCustomersState(state);
  }, []);

  const fetchData = async () => {
    setCustomers({ ...customers, ...{ loading: true } });
    customerService
      .all(stateRef.current)
      .then((result) => {
        setChangeAmount(0);
        setCustomers({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setCustomers({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  useEffect(() => {
    if (customersState == null) return;
    if (customersState.sort.size == 0) customersState.sort.set('lastEdit', 'desc');
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, customersState);
    fetchData();
  }, [customersState]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              toolbar: {
                changeAmount,
                setChangeAmount,
                fetchData,
                customersState,
                setCustomersState
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={customers.loading}
            rows={customers.rows}
            columns={columns ?? []}
            page={customersState?.paginationSettings?.page}
            pageSize={15}
            rowCount={customers.rowCount}
            rowsPerPageOptions={[15]}
            filterModel={filterService.createGridFilters(customersState?.filter)}
            sortModel={filterService.createGridSort(customersState?.sort)}
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onPageChange={(page) => {
              if (customersState == null) return;
              if (filterService.updateItemStatePage(customersState, page, 15))
                setCustomersState({ ...customersState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (customersState == null) return;
              if (
                filterService.updateItemStateFilter(
                  customersState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setCustomersState({ ...customersState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (customersState == null) return;
              if (
                filterService.updateItemStateSort(
                  customersState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setCustomersState({ ...customersState });
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
