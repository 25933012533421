import { SystemUser, TenantUser } from '../objects/user';
import eventHandler from './eventHandler';

// only use these function through the useToken hook, so the changes can be tracked
const getLocalRefreshToken = (): string | undefined => (getUser() as any).refreshToken;
const getLocalAccessToken = (): string => getUser()?.token;
const updateLocalAccessToken = (token: any, refreshToken?: any) => {
  let user = getUser();
  user.token = token;
  user.refreshToken = refreshToken;
  setUser(user);
};
let logoutTimer: any;

function runLogoutTimer(timer: number) {
  return setTimeout(() => {
    removeUser();
    eventHandler.dispatch('logout');
  }, timer);
}

const getUser = (): TenantUser | SystemUser => {
  return JSON.parse(localStorage.getItem('user')!);
};

const resetLogoutTimer = () => {
  clearTimeout(logoutTimer);
  logoutTimer = runLogoutTimer(3300000); // 55 minutes after last user interaction
};

const setUser = (user: TenantUser | SystemUser) => {
  resetLogoutTimer();
  localStorage.setItem('user', JSON.stringify(user));
};
const getTenantName = (): string => {
  return localStorage.getItem('tenantName')!;
};
const setTenantName = (tenantName: string) => {
  localStorage.setItem('tenantName', tenantName);
};
const removeUser = () => {
  localStorage.removeItem('user');
};

const tenantFullfills = (modules: string[], rights: string[]) => {
  const currentUser = getUser();
  const userModules = (currentUser as TenantUser)?.modules ?? [];
  const userRights = (currentUser as TenantUser)?.employee?.rights ?? [];
  let result = true;
  modules?.forEach((module) => {
    if (userModules.indexOf(module) === -1) result = false;
  });
  rights?.forEach((right) => {
    if (userRights.indexOf(right) === -1) result = false;
  });

  return result;
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  getTenantName,
  setTenantName,
  removeUser,
  tenantFullfills,
  resetLogoutTimer
};
export default TokenService;
