import instance, { defaultService } from './api';

const appointmentApiRoute = 'appointments';
const getAppointments = (filters?: Map<string, string | string[]>) => {
  return instance.get('/' + appointmentApiRoute, {
    params: filters,
    paramsSerializer: (params: Map<string, string | string[]>) => {
      return Array.from(params.entries())
        .map(([key, value]) => {
          if (value instanceof Array) return `${key}=${value.join('&' + key + '=')}`;
          else return `${key}=${value}`;
        })
        .join('&');
    }
  });
};

const createAppointment = (params: any, type?: string, queryParams?: any) =>
  defaultService.create(type + appointmentApiRoute, params, queryParams);
const updateAppointment = (id: string, params: any, queryParams?: any) =>
  defaultService.update(appointmentApiRoute, id, params, queryParams);
const deleteAppointment = (id: string) => defaultService.delete(appointmentApiRoute, id);

const appointmentUserTypesApiRoute = 'appointment-usertypes';
const getAppointmentUserTypes = async (id: string) =>
  (await defaultService.get(appointmentUserTypesApiRoute, id)).data;

const appointmentService = {
  createAppointment,
  updateAppointment,
  deleteAppointment,
  getAppointments,
  getAppointmentUserTypes
};

export default appointmentService;
