import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useIcons from '../hooks/useIcons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import TokenService from '../services/tokenService';

const StyledListItem = styled(ListItem)<{ $active: boolean }>`
  &:hover {
    background: #141414;
  }

  ${({ $active }) => $active && `background: #141414; border-left: 5px solid #a71d31;`}
`;

const MyIcon = (data: any) => {
  const CustomIcon = useIcons(data.iconName);
  return <CustomIcon />;
};

export default function NavLinks() {
  const { t } = useTranslation('navigation');
  let navigate = useNavigate();

  const canImportOrExport =
    TokenService.tenantFullfills(['Export'], ['ImportExport:Import']) ||
    TokenService.tenantFullfills(['Export'], ['ImportExport:Export']);

  let modules = [
    {
      name: 'articles',
      icon: 'Storage',
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Articles:View'])
    },
    {
      name: 'equipments',
      path: 'equipments',
      icon: 'Construction',
      hidden: !TokenService.tenantFullfills(['Equipment'], ['Equipments:View'])
    },
    {
      name: 'disposition',
      path: 'disposition',
      icon: 'PendingActions',
      hidden: !TokenService.tenantFullfills(['Disposition'], ['Disposition:View'])
    },
    {
      name: 'bookings',
      icon: 'Receipt',
      hidden: !(
        TokenService.tenantFullfills(['Warehouse'], ['Articles:Bookings:View']) ||
        TokenService.tenantFullfills(['Warehouse', 'Project'], ['Project:Bookings:View']) ||
        TokenService.tenantFullfills(['Equipment'], ['Equipment:Bookings:View']) ||
        TokenService.tenantFullfills(['Equipment', 'Project'], ['Project:Bookings:View'])
      )
    },
    {
      name: 'projects',
      path: 'projects',
      icon: 'AccountTree',
      hidden: !TokenService.tenantFullfills(['Project'], ['Projects:View'])
    },
    {
      name: 'customers',
      path: 'customers',
      icon: 'Group',
      hidden: !TokenService.tenantFullfills(['Project'], ['Projects:View'])
    },
    {
      name: 'external-services',
      path: 'external-services',
      icon: 'MiscellaneousServices',
      hidden: !TokenService.tenantFullfills(['Disposition'], ['ExternalServices:View'])
    },
    {
      name: 'suppliers',
      path: 'suppliers',
      icon: 'LocalShipping',
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Suppliers:View'])
    },
    {
      name: 'warehouses',
      path: 'warehouses',
      icon: 'Inventory',
      hidden: !TokenService.tenantFullfills(['Warehouse'], ['Warehouses:View'])
    },
    {
      name: 'calendar',
      path: 'calendar',
      icon: 'Event',
      hidden: !TokenService.tenantFullfills(['Calendar'], ['Appointments:View'])
    },
    {
      name: 'export',
      path: 'export',
      icon: 'ImportExport',
      hidden: !canImportOrExport
    },
    {
      name: 'administration',
      path: 'administration',
      icon: 'AdminPanelSettings',
      children: [
        {
          name: 'employees',
          icon: 'Work',
          hidden: !TokenService.tenantFullfills([], ['Employees:View'])
        },
        {
          name: 'roles',
          icon: 'Key',
          hidden: !TokenService.tenantFullfills([], ['Roles:View'])
        }
      ]
    }
  ];
  const [open, setOpen] = useState(false);

  const handleClick = (name: string, children?: any) => {
    if (children) {
      if (!open) navigate('/' + name + '/' + children[0].name.toLowerCase());
      setOpen(!open);
    } else navigate('/' + name.toLowerCase());
  };

  const isActive = (name: string) => {
    return window.location.pathname.startsWith('/' + name.toLowerCase());
  };
  const getPath = (module: any) => {
    return module.path ?? module.name;
  };
  const getIsHidden = (module: any) => {
    return module.hidden ?? module.children.findIndex((child: any) => !child.hidden) === -1;
  };

  return (
    <List>
      {modules
        .filter((module) => !getIsHidden(module))
        .map((i) => (
          <div key={i.name}>
            <StyledListItem
              hidden={getIsHidden(i)}
              $active={isActive(i.name)}
              onClick={() => handleClick(getPath(i), i.children)}>
              <ListItemIcon style={{ color: '#fff' }}>
                <MyIcon iconName={i.icon} />
              </ListItemIcon>
              <ListItemText primary={t(i.name)} />
              {i.children && (open ? <ExpandLess /> : <ExpandMore />)}
            </StyledListItem>
            {i.children && (
              <Collapse in={open} timeout="auto">
                <List component="div" disablePadding>
                  {i.children
                    ?.filter((child) => !getIsHidden(child))
                    .map((child) => (
                      <StyledListItem
                        key={child.name}
                        $active={isActive(i.name + '/' + child.name)}
                        onClick={() => handleClick(getPath(i) + '/' + child.name)}>
                        <ListItemIcon style={{ color: '#fff' }}>
                          <MyIcon iconName={child.icon} />
                        </ListItemIcon>
                        <ListItemText primary={t(child.name)} />
                      </StyledListItem>
                    ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
    </List>
  );
}
