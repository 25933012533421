import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { ItemState } from './api';

const createFiltersByGridFilter = (gridFilter: GridFilterModel) => {
  let map = new Map<string, string>();
  gridFilter.items.forEach((element) => {
    map.set(element.columnField, element.value);
  });
  return map;
};

const createSorterByGridSort = (gridSort: GridSortModel) => {
  let map = new Map<string, 'asc' | 'desc'>();

  gridSort.forEach((gridSort) => {
    map.set(gridSort.field, gridSort.sort == 'asc' ? 'asc' : 'desc');
  });

  return map;
};

const setSearchParamsByGridFilter = (params: URLSearchParams, gridFilter: GridFilterModel) => {
  gridFilter.items.forEach((element) => {
    if (element.value != undefined) params.set(element.columnField, element.value);
  });
};

const createGridFilters = (filters?: Map<string, string>) => {
  if (filters == null) return { items: [] };

  return {
    items: Array.from(filters.entries()).map((entry) => {
      return {
        columnField: entry[0],
        value: entry[1],
        operatorValue: 'contains'
      };
    })
  };
};

const createGridSort = (sort?: Map<string, 'asc' | 'desc'>): GridSortModel => {
  if (sort == null || Array.from(sort.entries()).length < 1) return [];
  let sortArray = Array.from(sort.entries());
  return sortArray?.map((sort) => {
    return {
      field: sort[0],
      sort: sort[1]
    };
  });
};

const updateItemStatePage = (itemState: ItemState, page: number, pageSize: number): boolean => {
  if (itemState.paginationSettings?.page != page) {
    itemState.paginationSettings = {
      pageSize: itemState.paginationSettings?.pageSize ?? pageSize,
      page: page
    };
    return true;
  }
  return false;
};
const updateItemStateFilter = (itemState: ItemState, filter: Map<string, string>): boolean => {
  let hasChanges = false;
  if (itemState.filter == null || itemState.filter.size != filter.size) {
    hasChanges = true;
  } else {
    for (let key of Array.from(itemState.filter.keys())) {
      let value = itemState.filter.get(key);
      if (!filter.has(key) || filter.get(key) != value) {
        hasChanges = true;
        break;
      }
    }
  }
  if (hasChanges) itemState.filter = filter;
  return hasChanges;
};
const updateItemStateSort = (itemState: ItemState, sort: Map<string, 'asc' | 'desc'>): boolean => {
  let hasChanges = false;
  if (itemState.sort == null || itemState.sort.size != sort.size) {
    hasChanges = true;
  } else {
    for (let sortEntry in Array.from(itemState.sort.entries()))
      if (!sort.has(sortEntry[0]) || sort.get(sortEntry[0]) !== sortEntry[1]) {
        hasChanges = true;
        break;
      }
  }
  if (hasChanges) itemState.sort = sort;
  return hasChanges;
};
const updateItemStateFilterType = (itemState: ItemState, filterType: 'or' | 'and'): boolean => {
  if (itemState.filterType != filterType) {
    itemState.filterType = filterType;
    return true;
  }
  return false;
};

const filterService = {
  createGridFilters,
  createGridSort,
  createSorterByGridSort,
  createFiltersByGridFilter,
  setSearchParamsByGridFilter,
  updateItemStatePage,
  updateItemStateFilter,
  updateItemStateSort,
  updateItemStateFilterType
};

export default filterService;
