import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { CssBaseline, Typography, Tooltip, Menu, MenuItem, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Language from '../components/topBar/Language';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SystemNavLinks from './SystemNavLinks';
// import eWorkLogo from '../assets/logo_ework.png';

const drawerWidth = 230;
const settings = ['account', 'logout'];
const footerLinks = ['conditions', 'privacy', 'legalNotice'];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  background: '#2C2C2C',
  boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 25%)',
  color: '#fff',
  zIndex: 9,
  border: 0
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  },
  background: '#2C2C2C',
  boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 25%)',
  color: '#fff'
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '20px !important',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

interface DrawerProps {
  children: any;
}

export default function SystemLayout({ children }: DrawerProps) {
  const theme = useTheme();
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('navigation');

  const handleMenuClick = (action: string) => {
    if (action == 'logout') {
      localStorage.removeItem('user');
      window.location.reload();
    } else if (action == 'account') navigate('/account');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  function getCurrentPage() {
    return t(window.location.pathname.slice(1));
  }

  return (
    <Box sx={{ display: 'flex' }} style={{ background: '#f6f6f6', height: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          backgroundImage: 'linear-gradient(315deg, #70101a 0%, #a71d31 70%)',
          boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 25%)'
        }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: -2,
                marginRight: '30px',
                ...(open && { display: 'none' })
              }}>
              <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
            <Typography variant="h6" noWrap color="#fff">
              {getCurrentPage()}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 0 }}>
            <Language />
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                <AccountCircleIcon style={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleMenuClick(setting)}>
                  <Typography textAlign="center">{t(setting)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ background: '#a71d31' }}>
          <Typography variant="h6" noWrap color="#fff">
            e.work
          </Typography>
          {/* <img
            src={eWorkLogo}
            height="40"
            style={{
              borderRadius: 5,
              paddingLeft: 25,
              paddingRight: 25,
              backgroundColor: '#fff',
              marginLeft: -5
            }}
            // style={{ margin: -70, marginBottom: -75, marginLeft: -25 }}
          /> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRight style={{ color: '#fff' }} />
            ) : (
              <ChevronLeft style={{ color: '#fff' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <SystemNavLinks />
      </Drawer>
      <Box
        component="main"
        style={{ marginTop: 65, display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ padding: 10, flex: 1 }}>{children}</div>
        <Box
          style={{
            marginTop: 5,
            background: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {footerLinks.map((i) => (
            <Link
              key={i}
              style={{ margin: 5, fontSize: 15, marginRight: 5, marginLeft: 5 }}
              href={'/' + i}
              color="#999"
              underline="hover"
              rel="noopener"
              target="_blank">
              {t(i)}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
