import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyDialog from './MyDialog';

export default function DeleteDialog({ deleteItem, type, name, isSelection }: any) {
  const { t } = useTranslation('dialog');
  return (
    <MyDialog
      title={isSelection ? t('unselect') : t('delete')}
      handleBtnTitle={isSelection ? t('unselect') : t('delete')}
      saveItem={deleteItem}
      iconName={'Delete'}
      showButton
      isDelete
      withActions>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <p>
          {isSelection
            ? t('deleteSelectionMessage', { type: type, name: name })
            : t('deleteMessage', { type: type, name: name })}
        </p>
      </Paper>
    </MyDialog>
  );
}
