import Article from '../objects/article';
import { defaultService, ItemState } from './api';

const makeArticleModel = (article: Partial<Article>) => {
  return {
    reference: article.reference,
    name: article.name,
    description: article.description,
    minimalAmount: article.minimalAmount,
    price: article.price,
    unit: article.unit,
    suppliers: article.suppliers?.map((supplier) => {
      return {
        price: supplier.price,
        supplierId: supplier.supplier!.id
      };
    }),
    warehouses: article.warehouses?.map((warehouse) => {
      return {
        amount: warehouse.amount,
        warehouseId: warehouse.warehouse!.id
      };
    })
  };
};

const route = 'articles';

const articleService = {
  validate: (article: any): boolean =>
    article?.reference && article?.name && article?.minimalAmount && article?.price,
  get: async (id: string): Promise<Partial<Article>> => (await defaultService.get(route, id)).data,
  create: (article: Partial<Article>) => defaultService.create(route, makeArticleModel(article)),
  update: (id: string, article: Partial<Article>) =>
    defaultService.update(route, id, makeArticleModel(article)),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Article>(route, itemState)
};

export default articleService;
