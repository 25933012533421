import User from '../../objects/system/user';
import { defaultService } from '../api';

const route = 'system/users';

const userService = {
  validate: (user: any): boolean => user.login?.length > 0 && user.password?.length > 0,
  create: (tenant: Partial<User>) => defaultService.create(route, tenant),
  delete: (id: string) => defaultService.delete(route, id),
  all: () => defaultService.getAllPaginated<Partial<User>>(route)
};

export default userService;
