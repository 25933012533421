import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from '../../../objects/system/user';

interface CreateUserProps {
  user: Partial<User>;
  handleChange: Function;
  error: boolean;
}

export default function CreateUser(props: CreateUserProps) {
  const { t } = useTranslation(['systemUsers']);
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    props.handleChange(user);
  }, [user]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <TextField
        required
        error={props.error}
        style={{ marginBottom: 10, marginRight: 10 }}
        label={t('login')}
        variant="outlined"
        value={user?.login ?? ''}
        onChange={(e) => setUser({ ...user, ...{ login: e.target.value } })}
        fullWidth
      />
      <TextField
        required
        error={props.error}
        style={{ marginBottom: 10, marginRight: 10 }}
        label={t('password')}
        variant="outlined"
        value={user?.password ?? ''}
        onChange={(e) => setUser({ ...user, ...{ password: e.target.value } })}
        fullWidth
      />
    </Paper>
  );
}
