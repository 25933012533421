import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import articleService from '../../services/articleService';
import navigationService from '../../services/navigationService';
import { Link, Paper, Tooltip, Typography } from '@mui/material';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import QuickActionPanel from '../../components/articles/QuickActionPanel';
import filterService from '../../services/filterService';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import { ItemState, itemStateService } from '../../services/api';
import CreateArticleSuppliers from '../../components/articles/CreateArticleSuppliers';
import warehouseService from '../../services/warehouseService';
import supplierService from '../../services/supplierService';
import Warehouse from '../../objects/warehouses';
import Article, { ArticleSupplier, ArticleWarehouse } from '../../objects/article';
import moment from 'moment';
import Supplier from '../../objects/supplier';
import WarningIcon from '@mui/icons-material/Warning';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TokenService from '../../services/tokenService';
import CustomToolbar from './CustomToolbar';
import { useSnackbar } from 'notistack';
import UpdateArticle from '../../components/articles/UpdateArticle';

export default function Articles() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['articles', 'toolbar', 'dateFormat', 'app']);
  const navigate = useNavigate();
  const { createColumn, createNavigationWithPropsColumn } = navigationService.create(t, navigate);
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeAmount, setChangeAmount] = useState<number>();
  const [suppliers, setSuppliers] = useState<Partial<Supplier>[]>([]);
  const [warehouses, setWarehouses] = useState<Partial<Warehouse>[]>([]);
  const [selectedArticles, setSelectedArticles] = useState<Map<string, Partial<Article>>>(
    new Map<string, Partial<Article>>()
  );
  const [articlesState, setArticlesState] = useState<ItemState>();
  const [articles, setArticles] = useState({
    loading: true,
    rows: new Array<Partial<Article>>(0),
    rowCount: 0
  });
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = articlesState;
  }, [articlesState]);

  const [columns, setColumns] = useState<any[]>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    price: false,
    minimalAmount: false,
    unit: false,
    suppliers: false,
    lowestPrice: false,
    lastEditor: false,
    reservedAmount: false,
    tags: false,
    trend: false,
    lastEdit: false
  });

  const hasProject = TokenService.tenantFullfills(['Project'], []);
  const hasAnalysis = TokenService.tenantFullfills(['Analysis'], []);
  const canUpdateArticles = TokenService.tenantFullfills([], ['Articles:Update']);
  const canViewWarehouses = TokenService.tenantFullfills([], ['Warehouses:View']);
  const canViewSuppliers = TokenService.tenantFullfills([], ['Suppliers:View']);
  const canUpdateSuppliers = TokenService.tenantFullfills(
    [],
    ['Suppliers:View', 'Suppliers:Update']
  );
  const canViewArticleBookings = TokenService.tenantFullfills([], ['Articles:Bookings:View']);
  const canUpdateArticleBookings = TokenService.tenantFullfills([], ['Articles:Bookings:Update']);
  const canUpdateArticleProjectBookings = TokenService.tenantFullfills(
    ['Project'],
    ['Projects:Bookings:Update']
  );

  useEffect(() => {
    const tempColumns = [];

    if (hasAnalysis)
      tempColumns.push(
        createNavigationWithPropsColumn(
          'name',
          'articles/details',
          (rowValue: any) => {
            return { articleId: rowValue.id };
          },
          { minWidth: 170, hideable: false },
          (rowValue: any) => {
            return { article: rowValue };
          }
        )
      );
    else tempColumns.push(createColumn('name', { minWidth: 170, hideable: false }));

    tempColumns.push(
      createColumn('reference', { minWidth: 120, hideable: false }),
      createColumn('totalAmount', {
        minWidth: 120,
        maxWidth: 150,
        type: 'number',
        hideable: false
      }),
      createColumn('unit', { minWidth: 80 })
    );

    if (canViewWarehouses)
      tempColumns.push(
        createColumn('warehouses', {
          sortable: false,
          minWidth: 200,
          hideable: false,
          valueGetter: (x: any) =>
            x.value?.map((warehouse: any) => warehouse.warehouse.displayName).join(', '),
          renderCell: (params: any) => {
            const TooltipTitle = (warehouses: Partial<ArticleWarehouse>[]) => {
              return (
                <div>
                  {warehouses.map((warehouse: any) => (
                    <p key={warehouse.warehouse.displayName}>
                      {warehouse.amount} - {warehouse.warehouse.displayName}
                    </p>
                  ))}
                </div>
              );
            };
            return (
              <>
                {params.row.warehouses?.length == 1 && (
                  <Typography>{params.row.warehouses[0].warehouse.displayName}</Typography>
                )}
                {params.row.warehouses?.length > 1 && (
                  <Tooltip title={TooltipTitle(params.row.warehouses)}>
                    <Link component="p" underline="none">
                      {t('multipleWarehouses')}
                    </Link>
                  </Tooltip>
                )}
              </>
            );
          }
        })
      );

    tempColumns.push(
      createColumn('minimalAmount', {
        minWidth: 120,
        type: 'number'
      }),
      createColumn('price', {
        minWidth: 100,
        valueGetter: (x: any) => (x.value ? x.value?.toFixed(2) + ' €' : '')
      }),
      createColumn('lowestPrice', {
        minWidth: 120,
        sortable: false,
        valueGetter: (x: any) => (x.value ? x.value?.toFixed(2) + ' €' : '')
      })
    );

    if (canViewSuppliers)
      tempColumns.push(
        createColumn('suppliers', {
          sortable: false,
          minWidth: 200,
          valueGetter: (x: any) =>
            x.value?.map((supplier: any) => supplier.supplier.displayName).join(', '),
          renderCell: (params: any) => {
            const TooltipTitle = (suppliers: Partial<ArticleSupplier>[]) => {
              return (
                <div>
                  {suppliers.map((supplier: any) => (
                    <p key={supplier.supplier.displayName}>
                      {supplier.price} - {supplier.supplier.displayName}
                    </p>
                  ))}
                </div>
              );
            };
            return (
              <>
                {params.row.suppliers?.length == 1 && (
                  <Typography>{params.row.suppliers[0].supplier.displayName}</Typography>
                )}
                {params.row.suppliers?.length > 1 && (
                  <Tooltip title={TooltipTitle(params.row.suppliers)}>
                    <Link component="p" underline="none">
                      {t('multipleSuppliers')}
                    </Link>
                  </Tooltip>
                )}
              </>
            );
          }
        })
      );

    tempColumns.push(
      createNavigationWithPropsColumn(
        'lastEditor',
        'employee-detail',
        (row: Partial<Article>) => {
          return { employeeId: row.lastEditor!.id };
        },
        {
          minWidth: 140,
          valueGetter: (x: any) => x.value?.displayName
        }
      ),
      createColumn('isBelowMinimalAmount', {
        minWidth: 120,
        maxWidth: 170,
        type: 'boolean',
        hideable: false,
        renderCell: (params: any) => (
          <>{params.row.isBelowMinimalAmount && <WarningIcon color="primary" />}</>
        )
      })
    );

    if (hasProject)
      tempColumns.push(createColumn('reservedAmount', { minWidth: 90, type: 'number' }));
    if (canViewArticleBookings)
      tempColumns.push(
        createColumn('trend', {
          minWidth: 60,
          type: 'number',
          sortable: false,
          renderCell: (params: any) => (
            <>
              {params.row.trend == 'Increasing' && <TrendingUpIcon color="primary" />}
              {params.row.trend == 'Stable' && <TrendingFlatIcon color="primary" />}
              {params.row.trend == 'Decreasing' && <TrendingDownIcon color="primary" />}
            </>
          )
        })
      );

    tempColumns.push(
      createColumn('lastEdit', {
        minWidth: 150,
        valueGetter: (x: any) => moment(new Date(x.value)).format(t('dateFormat:longDate'))
      })
    );

    tempColumns.push(
      createColumn('actions', {
        minWidth: 100,
        maxWidth: 100,
        sortable: false,
        hideable: false,
        renderCell: (params: any) => {
          let children = [
            {
              label: t('general'),
              render: (item: any, handleChange: any, validation: any) => (
                <UpdateArticle article={item} handleChange={handleChange} error={validation} />
              )
            }
          ];

          if (canUpdateSuppliers)
            children.push({
              label: t('suppliers'),
              render: (item, handleChange) => (
                <CreateArticleSuppliers
                  article={item}
                  suppliers={suppliers}
                  handleChange={handleChange}
                  isUpdate
                />
              )
            });

          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateArticles && (
                <>
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    type={t('type')}
                    initial={params.row}
                    validate={articleService.validate}
                    confirm={(item) => {
                      articleService
                        .update(item.id!, item)
                        .then(() => fetchData())
                        .catch(() =>
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' })
                        );
                    }}>
                    {children}
                  </ItemDialog>
                  <ItemDialog
                    kind={ItemDialogKind.Delete}
                    type={t('type')}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={(item) => {
                      articleService.delete(item.id!).then(() => fetchData());
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      })
    );
    setColumns(tempColumns);
  }, [suppliers, warehouses]);

  useEffect(() => {
    (async () => {
      if (canViewWarehouses)
        await warehouseService.all().then((result) => setWarehouses(result.rows));
      if (canViewSuppliers) await supplierService.all().then((result) => setSuppliers(result.rows));
      setChangeAmount(0);

      let state = itemStateService.getItemStateFromUrl(searchParams, 15);
      if (state.sort.size == 0) {
        state.sort.set('lastEdit', 'desc');
        itemStateService.setItemStateAsUrl(searchParams, setSearchParams, state);
      }
      setArticlesState(state);
    })();
  }, []);

  useEffect(() => {
    if (articlesState == null) return;
    if (articlesState.sort.size == 0) articlesState.sort.set('lastEdit', 'desc');
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, articlesState);
    fetchData();
  }, [articlesState]);

  const fetchData = () => {
    setArticles({ ...articles, ...{ loading: true } });
    articleService
      .all(stateRef.current)
      .then((result) => {
        setChangeAmount(0);
        setArticles({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setArticles({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            componentsProps={{
              panel: {
                sx: {
                  [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                    display: 'none'
                  }
                }
              },
              toolbar: {
                warehouses,
                suppliers,
                changeAmount,
                setChangeAmount,
                fetchData,
                articlesState,
                setArticlesState,
                selectedArticles
              }
            }}
            sx={{
              '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            pagination
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableColumnMenu
            loading={articles.loading}
            rows={articles.rows ?? []}
            columns={columns ?? []}
            page={articlesState?.paginationSettings?.page}
            pageSize={15}
            rowCount={articles.rowCount}
            rowsPerPageOptions={[15]}
            selectionModel={Array.from(selectedArticles.keys())}
            filterModel={filterService.createGridFilters(articlesState?.filter)}
            sortModel={filterService.createGridSort(articlesState?.sort)}
            checkboxSelection
            localeText={{
              toolbarColumns: t('toolbar:columns'),
              toolbarFilters: t('toolbar:filters'),
              toolbarDensity: t('toolbar:density'),
              toolbarExport: t('toolbar:export'),
              toolbarDensityCompact: t('toolbar:small'),
              toolbarDensityStandard: t('toolbar:medium'),
              toolbarDensityComfortable: t('toolbar:large'),
              noRowsLabel: t('toolbar:noRows'),
              footerRowSelected: (count) => t('toolbar:rowCount', { count })
            }}
            components={{
              Toolbar: CustomToolbar
            }}
            onSelectionModelChange={(selectionModel) => {
              let gridSelectedRowIds = new Set(selectionModel);
              let dataMap = new Map<string, readonly [any, boolean]>(
                articles.rows.map((row: any) => {
                  let enabled = gridSelectedRowIds.has(row.id);
                  return [row.id, [row, enabled]];
                })
              );
              let wasUpdated = false;
              dataMap.forEach((data, id) => {
                if (data[1]) {
                  if (!selectedArticles.has(id)) wasUpdated = true;
                  selectedArticles.set(id, data[0]);
                } else {
                  if (selectedArticles.has(id)) wasUpdated = true;
                  selectedArticles.delete(id);
                }
              });
              if (wasUpdated)
                setSelectedArticles(new Map<string, Partial<Article>>(selectedArticles));
            }}
            onPageChange={(page) => {
              if (articlesState == null) return;
              if (filterService.updateItemStatePage(articlesState, page, 15))
                setArticlesState({ ...articlesState });
            }}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              if (articlesState == null) return;
              if (
                filterService.updateItemStateFilter(
                  articlesState,
                  filterService.createFiltersByGridFilter(filterModel)
                )
              )
                setArticlesState({ ...articlesState });
            }}
            onSortModelChange={(sortModel: GridSortModel) => {
              if (articlesState == null) return;
              if (
                filterService.updateItemStateSort(
                  articlesState,
                  filterService.createSorterByGridSort(sortModel)
                )
              )
                setArticlesState({ ...articlesState });
            }}
          />
        </div>
      </Paper>
      {(canUpdateArticleBookings || canUpdateArticleProjectBookings) &&
        canViewWarehouses &&
        canViewSuppliers && (
          <QuickActionPanel
            selectedArticles={Array.from(selectedArticles.values())}
            allowArticleBookings={canUpdateArticleBookings}
            allowArticleProjectBookings={canUpdateArticleProjectBookings}
          />
        )}
    </div>
  );
}
