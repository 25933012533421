import { IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import projectService from '../../services/projectService';
import { ProjectArticleBooking, ProjectArticleReservation } from '../../objects/project';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import navigationService from '../../services/navigationService';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import Warehouse from '../../objects/warehouses';
import Supplier from '../../objects/supplier';
import TokenService from '../../services/tokenService';
import { Check, AccessTime } from '@mui/icons-material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import UpdateReservation from '../../components/projects/UpdateReservation';

interface ProjectArticle {
  id: string;
  isBooking: boolean;
  until?: Date;
  amount: number;
  unit: string;
  name: string;
  reference: string;
  warehouse: Partial<Warehouse>;
  supplier: Partial<Supplier>;
}

export default function ProjectArticles() {
  const { t } = useTranslation(['projects', 'articles', 'dateFormat']);
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const { enqueueSnackbar } = useSnackbar();

  // const [project, setProject] = useState<Partial<Project>>();
  const [bookedArticles, setBookedArticles] = useState<Partial<ProjectArticleBooking>[]>([]);
  const [reservedArticles, setReservedArticles] = useState<Partial<ProjectArticleReservation>[]>(
    []
  );
  const [articles, setArticles] = useState<ProjectArticle[]>([]);

  const canUpdateBookings = TokenService.tenantFullfills([], ['Projects:Bookings:Update']);
  const canUpdateReservations = TokenService.tenantFullfills([], ['Projects:Reservations:Update']);
  const canViewBookings = TokenService.tenantFullfills([], ['Projects:Bookings:View']);
  const canViewReservations = TokenService.tenantFullfills([], ['Projects:Reservations:View']);
  const canViewWarehouses = TokenService.tenantFullfills([], ['Warehouses:View']);
  const canViewSuppliers = TokenService.tenantFullfills([], ['Suppliers:View']);

  useEffect(() => {
    let bookedProjectArticles = bookedArticles.map((bookedArticle) => {
      return {
        id: bookedArticle.id,
        isBooking: true,
        amount: bookedArticle.amount,
        unit: bookedArticle.article?.unit,
        name: bookedArticle.article?.name,
        reference: bookedArticle.article?.reference,
        warehouse: bookedArticle.warehouse,
        supplier: bookedArticle.supplier
      } as ProjectArticle;
    });
    let reservedProjectArticles = reservedArticles.map((reservedArticle) => {
      return {
        id: reservedArticle.id,
        isBooking: false,
        until: reservedArticle.expirationTime,
        amount: reservedArticle.amount,
        unit: reservedArticle.article?.unit,
        name: reservedArticle.article?.name,
        reference: reservedArticle.article?.reference,
        warehouse: reservedArticle.warehouse,
        supplier: reservedArticle.supplier
      } as ProjectArticle;
    });
    setArticles(bookedProjectArticles.concat(reservedProjectArticles));
  }, [bookedArticles, reservedArticles]);

  const fetchData = async () => {
    if (projectId != null) {
      if (canViewBookings)
        await projectService
          .allArticleBookings(projectId)
          .then((result) => setBookedArticles(result.rows));
      if (canViewReservations)
        await projectService
          .allArticleReservations(projectId)
          .then((result) => setReservedArticles(result.rows));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  let columns = [
    createColumn('isBooking', {
      minWidth: 90,
      headerName: t('detail.isReservation'),
      renderCell: (params: any) => {
        const TooltipTitle = (projectArticle: ProjectArticle) => {
          if (projectArticle.isBooking)
            return (
              <div>
                <Typography>{t('detail.booked')}</Typography>
              </div>
            );
          else
            return (
              <div>
                <Typography>
                  {t('detail.reservedUntil', {
                    until: moment(new Date(projectArticle.until!)).format(t('dateFormat:longDate'))
                  })}
                </Typography>
              </div>
            );
        };
        return (
          <>
            <Tooltip title={TooltipTitle(params.row)}>
              {params.row.isBooking ? <Check color="primary" /> : <AccessTime color="primary" />}
            </Tooltip>
          </>
        );
      }
    }),
    createColumn('timeperiod', {
      minWidth: 140,
      maxWidth: 140,
      headerName: t('detail.timeperiod'),
      renderCell: (params: any) => {
        const TooltipTitle = (projectArticle: ProjectArticle) => {
          if (!projectArticle.isBooking)
            return (
              <div>
                <Typography>
                  {moment(new Date(projectArticle.until!)).format(t('dateFormat:longDate'))}
                </Typography>
              </div>
            );
        };
        return <>{!params.row.isBooking && TooltipTitle(params.row)}</>;
      }
    }),
    createColumn('amount', {
      minWidth: 80,
      headerName: t('detail.amount')
    }),
    createColumn('name', {
      minWidth: 170,
      headerName: t('articles:name')
    }),
    createColumn('reference', {
      minWidth: 170,
      headerName: t('articles:reference')
    }),
    createColumn('unit', {
      minWidth: 80,
      headerName: t('articles:unit')
    })
  ];

  if (canViewWarehouses)
    columns.push(
      createColumn('warehouse', {
        minWidth: 200,
        headerName: t('articles:warehouse'),
        valueGetter: (params: any) => params.row.warehouse?.displayName
      })
    );

  if (canViewSuppliers)
    columns.push(
      createColumn('supplier', {
        minWidth: 200,
        headerName: t('articles:supplier'),
        valueGetter: (params: any) => params.row.supplier?.displayName
      })
    );

  columns.push(
    createColumn('actions', {
      minWidth: 140,
      maxWidth: 140,
      sortable: false,
      hideable: false,
      renderCell: (params: any) => {
        return (
          <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
            {((canUpdateBookings && params.row.isBooking) ||
              (canUpdateReservations && !params.row.isBooking)) && (
              <>
                <ItemDialog
                  kind={ItemDialogKind.Delete}
                  type={t(params.row.isBooking ? 'detail.bookingType' : 'detail.reservationType')}
                  initial={params.row}
                  name={params.row.name!}
                  confirm={async (item) => {
                    if (projectId == null) return;
                    if (!params.row.isBooking)
                      await projectService
                        .deleteArticleReservation(projectId, item.id!)
                        .then(() => fetchData());
                    else
                      await projectService
                        .deleteArticleBooking(projectId, item.id!)
                        .then(() => fetchData());
                  }}
                />
                {!params.row.isBooking && (
                  <>
                    <ItemDialog
                      kind={ItemDialogKind.Update}
                      type={t(
                        params.row.isBooking ? 'detail.bookingType' : 'detail.reservationType'
                      )}
                      initial={params.row}
                      name={params.row.name!}
                      confirm={async (item) => {
                        if (projectId == null) return;

                        try {
                          await projectService.updateArticleReservations(
                            projectId!,
                            item!.id!,
                            item.data
                          );
                          fetchData();
                        } catch {
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
                        }
                      }}>
                      {(item, handleChange, validation) => (
                        <UpdateReservation
                          item={item}
                          handleChange={handleChange}
                          error={validation}
                          type="article"
                        />
                      )}
                    </ItemDialog>
                    <IconButton
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (projectId == null) return;
                        await projectService.deleteArticleReservation(projectId, params.row.id!);
                        let reservation = reservedArticles.find(
                          (reservation) => reservation.article?.reference == params.row.reference
                        );
                        let toBookOrReserveArticles = [
                          {
                            projectId: projectId,
                            article: reservation!.article,
                            warehouse: params.row.warehouse,
                            supplier: params.row.supplier,
                            amount: params.row.amount
                          }
                        ];

                        await projectService
                          .createArticleBookings(projectId, toBookOrReserveArticles)
                          .then(() =>
                            enqueueSnackbar(
                              t('movement.project.bookingSuccess', { ns: 'articles' }),
                              {
                                variant: 'success'
                              }
                            )
                          )
                          .catch(() =>
                            enqueueSnackbar(
                              t('movement.project.bookingFailure', { ns: 'articles' }),
                              {
                                variant: 'error'
                              }
                            )
                          )
                          .then(() => fetchData());
                      }}>
                      <Check color="primary" />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </div>
        );
      }
    })
  );

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
          display: 'none'
        }
      }}
      disableColumnMenu
      loading={false}
      rows={articles ?? []}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      localeText={{
        toolbarColumns: t('toolbar:columns'),
        toolbarFilters: t('toolbar:filters'),
        toolbarDensity: t('toolbar:density'),
        toolbarExport: t('toolbar:export')
      }}
      components={{
        Toolbar: CustomToolbar
      }}
    />
  );
}
