import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  Step,
  StepButton,
  Stepper
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

type ItemDialogChild = (
  initial: any,
  handleChange: (item: any) => void,
  error: boolean
) => ReactNode | undefined;
type ItemDialogSteppedChild = {
  label: string;
  render: (
    initial: any,
    handleChange: (item: any) => void,
    error: boolean
  ) => ReactNode | undefined;
};

export enum ItemDialogKind {
  Create,
  Update,
  Delete
}
export interface ItemDialogProps {
  iconButtonProps?: IconButtonProps;
  buttonProps?: ButtonProps;
  buttonContainerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  kind: ItemDialogKind;
  type: string;
  name?: string;
  autoSize?: boolean;
  open?: boolean;
  validate?: (item: any) => boolean;
  onOpenChange?: (open: boolean) => void;
  withText?: boolean;
  withTypedText?: boolean;
  initial?: any;
  children?: ItemDialogChild | ItemDialogSteppedChild[];
  confirm?: ((item: any) => void) | ((item: any) => Promise<void>);
}

export default function ItemDialog(props: ItemDialogProps) {
  const { t } = useTranslation('itemDialog');
  const [open, setOpen] = useState(props.open ?? false);
  const [item, setItem] = useState(props.initial ?? {});
  const [error, setError] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setItem(props.initial);
  }, [open]);

  useEffect(() => {
    setOpen(props.open ?? false);
  }, [props.open]);

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    setError(false);
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) descriptionElement.focus();
    }
  }, [open]);

  const isCreate = props.kind == ItemDialogKind.Create;
  const isUpdate = props.kind == ItemDialogKind.Update;
  const isDelete = props.kind == ItemDialogKind.Delete;
  const kindString = ItemDialogKind[props.kind];
  const dialogTitle = t(`${kindString.toLowerCase()}Title`, { type: props.type });
  const confirmButtonTitle = t(`confirm${kindString}`, {
    type: props.type
  });

  const cancelButton = <Button onClick={handleClose}>{t('cancel')}</Button>;
  const deleteMessage = (
    <>{isDelete && <p>{t('deleteMessage', { type: props.type, name: props.name })}</p>}</>
  );

  let content = <></>;
  let actions = <></>;

  if (props.children != undefined && Array.isArray(props.children)) {
    const [step, setStep] = useState(0);
    actions = (
      <Grid container spacing={1}>
        <Grid sx={{ width: '100%' }} item xs={12}>
          <Stepper nonLinear activeStep={step} alternativeLabel>
            {props.children.map((child, index) => (
              <Step key={child.label}>
                <StepButton
                  disableRipple
                  color="inherit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setStep(index);
                  }}>
                  {child.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Box style={{ marginTop: 10 }} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={async (e: any) => {
                e.stopPropagation();
                if (props.validate) {
                  let error = !props.validate(item);
                  setError(error);
                  if (error) return;
                }
                if (props.confirm != undefined)
                  if (props.confirm.constructor.name === 'AsyncFunction') await props.confirm(item);
                  else props.confirm(item);
                handleClose();
              }}>
              {confirmButtonTitle}
            </Button>
            {cancelButton}
          </Box>
        </Grid>
      </Grid>
    );
    content = (
      <>
        {deleteMessage}
        {props.children.at(step)!.render(item, setItem, error)}
      </>
    );
  } else {
    actions = (
      <>
        <Button
          variant="contained"
          onClick={async (e: any) => {
            e.stopPropagation();
            if (props.validate) {
              let error = !props.validate(item);
              setError(error);
              if (error) return;
            }
            if (props.confirm != undefined)
              if (props.confirm.constructor.name === 'AsyncFunction') await props.confirm(item);
              else props.confirm(item);
            handleClose();
          }}>
          {confirmButtonTitle}
        </Button>
        <Button onClick={handleClose}>{t('cancel')}</Button>
      </>
    );
    content = (
      <>
        {isDelete && <p>{t('deleteMessage', { type: props.type, name: props.name })}</p>}
        {props.children != undefined && props.children(item, setItem, error)}
      </>
    );
  }

  const defaultPaperProps = { sx: { minWidth: '35%' } };

  return (
    <div {...props.buttonContainerProps}>
      {isCreate && !(props.withText || props.withTypedText) && (
        <IconButton onClick={handleClickOpen} {...props.iconButtonProps} color="primary">
          <AddIcon />
        </IconButton>
      )}
      {isCreate && (props.withText || props.withTypedText) && (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          size="small"
          {...props.buttonProps}
          onClick={handleClickOpen}
          startIcon={<AddIcon />}>
          {props.withTypedText ? t('typedCreate', { type: props.type }) : t('create')}
        </Button>
      )}

      {isUpdate && !(props.withText || props.withTypedText) && (
        <IconButton onClick={handleClickOpen} {...props.iconButtonProps} color="primary">
          <EditIcon />
        </IconButton>
      )}
      {isUpdate && (props.withText || props.withTypedText) && (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          size="small"
          {...props.buttonProps}
          onClick={handleClickOpen}
          startIcon={<EditIcon />}>
          {props.withTypedText ? t('typedUpdate', { type: props.type }) : t('update')}
        </Button>
      )}

      {isDelete && !(props.withText || props.withTypedText) && (
        <IconButton onClick={handleClickOpen} {...props.iconButtonProps} color="primary">
          <DeleteIcon />
        </IconButton>
      )}
      {isDelete && (props.withText || props.withTypedText) && (
        <Button
          style={{ margin: 5 }}
          variant="contained"
          size="small"
          {...props.buttonProps}
          onClick={handleClickOpen}
          startIcon={<DeleteIcon />}>
          {props.withTypedText ? t('typedDelete', { type: props.type }) : t('delete')}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        PaperProps={
          isDelete || props.autoSize
            ? defaultPaperProps
            : { ...defaultPaperProps, ...{ sx: { width: '50%', height: '80%' } } }
        }>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
}
