import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import appointmentService from '../../services/appointmentService';
import allLocales from '@fullcalendar/core/locales-all';
import DetailPopover from '../../components/appointments/DetailPopover';
import CreateEquipmentAppointment from '../../components/equipments/CreateEquipmentAppointment';
import EditAppointment from '../../components/appointments/EditAppointment';
import Legend from '../appointments/Legend';
import equipmentService from '../../services/equipmentService';

export default function EquipmentCalendar() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [events, setEvents] = useState();
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [coordX, setCoordX] = useState(0);
  const [coordY, setCoordY] = useState(0);

  const fetchCalendar = async (id: string) => {
    const result = await appointmentService.getAppointments(
      new Map<string, string | string[]>([
        ['equipmentId', id],
        ['appointmentTypes', ['Equipment']]
      ])
    );

    const mappedData = result.data.elements.map(function (i: any) {
      return {
        id: i.id,
        title: i.subject,
        extendedProps: {
          editable: true,
          description: i.description
        },
        start: i.start,
        end: i.end,
        backgroundColor: '#fbc02d',
        borderColor: '#fbc02d'
      };
    });

    const reservations = await equipmentService.getReservations(id, new Date(), new Date());
    const mappedReservationData = reservations.data.map(function (i: any) {
      return {
        id: 'reservation_' + i.id,
        title: i.project.displayName,
        extendedProps: {
          editable: false,
          description: i.project.displayName
        },
        start: i.from,
        end: i.to,
        backgroundColor: '#fbc02d',
        borderColor: '#fbc02d'
      };
    });

    setEvents(mappedData.concat(mappedReservationData));
  };

  useEffect(() => {
    fetchCalendar(id!);
  }, [open, openPopover, openEditDialog]);

  useEffect(() => {
    // equipmentService.get(id!).then((result) => {
    //   setEquipment(result);
    // });
    fetchCalendar(id!);
  }, []);
  return (
    <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#fff',
          padding: 20,
          borderRadius: 10
        }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          events={events}
          locales={allLocales}
          nowIndicator
          locale={localStorage.getItem('i18nextLng') ?? 'en'}
          height="70vh"
          weekNumbers
          selectable
          dayMaxEventRows
          views={{
            dayGridMonth: { dayMaxEventRows: 4 }
          }}
          customButtons={{
            create: {
              text: '+',
              click: function () {
                setSelectedDate(null);
                setSelectedEvent(null);
                setOpen(true);
              }
            }
          }}
          headerToolbar={{
            start: 'prev,next,today create', // will normally be on the left. if RTL, will be on the right
            center: 'title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' // will normally be on the right. if RTL, will be on the left
          }}
          dateClick={(info: any) => {
            setSelectedEvent(info);
            setOpen(true);
          }}
          select={(info: any) => {
            setSelectedEvent(info);
            setOpen(true);
          }}
          eventClick={(info: any) => {
            setSelectedEvent(info);
            console.log(info);
            setCoordX(info.jsEvent.pageX);
            setCoordY(info.jsEvent.pageY);
            setOpenPopover(true);
          }}
        />
        <Legend usedTypes={['Equipment']} />
      </div>
      <CreateEquipmentAppointment
        //type={'Equipment'}
        //userTypes={['Service', 'Anderes']}
        equipmentId={id}
        open={open}
        setOpen={setOpen}
        selectedDate={selectedDate}
        selectedEvent={selectedEvent}
      />
      <EditAppointment data={selectedEvent} open={openEditDialog} setOpen={setOpenEditDialog} />
      <Popover
        open={openPopover}
        anchorReference="anchorPosition"
        onClose={() => setOpenPopover(false)}
        anchorPosition={{ left: coordX, top: coordY - 35 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <DetailPopover
          data={selectedEvent}
          isEditable={selectedEvent?.event?.extendedProps?.editable}
          setOpenPopover={setOpenPopover}
          setOpenEditDialog={setOpenEditDialog}
        />
      </Popover>
    </div>
  );
}
