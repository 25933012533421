import Customer from '../objects/customer';
import { defaultService, ItemState } from './api';

const route = 'customers';
const customerService = {
  validate: (customer: any): boolean =>
    customer?.name?.length > 0 && customer?.reference?.length > 0,
  get: async (id: string): Promise<Partial<Customer>> => (await defaultService.get(route, id)).data,
  create: (employee: Partial<Customer>) => defaultService.create(route, employee),
  update: (id: string, employee: Partial<Customer>) => defaultService.update(route, id, employee),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Customer>(route, itemState)
};

export default customerService;
