import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import { validate } from 'email-validator';
import ExternalService from '../../objects/externalService';

export default function CreateExternalService(props: any) {
  const { t } = useTranslation(['externalServices', 'dateFormat']);
  const [externalService, setExternalServices] = useState<Partial<ExternalService>>();

  useEffect(() => {
    props.handleChange(externalService);
  }, [externalService]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          required
          error={props.error && !externalService?.name}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('name')}
          variant="outlined"
          value={externalService?.name ?? ''}
          onChange={(e) => setExternalServices({ ...externalService, ...{ name: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !externalService?.description}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('description')}
          variant="outlined"
          value={externalService?.description ?? ''}
          onChange={(e) =>
            setExternalServices({ ...externalService, ...{ description: e.target.value } })
          }
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10, marginRight: 10, flex: 1 }}
            label={t('address')}
            variant="outlined"
            value={externalService?.address ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ address: e.target.value } })
            }
          />
          <TextField
            style={{ marginBottom: 10, marginRight: 10, width: 100 }}
            label={t('zipCode')}
            variant="outlined"
            value={externalService?.zipCode ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ zipCode: e.target.value } })
            }
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('city')}
            variant="outlined"
            value={externalService?.city ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ city: e.target.value } })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!IBAN.isValid(externalService?.iban!) && externalService?.iban != undefined}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('iban')}
            variant="outlined"
            value={externalService?.iban ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ iban: e.target.value } })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            error={!validate(externalService?.email!) && externalService?.email != undefined}
            style={{ marginBottom: 10, flex: 1 }}
            label={t('email')}
            variant="outlined"
            value={externalService?.email ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ email: e.target.value } })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            label={t('phone')}
            variant="outlined"
            value={externalService?.phone ?? ''}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ phone: e.target.value } })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            label={t('priceByHour')}
            variant="outlined"
            value={externalService?.priceByHour ?? 0}
            onChange={(e) =>
              setExternalServices({
                ...externalService,
                ...{ priceByHour: Number(e.target.value) }
              })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            label={t('costByHour')}
            variant="outlined"
            value={externalService?.costByHour ?? 0}
            onChange={(e) =>
              setExternalServices({ ...externalService, ...{ costByHour: Number(e.target.value) } })
            }
          />
        </div>
      </div>
    </Paper>
  );
}
