import { useAppDispatch } from '../redux/hooks';
import { updateLanguage } from '../redux/reducer/languageSlice';

export default function useLocales() {
  const dispatch = useAppDispatch();

  const changeLocale = (newValue: any) => {
    dispatch(updateLanguage(newValue));
  };

  return {
    changeLocale
  };
}
