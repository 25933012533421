import { Paper } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridPro, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import navigationService from '../../services/navigationService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import filterService from '../../services/filterService';
import roleService from '../../services/roleService';
import rightService from '../../services/rightService';
import UpdateRole from '../../components/roles/UpdateRole';
import { ItemState, itemStateService } from '../../services/api';
import Role from '../../objects/role';
import TokenService from '../../services/tokenService';
import StarIcon from '@mui/icons-material/Star';
import { useSnackbar } from 'notistack';
import CustomToolbar from './CustomToolbar';

export default function Roles() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['roles', 'toolbar', 'app']);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { createColumn } = navigationService.create(t, navigate);
  const [changeAmount, setChangeAmount] = useState<number>();
  const [columns, setColumns] = useState<any[]>([]);

  const [rolesState, setRolesState] = useState<ItemState>(
    itemStateService.getItemStateFromUrl(searchParams, 15)
  );
  const stateRef = useRef<ItemState>();
  useEffect(() => {
    stateRef.current = rolesState;
  }, [rolesState]);
  const [roles, setRoles] = useState({
    loading: true,
    rows: new Array<Partial<Role>>(0),
    rowCount: 0
  });

  const [rights, setRights] = useState<string[]>([]);

  const fetchData = () => {
    setRoles({ ...roles, ...{ loading: true } });
    roleService
      .all(stateRef.current)
      .then((result) => {
        setChangeAmount(0);
        setRoles({
          loading: false,
          rows: result.rows,
          rowCount: result.rowCount
        });
      })
      .catch(() => {
        setRoles({
          loading: false,
          rows: [],
          rowCount: 0
        });
      });
  };

  useEffect(() => {
    fetchData();
    itemStateService.setItemStateAsUrl(searchParams, setSearchParams, rolesState);
  }, [rolesState]);

  useEffect(() => {
    const tempColumns = [
      createColumn('isExecutive', {
        sortable: false,
        headerName: '',
        maxWidth: 40,
        minWidht: 40,
        renderCell: (params: any) => (params.row.isExecutive ? <StarIcon color="primary" /> : <></>)
      }),
      createColumn('name'),
      createColumn('rights', {
        sortable: false,
        headerName: t('rights') + ' (' + rights.length + ')',
        valueGetter: (x: any) => x.value.length
      }),
      {
        field: 'actions',
        headerName: t('actions'),
        sortable: false,
        width: 120,
        hideable: false,
        renderCell: (params: any) => {
          return (
            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
              {canUpdateRoles && !params.row.isExecutive && (
                <>
                  <ItemDialog
                    kind={ItemDialogKind.Update}
                    autoSize
                    type={t('type')}
                    initial={params.row}
                    validate={roleService.validate}
                    confirm={(item) => {
                      roleService
                        .update(item.id!, item)
                        .then(() => fetchData())
                        .catch(() =>
                          enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' })
                        );
                    }}>
                    {(item, handleChange, validation) => {
                      return (
                        <UpdateRole
                          rights={rights}
                          role={item}
                          handleChange={handleChange}
                          error={validation}
                        />
                      );
                    }}
                  </ItemDialog>
                  <ItemDialog
                    kind={ItemDialogKind.Delete}
                    type={t('type')}
                    initial={params.row}
                    name={params.row.name!}
                    confirm={(item) => {
                      roleService.delete(item.id!).then(() => fetchData());
                    }}
                  />
                </>
              )}
            </div>
          );
        }
      }
    ];
    setColumns(tempColumns);
  }, [rights]);

  useEffect(() => {
    rightService.all().then((result) => setRights(result.data));
    setChangeAmount(0);
  }, []);

  const canUpdateRoles = TokenService.tenantFullfills([], ['Roles:Update']);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}
        elevation={0}>
        <div style={{ flexGrow: 1 }}>
          {rights.length > 0 && (
            <DataGridPro
              componentsProps={{
                toolbar: {
                  rights,
                  changeAmount,
                  setChangeAmount,
                  fetchData,
                  rolesState,
                  setRolesState
                }
              }}
              sx={{
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                  display: 'none'
                }
              }}
              pagination
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              disableColumnMenu
              loading={roles.loading}
              rows={roles.rows ?? []}
              columns={columns}
              page={rolesState.paginationSettings!.page}
              pageSize={rolesState.paginationSettings!.pageSize}
              rowCount={roles.rowCount}
              rowsPerPageOptions={[15]}
              getRowId={(r: any) => r.name}
              filterModel={filterService.createGridFilters(rolesState.filter)}
              sortModel={filterService.createGridSort(rolesState.sort)}
              localeText={{
                toolbarColumns: t('toolbar:columns'),
                toolbarFilters: t('toolbar:filters'),
                toolbarDensity: t('toolbar:density'),
                toolbarExport: t('toolbar:export'),
                toolbarDensityCompact: t('toolbar:small'),
                toolbarDensityStandard: t('toolbar:medium'),
                toolbarDensityComfortable: t('toolbar:large'),
                noRowsLabel: t('toolbar:noRows'),
                footerRowSelected: (count) => t('toolbar:rowCount', { count })
              }}
              components={{
                Toolbar: CustomToolbar
              }}
              onPageChange={(page) => {
                setRolesState({
                  ...rolesState,
                  ...{
                    paginationSettings: {
                      page: page,
                      pageSize: rolesState.paginationSettings!.pageSize
                    }
                  }
                });
              }}
              onFilterModelChange={(filterModel: GridFilterModel) => {
                setRolesState({
                  ...rolesState,
                  ...{ filter: filterService.createFiltersByGridFilter(filterModel) }
                });
              }}
              onSortModelChange={(sortModel: GridSortModel) => {
                setRolesState({
                  ...rolesState,
                  ...{ sort: filterService.createSorterByGridSort(sortModel) }
                });
              }}
            />
          )}
        </div>
      </Paper>
    </div>
  );
}
