import { TextField, Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';
import Warehouse from '../../objects/warehouses';
import TokenService from '../../services/tokenService';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';

import { ItemState } from '../../services/api';
import equipmentService from '../../services/equipmentService';
import CreateEquipment from '../../components/equipments/CreateEquipment';
import CreateEquipmentWarehouses from '../../components/equipments/CreateEquipmentWarehouses';
import GenerateQRCodes from '../../components/articles/GenerateQRCodes';
import Equipment from '../../objects/equipment';

interface CustomToolbarProps {
  warehouses: Partial<Warehouse>[];
  // changeAmount: number | undefined;
  // setChangeAmount(input: number): void;
  fetchData(): void;
  equipmentsState: ItemState;
  setEquipmentsState(input: ItemState): void;
  selectedEquipments: Map<string, Partial<Equipment>>;
}

const CustomToolbar = (props: CustomToolbarProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { warehouses, fetchData, equipmentsState, setEquipmentsState, selectedEquipments } = props;
  const { t } = useTranslation(['equipments', 'app', 'customToolBar']);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [beforeSearchState, setBeforeSearchState] = useState<ItemState>();

  const canUpdateEquipments = TokenService.tenantFullfills([], ['Equipments:Update']);

  useEffect(() => {
    if (searchText == null) setBeforeSearchState(equipmentsState);
  }, [equipmentsState]);

  function handleSearch() {
    if (equipmentsState != null && searchText != null) {
      equipmentsState.filterType = 'or';
      equipmentsState.filter.set('reference', searchText);
      equipmentsState.filter.set('name', searchText);
      setEquipmentsState({ ...equipmentsState });
      setIsSearching(false);
    } else if (isSearching) setEquipmentsState(beforeSearchState!);
  }

  return (
    <GridToolbarContainer>
      {isSearching ? (
        <div style={{ margin: 5, display: 'flex', flex: 1 }}>
          <TextField
            size="small"
            label={t('search')}
            style={{ flex: 5 }}
            variant="outlined"
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && handleSearch()}
            autoFocus
            spellCheck={false}
          />
          <Button
            variant="contained"
            style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
            onClick={handleSearch}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <Button size="small" onClick={() => setIsSearching(false)}>
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <>
          {canUpdateEquipments && (
            <ItemDialog
              withText
              kind={ItemDialogKind.Create}
              type={t('type')}
              validate={equipmentService.validate}
              initial={{
                userType: 'Equipment'
              }}
              confirm={(item) => {
                equipmentService
                  .create(item)
                  .then(() => fetchData())
                  .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
              }}>
              {{
                label: t('general'),
                render: (item, handleChange, validation) => (
                  <CreateEquipment
                    equipment={item}
                    handleChange={handleChange}
                    error={validation}
                  />
                )
              }}
              {{
                label: t('warehouses'),
                render: (item, handleChange) => (
                  <CreateEquipmentWarehouses
                    equipment={item}
                    warehouses={warehouses}
                    handleChange={handleChange}
                  />
                )
              }}
            </ItemDialog>
          )}
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <GenerateQRCodes selectedArticles={Array.from(selectedEquipments.values())} />
          <Button size="small" startIcon={<SearchIcon />} onClick={() => setIsSearching(true)}>
            {t('search', { ns: 'customToolBar' })}
          </Button>

          {/* <ChangeIndicator
            changeAmount={changeAmount ?? 0}
            setChangeAmount={setChangeAmount}
            type={'equipments'}
            onClick={fetchData}
          /> */}
        </>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
