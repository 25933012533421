import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navigationService from '../../services/navigationService';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Chip
} from '@mui/material';
import { DataGridPro, GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import DeleteDialog from '../../components/Helper/DeleteDialog';
import projectService from '../../services/projectService';
import { useSnackbar } from 'notistack';
import Article, { ArticleSupplier, ArticleWarehouse } from '../../objects/article';
import Warehouse from '../../objects/warehouses';
import Supplier from '../../objects/supplier';
import { Project } from '../../objects/project';

interface ArticleProjectMovementLocationState {
  articles: Partial<Article>[];
}

interface ArticleProjectMovementData extends Partial<Article> {
  movementAmount: number;
  movementFromWarehouse: Partial<Warehouse>;
  movementWithSupplier?: Partial<Supplier>;
}

export default function ArticleProjectMovement() {
  const { t } = useTranslation(['articles', 'toolbar']);
  const location = useLocation();
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);
  const { enqueueSnackbar } = useSnackbar();
  const locationState = location.state as ArticleProjectMovementLocationState;

  const [articles, setArticles] = useState<ArticleProjectMovementData[]>([]);

  const [projects, setProjectes] = useState<Partial<Project>[]>([]);
  const [selectedProject, setSelectedProject] = useState<Partial<Project> | null>(null);

  const [isReservation, setIsReservation] = useState<boolean>(false);
  const [reservationHours, setReservationHours] = useState<number>(48);
  const [columns, setColumns] = useState<any>([]);
  const [currentChangeAmount, setCurrentChangeAmount] = useState(0);
  const [currentChangeWarehouse, setCurrentChangeWarehouse] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    setArticles(
      locationState?.articles?.map((article: Partial<Article>) => ({
        ...article,
        movementAmount: 0,
        movementFromWarehouse: article.warehouses
          ?.sort((warehouse) => warehouse.amount ?? 0)!
          ?.at(0)?.warehouse!,
        movementWithSupplier: article.suppliers?.sort((supplier) => supplier?.price ?? 0.0)!.at(0)
          ?.supplier
      }))
    );
    projectService.all().then((result) => setProjectes(result.rows));

    const tempColumns = [
      createColumn('name', { minWidth: 170 }),
      createColumn('reference', { minWidth: 100 }),
      createColumn('totalAmount', { minWidth: 60, type: 'number' }),
      createColumn('movementAmount', {
        headerName: t('movement.amount'),
        minWidth: 100,
        type: 'number',
        renderCell: (params: any) => {
          return (
            <TextField
              InputProps={{ inputProps: { min: 0, autoComplete: 'new-password' } }}
              style={
                params.row.movementAmount >
                  params.row.warehouses?.find(
                    (warehouse: ArticleWarehouse) =>
                      warehouse?.warehouse?.id == params.row?.movementFromWarehouse.id
                  )?.amount! || params.row.movementAmount == 0
                  ? { background: '#ea9b9b' }
                  : { background: '#ffffe6' }
              }
              fullWidth
              type="number"
              onFocus={(event) => {
                event.target.select();
              }}
              value={params.row?.movementAmount ?? currentChangeAmount ?? 0}
              onChange={(e) => {
                let parsedNumber = Math.abs(Number.parseInt(e.target.value));
                params.row.movementAmount = parsedNumber;
                setCurrentChangeAmount(parsedNumber);
              }}
            />
          );
        }
      }),
      createColumn('movementFromWarehouse', {
        headerName: t('movement.fromWarehouse'),
        minWidth: 200,
        editable: false,
        renderCell: (params: any) => (
          <Select
            style={{
              background:
                params.row.warehouses?.length < 1
                  ? 'white'
                  : !params.row.movementFromWarehouse
                  ? '#ea9b9b'
                  : '#ffffe6'
            }}
            fullWidth
            value={params.row?.movementFromWarehouse?.id ?? currentChangeWarehouse ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              let warehouse = params.row.warehouses?.find(
                (warehouse: any) => warehouse.warehouse?.id == e.target.value
              );
              if (warehouse?.warehouse != undefined) {
                params.row.movementFromWarehouse = warehouse.warehouse;
                setCurrentChangeWarehouse(warehouse.warehouse);
              }
            }}>
            {params.row.warehouses?.map((warehouse: Partial<ArticleWarehouse>) => (
              <MenuItem key={warehouse.warehouse!.id} value={warehouse.warehouse!.id}>
                <Chip size="small" label={warehouse.amount ?? 0} style={{ marginRight: 10 }} />
                {warehouse.warehouse!.displayName}
              </MenuItem>
            ))}
          </Select>
        )
      }),
      createColumn('movementWithSupplier', {
        headerName: t('movement.withSupplier'),
        minWidth: 200,
        renderCell: (params: any) => (
          <Select
            style={{ background: params.row.suppliers?.length < 1 ? 'white' : '#ffffe6' }}
            disabled={params.row.suppliers?.length < 1}
            fullWidth
            value={params.row?.movementWithSupplier?.id ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              let supplier = params.row.suppliers?.find(
                (supplier: any) => supplier.supplier?.id == e.target.value
              );
              params.row.movementWithSupplier = supplier?.supplier;
            }}>
            {params.row.suppliers?.map((supplier: Partial<ArticleSupplier>) => (
              <MenuItem key={supplier.supplier!.id} value={supplier.supplier!.id}>
                <Chip
                  size="small"
                  label={(supplier?.price?.toFixed(2) ?? 0) + ' €'}
                  style={{ marginRight: 10 }}
                />
                {supplier.supplier!.displayName}
              </MenuItem>
            ))}
          </Select>
        )
      }),
      createColumn('actions', {
        headerName: '',
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        renderCell: (params: any) => {
          const onDeleteItem = () =>
            setArticles((prevState) =>
              prevState.filter((article) => article.reference != params.row.reference)
            );
          return (
            <DeleteDialog
              isSelection
              deleteItem={onDeleteItem}
              type={t('type')}
              name={params.row.name}
            />
          );
        }
      })
    ];
    setColumns(tempColumns);
  }, []);

  const bookOrReserveArticles = async () => {
    let error = false;

    let projectId = selectedProject?.id;
    if (projectId == null) {
      error = true;
      setError(error);
      return;
    }
    let toBookOrReserveArticles = articles.map((article) => {
      if (!error)
        error =
          !article.movementFromWarehouse! ||
          !article.movementAmount ||
          article.movementAmount >
            article?.warehouses?.find(
              (warehouse) => warehouse?.warehouse?.id == article?.movementFromWarehouse.id
            )?.amount! ||
          article.movementAmount == 0;
      return {
        projectId: projectId,
        article: article,
        warehouse: article.movementFromWarehouse,
        supplier: article.movementWithSupplier,
        amount: article.movementAmount,
        for: `00:${reservationHours}:00`
      };
    });

    if (error) {
      setError(error);
      return;
    }

    if (isReservation)
      await projectService
        .createArticleReservations(projectId, toBookOrReserveArticles)
        .then(() =>
          enqueueSnackbar(t('movement.project.reservationSuccess'), { variant: 'success' })
        )
        .catch(() =>
          enqueueSnackbar(t('movement.project.reservationFailure'), { variant: 'error' })
        )
        .finally(() => navigate('/articles'));
    else
      await projectService
        .createArticleBookings(projectId, toBookOrReserveArticles)
        .then(() => enqueueSnackbar(t('movement.project.bookingSuccess'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(t('movement.project.bookingFailure'), { variant: 'error' }))
        .finally(() => navigate('/articles'));
  };

  // we need this because mui doesnt update cells by them self, only the copy
  const handleCommit = (e: GridCellEditCommitParams) => {
    const array = articles.map((i: any) => {
      if (i.id === e.id) return { ...i, [e.field]: e.value };
      else return { ...i };
    });
    setArticles(array);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        height: '100%'
      }}>
      <Paper style={{ display: 'flex', flexDirection: 'column', width: '100%' }} elevation={0}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          }}
          pagination
          pageSize={15}
          onCellEditCommit={handleCommit}
          style={{ height: '92%' }}
          rows={articles}
          columns={columns}
          disableSelectionOnClick
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: '#eee'
          }}>
          <div>
            <FormControlLabel
              style={{ margin: 10 }}
              control={
                <Checkbox
                  checked={isReservation}
                  onChange={(e) => {
                    e.stopPropagation();
                    setIsReservation(e.target.checked);
                  }}
                />
              }
              label={<Typography>{t('movement.project.isReservation')}</Typography>}
            />
            <TextField
              hidden={!isReservation}
              disabled={!isReservation}
              size="small"
              style={{ backgroundColor: 'white', margin: 10 }}
              label={t('movement.project.reservationHours')}
              value={reservationHours}
              onChange={(e) => {
                e.stopPropagation();
                setReservationHours(Number.parseInt(e.target.value));
              }}
            />
            <FormControl size="small" style={{ width: 200, margin: 10 }}>
              <InputLabel>{t('movement.project.project')}</InputLabel>
              <Select
                label={t('movement.project.project')}
                style={{ backgroundColor: 'white' }}
                error={error && !selectedProject?.id}
                onChange={(e) => {
                  e.stopPropagation();
                  let projectId = e.target.value;
                  if (projectId == null) return;
                  setSelectedProject(projects.find((project) => project.id == projectId) ?? null);
                }}
                value={selectedProject?.id ?? ''}>
                {projects?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {error && (
            <Typography color="error" style={{ margin: 10, alignSelf: 'center' }}>
              {t('resolveError')}
            </Typography>
          )}
          <Button onClick={bookOrReserveArticles} style={{ margin: 10 }} variant="contained">
            {isReservation ? t('movement.project.reserve') : t('movement.project.book')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
