import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navigationService from '../../services/navigationService';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  TextField,
  Chip
} from '@mui/material';
import { DataGridPro, GridCellEditCommitParams } from '@mui/x-data-grid-pro';
import DeleteDialog from '../../components/Helper/DeleteDialog';
import projectService from '../../services/projectService';
import { useSnackbar } from 'notistack';
import Equipment, { EquipmentWarehouse } from '../../objects/equipment';
import Warehouse from '../../objects/warehouses';
import Supplier from '../../objects/supplier';
import { Project } from '../../objects/project';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
// import moment from 'moment';

interface EquipmentProjectMovementLocationState {
  equipments: Partial<Equipment>[];
}

interface EquipmentProjectMovementData extends Partial<Equipment> {
  movementAmount: number;
  movementFromWarehouse: Partial<Warehouse>;
  movementWithSupplier?: Partial<Supplier>;
  from?: Date;
  to?: Date;
}

export default function EquipmentProjectReservation() {
  const { t } = useTranslation(['equipments', 'toolbar']);
  const location = useLocation();
  const navigate = useNavigate();
  const { createColumn } = navigationService.create(t, navigate);
  const { enqueueSnackbar } = useSnackbar();
  const locationState = location.state as EquipmentProjectMovementLocationState;

  const [equipments, setEquipments] = useState<EquipmentProjectMovementData[]>([]);
  const [currentChange, setCurrentChange] = useState('');
  const [projects, setProjectes] = useState<Partial<Project>[]>([]);
  const [selectedProject, setSelectedProject] = useState<Partial<Project> | null>(null);

  const [columns, setColumns] = useState<any>([]);
  const [currentChangeAmount, setCurrentChangeAmount] = useState(0);

  const dateNow = new Date();
  useEffect(() => {
    setEquipments(
      locationState?.equipments?.map((equipment: Partial<Equipment>) => ({
        ...equipment,
        movementAmount: 0,
        movementFromWarehouse: equipment.warehouses
          ?.sort((warehouse) => warehouse.amount ?? 0)!
          ?.at(0)?.warehouse!,
        from: moment(dateNow).startOf('day').toDate(),
        to: moment(dateNow).endOf('day').toDate()
      }))
    );
    projectService.all().then((result) => setProjectes(result.rows));

    const tempColumns = [
      createColumn('name', { minWidth: 170 }),
      createColumn('reference', { minWidth: 100 }),
      createColumn('totalAmount', { minWidth: 60, type: 'number' }),
      createColumn('movementAmount', {
        headerName: t('movement.amount'),
        minWidth: 100,
        type: 'number',
        renderCell: (params: any) => {
          return (
            <TextField
              InputProps={{ inputProps: { min: 0, autoComplete: 'new-password' } }}
              style={{ background: '#ffffe6' }}
              fullWidth
              type="number"
              onFocus={(event) => {
                event.target.select();
              }}
              value={params.row?.movementAmount ?? currentChangeAmount ?? 0}
              onChange={(e) => {
                let parsedNumber = Math.abs(Number.parseInt(e.target.value));
                params.row.movementAmount = parsedNumber;
                setCurrentChangeAmount(parsedNumber);
              }}
            />
          );
        }
      }),
      createColumn('movementFromWarehouse', {
        headerName: t('movement.fromWarehouse'),
        minWidth: 200,
        editable: false,
        renderCell: (params: any) => (
          <Select
            style={{ background: '#ffffe6' }}
            fullWidth
            value={params.row?.movementFromWarehouse?.id ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              let warehouse = params.row.warehouses?.find(
                (warehouse: any) => warehouse.warehouse?.id == e.target.value
              );
              if (warehouse?.warehouse != undefined) {
                params.row.movementFromWarehouse = warehouse.warehouse;
              }
            }}>
            {params.row.warehouses?.map((warehouse: Partial<EquipmentWarehouse>) => (
              <MenuItem key={warehouse.warehouse!.id} value={warehouse.warehouse!.id}>
                <Chip size="small" label={warehouse.amount ?? 0} style={{ marginRight: 10 }} />
                {warehouse.warehouse!.displayName}
              </MenuItem>
            ))}
          </Select>
        )
      }),
      createColumn('from', {
        type: 'date',
        minWidth: 100,
        editable: false,
        renderCell: (params: any) => (
          <div>
            <DatePicker
              onOpen={() => setCurrentChange('')}
              label={t('startdate')}
              value={params.row.from ?? currentChange}
              mask="__.__.____"
              onChange={(value: any) => {
                let date = moment(value).startOf('day').toDate();
                params.row.from = date;
                setCurrentChange(value);
                if (date > params.row.to!) params.row.to = moment(date).endOf('day').toDate();
              }}
              renderInput={(params) => (
                <TextField {...params} required style={{ marginBottom: 10, marginRight: 10 }} />
              )}
            />
          </div>
        )
      }),
      createColumn('to', {
        type: 'date',
        minWidth: 100,
        editable: false,
        renderCell: (params: any) => (
          <div style={{ background: '#ffffe6' }}>
            <DatePicker
              onOpen={() => setCurrentChange('')}
              label={t('enddate')}
              value={params.row.to ?? currentChange}
              mask="__.__.____"
              onChange={(value: any) => {
                let date = moment(value).endOf('day').toDate();
                params.row.to = date;
                setCurrentChange(value);
                if (date < params.row.from!) params.row.from = moment(date).startOf('day').toDate();
              }}
              renderInput={(params) => (
                <TextField {...params} required style={{ marginBottom: 10, marginRight: 10 }} />
              )}
            />
          </div>
        )
      }),
      createColumn('actions', {
        headerName: '',
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        renderCell: (params: any) => {
          const onDeleteItem = () =>
            setEquipments((prevState) =>
              prevState.filter((equipment) => equipment.reference != params.row.reference)
            );
          return (
            <DeleteDialog
              isSelection
              deleteItem={onDeleteItem}
              type={t('type')}
              name={params.row.name}
            />
          );
        }
      })
    ];
    setColumns(tempColumns);
  }, []);

  const bookOrReserveEquipments = async () => {
    let projectId = selectedProject?.id;
    if (projectId == null) return;
    let toReserveEquipments = equipments.map((equipment) => {
      return {
        equipment: equipment,
        warehouse: equipment.movementFromWarehouse,
        supplier: equipment.movementWithSupplier,
        amount: equipment.movementAmount,
        from: equipment.from,
        to: equipment.to
      };
    });
    await projectService
      .createEquipmentReservations(projectId, toReserveEquipments)
      .then(() => enqueueSnackbar(t('movement.project.reservationSuccess'), { variant: 'success' }))
      .catch(() => enqueueSnackbar(t('movement.project.reservationFailure'), { variant: 'error' }))
      .finally(() => navigate('/equipments'));
  };

  // we need this because mui doesnt update cells by them self, only the copy
  const handleCommit = (e: GridCellEditCommitParams) => {
    const array = equipments.map((i: any) => {
      if (i.id === e.id) return { ...i, [e.field]: e.value };
      else return { ...i };
    });
    setEquipments(array);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        height: '100%'
      }}>
      <Paper style={{ display: 'flex', flexDirection: 'column', width: '100%' }} elevation={0}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none'
            }
          }}
          pagination
          pageSize={15}
          onCellEditCommit={handleCommit}
          style={{ height: '92%' }}
          rows={equipments}
          columns={columns}
          disableSelectionOnClick
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: '#eee'
          }}>
          <div>
            <FormControl size="small" style={{ width: 200, margin: 10 }}>
              <InputLabel>{t('movement.project.project')}</InputLabel>
              <Select
                label={t('movement.project.project')}
                style={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  e.stopPropagation();
                  let projectId = e.target.value;
                  if (projectId == null) return;
                  setSelectedProject(projects.find((project) => project.id == projectId) ?? null);
                }}
                value={selectedProject?.id ?? ''}>
                {projects?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button onClick={bookOrReserveEquipments} style={{ margin: 10 }} variant="contained">
            {t('movement.project.reserve')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
