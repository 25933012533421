import { Button, Typography } from '@mui/material';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { changeService } from '../services/api';
import RefreshIcon from '@mui/icons-material/Refresh';

interface ChangeIndicatorProps {
  changeAmount: number;
  type: string;
  setChangeAmount: Function;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function ChangeIndicator(props: ChangeIndicatorProps) {
  const changeAmountRef = useRef<number>(0);

  useEffect(() => {
    changeAmountRef.current = props.changeAmount;
  }, [props.changeAmount]);

  useEffect(() => {
    return changeService.get(props.type).onChange(() => {
      props.setChangeAmount(changeAmountRef.current + 1);
    });
  }, []);

  if (props.changeAmount > 0)
    return (
      <Button
        style={{ position: 'relative', margin: 5 }}
        startIcon={<RefreshIcon />}
        onClick={props.onClick}>
        <Typography>{props.changeAmount > 99 ? '99+' : props.changeAmount}</Typography>
      </Button>
    );
  else return <></>;
}
