import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function UpdateWarehouse(props: any) {
  const { t } = useTranslation('warehouses');
  const [warehouse, setWarehouse] = useState(props.warehouse);

  useEffect(() => {
    props.handleChange(warehouse);
  }, [warehouse]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        <TextField
          required
          style={{ marginBottom: 10, marginRight: 10 }}
          error={props.error}
          label={t('name')}
          variant="outlined"
          value={warehouse.name ?? ''}
          onChange={(e) => setWarehouse({ ...warehouse, ...{ name: e.target.value } })}
          fullWidth
        />
      </div>
    </Paper>
  );
}
