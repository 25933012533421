import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import useLocales from '../hooks/useLocales';
import { useAppSelector } from '../redux/hooks';

// eslint-disable-next-line react/prop-types
export default function LocalesProvider({ children }) {
  const language = useAppSelector((state) => state.language.language);
  const { changeLocale } = useLocales();
  const { i18n } = useTranslation();

  //TODO: this is dirty, find better solution, this can only be used for deDE, enEN miror type languages
  changeLocale(i18n.language + i18n.language.toUpperCase());

  const themeWithLocale = createTheme(
    {
      palette: {
        type: 'light',
        primary: {
          main: '#a71d31', //complementary: #1da792
          dark: '#70101a'
        },
        secondary: {
          main: '#f50057'
        },
        background: {
          default: '#f6f6f6'
        }
      }
    },
    locales[language]
  );

  return <ThemeProvider theme={themeWithLocale}>{children}</ThemeProvider>;
}
