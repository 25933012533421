import { Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Role from '../../objects/role';
import TransferList from '../Helper/TransferList';

interface UpdateRoleProps {
  role: Partial<Role>;
  rights: string[];
  handleChange: Function;
  error: boolean;
}

export default function UpdateRole(props: UpdateRoleProps) {
  const { t } = useTranslation('roles');

  const [role, setRole] = useState<Partial<Role>>({});
  const [rights, setRights] = useState<string[]>([]);

  useEffect(() => {
    setRole(props.role);
    setRights(props.rights);
  }, []);

  useEffect(() => {
    props.handleChange(role);
  }, [role]);

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <TextField
        required
        error={props.error}
        style={{ marginBottom: 10 }}
        label={t('name')}
        variant="outlined"
        value={role.name ?? ''}
        onChange={(e) => {
          e.stopPropagation();
          setRole({ ...role, ...{ name: e.target.value } });
        }}
        fullWidth
      />
      <TransferList
        leftArray={rights}
        rightArray={role.rights!}
        changeSelection={(rights: string[]) => {
          setRole({ ...role, ...{ rights: rights } });
        }}
      />
    </Paper>
  );
}
