/* eslint-disable no-unused-vars */
import { Paper, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import Article from '../../objects/article';
import moment from 'moment';
import analysisService from '../../services/analysisService';
import ArticleBookingAnalysis from '../../objects/articleBookingAnalysis';
import TimeSeriesWithPrognosisChart from '../../components/TimeSeriesWithPrognosisChart';
import { useTranslation } from 'react-i18next';

interface ArticleBookingTrendProps {
  article: Partial<Article> | undefined;
}

export default function ArticleBookingTrend(props: ArticleBookingTrendProps) {
  const [articleBookingAnalysis, setArticleBookingAnalysis] = useState<ArticleBookingAnalysis>();
  const { article } = props;
  const { t } = useTranslation(['articles']);

  useEffect(() => {
    const dateNow = new Date();
    let start = moment.utc(dateNow).startOf('day').subtract(30, 'days').toDate();
    let end = moment.utc(dateNow).endOf('day').toDate();
    let cumulation = '01:00:00:00';

    analysisService
      .getArticleBookingAnalysis(article?.id!, start, end, cumulation)
      .then((result) => setArticleBookingAnalysis(result));
  }, []);
  return (
    <>
      <Paper
        style={{
          height: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        {!articleBookingAnalysis && <Skeleton variant="rectangular" width={'100%'} height={500} />}
        {articleBookingAnalysis && (
          <TimeSeriesWithPrognosisChart
            cumulation
            cumulationOffset={articleBookingAnalysis.currentAmount}
            data={articleBookingAnalysis.points}
            prognosis={articleBookingAnalysis.prognosisPoints}
            getDate={(point) => new Date(point.start)}
            getPositiveValue={(point) => point.receiptAmount}
            getNegativeValue={(point) => point.issuedAmount}
            legend={{
              label: t('bookingTrend.amount'),
              prognosisLabel: t('bookingTrend.amountPrognosis'),
              cumulationLabel: t('bookingTrend.cumulation')
            }}
          />
        )}
      </Paper>
    </>
  );
}
