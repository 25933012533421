import { FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import CreateDialog from '../../Helper/MyDialog';
import tenantService from '../../../services/system/tenantService';

export interface RequestSupportAccessProps {
  tenantName: string;
}

export default function RequestSupportAccess(props: RequestSupportAccessProps) {
  const [executiveLogins, setExecutiveLogins] = useState<string[]>([]);
  const [executiveLogin, setExecutiveLogin] = useState<string>();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['appointments', 'app']);

  async function sendRequest() {
    if (executiveLogin) {
      try {
        await tenantService.requestSupport(props.tenantName, executiveLogin!).then(() => {
          enqueueSnackbar(t('app:mailRequestSuccessfull'), { variant: 'success' });
        });
      } catch {
        enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' });
      }
      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  useEffect(() => {
    tenantService.getExecutives(props.tenantName).then((result) => setExecutiveLogins(result.data));
    setError(false);
  }, [props.tenantName]);

  return (
    <CreateDialog
      isEdit
      noBackdrop
      showButton
      handleBtnTitle={t('sendMail', { ns: 'app' })}
      title={t('requestSupport', { ns: 'app' })}
      saveItem={sendRequest}
      open={open}
      setOpen={setOpen}
      withActions
      iconName="SupportAgent">
      <Paper
        style={{
          height: '100%',
          maxWidth: 380,
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        elevation={0}>
        <div>
          <Typography>{t('supportHelperText', { ns: 'app' })}</Typography>
          <FormControl style={{ marginBottom: 10, flex: 1, marginTop: 20 }} fullWidth required>
            <InputLabel>{t('allExecutives', { ns: 'app' })}</InputLabel>
            <Select
              required
              label={t('allExecutives', { ns: 'app' })}
              error={error}
              value={executiveLogin}
              onChange={(e) => setExecutiveLogin(e.target.value)}>
              {executiveLogins!.map((executiveLogin) => (
                <MenuItem key={executiveLogin} value={executiveLogin}>
                  {executiveLogin}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Paper>
    </CreateDialog>
  );
}
