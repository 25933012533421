import { Disposition } from '../pages/disposition/DispositionChartProps';
import instance from './api';

const employeeDispositionService = {
  get: async (start: Date, end: Date): Promise<Partial<Disposition>> =>
    (
      await instance.get('employee-disposition', {
        params: {
          start: start.toISOString(),
          end: end.toISOString()
        }
      })
    ).data,
  delete: async (category: string, id: string): Promise<any> =>
    await instance.delete('employee-disposition/actions/delete/' + id, {
      params: {
        category: category
      }
    }),
  updateTime: async (category: string, id: string, start: Date, end: Date): Promise<any> =>
    await instance.delete('employee-disposition/actions/update-time/' + id, {
      params: {
        category: category
      },
      data: {
        start: start.toISOString(),
        end: end.toISOString()
      }
    })
};

const equipmentDispositionService = {
  get: async (start: Date, end: Date): Promise<Partial<Disposition>> =>
    (
      await instance.get('equipment-disposition', {
        params: {
          start: start.toISOString(),
          end: end.toISOString()
        }
      })
    ).data,
  delete: async (category: string, id: string): Promise<any> =>
    await instance.delete('equipment-disposition/actions/delete/' + id, {
      params: {
        category: category
      }
    }),
  updateTime: async (category: string, id: string, start: Date, end: Date): Promise<any> =>
    await instance.delete('equipment-disposition/actions/update-time/' + id, {
      params: {
        category: category
      },
      data: {
        start: start.toISOString(),
        end: end.toISOString()
      }
    })
};

const projectDispositionService = {
  get: async (start: Date, end: Date): Promise<Partial<Disposition>> =>
    (
      await instance.get('project-disposition', {
        params: {
          start: start.toISOString(),
          end: end.toISOString()
        }
      })
    ).data,
  delete: async (category: string, id: string): Promise<any> =>
    await instance.delete('project-disposition/actions/delete/' + id, {
      params: {
        category: category
      }
    }),
  updateTime: async (category: string, id: string, start: Date, end: Date): Promise<any> =>
    await instance.delete('project-disposition/actions/update-time/' + id, {
      params: {
        category: category
      },
      data: {
        start: start.toISOString(),
        end: end.toISOString()
      }
    })
};

const dispositionService = {
  get: async (start: Date, end: Date): Promise<Partial<Disposition>> =>
    (
      await instance.get('disposition', {
        params: {
          start: start.toISOString(),
          end: end.toISOString()
        }
      })
    ).data,
  delete: async (category: string, id: string): Promise<any> =>
    await instance.delete('disposition/actions/delete/' + id, {
      params: {
        category: category
      }
    }),
  updateTime: async (category: string, id: string, start: Date, end: Date): Promise<any> =>
    await instance.delete('disposition/actions/update-time/' + id, {
      params: {
        category: category
      },
      data: {
        start: start.toISOString(),
        end: end.toISOString()
      }
    })
};

export {
  employeeDispositionService,
  equipmentDispositionService,
  projectDispositionService,
  dispositionService
};
