import { TextField, Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeIndicator from '../../components/ChangeIndicator';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';

import TokenService from '../../services/tokenService';
import SearchIcon from '@mui/icons-material/Search';

import { ItemState } from '../../services/api';
import { useSnackbar } from 'notistack';
import CreateExternalService from '../../components/externalServices/CreateExternalService';
import externalServiceService from '../../services/externalServiceService';

interface CustomToolbarProps {
  changeAmount: number | undefined;
  setChangeAmount(input: number): void;
  fetchData(): void;
  customersState: ItemState;
  setCustomersState(input: ItemState): void;
}

const CustomToolbar = (props: CustomToolbarProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { changeAmount, setChangeAmount, fetchData, customersState, setCustomersState } = props;
  const { t } = useTranslation(['externalServices', 'app', 'customToolBar']);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [beforeSearchState, setBeforeSearchState] = useState<ItemState>();

  const canUpdateExternalService = TokenService.tenantFullfills(
    ['Disposition'],
    ['ExternalServices:Update']
  );

  useEffect(() => {
    if (searchText == null) setBeforeSearchState(customersState);
  }, [customersState]);

  function handleSearch() {
    if (customersState != null && searchText != null) {
      customersState.filterType = 'or';
      customersState.filter.set('city', searchText);
      customersState.filter.set('name', searchText);
      setCustomersState({ ...customersState });
      setIsSearching(false);
    } else if (isSearching) setCustomersState(beforeSearchState!);
  }

  return (
    <GridToolbarContainer>
      {isSearching ? (
        <div style={{ margin: 5, display: 'flex', flex: 1 }}>
          <TextField
            size="small"
            label={t('search')}
            style={{ flex: 5 }}
            variant="outlined"
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && handleSearch()}
            autoFocus
            spellCheck={false}
          />
          <Button
            variant="contained"
            style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
            onClick={handleSearch}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <Button size="small" onClick={() => setIsSearching(false)}>
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <>
          {canUpdateExternalService && (
            <ItemDialog
              withText
              kind={ItemDialogKind.Create}
              type={t('type')}
              validate={externalServiceService.validate}
              confirm={(item) => {
                externalServiceService
                  .create(item)
                  .then(() => fetchData())
                  .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
              }}>
              {(item, handleChange, validation) => (
                <CreateExternalService
                  externalService={item}
                  handleChange={handleChange}
                  error={validation}
                />
              )}
            </ItemDialog>
          )}
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Button size="small" startIcon={<SearchIcon />} onClick={() => setIsSearching(true)}>
            {t('search', { ns: 'customToolBar' })}
          </Button>

          <ChangeIndicator
            changeAmount={changeAmount ?? 0}
            setChangeAmount={setChangeAmount}
            type={'externalService'}
            onClick={fetchData}
          />
        </>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
