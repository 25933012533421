import { Avatar, Paper, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import avatarService from '../services/avatarService';
import UpdatePassword from '../components/employees/UpdatePassword';
import UpdateLogin from '../components/employees/UpdateLogin';

export default function Account() {
  const [employee, setEmployee] = useState<any>();

  useEffect(() => {
    let sessionUser = JSON.parse(localStorage.getItem('user')!);
    setEmployee(sessionUser.employee);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        height: '100%'
      }}>
      <Paper
        style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%', padding: 10 }}
        elevation={0}
        variant="outlined">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 10,
            gap: 10,
            alignItems: 'center'
          }}>
          <Avatar {...avatarService.stringAvatar(employee?.firstName + ' ' + employee?.lastName)} />
          <h3>{employee?.firstName + ' ' + employee?.lastName}</h3>
        </div>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'start',
            width: 400
          }}>
          <UpdateLogin employee={employee} />
          <UpdatePassword employee={employee} />
        </div>
      </Paper>
    </div>
  );
}
