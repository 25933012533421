import { TextField, Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeIndicator from '../../components/ChangeIndicator';
import ItemDialog, { ItemDialogKind } from '../../components/Helper/ItemDialog';

import TokenService from '../../services/tokenService';
import SearchIcon from '@mui/icons-material/Search';

import { ItemState } from '../../services/api';
import { useSnackbar } from 'notistack';
import employeeService from '../../services/employeeService';
import CreateEmployee from '../../components/employees/CreateEmployee';
import { DesktopWindows, PhoneAndroid } from '@mui/icons-material';
import Tenant from '../../objects/system/tenant';
import tenantService from '../../services/system/tenantService';
import Employee from '../../objects/employee';

interface CustomToolbarProps {
  employees: Employee[];
  changeAmount: number | undefined;
  setChangeAmount(input: number): void;
  fetchData(): void;
  employeesState: ItemState;
  setEmployeesState(input: ItemState): void;
}

const CustomToolbar = (props: CustomToolbarProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { employees, changeAmount, setChangeAmount, fetchData, employeesState, setEmployeesState } =
    props;
  const { t } = useTranslation(['employees', 'app', 'customToolBar']);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [beforeSearchState, setBeforeSearchState] = useState<ItemState>();

  const [userCount, setUserCount] = useState(0);
  const [appUserCount, setAppUserCount] = useState(0);
  const [tenant, setTenant] = useState<Partial<Tenant>>({});

  const canCreateEmployees = TokenService.tenantFullfills([], ['Employees:Create']);
  const canCreateUsers = TokenService.tenantFullfills([], ['Users:Update']);

  useEffect(() => {
    if (searchText == null) setBeforeSearchState(employeesState);
  }, [employeesState]);

  useEffect(() => {
    let [userAmount, appUserAmount] = [0, 0];
    employees.forEach((employee: Employee) => {
      userAmount += employee?.user ? 1 : 0;
      appUserAmount += employee?.user?.appUser ? 1 : 0;
    });

    setUserCount(userAmount);
    setAppUserCount(appUserAmount);
  }, [employees]);

  useEffect(() => {
    // get tenant information for displaying remaining user amount
    tenantService.getInformation().then((result) => setTenant(result.data));
  }, []);

  function handleSearch() {
    if (employeesState != null && searchText != null) {
      employeesState.filterType = 'or';
      employeesState.filter.set('reference', searchText);
      employeesState.filter.set('firstName', searchText);
      employeesState.filter.set('lastName', searchText);
      employeesState.filter.set('name', searchText);
      employeesState.filter.set('email', searchText);
      setEmployeesState({ ...employeesState });
      setIsSearching(false);
    } else if (isSearching) setEmployeesState(beforeSearchState!);
  }

  return (
    <GridToolbarContainer>
      {isSearching ? (
        <div style={{ margin: 5, display: 'flex', flex: 1 }}>
          <TextField
            size="small"
            label={t('search')}
            style={{ flex: 5 }}
            variant="outlined"
            value={searchText ?? ''}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && handleSearch()}
            autoFocus
            spellCheck={false}
          />
          <Button
            variant="contained"
            style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
            onClick={handleSearch}>
            {t('search', { ns: 'customToolBar' })}
          </Button>
          <Button size="small" onClick={() => setIsSearching(false)}>
            {t('cancel')}
          </Button>
        </div>
      ) : (
        <>
          {canCreateEmployees && (
            <ItemDialog
              withText
              kind={ItemDialogKind.Create}
              type={t('type')}
              initial={{ employmentDate: new Date() }}
              validate={employeeService.validate}
              confirm={(item) => {
                employeeService
                  .create(item)
                  .then(() => fetchData())
                  .catch(() => enqueueSnackbar(t('app:failedProcedure'), { variant: 'error' }));
              }}>
              {(item, handleChange, validation) => (
                <CreateEmployee
                  canCreateUsers={canCreateUsers}
                  handleChange={handleChange}
                  error={validation}
                />
              )}
            </ItemDialog>
          )}
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Button size="small" startIcon={<SearchIcon />} onClick={() => setIsSearching(true)}>
            {t('search', { ns: 'customToolBar' })}
          </Button>

          <ChangeIndicator
            changeAmount={changeAmount ?? 0}
            setChangeAmount={setChangeAmount}
            type={'employee'}
            onClick={fetchData}
          />
          {tenant && (
            <div
              style={{
                flexGrow: 1,
                margin: '5px 10px',
                display: 'flex',
                flexDirection: 'row'
              }}>
              <>
                <p style={{ textAlign: 'end', flex: 1, fontSize: 18, margin: 0 }}>
                  {userCount}/{tenant.userLimit}
                </p>
                <DesktopWindows
                  fontSize="small"
                  style={{ margin: '0 20px 0 10px', alignSelf: 'center' }}
                />
                <p style={{ textAlign: 'end', fontSize: 18, margin: 0 }}>
                  {appUserCount}/{tenant.appUserLimit}
                </p>
                <PhoneAndroid
                  fontSize="small"
                  style={{ margin: '0 0px 0 5px', alignSelf: 'center' }}
                />
              </>
            </div>
          )}
        </>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
