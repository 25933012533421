import {
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField
} from '@mui/material';
import { validate } from 'email-validator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tenant from '../../../objects/system/tenant';

interface CreateTenantProps {
  modules: string[];
  tenant: Partial<Tenant>;
  handleChange: Function;
  error: boolean;
}

export default function CreateTenant(props: CreateTenantProps) {
  const { t } = useTranslation(['tenants', 'employees']);
  const [tenant, setTenant] = useState(props.tenant);

  useEffect(() => {
    props.handleChange(tenant);
  }, [tenant]);

  //TODO: clear user input

  return (
    <Paper
      style={{
        height: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      elevation={0}>
      <div>
        {/* TODO: get all template names and check if this one exists? */}
        <TextField
          error={
            props.error &&
            tenant?.template != 'workshop' &&
            tenant?.template?.length != 0 &&
            tenant?.template != undefined
          }
          helperText={
            props.error &&
            tenant?.template != 'workshop' &&
            tenant?.template?.length != 0 &&
            tenant?.template != undefined &&
            t('wrongTenant')
          }
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('template')}
          variant="outlined"
          value={tenant?.template ?? ''}
          onChange={(e) => setTenant({ ...tenant, ...{ template: e.target.value } })}
          fullWidth
        />
        <TextField
          required
          error={props.error && !tenant?.name}
          style={{ marginBottom: 10, marginRight: 10 }}
          label={t('name')}
          variant="outlined"
          value={tenant?.name ?? ''}
          onChange={(e) => setTenant({ ...tenant, ...{ name: e.target.value.toLowerCase() } })}
          inputProps={{
            autoCapitalize: 'none'
          }}
          fullWidth
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && (!tenant?.userLimit || tenant?.userLimit == 0)}
            style={{ marginBottom: 10, marginRight: 10, width: 100 }}
            label={t('userLimit')}
            variant="outlined"
            value={tenant?.userLimit ?? 0}
            onChange={(e) => {
              let parsedNumber = Number.parseInt(e.target.value);
              let userLimit = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
              setTenant({ ...tenant, ...{ userLimit } });
            }}
          />
          <TextField
            style={{ marginBottom: 10, flex: 1 }}
            label={t('appUserLimit')}
            variant="outlined"
            value={tenant?.appUserLimit ?? 0}
            onChange={(e) => {
              let parsedNumber = Number.parseInt(e.target.value);
              let appUserLimit = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
              setTenant({ ...tenant, ...{ appUserLimit } });
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl
            style={{ marginBottom: 10, flex: 1 }}
            fullWidth
            required
            error={props.error && !tenant?.activeModules}>
            <InputLabel>{t('activeModules')}</InputLabel>
            <Select
              multiple
              value={tenant?.activeModules ?? []}
              onChange={(e) => {
                e.stopPropagation();
                setTenant({ ...tenant, ...{ activeModules: e.target.value as string[] } });
              }}
              input={<OutlinedInput label={t('activeModules')} />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected?.map((value: any, index: any) => (
                    <Chip key={index} label={value} />
                  ))}
                </Box>
              )}>
              {props.modules.map((module: string) => (
                <MenuItem key={module} value={module}>
                  {module}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl style={{ marginBottom: 10, flex: 1 }} fullWidth required>
            <InputLabel>{t('employees:nationality')}</InputLabel>
            <Select
              required
              error={props.error && !tenant?.culture}
              value={tenant?.culture}
              onChange={(e) => {
                setTenant({ ...tenant, ...{ culture: e.target.value } });
              }}
              input={<OutlinedInput label={t('employees:nationality')} />}>
              <MenuItem key={'en-EN'} value={'en-EN'}>
                English
              </MenuItem>
              <MenuItem key={'de-DE'} value={'de-DE'}>
                Deutsch
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && !tenant?.executiveEmployee?.firstName}
            style={{ width: '50%', marginBottom: 10, marginRight: 10 }}
            label={t('employees:firstName')}
            variant="outlined"
            value={tenant?.executiveEmployee?.firstName ?? ''}
            onChange={(e) => {
              setTenant({
                ...tenant,
                ...{
                  executiveEmployee: {
                    ...tenant?.executiveEmployee,
                    ...{ firstName: e.target.value }
                  }
                }
              });
            }}
          />
          <TextField
            required
            error={props.error && !tenant?.executiveEmployee?.lastName}
            style={{ width: '50%', marginBottom: 10 }}
            label={t('employees:lastName')}
            variant="outlined"
            value={tenant?.executiveEmployee?.lastName ?? ''}
            onChange={(e) => {
              setTenant({
                ...tenant,
                ...{
                  executiveEmployee: {
                    ...tenant?.executiveEmployee,
                    ...{ lastName: e.target.value }
                  }
                }
              });
            }}
          />
        </div>
        <TextField
          style={{ marginBottom: 10 }}
          label={t('employees:reference')}
          fullWidth
          variant="outlined"
          value={tenant?.executiveEmployee?.reference ?? ''}
          onChange={(e) => {
            setTenant({
              ...tenant,
              ...{
                executiveEmployee: {
                  ...tenant?.executiveEmployee,
                  ...{ reference: e.target.value }
                }
              }
            });
          }}
        />
        <TextField
          required
          error={
            (!validate(tenant?.executiveEmployee?.email!) &&
              tenant?.executiveEmployee?.email != undefined) ||
            (props.error && !validate(tenant?.executiveEmployee?.email!))
          }
          style={{ marginBottom: 10 }}
          label={t('employees:email')}
          fullWidth
          variant="outlined"
          value={tenant?.executiveEmployee?.email ?? ''}
          onChange={(e) => {
            setTenant({
              ...tenant,
              ...{
                executiveEmployee: {
                  ...tenant?.executiveEmployee,
                  ...{ email: e.target.value }
                }
              }
            });
          }}
        />
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && !tenant?.executiveEmployee?.user?.login}
            style={{ marginBottom: 10 }}
            label={t('employees:user.login')}
            fullWidth
            variant="outlined"
            value={tenant?.executiveEmployee?.user?.login ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              setTenant({
                ...tenant,
                ...{
                  executiveEmployee: {
                    ...tenant?.executiveEmployee,
                    ...{
                      user: {
                        ...tenant?.executiveEmployee?.user,
                        ...{ login: e.target.value }
                      }
                    }
                  }
                }
              });
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            error={props.error && !tenant?.executiveEmployee?.user?.password}
            label={t('employees:user.password')}
            style={{ marginBottom: 10 }}
            fullWidth
            type="password"
            variant="outlined"
            value={tenant?.executiveEmployee?.user?.password ?? ''}
            onChange={(e) => {
              e.stopPropagation();
              setTenant({
                ...tenant,
                ...{
                  executiveEmployee: {
                    ...tenant?.executiveEmployee,
                    ...{
                      user: {
                        ...tenant?.executiveEmployee?.user,
                        ...{ password: e.target.value }
                      }
                    }
                  }
                }
              });
            }}
          />
        </div>
      </div>
    </Paper>
  );
}
