import Warehouse from '../objects/warehouses';
import { defaultService, ItemState } from './api';

const route = 'warehouses';

const warehouseService = {
  validate: (warehouse: any): boolean => warehouse.name?.length > 0,
  get: async (id: string): Promise<Partial<Warehouse>> =>
    (await defaultService.get(route, id)).data,
  create: (role: Partial<Warehouse>) => defaultService.create(route, role),
  update: (id: string, role: Partial<Warehouse>) => defaultService.update(route, id, role),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState, includeProjectWarehouses: boolean = true) => {
    itemState?.filter.set('includeProjectWarehouses', includeProjectWarehouses.toString());
    return defaultService.getAllPaginated<Warehouse>(route, itemState);
  }
};

export default warehouseService;
