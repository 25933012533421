import { Link } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid';
import { NavigateFunction } from 'react-router-dom';

const createColumn: any = (name: string, translate: Function, additional?: any) => {
  var column = {
    filterOperators: getGridStringOperators().filter((operator) => operator.value == 'contains'),
    field: name,
    headerName: translate(name),
    width: 80,
    flex: 1
  };
  return { ...column, ...additional };
};
const renderNavigationCell = (params: any, to: string, navigate: NavigateFunction) => {
  const onClick = async (e: any) => {
    e.stopPropagation();
    navigate('/' + to);
  };

  return (
    <Link component="button" onClick={onClick} underline="none">
      {params.value}
    </Link>
  );
};
const renderNavigationWithPropsCell = (
  params: any,
  to: string,
  getProps: Function,
  navigate: NavigateFunction,
  column: string,
  getState?: Function
) => {
  const onClick = async (e: any) => {
    e.stopPropagation();
    let props = getProps(params.row);
    navigate(
      {
        pathname: '/' + to,
        search: Array.from(Object.entries(props))
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      },
      {
        state: getState == null ? undefined : getState(params.row)
      }
    );
  };

  return (
    <Link component="button" onClick={onClick} underline="none">
      {params.getValue(params.id, column)}
    </Link>
  );
};
const renderDynamicNavigationCell = (
  params: any,
  to: Function,
  getProps: Function,
  navigate: NavigateFunction,
  column: string,
  getState?: Function
) => {
  const onClick = async (e: any) => {
    e.stopPropagation();
    let props = getProps(params.row);
    navigate(
      {
        pathname: '/' + to(params.row),
        search: Array.from(Object.entries(props))
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      },
      {
        state: getState == null ? undefined : getState(params.row)
      }
    );
  };
  return (
    <Link component="button" onClick={onClick} underline="none">
      {params.getValue(params.id, column)}
    </Link>
  );
};
const createNavigationColumn = (
  name: string,
  to: string,
  translate: Function,
  navigate: NavigateFunction,
  additional?: any
) => {
  var navigationColumn = createColumn(name, translate, {
    renderCell: (params: any) => renderNavigationCell(params, to, navigate)
  });
  return { ...navigationColumn, ...additional };
};
const createNavigationWithPropsColumn = (
  name: string,
  to: string,
  translate: Function,
  getProps: Function,
  navigate: NavigateFunction,
  additional?: any,
  getState?: Function
) => {
  var navigationColumn = createColumn(name, translate, {
    renderCell: (params: any) =>
      renderNavigationWithPropsCell(params, to, getProps, navigate, name, getState)
  });
  return { ...navigationColumn, ...additional };
};
const createDynamicNavigationColumn = (
  name: string,
  to: Function,
  translate: Function,
  getProps: Function,
  navigate: NavigateFunction,
  additional?: any,
  getState?: Function
) => {
  var navigationColumn = createColumn(name, translate, {
    renderCell: (params: any) =>
      renderDynamicNavigationCell(params, to, getProps, navigate, name, getState)
  });
  return { ...navigationColumn, ...additional };
};

const create = (translate: Function, navigate: NavigateFunction) => {
  return {
    createColumn: (name: string, additional?: any) => createColumn(name, translate, additional),
    createNavigationColumn: (name: string, to: string, additional?: any) =>
      createNavigationColumn(name, to, translate, navigate, additional),
    createNavigationWithPropsColumn: (
      name: string,
      to: string,
      getProps: Function,
      additional?: any,
      getState?: Function
    ) =>
      createNavigationWithPropsColumn(
        name,
        to,
        translate,
        getProps,
        navigate,
        additional,
        getState
      ),
    createDynamicNavigationWithPropsColumn: (
      name: string,
      getTo: Function,
      getProps: Function,
      additional?: any,
      getState?: Function
    ) =>
      createDynamicNavigationColumn(
        name,
        getTo,
        translate,
        getProps,
        navigate,
        additional,
        getState
      )
  };
};

const navigationService = {
  create
};

export default navigationService;
