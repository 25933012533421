import Paginated from '../objects/paginated';
import {
  BookingCosts,
  EmployeeCosts,
  EquipmentCosts,
  ExternalServiceCosts,
  ProjectArticleBooking,
  ProjectCosts,
  ProjectEquipmentBooking,
  ProjectEquipmentReservation
} from '../objects/project';
import { ProjectArticleReservation } from '../objects/project';
import { Project } from '../objects/project';
import instance, { defaultService, ItemState } from './api';

const makeProjectArticleBookingModel = (projectArticleBooking: Partial<ProjectArticleBooking>) => {
  return {
    articleId: projectArticleBooking.article?.id,
    warehouseId: projectArticleBooking.warehouse?.id,
    supplierId: projectArticleBooking.supplier?.id,
    amount: projectArticleBooking.amount
  };
};
const makeProjectEquipmentBookingModel = (
  projectEquipmentBooking: Partial<ProjectEquipmentBooking>
) => {
  return {
    equipmentId: projectEquipmentBooking.equipment?.id,
    warehouseId: projectEquipmentBooking.warehouse?.id,
    amount: projectEquipmentBooking.amount
  };
};
const makeProjectArticleReservationModel = (
  projectArticleReservation: Partial<ProjectArticleReservation>
) => {
  return {
    articleId: projectArticleReservation.article?.id,
    warehouseId: projectArticleReservation.warehouse?.id,
    supplierId: projectArticleReservation.supplier?.id,
    amount: projectArticleReservation.amount,
    for: projectArticleReservation.for
  };
};
const makeProjectEquipmentReservationModel = (
  projectArticleReservation: Partial<ProjectEquipmentReservation>
) => {
  return {
    equipmentId: projectArticleReservation.equipment?.id,
    warehouseId: projectArticleReservation.warehouse?.id,
    supplierId: projectArticleReservation.supplier?.id,
    amount: projectArticleReservation.amount,
    from: projectArticleReservation.from,
    to: projectArticleReservation.to
  };
};

const makeCreateProjectModel = (project: Partial<Project>) => {
  return {
    name: project.name,
    reference: project.reference,
    customerId: project.customer?.id,
    responsibleId: project.responsible?.id,
    state: project.state,
    budget: project.budget
  };
};

const route = 'projects';

const projectService = {
  validate: (project: any): boolean =>
    project?.name?.length > 0 &&
    project?.customer?.id?.length > 0 &&
    project?.responsible?.id?.length,
  validateUpdate: (project: any): boolean =>
    project?.name?.length > 0 && project?.customerId?.length > 0 && project?.responsibleId?.length,
  get: async (id: string): Promise<Partial<Project>> => (await defaultService.get(route, id)).data,
  create: (project: Partial<Project>) =>
    defaultService.create(route, makeCreateProjectModel(project)),
  update: (id: string, project: Partial<Project>) => defaultService.update(route, id, project),
  delete: (id: string) => defaultService.delete(route, id),
  all: (itemState?: ItemState) => defaultService.getAllPaginated<Project>(route, itemState),
  createArticleBookings: (projectId: string, articleBookings: Partial<ProjectArticleBooking>[]) =>
    defaultService.create(
      `${route}/${projectId}/article-bookings/create`,
      articleBookings.map(makeProjectArticleBookingModel)
    ),
  updateArticleBookings: (projectId: string, articleBookings: Partial<ProjectArticleBooking>[]) =>
    defaultService.update(
      `${route}/${projectId}/article-bookings/update`,
      projectId,
      articleBookings.map(makeProjectArticleBookingModel)
    ),
  createEquipmentBookings: (
    projectId: string,
    equipmentBookings: Partial<ProjectEquipmentBooking>[]
  ) =>
    defaultService.create(
      `${route}/${projectId}/equipment-bookings/create`,
      equipmentBookings.map(makeProjectEquipmentBookingModel)
    ),
  allArticleBookings: (projectId: string): Promise<Paginated<Partial<ProjectArticleBooking>>> =>
    defaultService.getAllPaginated<Partial<ProjectArticleBooking>>(
      `${route}/${projectId}/article-bookings`
    ),
  allEquipmentBookings: (projectId: string): Promise<Paginated<Partial<ProjectEquipmentBooking>>> =>
    defaultService.getAllPaginated<Partial<ProjectEquipmentBooking>>(
      `${route}/${projectId}/equipment-bookings`
    ),
  allEquipmentReservations: (
    projectId: string
  ): Promise<Paginated<Partial<ProjectEquipmentReservation>>> =>
    defaultService.getAllPaginated<Partial<ProjectEquipmentReservation>>(
      `${route}/${projectId}/equipment-reservations`
    ),
  deleteArticleBooking: (projectId: string, id: string) =>
    defaultService.delete(`${route}/${projectId}/article-bookings`, id),
  deleteEquipmentBooking: (projectId: string, id: string) =>
    defaultService.delete(`${route}/${projectId}/equipment-bookings`, id),
  createArticleReservations: (
    projectId: string,
    articleReservations: Partial<ProjectArticleReservation>[]
  ) =>
    defaultService.create(
      `${route}/${projectId}/article-reservations`,
      articleReservations.map(makeProjectArticleReservationModel)
    ),
  updateArticleReservations: (projectId: string, articleId: string, data: any) =>
    defaultService.update(`${route}/${projectId}/article-reservations`, articleId, data),
  createEquipmentReservations: (
    projectId: string,
    articleReservations: Partial<ProjectArticleReservation>[]
  ) =>
    defaultService.create(
      `${route}/${projectId}/equipment-reservations`,
      articleReservations.map(makeProjectEquipmentReservationModel)
    ),
  updateEquipmentReservations: (projectId: string, equipmentId: string, data: any) =>
    defaultService.update(`${route}/${projectId}/equipment-reservations`, equipmentId, data),
  allArticleReservations: (
    projectId: string
  ): Promise<Paginated<Partial<ProjectArticleReservation>>> =>
    defaultService.getAllPaginated<Partial<ProjectArticleReservation>>(
      `${route}/${projectId}/article-reservations`
    ),
  deleteArticleReservation: (projectId: string, id: string) =>
    defaultService.delete(`${route}/${projectId}/article-reservations`, id),
  deleteEquipmentReservation: (projectId: string, id: string) =>
    defaultService.delete(`${route}/${projectId}/equipment-reservations`, id),

  cumulateCosts: async (projectId: string): Promise<ProjectCosts> =>
    (await instance.get(`${route}/${projectId}/actions/cumulate-costs`)).data,
  cumulateEmployeeCosts: async (projectId: string): Promise<EmployeeCosts> =>
    (await instance.get(`${route}/${projectId}/actions/get-employee-costs`)).data,
  cumulateEquipmentCosts: async (projectId: string): Promise<EquipmentCosts> =>
    (await instance.get(`${route}/${projectId}/actions/get-equipment-costs`)).data,
  cumulateExternalServiceCosts: async (projectId: string): Promise<ExternalServiceCosts> =>
    (await instance.get(`${route}/${projectId}/actions/get-external-service-costs`)).data,
  cumulateBookingCosts: async (projectId: string): Promise<BookingCosts> =>
    (await instance.get(`${route}/${projectId}/actions/get-booking-costs`)).data,
  updateState: async (projectId: string, projectState: number) =>
    (await instance.post(`${route}/${projectId}/actions/update-state`, projectState)).data,
  moveEquipmentReservationToProject: async (projectId: string, equipmentReservationId: string) =>
    (
      await instance.post(
        `${route}/${projectId}/actions/move-equipment-reservation-to-project`,
        equipmentReservationId
      )
    ).data
};

export default projectService;
