import { AppBar, Toolbar, Typography } from '@mui/material';

export default function Privacy() {
  return (
    <div style={{ padding: 20 }}>
      <AppBar
        position="fixed"
        style={{
          backgroundImage: 'linear-gradient(315deg, #70101a 0%, #a71d31 70%)',
          boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 25%)'
        }}>
        <Toolbar>
          <Typography variant="h6" noWrap color="#fff">
            E.Work
          </Typography>
        </Toolbar>
      </AppBar>
      <h2 style={{ marginTop: 60 }}>
        Geschäftsbedingungen der Jostec Systems UG (haftungsbeschränkt) für die Überlassung der
        Jostec Systems e.work Software (Stand 16.04.2022)
      </h2>
      <div>
        <p>
          Diese Geschäftsbedingungen gelten für die Überlassung des Jostec Systems UG
          (haftungsbeschränkt) e.work (nachfolgend als Software bezeichnet) zwischen dem
          Lizenznehmer und dem Lizenzgeber. Diese Geschäftsbedingungen sowie der
          e.work-Leistungsumfang (nachfolgend Leistungsumfang) gelten für sämtliche Leistungen, die
          der Lizenzgeber oder ein mit ihm verbundenes Unternehmen i.S.d. § 15 AktG im Zusammenhang
          mit der Überlassung der Software erbringt.
        </p>
        <h3>1. Rangfolge</h3>
        <p>1.1. Die vertraglichen Vereinbarungen stehen in folgender Rangfolge:</p>
        <p>• Individuelle Änderungen und/oder Ergänzungen nach Vertragsschluss</p>
        <p>• Lizenzvertrag • diese Geschäftsbedingungen ohne Anlagen</p>
        <p>• die Anlagen zu diesen Geschäftsbedingungen</p>
        <p>
          • Gesetzliche Vorschriften und Normen, beispielsweise ISO-Normen und sonstige allgemein
          gültige Standards
        </p>
        <p>
          Die zuerst genannten Bestimmungen haben bei Widersprüchen stets Vorrang gegenüber den
          zuletzt genannten. Lücken werden durch die jeweils nachrangigen Bestimmungen ausgefüllt.
          Gleiches gilt für Ergänzungen, die in den nachrangigen Bestimmungen enthalten sind. Bei
          Dokumenten der gleichen Stufe hat das jüngere Dokument Vorrang vor dem älteren Dokument.
        </p>
        <p>
          1.2. Andere Allgemeine Geschäfts- Einkaufs- oder Vertragsbedingungen, auf die der
          Lizenznehmer in Erklärungen, namentlich in Aufträgen, Angeboten oder Einladungen zu
          Angeboten hinweist, werden nicht Vertragsinhalt, selbst wenn der Lizenzgeber diesen nicht
          ausdrücklich widerspricht, sie ablehnt oder Leistungen vorbehaltlos annimmt.
        </p>
        <p>
          1.3. Die Geschäftsbedingungen gelten nur gegenüber Unternehmern i.S.v. § 310 Abs. 1 BGB.
        </p>
        <h3>2. Vertragsgegenstand</h3>
        <p>
          2.1. Gegenstand dieser Geschäftsbedingungen ist die auf die Vertragslaufzeit befristete
          Überlassung der Software entsprechend des aktuell gültigen Leistungsumfanges nebst
          Einräumung der zu deren vertragsgemäßen Nutzung erforderlichen Rechte nach Maßgabe von
          Ziff. 3.3. Die Anpassung und Konfiguration/ Implementierung der Software ist nicht
          Gegenstand dieser Geschäftsbedingungen. Diese Leistungen sind unabhängig von den
          vorliegenden Geschäftsbedingungen gesondert zu beauftragen und werden ausschließlich von
          einem zertifiziertem Drittanbieter erbracht, der in keinem rechtlichem oder tatsächlichen
          Verhältnis zum Lizenzgeber steht.
        </p>
        <p>
          2.2. Der Betrieb der Software erfolgt durch den Lizenzgeber als Software as a Service
          (SaaS). Der Lizenzgeber erbringt seine Leistung, indem er dem Lizenznehmer den Zugang zur
          Software ermöglicht. Die geschuldete Beschaffenheit der Software ergibt sich abschließend
          aus dem Leistungsumfang. Die Software wird ohne andere Vereinbarung in der jeweils
          aktuellsten Version zur Verfügung gestellt. Eine darüber hinausgehende Beschaffenheit der
          Software oder sonstige Garantien sind nicht geschuldet, sofern sie nicht ausdrücklich und
          schriftlich vom Lizenzgeber zugesichert wurden.
        </p>
        <p>
          2.3. Der Lizenzgeber übernimmt die systematische Sicherung der Daten. Die Daten werden
          entsprechend bewährter Industriestandards regelmäßig gesichert.
        </p>
        <p>2.4. Das Eigentum an den Daten verbleibt beim Lizenznehmer.</p>
        <p>
          2.5. Der Lizenznehmer hat sich vor Abschluss eines Vertrags mit dem Lizenzgeber über den
          wesentlichen Leistungsumfang der Software informiert.
        </p>
        <h3>3. Kooperation und Pflichten der Parteien</h3>
        <p>
          3.1. Der Lizenznehmer gewährleistet ein dauerndes Systemmanagement der Systemumgebung, in
          der der Lizenzgegenstand läuft. Der Lizenznehmer wird seine Systemumgebung (Hardware und
          Software) laufend warten.
        </p>
        <p>
          3.2. Von dem Lizenzgeber dem Lizenznehmer im Rahmen von Vertragsverhandlungen zur
          Verfügung gestellte Software, Konzepte oder andere Arbeiten sind geistiges Eigentum des
          Lizenzgebers (vgl. Ziff. ‎3.3). Sie dürfen weder vervielfältigt noch Dritten zugänglich
          gemacht werden. Im Übrigen haben diese Bedingungen auch im vorvertraglichen Verhältnis
          Gültigkeit.
        </p>
        <p>
          3.3. Der Lizenzgeber ist berechtigt, beim Lizenznehmer ein Audit über die tatsächliche
          Nutzung der Software vorzunehmen. Er ist insbesondere berechtigt, in der für ihn
          geeigneten Weise Nachweise über die Einhaltung des Lizenzumfangs nach diesen
          Geschäftsbedingungen zu erheben oder vom Lizenznehmer anzufordern. Der Lizenznehmer
          verpflichtet sich zur angemessenen Mitwirkung bei einem Audit im vorstehenden Sinne.
        </p>
        <h3>4. Rechteeinräumung</h3>
        <p>
          4.1. Der Lizenznehmer erhält das nicht ausschließliche, einfache, zeitlich auf die
          Laufzeit des Vertrags beschränkte, nicht übertragbare und nicht unterlizenzierbare Recht,
          die Software zu nutzen. Inhalt, Umfang und Dauer dieses Nutzungsrecht ergeben sich aus dem
          vereinbarten Leistungsumfang.
        </p>
        <p>
          4.2. Sofern dem Lizenznehmer im Rahmen einer Mangelbeseitigung oder Fehlerbehebung der
          Software eine neue Version zur Verfügung gestellt wird, erlöschen seine Rechte an der
          früheren Version.
        </p>
        <p>
          4.3. Die Rechte an den durch den Lizenznehmer gespeicherten, eingegebenen oder
          eingelesenen Daten stehen ausschließlich diesem zu.
        </p>
        <p>
          4.4. Sämtliche Rechte an der Software, insbesondere das Urheberrecht, stehen im Verhältnis
          zum Lizenznehmer allein dem Lizenzgeber und seinen Lizenzgebern zu, auch dann, wenn
          Software oder Teile davon durch Vorgabe oder Mitarbeit des Lizenznehmers entstanden sind.
        </p>
        <p>
          4.5. Ziff. 4.1 gilt entsprechend für alle sonstigen dem Lizenznehmer eventuell im Rahmen
          der Vertragsverhandlungen oder Vertragserfüllungen einschließlich Nacherfüllung,
          Fehlerbehebung und Pflege überlassenen Gegenstände, Unterlagen und Informationen.
        </p>
        <h3>5. Rechtsmängel</h3>
        <p>
          5.1. Der Lizenzgeber gewährleistet, dass die nach diesen Geschäftsbedingungen geschuldeten
          Leistungen frei von Schutzrechten Dritter sind, die die geschuldete Leistung verhindern.
        </p>
        <p>
          5.2. Sollten diese Leistungen Schutzrechte Dritter verletzen, wird der Lizenznehmer den
          Lizenzgeber unverzüglich schriftlich unterrichten und diesem die zur Abwehr erforderlichen
          Informationen und sonstige angemessene Unterstützung zur Verfügung stellen.
        </p>
        <p>
          5.3. Der Lizenzgeber wird auf eigene Kosten und nach seiner Wahl unverzüglich entweder dem
          Lizenznehmer die erforderlichen Nutzungsrechte verschaffen oder die
          vertragsgegenständlichen Leistungen so abändern, dass sie Schutzrechte Dritter nicht mehr
          verletzen, aber weiterhin den vertraglichen Vereinbarungen entsprechen. Im letzten Fall
          wird der Lizenzgeber alle dafür erforderlichen Konvertierungen, Umstellungen, Anpassung
          von Dokumentationen, Schulungen etc. durchführen. Ist der Lizenzgeber nicht in der Lage,
          die erforderlichen Nutzungsrechte zu gewähren oder die vertragsgegenständlichen Leistungen
          entsprechend abzuändern, ist der Lizenznehmer zur sofortigen Kündigung dieses Vertrags
          berechtigt. Das Recht des Lizenznehmers, darüber hinaus gehende Schadensersatzansprüche
          geltend zu machen, bleibt unberührt.
        </p>
        <p>
          5.4. Im Falle der Verletzung von Schutzrechten Dritter wird der Lizenzgeber den
          Lizenznehmer von allen daraus resultierenden Ansprüchen und Schadenersatzforderungen sowie
          von den Kosten der Rechtsverteidigung in angemessener Höhe gegen Nachweis freistellen. Die
          Freistellung steht unter der Voraussetzung, dass der Lizenznehmer nur mit vorheriger
          schriftlicher Zustimmung des Lizenzgebers einen Vergleich über die von dem Dritten geltend
          gemachten Ansprüche schließt oder diese anerkennt.
        </p>
        <h3>6. Zahlungskonditionen</h3>
        <p>
          6.1. Die Vergütung (zuzüglich Umsatzsteuer) ist monatlich über das
          SEPA-Lastschriftverfahren zu entrichten und für den jeweiligen Monat im Voraus am 3.
          Werktag eines jeden Monats fällig. Wird der Vertrag nicht am ersten Tag eines
          Kalendermonates geschlossen, berechnet sich die für den ersten Monat zu entrichtende
          Vergütung nicht anteilig nach den verbleibenden Tagen des Monats, sondern beginnt immer
          mit dem auf die Bereitstellung der Software folgenden Monatsersten. Die Vergütung wird
          erstmalig 30 Tage nach Vertragsunterschrift fällig.
        </p>
        <p>
          6.2. Bleibt die Zahlung auch nach Erhalt der Mahnung aus, ist der Lizenzgeber berechtigt,
          sämtliche Zugänge zu sperren. Die Vergütung für Überlassung und Betrieb der Software ist
          auch bei gesperrten Zugang geschuldet.
        </p>
        <p>
          6.3. Der Lizenzgeber behält sich danach das Recht vor, die Vergütung nach schriftlicher
          Ankündigung (Textform genügt) unter Einhaltung einer Frist von 3 (drei) Monaten zum Ende
          jedes Kalenderhalbjahres unabhängig von der Mindestvertragslaufzeit zu ändern. Eine solche
          Änderung darf die Vergütung des vorausgehenden Zwölf-Monats-Zeitraums um nicht mehr als 5
          (fünf) Prozent überschreiten.
        </p>
        <p>
          6.4. Leistungen außerhalb des vereinbarten Leistungsumfangs oder Vertragsgegenstands sind
          gesondert zu vereinbaren und vom Lizenznehmer gesondert zu vergüten. Es gelten hierfür die
          jeweils anwendbaren Sätze des Lizenzgebers. Das Vorstehende gilt auch für Leistungen
          aufgrund unrichtiger oder unvollständiger Angaben des Lizenznehmers, nicht nachprüfbarer
          Mängelrügen, unsachgemäßen Systemgebrauchs oder Pflichtverletzungen, beispielsweise durch
          fehlerhaften Datenimport. Der Lizenzgeber rechnet die erforderlichen Auslagen und
          Aufwendungen prüffähig zusammen mit den von ihm erbrachten Leistungen zeitnah gesondert
          ab. Bei aufwandsbezogener Abrechnung weist der Lizenzgeber Zahl, Namen, Umfang, Tages-
          oder Stundensätze sowie eine kurze Tätigkeitsbeschreibung der eingesetzten Mitarbeiter
          aus.
        </p>
        <h3>7. Gewährleistung für Software as a Service (SaaS)</h3>
        <p>
          7.1. Der Lizenzgeber gewährleistet die Aufrechterhaltung der vertraglich vereinbarten
          Beschaffenheit gemäß dem Leistungsumfang der Software während der Vertragslaufzeit. Er
          wird Mängel an der Software, die während der Vertragslaufzeit auftreten, nach Maßgabe der
          gesetzlichen Regelungen innerhalb angemessener Zeit beseitigen. Darüber hinaus schuldet
          der Lizenzgeber keine weiteren Leistungen, insbesondere keinen Anwender-Support, keine
          Pflege von Konfigurierungs-Leistungen sowie keine Pflege des Systems. Zum Erhalt der
          vorstehend genannten Leistungen hat der Lizenznehmer einen gesonderten
          Software-Pflegevertrag mit einem zertifiziertem Drittanbieter abzuschließen.
        </p>
        <p>
          7.2. Mängelansprüche entfallen, wenn der Lizenznehmer ohne vorherige Zustimmung des
          Lizenzgebers in Textform, Änderungen an der Software selbst oder durch einen Dritten
          vorgenommen hat, ohne hierfür die API oder die vom Lizenzgeber bereitgestellten Standard-
          Konfigurationsmöglichkeiten zu nutzen, oder wenn die Software nicht vertragsgemäß
          eingesetzt wird und die Änderung oder die vertragswidrige Nutzung für das Auftreten des
          Mangels allein verantwortlich ist. §§ 69d Abs. 1, 69e UrhG bleiben unberührt.
        </p>
        <p>8. Verfügbarkeit</p>
        <p>
          8.1. Der Lizenzgeber ist verpflichtet sicherzustellen, dass die effektive Verfügbarkeit
          der Software insgesamt 99,0% im Jahr nicht unterschreitet. Die effektive Verfügbarkeit (in
          Prozent) berechnet sich hierbei wie folgt: Effektive Verfügbarkeit = [Jährliche
          Bereitstellungzeit (h) – Jährliche Ausfallzeit] x 100 Jährliche Bereitstellungzeit (h)
        </p>
        <p>
          8.2. Der Lizenzgeber schuldet die Bereitstellungszeit im vorgenannten Sinne 365 Tage im
          Jahr sowie 24 Stunden am Tag.
        </p>
        <p>
          8.3. Die Ausfallzeit ist die Zeit, in der die Software nicht vertragsgemäß genutzt werden
          kann. Bei der Ermittlung der Ausfallzeit bleibt die Nichtverfügbarkeit der Software außer
          Betracht, wenn die Nichtverfügbarkeit aufgrund von Wartungsarbeiten erfolgt, die im
          Standardwartungsfenster durchgeführt werden welches nicht zwischen nicht zwischen Montag
          bis Freitag, 09:00 Uhr bis 18:00 Uhr liegt.
        </p>
        <h3>9. Haftung</h3>
        <p>
          9.1. Der Lizenzgeber haftet unbeschränkt • bei Vorsatz oder grober Fahrlässigkeit, • für
          die Verletzung von Leben, Leib oder Gesundheit, • nach den Vorschriften des
          Produkthaftungsgesetzes sowie • im Umfang einer vom Lizenzgeber übernommenen Garantie.
        </p>
        <p>
          9.2. Im Übrigen beschränkt sich die Haftung des Lizenzgebers auf die Höhe der mit dem
          Lizenznehmer monatlich vereinbarten Vergütung, bei laufenden Verträgen auf die Höhe von
          maximal 12 (zwölf) Monatsvergütungen.
        </p>
        <p>
          9.3. Die Haftung für entgangenen Gewinn und vergebliche Aufwendungen wird ausgeschlossen.
        </p>
        <p>
          9.4. Eine weitergehende Haftung des Lizenzgebers besteht nicht. Insbesondere besteht keine
          Haftung des Lizenzgebers für anfängliche Mängel, soweit nicht die Voraussetzungen der
          Ziffern 9.1 und 9.2 vorliegen.
        </p>
        <p>
          9.5. Der Lizenzgeber haftet für den Verlust von Daten nur bis zu dem Betrag, der bei
          ordnungsgemäßer und regelmäßiger Sicherung der Daten zu deren Wiederherstellung angefallen
          wäre.
        </p>
        <p>
          9.6. Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung der
          Mitarbeiter, Vertreter und Organe des Lizenzgebers.
        </p>
        <p>
          9.7. Schadenersatzansprüche verjähren innerhalb eines Jahres nach Kenntnis des Schadens
          durch den Lizenznehmer.
        </p>
        <h3>10. Geheimhaltung und Datenschutz</h3>
        <p>
          10.1. Der Lizenzgeber sichert zu, dass alle Tatsachen, die im Zusammenhang mit der
          Tätigkeit für den Lizenznehmer bekannt werden, insbesondere Informationen über Betriebs-
          und Geschäftsgeheimnisse sowie etwaige Informationen über Kunden oder Mitarbeiter des
          Lizenznehmers gemäß einschlägiger gesetzlicher Vorschriften geheim gehalten werden, d.h.
          ausschließlich zur Konfiguration der Software genutzt werden und keine Informationen an
          Dritte weitergegeben werden dürfen. Die Mitarbeiter des Lizenzgebers sind auf das
          Datengeheimnis verpflichtet.
        </p>
        <p>
          10.2. Der Lizenznehmer verpflichtet sich ebenfalls, das gesamte im Zusammenhang mit der
          Vertragserfüllung von dem Lizenzgeber erlangte geschäftliche, technische und
          wissenschaftliche Know-how vertraulich zu behandeln und Dritten nur mit ausdrücklicher
          Zustimmung des Lizenzgebers verfügbar zu machen.
        </p>
        <p>
          10.3. Die Empfängerpartei erkennt an, dass sie aus dem Besitz oder der Verwendung geheimer
          Informationen im Sinne der Ziffern 10.1 und 10.2 keinerlei Ansprüche, Eigentumsrecht oder
          sonstige Rechte an diesen Informationen ableiten wird und dass alle Änderungen,
          Verbesserungen bzw. Erweiterungen oder Anpassungen der Informationen Eigentum der die
          Information offenlegenden Partei sind. Ferner erkennen die Parteien an, dass nichts in
          dieser Vereinbarung der jeweils anderen Partei eine Lizenz an Rechten des geistigen
          Eigentums übertragen soll oder dahingehend auszulegen ist.
        </p>
        <p>10.4. Die Verpflichtung zur Geheimhaltung entfällt, soweit diese:</p>
        <p>• dem informierten Vertragspartner vor der Mitteilung nachweislich bekannt waren oder</p>
        <p>
          • der Öffentlichkeit vor der Mitteilung bekannt oder allgemein zugänglich waren oder der
          Öffentlichkeit nach der Mitteilung ohne Mitwirkung oder Verschulden des informierten
          Vertragspartners bekannt oder allgemein zugänglich werden oder
        </p>
        <p>
          • im wesentlichen Informationen entsprechen, die dem informierten Vertragspartner zu
          irgendeinem Zeitpunkt von einem berechtigten Dritten offenbart oder zugänglich gemacht
          werden oder
        </p>
        <p>
          10.5. Die Pflicht zur Geheimhaltung bleibt auch nach Kündigung des Vertrages bestehen. Bei
          Verstoß gegen die Einhaltung der Geheimhaltung wird eine Vertragsstrafe fällig deren Höhe
          vom Verletzten nach billigem Ermessen festzusetzen ist und im Streitfalle vom zuständigen
          Gericht auf deren Angemessenheit überprüft wird. Maßgeblich hierfür sind die Bedeutung der
          verletzten Pflicht, der Nachteil des Verletzten und der Grad der Pflichtverletzung und des
          Verschuldens des Verletzers. Die Geltendmachung darüber hinausgehender Unterlassungs- und
          Schadensersatzansprüche bleiben hiervon unberührt. Die Vertragsstrafe ist auf
          Schadensersatzansprüche anzurechnen. Die Geltendmachung darüber hinausgehender
          Unterlassungs- und Schadensersatzansprüche bleiben hiervon unberührt.
        </p>
        <p>
          10.6. Zum Schutz der Daten verpflichtet sich der Lizenzgeber, sich an die gesetzlichen
          Regelungen des Datenschutzes zu halten. Er verarbeitet die Daten ausschließlich nach
          Weisung des Lizenznehmers und schließt als Voraussetzung für den Einsatz der Software
          einen Vertrag über eine Datenverarbeitung im Auftrag ab.
        </p>
        <p>
          10.7. Der Lizenznehmer garantiert, dass seinerseits die rechtlichen Voraussetzungen in
          Bezug auf die Bearbeitung personenbezogener Daten gegeben sind, damit der Lizenzgeber
          seine Leistungen ohne die Verletzung datenschutzrechtlicher Bestimmungen erbringen kann.
        </p>
        <p>
          10.8. Der Lizenzgeber ist berechtigt, nach vorheriger Zustimmung des Lizenznehmers unter
          Verwendung dessen öffentlich zugänglichen Logos (z.B. auf der Website) auf die (online)
          Referenzliste zu nehmen. Der Lizenznehmer kann seine Zustimmung jederzeit schriftlich
          widerrufen.
        </p>
        <h3>11. Kündigung</h3>
        <p>
          11.1. Der Lizenznehmer kann den Vertrag mit einer Frist von einem Monat zum Monatsende
          kündigen.
        </p>
        <p>
          11.2. Das Recht zu außerordentlicher, fristloser Kündigung aus wichtigem Grund bleibt für
          jeden Vertragspartner unberührt. Als wichtiger Grund gilt insbesondere:
        </p>
        <p>
          • Der Lizenznehmer ist mit einer Zahlung trotz Mahnung und Fristsetzung über einen
          Zeitraum von mehr als 3 Monaten in Verzug;
        </p>
        <p>
          • einer der Vertragspartner verletzt wesentliche Vertragspflichten, beeinträchtigt durch
          diese Pflichtverletzung den anderen Vertragspartner erheblich und beseitigt die
          Vertragsverletzung trotz Aufforderung zum vertragsgemäßen Verhalten nicht innerhalb von 30
          Tagen
        </p>
        <p>
          11.3. Der Lizenzgeber kann den Vertrag aus wichtigem Grund mit einer Frist von 6 (sechs)
          Monaten zum Ende eines Kalenderjahres kündigen. Als wichtiger Grund im Sinne dieser Ziffer
          gilt, neben den in Ziffer 11.2 genannten Gründen, die Wirtschaftlichkeit des Produktes
          oder behördliche Sanktionen, die einen Betrieb des Produktes nicht unerheblich erschweren.
        </p>
        <p>11.4. Jede Kündigung bedarf zu ihrer Wirksamkeit der Textform.</p>
        <p>
          11.5. Regelungen zur Kündigung aus wichtigem Grund bleiben von den Regelungen zur
          Kündigung des Lizenzvertrags unberührt.
        </p>
        <p>
          11.6. Wird der Vertrag von einer der Parteien gekündigt, hat der Lizenznehmer zum
          Beendigungszeitpunkt die Möglichkeit zum selbständigen Datenexport. Gegen gesonderte
          Vergütung besteht die Möglichkeit der Übergabe der Daten durch den Lizenzgeber an den
          Lizenznehmer als sog. „Datenbank-Dump“ (CSV-Datei).
        </p>
        <h3>12. Höhere Gewalt</h3>
        <p>
          12.1. Ist eine Vertragspartei aufgrund höherer Gewalt ganz oder teilweise an der Erfüllung
          ihrer Leistungsverpflichtung aus diesem Vertrag gehindert oder kann sie die Erfüllung
          dieser Pflichten nicht mehr sicherstellen, so ist diese Partei für die Dauer und in dem
          Umfang, in dem die Höhere Gewalt die Leistungserbringung verhindert, von dieser
          Verpflichtung befreit.
        </p>
        <p>
          12.2. In jedem Fall liegt höhere Gewalt vor bei Kampfhandlungen (unabhängig davon, ob ein
          Krieg erklärt worden ist), Unruhen, Explosionen, Feuer, Flut, Erdbeben, Taifunen,
          Pandemien, Epidemien und bei arbeitsrechtlichen Streitigkeiten, aufgrund derer der
          Geschäftsbetrieb vollständig oder überwiegend zum Erliegen kommt, sowie bei Handlungen,
          Unterlassungen oder Maßnahmen einer Regierung oder beim Befolgen staatlicher
          Aufforderungen und bei der Störung von Betriebsanlagen oder Teilen davon, die zur
          Erfüllung von Verpflichtungen dieses Vertrages dienen.
        </p>
        <p>
          12.3. Im Fall des Eintritts höherer Gewalt haben sich die Parteien hiervon unverzüglich zu
          unterrichten und innerhalb von 15 (fünfzehn) Tagen detaillierte Informationen insbesondere
          über den Umfang und, soweit in zumutbarer Weise möglich, die voraussichtliche Dauer der
          höheren Gewalt vorzulegen.
        </p>
        <h3>13. Schlussbestimmungen</h3>
        <p>
          13.1. Der Lizenzgeber behält sich das Recht vor, solange es nicht den Leistungsumfang im
          Anhang verringert oder beeinträchtigt, jederzeit Fehlerbehebungs- und Anpassungsleistungen
          auszuführen, insbesondere:
        </p>
        <p>
          • Veränderungen an der Software, die nicht Gegenstand der Pflegeleistungen sind,
          insbesondere Anpassung an neue Produkte und Services sowie an geänderte Betriebsabläufe
          des Lizenzgebers;
        </p>
        <p>
          • Anpassung der Software neuer Programmversionen (z.B. neue Releases, Updates/Upgrades)
          von im systemverwendeter Drittsoftware;
        </p>
        <p>
          • Sonstige Anpassungen, Ergänzungen und Erweiterungen der Software. Der Lizenznehmer darf
          die Erbringung sonstiger Fehlerbehebungs- und Anpassungsleistungen nur verweigern, wenn
          ihm deren Ausführung im Rahmen seiner betrieblichen Leistungsfähigkeit nachweislich
          unzumutbar ist.
        </p>
        <p>
          Änderungen werden dem Lizenznehmer in geeigneter Weise mitgeteilt und gelten ohne
          schriftlichen Widerspruch innerhalb einer Frist von einem Monat nach Bekanntgabe, auf
          jeden Fall aber bei Nutzung der Software nach Ablauf der 30-tägigen Widerspruchsfrist, als
          genehmigt. Sollte der Lizenznehmer durch die Änderung erheblich benachteiligt sein, so ist
          er berechtigt, den Vertrag mit Inkrafttreten der Änderung zu kündigen. Das Kündigungsrecht
          erlischt mit Inkrafttreten der Änderung.
        </p>
        <p>
          13.2. Der Lizenzgeber hat das Recht, sich zur Erfüllung dieses Vertrags Subunternehmern zu
          bedienen.
        </p>
        <p>
          13.3. Eine Aufrechnung ist nur gegenüber unbestrittenen oder rechtskräftig festgestellten
          Forderungen des Lizenzgebers statthaft.
        </p>
        <p> 13.4. Sämtliche in diesem Vertrag genannten Anlagen sind Vertragsbestandteil.</p>
        <p>
          13.5. Diese Bedingungen unterstehen ausschließlich dem Recht der Bundesrepublik
          Deutschland. Die Anwendung des UN-Kaufrechts (United Nations Convention on Contracts for
          the International Sale of Goods vom 11. 4. 1980) wird ausgeschlossen.
        </p>
        <p>
          13.6. Die Parteien vereinbaren Freiburg i.Brsg. als ausschließlichen Gerichtsstand für
          sämtliche Streitigkeiten aus und im Zusammenhang mit diesem Vertrag.
        </p>
        <p>
          13.7. Der Lizenzgeber behält sich nach Maßgabe der nachfolgenden Bestimmungen das Recht
          vor, die Anlagen zu ändern, sofern diese Änderung unter Berücksichtigung der Interessen
          des Lizenznehmers, aus Sicht des Lizenzgebers zumutbar ist. Die geänderten Anlagen sind
          einsehbar im Jostec Systems UG (haftungsbeschränkt) Partnerportal. Im Übrigen wird der
          Lizenzgeber den Lizenznehmer vor einer Veröffentlichung der geänderten Anlagen mit
          angemessenem Vorlauf, mindestens jedoch einen Monat vor Veröffentlichung informieren. Die
          Information erfolgt an die vom Lizenznehmer benannte Email-Adresse. Sollte der
          Lizenznehmer mit einer vom Lizenzgeber beabsichtigten Änderung nicht einverstanden sein,
          hat er das Recht, der Änderung innerhalb eines Monats nach Mitteilung zu widersprechen.
          Wenn er fristgerecht widerspricht, ist der Lizenzgeber berechtigt, den Vertrag mit einer
          Frist von einem Monat zum Ende des Kalendermonats schriftlich zu kündigen.
        </p>
      </div>
    </div>
  );
}
