import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDialog from '../Helper/MyDialog';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import projectService from '../../services/projectService';
import projectStatesService from '../../services/projectStatesService';

interface Props {
  project: any;
  fetchData: any;
}

export default function UpdateState(props: Props) {
  const { t } = useTranslation(['itemDialog', 'projects']);
  const { enqueueSnackbar } = useSnackbar();

  const [projectStates, setProjectStates] = useState<string[]>();
  const [projectState, setProjectState] = useState<any>(props?.project?.state);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.stopPropagation();
    setOpen(true);
    projectStatesService.all().then((result) => setProjectStates(result));
  };

  async function saveItem() {
    if (projectState) {
      await projectService
        .updateState(props?.project?.id!, projectState)
        .then(() => {
          enqueueSnackbar(t('detail.stateChangeSuccessful', { ns: 'projects' }), {
            variant: 'success'
          });
          props.fetchData();
        })
        .catch(() => {
          enqueueSnackbar(t('projects:detail.failedProcedureUpdatingState'), { variant: 'error' });
          setProjectState(props?.project?.state);
        });

      setError(false);
      return true;
    } else setError(true);
    return false;
  }

  return (
    <>
      <Button
        disabled={props?.project?.state == 'Finished'}
        style={{ margin: 5 }}
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<EditIcon />}>
        {t('detail.changeProjectState', { ns: 'projects' })}
      </Button>
      <CreateDialog
        noBackdrop
        isEdit
        title={t('detail.changeProjectState', { ns: 'projects' })}
        saveItem={saveItem}
        open={open}
        setOpen={setOpen}
        withActions>
        <Paper
          style={{
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
          elevation={0}>
          <FormControl style={{ flex: 1 }} fullWidth required>
            <InputLabel>{t('state', { ns: 'projects' })}</InputLabel>
            <Select
              required
              label={t('state', { ns: 'projects' })}
              error={error}
              value={projectState}
              onChange={(e) => setProjectState(e.target.value)}>
              {projectStates?.map((state) => (
                <MenuItem key={state} value={state}>
                  {t('states.' + state, { ns: 'projects' })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      </CreateDialog>
    </>
  );
}
