import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import StyledDropzone from '../components/Helper/Dropzone';
import exportService from '../services/exportService';
import { useSnackbar } from 'notistack';
import { ImporterInformation } from '../objects/export';
import { useTranslation } from 'react-i18next';
import TokenService from '../services/tokenService';

export default function ImportExport() {
  const { t } = useTranslation(['export']);
  const [exporterTypes, setExporterTypes] = useState<Map<string, string[]>>();
  const [importerTypes, setImporterTypes] = useState<ImporterInformation[]>();

  const [currentImportOptions, setCurrentImportOptions] = useState<any>();
  const [currentImportFile, setCurrentImportFile] = useState<any>();
  const [currentImportInformation, setCurrentImportInformation] = useState<ImporterInformation>();

  const canImport = TokenService.tenantFullfills(['Export'], ['ImportExport:Import']);
  const canExport = TokenService.tenantFullfills(['Export'], ['ImportExport:Export']);

  useEffect(() => {
    if (currentImportInformation == null) {
      setCurrentImportOptions(undefined);
      setCurrentImportFile(undefined);
    }
  }, [currentImportInformation]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      await exportService.getExporters().then((result) => {
        let map = new Map<string, string[]>();
        Array.from(Object.entries(result.data)).forEach((entry) => {
          map.set(entry[0], entry[1] as string[]);
        });
        setExporterTypes(map);
      });
      await exportService.getImporters().then((result) => setImporterTypes(result));
    })();
  }, []);

  function ExportItem(type: string, name: string) {
    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 10,
          padding: 10
        }}
        elevation={1}>
        <div>
          <Typography
            style={{
              backgroundColor: 'rgb(167, 29, 49)',
              borderRadius: 5,
              marginBottom: 5,
              padding: 5,
              color: 'white',
              textAlign: 'center'
            }}>
            {t(name)}
          </Typography>
        </div>
        <div
          style={{
            flex: 1
          }}>
          <Button
            onClick={() => {
              exportService
                .export(type, name)
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `export-${name}-${new Date().toISOString()}.${type}`
                  );
                  document.body.appendChild(link);
                  link.click();
                  enqueueSnackbar(t('exportSuccess'), { variant: 'success' });
                })
                .catch(() => enqueueSnackbar(t('exportFailure'), { variant: 'success' }));
            }}>
            {t('download')}
          </Button>
        </div>
      </Paper>
    );
  }
  function ImportItem(information: ImporterInformation) {
    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 10,
          padding: 10
        }}
        elevation={1}>
        <div>
          <Typography
            style={{
              backgroundColor: 'rgb(167, 29, 49)',
              borderRadius: 5,
              marginBottom: 5,
              padding: 5,
              color: 'white',
              textAlign: 'center'
            }}>
            {t(information.name)}
          </Typography>
        </div>
        <div
          style={{
            flex: 1
          }}>
          <StyledDropzone
            accept={information.type + '/*'} //TODO fix accept to only accept correct type
            onDrop={(files: any) => {
              setCurrentImportFile(files[0]);
              setCurrentImportOptions({});
              setCurrentImportInformation(information);
            }}
          />
        </div>
      </Paper>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        height: '100%'
      }}>
      <>
        {canImport && (
          <>
            <Paper style={{ padding: 10, backgroundColor: 'transparent' }} variant="outlined">
              <h2 style={{ margin: 5 }}>Export</h2>
              <div style={{ display: 'flex' }}>
                {Array.from(exporterTypes?.entries() ?? []).map((type) => {
                  return type[1].map((name) => ExportItem(type[0], name));
                })}
              </div>
            </Paper>
            <Dialog
              open={currentImportInformation != null}
              onClose={() => setCurrentImportInformation(undefined)}
              maxWidth={'lg'}>
              <DialogTitle>{t('import.configurationHeader')}</DialogTitle>
              <DialogContent dividers>
                {Array.from(currentImportInformation?.options?.entries() ?? []).map((entry) => {
                  return (
                    <div key={entry[0]} style={{ display: 'flex', flexDirection: 'row' }}>
                      {entry[1] == 'String' && (
                        <TextField
                          style={{ marginBottom: 10 }}
                          fullWidth
                          label={t(
                            `import.${currentImportInformation?.type}.${currentImportInformation?.name}.${entry[0]}`,
                            {
                              defaultValue: entry[0]
                            }
                          )}
                          variant="outlined"
                          value={currentImportOptions[entry[0]] ?? ''}
                          onChange={(e) =>
                            setCurrentImportOptions({
                              ...currentImportOptions,
                              ...{ [entry[0]]: e.target.value }
                            })
                          }
                        />
                      )}
                      {entry[1] == 'Number' && (
                        <TextField
                          style={{ marginBottom: 10 }}
                          fullWidth
                          type="number"
                          label={t(
                            `import.${currentImportInformation?.type}.${currentImportInformation?.name}.${entry[0]}`,
                            {
                              defaultValue: entry[0]
                            }
                          )}
                          variant="outlined"
                          value={currentImportOptions[entry[0]] ?? 0}
                          onChange={(e) =>
                            setCurrentImportOptions({
                              ...currentImportOptions,
                              ...{ [entry[0]]: e.target.value }
                            })
                          }
                        />
                      )}
                      {entry[1] == 'Boolean' && (
                        <FormControlLabel
                          label={
                            <Typography>
                              {t(
                                `import.${currentImportInformation?.type}.${currentImportInformation?.name}.${entry[0]}`,
                                {
                                  defaultValue: entry[0]
                                }
                              )}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={currentImportOptions[entry[0]] ?? false}
                              onChange={(e, checked) =>
                                setCurrentImportOptions({
                                  ...currentImportOptions,
                                  ...{ [entry[0]]: checked }
                                })
                              }
                            />
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={async (e: any) => {
                    e.stopPropagation();
                    if (currentImportInformation == null) return;
                    exportService
                      .import(currentImportInformation, currentImportOptions, currentImportFile)
                      .then(() => {
                        enqueueSnackbar(t('importSuccess'), {
                          variant: 'success'
                        });
                        setCurrentImportInformation(undefined);
                      })
                      .catch(() => {
                        enqueueSnackbar(t('importFailure'), {
                          variant: 'error'
                        });
                        setCurrentImportInformation(undefined);
                      });
                  }}>
                  Import
                </Button>
                <Button onClick={() => setCurrentImportInformation(undefined)}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {canExport && (
          <Paper style={{ padding: 10, backgroundColor: 'transparent' }} variant="outlined">
            <h2 style={{ margin: 5 }}>Import</h2>
            <div style={{ display: 'flex' }}>{importerTypes?.map(ImportItem)}</div>
          </Paper>
        )}
      </>
    </div>
  );
}
